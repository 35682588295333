import React, { useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import { Box, useTheme, Typography, InputBase, useScrollTrigger } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useBreakpoints from '@/helpers/useBreakpoints';
import { Category, NewsState } from '@/types/news';

import CategoryBusinessDialog from './CategoryBusinessDialog';
import SearchResultBox from './SearchResultBox';

interface CategoryBusinessNewsProps {
  category: Category[];
  newsState: NewsState;
  updateNewsState: (newState: Partial<NewsState>) => void;
}

const CategoryBusinessNews: React.FC<CategoryBusinessNewsProps> = ({ category, newsState, updateNewsState }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const trigger = useScrollTrigger();
  const navigate = useNavigate();
  const location = useLocation();

  const [openCategory, setOpenCategory] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenCategory(true);
  };

  const handleCloseDialog = () => {
    setOpenCategory(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateNewsState({ searchValue: event.target.value });
    if (event.target.value === '') {
      updateNewsState({
        listFilterModal: [],
        listFilterPage: [],
        enterKeyPressed: false,
      });
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      updateNewsState({
        enterKeyPressed: true,
        listFilterModal: [],
        currentPage: 1,
        currentPageFilter: 1,
      });
    }
  };

  const handleClearFilter = () => {
    updateNewsState({
      searchValue: '',
      listFilterModal: [],
      listFilterPage: [],
      currentPageFilter: 1,
      enterKeyPressed: false,
    });
    if (newsState.enterKeyPressed && !location.pathname.includes('/news/business')) {
      navigate('/news/business');
      updateNewsState({
        listNews: [],
        currentPage: 1,
      });
    }
  };

  useEffect(() => {
    if (newsState.searchValue !== '' && !newsState.enterKeyPressed) {
      updateNewsState({
        searchValue: '',
        listFilterModal: [],
      });
    }
  }, [trigger]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: { xs: '75px', md: '90px' },
          display: 'flex',
          transform: trigger ? 'translateY(-180px)' : 'translateY(0px)',
          flexDirection: 'column',
          zIndex: 10,
          backgroundColor: 'background.menu',
          padding: { xs: 1, sm: 0, md: 1.5 },
          transition: 'all 0.6s ease-in-out 0s',
          borderRadius: 1,
          maxWidth: '1200px',
          alignSelf: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: { xs: '92%', sm: '96%' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10,
            transition: 'all 500ms cubic-bezier(0, 0.11, 0, 1.15)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isMobile ? (
              <MenuIcon
                sx={{
                  fontSize: '2rem',
                  cursor: 'pointer',
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
                onClick={handleClickOpenDialog}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: { lg: '80%', md: '90%' },
                  padding: '0px 24px 0px 16px',
                }}
              >
                <Box
                  sx={{
                    textDecoration: 'none',
                    position: 'relative',
                    '&:not(:first-of-type)': {
                      ml: { md: 2, lg: '30px' },
                    },
                  }}
                  onClick={() => {
                    if (location.pathname !== '/news/business') {
                      navigate('/news/business');
                      updateNewsState({
                        currentPage: 1,
                        listNews: [],
                        hasMore: true,
                        searchValue: '',
                        listFilterModal: [],
                        listFilterPage: [],
                        currentPageFilter: 1,
                        enterKeyPressed: false,
                      });
                    } else {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <Box
                    sx={{
                      color: theme.palette.text.secondary,
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      letterSpacing: { xs: 0, md: '0.3px' },
                    }}
                  >
                    <Typography
                      sx={{
                        padding: { sm: 2, md: 0.5 },
                        fontWeight: 700,
                      }}
                    >
                      {t('home.label.news.business')}
                    </Typography>
                  </Box>
                </Box>
                {(isTablet ? category.slice(0, 4) : category).map((categoryItem: Category) => (
                  <Box
                    key={categoryItem.id}
                    sx={{
                      textDecoration: 'none',
                      position: 'relative',
                      '&:not(:first-of-type)': {
                        ml: { md: 2, lg: '30px' },
                      },
                    }}
                    onClick={() => {
                      if (location.pathname !== `/news/${categoryItem.path}`) {
                        navigate(`/news/${categoryItem.path}`);
                        updateNewsState({
                          currentPage: 1,
                          listNews: [],
                          hasMore: true,
                          searchValue: '',
                          listFilterModal: [],
                          listFilterPage: [],
                          currentPageFilter: 1,
                          enterKeyPressed: false,
                        });
                      } else {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <Box
                      sx={{
                        color: location.pathname?.includes(`/${categoryItem.path}`)
                          ? '#4ec0e9'
                          : theme.palette.text.secondary,
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        letterSpacing: { xs: 0, md: '0.3px' },
                        ':hover': {
                          color: '#4ec0e9',
                        },
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          width: '100%',
                          height: '2px',
                          bgcolor: '#4ec0e9',
                          top: { sm: '80%', md: '100%' },
                          left: 0,
                          transform: location.pathname?.includes(`/${categoryItem.path}`) ? 'scaleX(1)' : 'scaleX(0)',
                          transformOrigin: 'right',
                          transition: 'transform 0.5s',
                        },
                        '&:hover::after': {
                          transform: 'scaleX(1)',
                          transformOrigin: 'left',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '1.3rem',
                          padding: { sm: 2, md: 0.5 },
                          fontWeight: 700,
                        }}
                      >
                        {categoryItem.label}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                {isTablet && category.length > 4 && (
                  <Box>
                    <MoreHorizIcon
                      sx={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'primary.main',
                        '&:hover': {
                          color: 'primary.dark',
                        },
                      }}
                      onClick={handleClickOpenDialog}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box
              sx={{
                position: 'relative',
                paddingLeft: { xs: 0, sm: 4 },
                paddingRight: { xs: 0, sm: 2 },
                mt: '5px',
                cursor: 'pointer',
                '&:hover .search-input, &:focus-within .search-input': {
                  width: '250px',
                  opacity: 1,
                  visibility: 'visible',
                },
              }}
            >
              <Box
                className="search-input"
                sx={{
                  position: 'absolute',
                  right: { xs: '0px', sm: '5px', md: '-1px' },
                  top: '-6px',
                  width: '150px',
                  display: 'flex',
                  backgroundColor: 'background.main',
                  border: `1px solid ${theme.palette.divider}`,
                  padding: { xs: 0, sm: 0.5 },
                  borderRadius: '5px',
                  opacity: newsState.searchValue ? 1 : 0,
                  visibility: newsState.searchValue ? 'visible' : 'hidden',
                  transition: ' all 0.4s ease',
                }}
              >
                <InputBase
                  autoFocus
                  sx={{
                    ml: 1,
                    flex: 1,
                    input: {
                      '&::placeholder': {
                        color: 'text.primary',
                        fontWeight: 400,
                        fontSize: '1.2rem !important',
                      },
                    },
                  }}
                  placeholder={t('placeholder.typeSearch')}
                  inputProps={{
                    'aria-label': 'filter news',
                    style: { fontSize: '1.4rem' },
                  }}
                  disabled={newsState.enterKeyPressed}
                  endAdornment={
                    newsState.searchValue && (
                      <ClearIcon
                        onClick={() => {
                          handleClearFilter();
                        }}
                        sx={{
                          mr: 1,
                          color: 'text.disabled',
                          '&:hover': {
                            cursor: 'pointer',
                            color: 'text.secondary',
                          },
                        }}
                      />
                    )
                  }
                  value={newsState.searchValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
              </Box>
              <SearchIcon
                sx={{
                  mr: { xs: 0, sm: 1 },
                  fontSize: { xs: '2rem', sm: '2.5rem' },
                  color: 'text.secondary',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Box>
        </Box>
        {newsState.searchValue && !newsState.enterKeyPressed && (
          <SearchResultBox newsState={newsState} updateNewsState={updateNewsState} />
        )}
      </Box>

      <CategoryBusinessDialog
        open={openCategory}
        onClose={handleCloseDialog}
        updateNewsState={updateNewsState}
        categories={category}
      />
    </>
  );
};
export default CategoryBusinessNews;
