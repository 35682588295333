import { Card, CardHeader, Skeleton } from '@mui/material';

const SkeletonItemSocial = () => {
  return (
    <Card sx={{ backgroundColor: 'background.main' }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={15} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={15} width="80%" style={{ marginBottom: 6 }} />}
      />
    </Card>
  );
};
export default SkeletonItemSocial;
