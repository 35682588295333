import { useTheme, Box, Typography } from '@mui/material';
import * as _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import ShowMoreText from 'react-show-more-text';
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';

import useBreakpoints from '@/helpers/useBreakpoints';
import { DANGEROUS_PORT } from '@/utils/constants';

interface PortModalProps {
  port: any;
}

const PortModal: React.FC<PortModalProps> = ({ port }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isTablet } = useBreakpoints();

  // chuyển version sang object
  const updatedData = port?.map((item: any) => {
    return {
      ...item,
      version: { 0: item.version },
    };
  });

  // gộp extra_info với version, xóa value rỗng

  const mergedVersion = updatedData?.map((item: any) => {
    const mergedData: any = { ...item?.version, ...item.extra_info };
    const filteredData: any = {};
    for (const key in mergedData) {
      if (mergedData[key]?.trim() !== '') {
        filteredData[key] = mergedData[key];
      }
    }
    return {
      ...item,
      mergedVersion: filteredData,
    };
  });

  const transformedData = mergedVersion?.map((item: any) => ({
    // Ghép cột "port" và "protocol" vào một cột mới "portProtocol"
    portProtocol: item.port ? `${item.port}/${item.protocol}` : '-',
    state: item.state,
    service: item.service,
    version: item?.version,
    extra_info: item.extra_info,
    mergedVersion: item.mergedVersion,
  }));

  const columns = [
    {
      name: 'portProtocol',
      label: 'Port',
      options: {
        responsive: 'standard',
        customBodyRender: (value: any) => {
          const portProtocolParts = value?.split('/');
          const portUp = portProtocolParts[0];
          const isDangerous = DANGEROUS_PORT.includes(+portUp);
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {isDangerous ? (
                  <>
                    <Typography sx={{ color: 'text.primary', fontSize: isTablet ? '1.2rem' : '1.4rem' }}>
                      {value}
                    </Typography>
                    {isDangerous ? <UseAnimations strokeColor="#f44336" animation={alertCircle} size={30} /> : ''}
                  </>
                ) : (
                  <Typography sx={{ color: 'text.primary', fontSize: isTablet ? '1.2rem' : '1.4rem' }}>
                    {value}
                  </Typography>
                )}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        responsive: 'standard',
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'service',
      label: 'Service',
      options: {
        responsive: 'standard',
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'mergedVersion',
      label: 'Version',
      options: {
        filter: false,
        responsive: 'standard',
        setCellProps: () => ({ style: { width: '50%' } }),
        customBodyRender: (value: {}) => {
          return (
            <>
              {Object.entries(value).map(([key, valueSub]) => (
                <Box
                  sx={{
                    wordWrap: 'break-word',
                    padding: '2px',
                    '&:not(:last-of-type)': {
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    },
                  }}
                  key={key}
                >
                  <ShowMoreText
                    lines={2}
                    more={
                      <span style={{ cursor: 'pointer', color: `${theme.palette.info.main}`, fontSize: '1.2rem' }}>
                        {t('tooltip.showMore')}
                      </span>
                    }
                    less={
                      <span style={{ cursor: 'pointer', color: `${theme.palette.info.main}`, fontSize: '1.2rem' }}>
                        {t('tooltip.showLess')}
                      </span>
                    }
                    width={600}
                  >
                    <Typography
                      sx={{
                        lineHeight: '20px',
                        fontSize: isTablet ? '1.2rem' : '1.4rem',
                        color: `${theme.palette.text.primary}`,
                      }}
                    >
                      {valueSub ? `- ${valueSub}` : '-'}
                    </Typography>
                  </ShowMoreText>
                </Box>
              ))}
              {_.isEmpty(value) && '-'}
            </>
          );
        },
      },
    },
  ];

  const options = {
    enableNestedDataAccess: '.',
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    pagination: false,
    download: false,
    print: false,
    search: false,
    filter: false,
    // search: port?.length > 5,
    // filter: port?.length > 5,
  };

  return <MUIDataTable title="" data={transformedData} columns={columns} options={options as any} />;
};
export default PortModal;
