/* eslint-disable @typescript-eslint/naming-convention */
import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import DateRange from '@/components/input/DateRange';
import SkeletonItemSocial from '@/components/Skeleton/SkeletonItemSocial';
import Detail from '@/components/Social/statisticsOverview/Detail';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocial from '@/Hooks/fetchApi/useSocial';
import {
  TopicTypes,
  FeatureArticleTypes,
  FeatureSourceTypes,
  KeywordTypes,
  MentionedBySourceTypes,
  SentimentStatisticTypes,
  StatisticalByTimeTypes,
  TotalMentionsTypes,
  SentimentByTimeStatisticTypes,
} from '@/types/Social';
import { PAGE_SIZE } from '@/utils/constants';

const StatisticsOverview = (): ReactElement => {
  const { t } = useTranslation();
  const { timeScan } = useSocialInfo();
  const { apiError } = useCommonInfo();
  const { handleListTopic, handleGetStatisticalSocial } = useSocial();

  const listSource = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'forum', label: t('smcc.overview.forum') },
    { value: 'tiktok', label: 'Tiktok' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'newspaper', label: t('smcc.overview.news') },
  ];

  const [loading, setLoading] = useState(false);
  const [valueTab, setValueTab] = useState('all');
  const [listTopic, setListTopic] = useState<TopicTypes[]>([]);
  const [source, setSource] = useState<string[]>(listSource.map((option) => option.value));
  const [listKeywords, setListKeywords] = useState<KeywordTypes[]>([]);
  const [sentimentStatistic, setSentimentStatistic] = useState<SentimentStatisticTypes[]>([]);
  const [mentionedBySource, setMentionedBySource] = useState<MentionedBySourceTypes[]>([]);
  const [totalMentions, setTotalMentions] = useState<TotalMentionsTypes[]>([]);
  const [statisticalByTime, setStatisticalByTime] = useState<StatisticalByTimeTypes[]>([]);
  const [sentimentByTime, setSentimentByTime] = useState<SentimentByTimeStatisticTypes[]>([]);
  const [listArticles, setListArticles] = useState<FeatureArticleTypes[]>([]);
  const [listSources, setListSources] = useState<FeatureSourceTypes[]>([]);

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (timeScan) {
      handleGetStatistical();
    }
  }, [timeScan, valueTab, source]);

  const getListTopic = async () => {
    const params = {
      keyword: '',
      page: 1,
      page_size: PAGE_SIZE,
    };
    const res = await handleListTopic(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_topic } = res;
    setListTopic(list_topic);
  };

  const handleGetStatistical = async () => {
    setLoading(true);
    const params = {
      topic: valueTab,
      source,
      time_range: timeScan,
    };
    const res = await handleGetStatisticalSocial(params, handleLoading);
    const { data } = res;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      keywords,
      sentiment,
      mentioned_topics,
      total_mentions,
      statistical_time,
      sentiment_time,
      featured_articles,
      featured_source,
    } = data;

    setListKeywords(keywords);
    setSentimentStatistic(sentiment);
    setMentionedBySource(mentioned_topics);
    setTotalMentions(total_mentions);
    setStatisticalByTime(statistical_time);
    setSentimentByTime(sentiment_time);
    setListArticles(featured_articles);
    setListSources(featured_source);
  };

  useEffect(() => {
    getListTopic();
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const handleChangeSource = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.length !== 0) {
      setSource(value);
    }
  };

  const isAllSelected = source.length === listSource.length;

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('smcc.statisticsOverview')}
      </Typography>

      <Box sx={{ mt: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label={t('smcc.all')} value="all" />
            {listTopic.map((topic: TopicTypes) => (
              <Tab key={topic.topic_id} label={topic.topic_name} value={topic.topic_id} />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box sx={{ minWidth: 120, padding: '7px 15px', fontSize: '1.2rem' }}>
              <FormControl size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  value={source}
                  onChange={handleChangeSource}
                  renderValue={
                    (selected) =>
                      isAllSelected
                        ? t('smcc.allSources')
                        : (selected as string[])
                            .map((value) => listSource.find((option) => option.value === value)?.label)
                            .join(', ')
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  {listSource.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={source.indexOf(option.value) > -1} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, mt: { xs: 1, sm: 0 } }}
          >
            <DateRange />
          </Grid>
        </Grid>
        {!loading ? (
          <Detail
            listKeywords={listKeywords}
            sentimentStatistic={sentimentStatistic}
            mentionedBySource={mentionedBySource}
            totalMentions={totalMentions}
            statisticalByTime={statisticalByTime}
            sentimentByTime={sentimentByTime}
            listArticles={listArticles}
            listSources={listSources}
          />
        ) : (
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item sm={6} xs={12}>
              <SkeletonItemSocial />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SkeletonItemSocial />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
export default StatisticsOverview;
