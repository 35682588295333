import { Tab, styled } from '@mui/material';

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'capitalize',
  color: 'text.primary',
  '&.Mui-selected': {
    color: 'primary.main',
  },
  transition: 'color 0.4s ease',
  '&:hover, &.Mui-focusVisible': {
    color: 'text.secondary',
  },
  '&.MuiTab-root': {
    fontSize: '1.6rem',
    fontWeight: 500,
    '&:not(:last-of-type)': {
      marginRight: '1rem',
    },
  },
}));
export default StyledTab;
