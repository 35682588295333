/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Link, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { TwitterPostsTypes } from '@/types/Social';

import ReactionsTwitter from './ReactionsTwitter';
import TitlePostsTwitter from './TitlePostsTwitter';

interface PostsTwitterDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: TwitterPostsTypes;
}

const PostsTwitterDetail: React.FC<PostsTwitterDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.tweet_link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <TitlePostsTwitter item={detailSelected} />
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                letterSpacing: '0.01em',
              }}
            >
              <HighlightTextSocial text={detailSelected?.post_content} keyword={topicSocial?.key_word_main} />
            </Typography>
            {!_.isEmpty(detailSelected?.image) && (
              <Box
                component="img"
                src={detailSelected?.image}
                sx={{
                  mt: 2,
                  height: '400px',
                  borderRadius: 2,
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
              />
            )}
          </Box>
          <Divider sx={{ mt: 5 }} />
          <ReactionsTwitter item={detailSelected} />
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsTwitterDetail;
