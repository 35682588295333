import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import Header from './Header';

interface SecurityAssessmentLayoutProps {
  children: ReactNode;
}

const SecurityAssessmentLayout: React.FC<SecurityAssessmentLayoutProps> = ({ children }) => {
  return (
    <div>
      <Header />
      <Box
        sx={{
          padding: '60px 20px',
          minHeight: '100vh',
          backgroundColor: 'background.dark',
        }}
      >
        <Box>{children}</Box>
      </Box>
    </div>
  );
};

export default SecurityAssessmentLayout;
