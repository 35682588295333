import { useCommonInfo } from '@/contexts/Common';
import { actionType } from '@/contexts/Common/action';

const useApiError = () => {
  const { dispatch } = useCommonInfo();
  const setApiError = (apiError: any) => {
    dispatch({
      type: actionType.CALL_API_ERROR,
      apiError,
    });
  };

  return { setApiError };
};
export default useApiError;
