import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface IFormatTimeNews {
  publishDate: string;
}

const FormatTimeNews: React.FC<IFormatTimeNews> = ({ publishDate }) => {
  const now = moment();
  const publishedAt = moment(publishDate);
  const diffInMinutes = now.diff(publishedAt, 'minutes');
  const { t } = useTranslation();

  if (diffInMinutes < 1) {
    return <>{t('time.justNow')}</>;
  }
  if (diffInMinutes < 60) {
    return <>{`${diffInMinutes} ${t('time.minutesAgo')}`}</>;
  }
  if (diffInMinutes < 1440) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return <>{`${diffInHours} ${t('time.hoursAgo')}`}</>;
  }
  if (diffInMinutes < 43200) {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return <>{`${diffInDays} ${t('time.daysAgo')}`}</>;
  }
  if (diffInMinutes < 525600) {
    const diffInMonths = Math.floor(diffInMinutes / 43200);
    return <>{`${diffInMonths} ${t('time.monthsAgo')}`}</>;
  }
  const diffInYears = Math.floor(diffInMinutes / 525600);
  return <>{`${diffInYears} ${t('time.yearsAgo')}`}</>;
};

export default FormatTimeNews;
