/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import {
  Avatar,
  AvatarGroup,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import likeIcon from '@/assets/image/facebook-like.png';
import loveIcon from '@/assets/image/facebook-love.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { FacebookCommentsTypes, FacebookPostsTypes } from '@/types/Social';

import TitlePostsFacebook from './TitlePostsFacebook';

interface PostsFacebookDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: FacebookPostsTypes;
}

const PostsFacebookDetail: React.FC<PostsFacebookDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <TitlePostsFacebook item={detailSelected} />
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                letterSpacing: '0.01em',
              }}
            >
              <HighlightTextSocial text={detailSelected?.post_content} keyword={topicSocial?.key_word_main} />
            </Typography>
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction="row">
              <AvatarGroup max={2}>
                <Avatar alt="Like Icon" src={likeIcon} sx={{ width: 19, height: 19 }} />
                <Avatar alt="Love Icon" src={loveIcon} sx={{ width: 19, height: 19 }} />
              </AvatarGroup>
              <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>
                {detailSelected?.reactions}
              </Typography>
            </Stack>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography sx={{ color: 'text.secondary', fontSize: '14px' }}>{detailSelected?.comments}</Typography>
              <Typography sx={{ ml: 0.5, color: 'text.secondary', fontSize: '14px' }}>{t('smcc.comment')}</Typography>
            </Box>
          </Box>

          <Divider sx={{ mt: 3, mb: 2 }} />
        </Box>

        <Box sx={{ mt: 1, ml: 2, mb: 2 }}>
          {!_.isEmpty(detailSelected) && (
            <>
              {detailSelected?.comments_preview.map((item: FacebookCommentsTypes, index: number) => (
                <Stack key={index} direction="row" spacing={2} sx={{ mt: 1.5 }}>
                  <Link href={`https://facebook.com/${item?.username}`} target="_blank" rel="noopener">
                    <Avatar sx={{ width: '30px', height: '30px', mt: 1 }} />
                  </Link>
                  <Box sx={{ padding: 1.5, backgroundColor: 'action.hover', borderRadius: 1, width: '100%' }}>
                    <Link
                      href={`https://facebook.com/${item?.username}`}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {item?.name || 'User Facebook'}
                      </Typography>
                    </Link>
                    <Typography variant="body2">{item?.comment}</Typography>
                  </Box>
                </Stack>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsFacebookDetail;
