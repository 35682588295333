import { useEffect, useRef } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import newsDefault from '@/assets/image/news-default.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { NewsPostsTypes } from '@/types/Social';

import TitlePostsNews from './TitlePostsNews';

interface PostsNewsDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: NewsPostsTypes;
}

const PostsNewsDetail: React.FC<PostsNewsDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2, mb: 3 }}>
          <TitlePostsNews item={detailSelected} />
          <Box sx={{ mt: 3, cursor: 'pointer' }}>
            <Grid container spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={8} sm={9}>
                <Typography
                  sx={{
                    letterSpacing: '0.01em',
                    fontWeight: 600,
                  }}
                >
                  <HighlightTextSocial text={detailSelected.title} keyword={topicSocial?.key_word_main} />
                </Typography>
                <Typography
                  sx={{
                    mt: 2,

                    letterSpacing: '0.01em',
                    fontWeight: 600,
                  }}
                >
                  <HighlightTextSocial text={detailSelected.summary} keyword={topicSocial?.key_word_main} />
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  component="img"
                  src={detailSelected?.image || newsDefault}
                  sx={{
                    height: '80px',
                    borderRadius: 1,
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                />
              </Grid>
            </Grid>

            <Typography
              sx={{
                mt: 2,
                letterSpacing: '0.01em',
              }}
            >
              <HighlightTextSocial text={detailSelected.content} keyword={topicSocial?.key_word_main} />
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsNewsDetail;
