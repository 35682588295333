import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WelcomeByTime = () => {
  const { t } = useTranslation();

  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      return t('home.profile.welcomeTime.morning');
    }
    if (hour < 18) {
      return t('home.profile.welcomeTime.afternoon');
    }
    return t('home.profile.welcomeTime.evening');
  };
  return <Typography sx={{ fontSize: '1.2rem' }}>{getGreeting()}</Typography>;
};

export default WelcomeByTime;
