import { Box, Stack, Typography, useTheme } from '@mui/material';

import { TwitterPostsTypes } from '@/types/Social';

interface PostsTwitterProps {
  item: TwitterPostsTypes;
}

const ReactionsTwitter: React.FC<PostsTwitterProps> = ({ item }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row">
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20 20.59L16.41 17H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2zM20 22h1V6a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h11z"
            />
          </svg>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.comment_count}</Typography>
        </Stack>

        <Stack direction="row">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
            <path
              fill={theme.palette.text.secondary}
              d="M19 7a1 1 0 0 0-1-1h-8v2h7v5h-3l3.969 5L22 13h-3zM5 17a1 1 0 0 0 1 1h8v-2H7v-5h3L6 6l-4 5h3z"
            />
          </svg>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.repost_count}</Typography>
        </Stack>

        <Stack direction="row">
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M19.285 12.645a3.8 3.8 0 0 0-5.416-5.332q-.288.288-.732.707l-.823.775l-.823-.775q-.445-.42-.733-.707a3.8 3.8 0 0 0-5.374 0c-1.468 1.469-1.485 3.844-.054 5.32l6.984 6.984l6.97-6.972zm-14.75-6.18a5 5 0 0 1 7.072 0q.273.274.707.682q.432-.408.707-.683a5 5 0 0 1 7.125 7.017l-7.125 7.126a1 1 0 0 1-1.414 0L4.48 13.48a5 5 0 0 1 .055-7.017z"
            />
          </svg>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.like_count}</Typography>
        </Stack>
        <Stack direction="row">
          <svg
            style={{ color: 'text.secondary' }}
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M18.437 20.936H5.563a2.5 2.5 0 0 1-2.5-2.5V5.562a2.5 2.5 0 0 1 2.5-2.5h12.874a2.5 2.5 0 0 1 2.5 2.5v12.874a2.5 2.5 0 0 1-2.5 2.5M5.563 4.062a1.5 1.5 0 0 0-1.5 1.5v12.874a1.5 1.5 0 0 0 1.5 1.5h12.874a1.5 1.5 0 0 0 1.5-1.5V5.562a1.5 1.5 0 0 0-1.5-1.5Z"
            />
            <path
              fill="currentColor"
              d="M12.5 14.544a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 1 0Zm4.217-2.091a.5.5 0 0 1-1 0V6.544a.5.5 0 0 1 1 0ZM8.28 6.544a.5.5 0 0 0-1 0v4a.5.5 0 0 0 1 0Z"
            />
          </svg>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.view_count}</Typography>
        </Stack>
      </Box>
    </>
  );
};
export default ReactionsTwitter;
