import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserItemTypes } from '@/types/Social';

interface UserItemFacebookProps {
  item: UserItemTypes;
}

const UserItemFacebook: React.FC<UserItemFacebookProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'action.hover',
          borderRadius: 1,
          padding: '8px 16px',
        }}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Link href={`https://facebook.com/${item?.username}`} target="_blank" rel="noopener">
            <Avatar sx={{ width: '35px', height: '35px' }} />
          </Link>

          <Link
            href={`https://facebook.com/${item?.username}`}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {item?.name || 'User Facebook'}
            </Typography>
          </Link>
        </Stack>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('smcc.overview.post')}
          </Typography>
          <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary', fontWeight: 600 }}>
            {item.user_post_count}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default UserItemFacebook;
