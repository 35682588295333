import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const usePhishingService = () => {
  const { methodGet, methodPost, methodPut, methodDelete, methodPostUpload, methodPutUpload } = useRequest();

  const getContent = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.phishing.getContent, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createCampaign = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.phishing.campaign, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const listCampaign = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.phishing.listCampaign, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDetailCampaign = (id: string, params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(`${urlApi.phishing.campaign}${id}`, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateDetailCampaign = (id: string, params: {}) => {
    return new Promise((resolve, reject) => {
      methodPut(`${urlApi.phishing.campaign}${id}`, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteDetailCampaign = (id: string) => {
    return new Promise((resolve, reject) => {
      methodDelete(`${urlApi.phishing.campaign}${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const runCampaign = (id: string, body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(`${urlApi.phishing.campaign}${id}/run`, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // malware

  const listMalware = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.phishing.listMalware, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createMalware = (body: any) => {
    return new Promise((resolve, reject) => {
      methodPostUpload(urlApi.phishing.createMalware, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDetailMalware = (id: string, params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(`${urlApi.phishing.malware}${id}`, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateDetailMalware = (id: string, body: any) => {
    return new Promise((resolve, reject) => {
      methodPutUpload(`${urlApi.phishing.malware}${id}/`, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteDetailMalware = (id: string) => {
    return new Promise((resolve, reject) => {
      methodDelete(`${urlApi.phishing.malware}${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getContent,
    createCampaign,
    listCampaign,
    getDetailCampaign,
    updateDetailCampaign,
    deleteDetailCampaign,
    runCampaign,
    listMalware,
    getDetailMalware,
    createMalware,
    updateDetailMalware,
    deleteDetailMalware,
  };
};

export default usePhishingService;
