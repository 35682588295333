import { Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Comment from '@/assets/image/comment.png';
import Like from '@/assets/image/like.png';
import Link from '@/assets/image/link.png';
import Mention from '@/assets/image/mention.png';
import View from '@/assets/image/view.png';
import { OverviewSourceByTopicTypes } from '@/types/Social';

interface OverviewForumProps {
  data: OverviewSourceByTopicTypes[];
}

const OverviewForum: React.FC<OverviewForumProps> = ({ data }) => {
  const { t } = useTranslation();

  // Đề cập theo nguồn
  const transformData = (list: OverviewSourceByTopicTypes[]) => {
    return list.map((item: OverviewSourceByTopicTypes) => {
      let label;
      let icon;
      switch (item.label) {
        case 'mentions':
          label = t('smcc.overview.mentions');
          icon = Mention;
          break;
        case 'source_mentions':
          label = t('smcc.overview.sourceMentioned');
          icon = Link;
          break;
        case 'interactions':
          label = t('smcc.overview.reactions');
          icon = Like;
          break;
        case 'comments':
          label = t('smcc.overview.comments');
          icon = Comment;
          break;
        case 'views':
          label = t('smcc.overview.views');
          icon = View;
          break;
        default:
          label = item.label;
          icon = Mention;
          break;
      }
      return {
        label,
        count: item.count,
        icon,
      };
    });
  };

  return (
    <Grid container spacing={2}>
      {transformData(data).map((item: OverviewSourceByTopicTypes) => (
        <Grid item lg={3} sm={6} xs={12} key={item.label}>
          <Box sx={{ padding: 2, boxShadow: 4, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
              <Box sx={{ width: '45px', height: '45px' }} component="img" src={item.icon} />

              <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ alignSelf: 'center' }} color="text.secondary" gutterBottom>
                  {item.label}
                </Typography>
                <Typography
                  sx={{ fontSize: '1.8rem', fontWeight: 600, alignSelf: 'center' }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item.count}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewForum;
