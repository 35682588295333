import { Typography, FormControl, Select, MenuItem, Box, useTheme, SelectChangeEvent } from '@mui/material';

import { decrypt } from '@/utils/crypto';

interface IFormSelectDomain {
  domain: string;
  handleChangeDomain: (event: SelectChangeEvent) => void;
}
const FormSelectDomain: React.FC<IFormSelectDomain> = ({ domain, handleChangeDomain }) => {
  const theme = useTheme();
  const listDomain = JSON.parse(decrypt(localStorage.getItem('listDomain') as string));

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
      {/* <InputLabel id="demo-simple-select-standard-label">Domain</InputLabel> */}
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={domain}
        onChange={handleChangeDomain}
        label=""
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: 10,
              border: `1px solid ${theme.palette.divider}`,
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
          },
          '.MuiInputBase-input': {
            padding: '10px 10px',
          },
        }}
      >
        {listDomain.map((item: any) => (
          <MenuItem
            key={item}
            value={item}
            sx={{
              '&.MuiMenuItem-root': {
                '&.Mui-selected': {
                  backgroundColor: '#4590d9',
                  color: 'common.white',
                },
              },
            }}
          >
            <Box
              component="img"
              src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${item}&size=32`}
              alt="favicon"
              sx={{
                width: '17px',
                height: '17px',
                alignSelf: 'center',
              }}
            />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {item}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default FormSelectDomain;
