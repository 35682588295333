import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useWeatherService = () => {
  const { methodGet } = useRequest();

  const checkLocation = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.weather.ipLookup, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListWeather = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.weather.listWeather, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    checkLocation,
    getListWeather,
  };
};
export default useWeatherService;
