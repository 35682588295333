import React from 'react';

import { Box, Link } from '@mui/material';

import ProfileHeader from '@/components/Header/ProfileHeader';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

const Header: React.FC = () => {
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  return (
    <Box sx={{ position: 'fixed', top: 0, zIndex: 20, width: '100%', backdropFilter: 'blur(8px)', fontSize: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5em' }}>
        <Link
          href="/"
          sx={{
            fontSize: '1.5em',
            color: 'rgb(231 232 236)',
            textDecoration: 'none',
            fontWeight: 700,
            ml: { sm: 4, xs: 0 },
          }}
        >
          <Box
            component="img"
            src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
            sx={{
              width: '120px',
              justifyContent: 'center',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Link>
        <Box sx={{ padding: 2 }}>
          <ProfileHeader />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
