/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import { Box, Grid, Pagination, Slider, Stack, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoResult } from '@/components/common/NoResult';
import SkeletonItemSocial from '@/components/Skeleton/SkeletonItemSocial';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocialApi from '@/Hooks/fetchApi/useSocial';
import { FacebookPostsTypes, UserItemTypes, SentimentStateTypes } from '@/types/Social';
import { MAX_KEYWORD, MIDDLE_KEYWORD, MIN_KEYWORD, PAGE_SIZE, SEARCH_FACEBOOK } from '@/utils/constants';

import WordCloud from '../chart/WordCloud';
import PostsFacebook from '../facebook/PostsFacebook';
import UserItemFacebook from '../facebook/UserItemFacebook';

interface FacebookProps {
  sentimentState: SentimentStateTypes;
}

const Facebook: React.FC<FacebookProps> = ({ sentimentState }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { timeScan } = useSocialInfo();
  const { handleGetTrendingSocial } = useSocialApi();
  const { apiError } = useCommonInfo();

  const [featuredSource, setFeaturedSource] = useState<UserItemTypes[]>([]);
  const [listKeyword, setListKeyword] = useState([]);
  const [listPosts, setListPosts] = useState<FacebookPostsTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [numberKeyword, setNumberKeyword] = useState<number>(MIDDLE_KEYWORD);
  const [totalPosts, setTotalPosts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * PAGE_SIZE;
  const indexOfFirstPost = indexOfLastPost - PAGE_SIZE;
  const currentPosts = listPosts.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeNumberKeyword = (event: Event, newValue: number | number[]) => {
    setNumberKeyword(newValue as number);
  };

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (timeScan) {
      handleRequest();
    }
  }, [sentimentState, timeScan]);

  const handleRequest = async () => {
    const listSentiments = (Object.keys(sentimentState) as (keyof SentimentStateTypes)[]).filter(
      (key) => sentimentState[key] === true,
    );
    setLoading(true);
    const params = {
      social: SEARCH_FACEBOOK,
      time_range: timeScan,
      sentiments: listSentiments,
    };

    const res = await handleGetTrendingSocial(params, handleLoading);
    const { data } = res;
    const { posts, featured_source, keywords } = data;
    const { results, total_results } = posts;
    setListPosts(results);
    setTotalPosts(total_results);
    setFeaturedSource(featured_source);
    setListKeyword(keywords);
  };

  return (
    <>
      {!loading ? (
        <>
          <Box sx={{ padding: 2, boxShadow: 4, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                {t('smcc.overview.featuredKeywords')}
              </Typography>
              <Box sx={{ maxWidth: 150 }}>
                <Stack direction="row">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('smcc.numberKeyword')}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1, color: 'primary.main' }}>
                    {numberKeyword}
                  </Typography>
                </Stack>
                <Slider
                  marks
                  size="small"
                  step={10}
                  value={numberKeyword}
                  valueLabelDisplay="auto"
                  min={MIN_KEYWORD}
                  max={MAX_KEYWORD}
                  onChange={handleChangeNumberKeyword}
                />
              </Box>
            </Box>
            {!_.isEmpty(listKeyword) ? <WordCloud data={listKeyword.slice(0, numberKeyword)} /> : <NoResult />}
          </Box>
          <Box sx={{ mt: 3, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.featuredSources')}
            </Typography>
            {!_.isEmpty(featuredSource) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {featuredSource.map((item: UserItemTypes) => (
                  <Grid item sm={6} xs={12} key={item.name}>
                    <UserItemFacebook item={item} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
          </Box>
          <Box sx={{ mt: 3, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.featuredPosts')}
            </Typography>
            {!_.isEmpty(currentPosts) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {currentPosts.map((item: FacebookPostsTypes, index: number) => (
                  <Grid key={index} item xs={12} md={6}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        padding: 2,
                        minHeight: '280px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PostsFacebook item={item} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
            {!_.isEmpty(currentPosts) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                <Pagination
                  count={Math.ceil(totalPosts / PAGE_SIZE)}
                  color="primary"
                  onChange={(event, value) => {
                    handlePageChange(value);
                  }}
                  page={currentPage}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Facebook;
