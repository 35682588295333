import { useMemo } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HelpIcon from '@mui/icons-material/Help';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';

import { formatDateHours } from '@/configs/date';
import { Consoles, Cookies, ScanUrlTypes, Variables } from '@/types/Osint';

interface UrlscanProps {
  listUrlscan: ScanUrlTypes;
}

const Behaviour: React.FC<UrlscanProps> = ({ listUrlscan }) => {
  const theme = useTheme();

  const dataVariables = useMemo(() => listUrlscan?.data?.globals, [listUrlscan?.data?.globals]);
  const dataCookies = useMemo(() => listUrlscan?.data?.cookies, [listUrlscan?.data?.cookies]);
  const dataConsole = useMemo(() => listUrlscan?.data?.console, [listUrlscan?.data?.console]);

  return (
    <>
      <Box sx={{ pb: 3 }}>
        {!_.isEmpty(dataVariables) && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6"> {`${dataVariables.length} JavaScript Global Variables`}</Typography>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <HelpIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  These are the non-standard "global" variables defined on the window object. These can be helpful in
                  identifying possible client-side frameworks and code.
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mt: 1 }}>
                {dataVariables.map((item: Variables, index: number) => (
                  <Box key={index} sx={{ display: 'flex', border: `1px solid ${theme.palette.divider}`, padding: 0.5 }}>
                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                      {item.type}
                    </Typography>
                    <Box sx={{ border: `1px solid ${theme.palette.divider}`, mx: 0.5 }} />
                    <Typography variant="body2" sx={{}}>
                      {item.prop}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
        {!_.isEmpty(dataCookies) && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">{`${dataCookies.length} Cookies`}</Typography>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <HelpIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Cookies are little pieces of information stored in the browser of a user. Whenever a user visits the
                  site again, he will also send his cookie values, thus allowing the website to re-identify him even if
                  he changed locations. This is how permanent logins work.
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">
                          <Tooltip title="Secure">
                            <LockIcon sx={{ fontSize: '2rem' }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Session">
                            <FileDownloadIcon sx={{ fontSize: '2rem' }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="HTTP Only">
                            <VisibilityOutlinedIcon sx={{ fontSize: '2rem' }} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">Domain/Path</TableCell>
                        <TableCell align="left">Expires</TableCell>
                        <TableCell align="left">Name/Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataCookies.map((row: Cookies) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row" align="right">
                            {row.secure ? (
                              <CheckIcon sx={{ fontSize: '2rem' }} color="success" />
                            ) : (
                              <ClearIcon sx={{ fontSize: '2rem' }} color="error" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row.session ? (
                              <CheckIcon sx={{ fontSize: '2rem' }} color="success" />
                            ) : (
                              <ClearIcon sx={{ fontSize: '2rem' }} color="error" />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row.httpOnly ? (
                              <CheckIcon sx={{ fontSize: '2rem' }} color="success" />
                            ) : (
                              <ClearIcon sx={{ fontSize: '2rem' }} color="error" />
                            )}
                          </TableCell>
                          <TableCell align="right">{row.domain}</TableCell>
                          <TableCell align="left">{formatDateHours(row.expires)}</TableCell>
                          <TableCell align="left">
                            <Box sx={{ display: 'flex' }}>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                Name:
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {row.name}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                Value:
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {row.value}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        )}
        {!_.isEmpty(dataConsole) && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">{`${dataConsole.length} Console Messages`}</Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{ ml: 1 }}>
                A page may trigger messages to the console to be logged. These are often error messages about being
                unable to load a resource or execute a piece of JavaScript. Sometimes they also provide insight into the
                technology behind a website.
              </Typography>

              <Box sx={{ mt: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">URL</Typography>
                            <Typography variant="caption">Text</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataConsole.map((row: Consoles) => (
                        <TableRow key={row.message.source} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{row.message.source}</TableCell>
                          <TableCell>{row.message.level}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                URL:
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {row.message.url}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                Message:
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1, padding: 1, backgroundColor: 'warning.main' }}>
                                {row.message.text}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Behaviour;
