/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Menu, useTheme } from '@mui/material';
import { subDays, format } from 'date-fns';
import { vi, enUS } from 'date-fns/locale';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';

import { useSocialInfo } from '@/contexts/Social';
import useSetTimeScan from '@/Hooks/social/useSetTimeScan';

const DateRange = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const locale = localStorage.getItem('locale') || 'vi';
  const { setTimeScan } = useSetTimeScan();
  const { timeScan } = useSocialInfo();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    if (timeScan === null) {
      setTimeScan([format(subDays(new Date(), 30), 'dd/MM/yyyy'), format(new Date(), 'dd/MM/yyyy')]);
    }
  }, []);

  const formatDateRange = (startDate: any, endDate: any) => {
    return `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`;
  };

  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirmSelected = () => {
    setAnchorEl(null);
    setTimeScan([format(selectedDateRange.startDate, 'dd/MM/yyyy'), format(selectedDateRange.endDate, 'dd/MM/yyyy')]);
  };

  const onClickClear = () => {
    setSelectedDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
  };

  const staticRanges = [
    {
      label: t('smcc.dateRange.7days'),
      range: () => ({
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: t('smcc.dateRange.14days'),
      range: () => ({
        startDate: subDays(new Date(), 14),
        endDate: new Date(),
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: t('smcc.dateRange.30days'),
      range: () => ({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
  ];

  const inputRanges: any = [];

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant="outlined"
        color="secondary"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: '7px 15px', fontSize: '1.2rem' }}
      >
        {timeScan
          ? `${timeScan[0]} - ${timeScan[1]}`
          : formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate)}
        <KeyboardArrowDownIcon sx={{ ml: 1 }} />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box
          sx={{
            zIndex: 'modal',
            backgroundColor: 'background.paper',
            boxShadow: 4,
            padding: 1,
            borderRadius: 1,
            maxWidth: '900px',
            '.rdrDateRangePickerWrapper': {},
            '.rdrCalendarWrapper': {
              backgroundColor: 'background.paper',
            },
            '.rdrDateDisplayWrapper': {
              backgroundColor: 'background.paper',
            },
            '.rdrDefinedRangesWrapper': {
              backgroundColor: 'background.paper',
              borderRight: `1px solid ${theme.palette.divider}`,
            },

            '.rdrDayDisabled': {
              backgroundColor: 'background.paper',
            },

            '.rdrDateDisplayItem': {
              backgroundColor: 'background.paper',
              border: `1px solid ${theme.palette.divider}`,
            },
            '.rdrStaticRange': {
              backgroundColor: 'background.paper',
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel': {
              backgroundColor: 'action.hover',
              color: 'primary.main',
            },
            '.rdrStaticRangeLabel': {
              color: 'text.secondary',
            },
            '.rdrDayNumber span': {
              color: 'text.primary',
            },
            '.rdrDayDisabled .rdrDayNumber span': {
              color: 'text.disabled',
            },
            '.rdrNextPrevButton': {
              backgroundColor: 'action.selected',
            },
            '.rdrNextPrevButton:hover': {
              backgroundColor: 'scrollbar.hover',
            },
            '.rdrMonthName': {
              color: 'text.secondary',
            },
            '.rdrWeekDay': {
              color: 'text.primary',
            },
            '.rdrMonthAndYearPickers select': {
              color: 'text.secondary',
            },
            '.rdrMonthPicker select': {
              backgroundColor: 'background.paper',
            },
            '.rdrMonthPicker select:hover': {
              backgroundColor: 'background.paper',
            },

            '.rdrYearPicker select': {
              backgroundColor: 'background.paper',
            },
            '.rdrYearPicker select:hover': {
              backgroundColor: 'background.paper',
            },
          }}
        >
          <DateRangePicker
            locale={locale === 'vi' ? vi : enUS}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[selectedDateRange]}
            direction="horizontal"
            maxDate={new Date()}
            staticRanges={staticRanges} // Custom static ranges
            inputRanges={inputRanges} // Empty input ranges
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <Box sx={{ display: 'flex', ml: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmSelected}
              sx={{ fontSize: '1.2rem', fontWeight: 600 }}
            >
              {t('action.confirm')}
            </Button>
            <Button
              variant="text"
              color="error"
              onClick={onClickClear}
              sx={{ ml: 2, fontSize: '1.2rem', fontWeight: 600 }}
            >
              {t('action.reset')}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};
export default DateRange;
