import { Box } from '@mui/material';

import useBreakpoints from '@/helpers/useBreakpoints';

import HeaderAsm from './Header';
import SideBar from './SideBar';

interface IProps {
  children: React.ReactNode;
}

const AsmLayout = ({ children }: IProps) => {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <>
      <HeaderAsm />
      <Box>
        {!isMobile && !isTablet && <SideBar />}
        <Box
          sx={{
            marginLeft: !isMobile && !isTablet ? '140px' : 0,
            padding: !isMobile && !isTablet ? '60px 32px 30px' : '60px 20px 20px',
            minHeight: '100vh',
            backgroundColor: 'background.dark',
          }}
        >
          <Box sx={{ mt: isMobile ? '15px' : '20px' }}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};
export default AsmLayout;
