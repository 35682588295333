/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { SentimentStateTypes } from '@/types/Social';

interface FilterSentimentProps {
  sentimentState: SentimentStateTypes;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterSentiment: React.FC<FilterSentimentProps> = ({ sentimentState, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
      <FormLabel component="legend" sx={{ alignSelf: 'center', mr: 3, fontWeight: 500, fontSize: '1.4rem' }}>
        {t('smcc.status.status')}
      </FormLabel>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '1.4rem',
            },
          }}
          control={<Checkbox checked={sentimentState.positive} onChange={onChange} name="positive" color="primary" />}
          label={t('smcc.status.positive')}
        />
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '1.4rem',
            },
            ml: 0.5,
          }}
          control={<Checkbox checked={sentimentState.neutral} onChange={onChange} name="neutral" color="primary" />}
          label={t('smcc.status.neutral')}
        />
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '1.4rem',
            },
            ml: 0.5,
          }}
          control={<Checkbox checked={sentimentState.negative} onChange={onChange} name="negative" color="primary" />}
          label={t('smcc.status.negative')}
        />
      </Box>
    </FormGroup>
  );
};
export default FilterSentiment;
