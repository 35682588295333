import useWeatherService from '@/services/weather';

const useWeather = () => {
  const { checkLocation, getListWeather } = useWeatherService();

  const handleGetLocation = async () => {
    const res: any = await checkLocation({});
    const { data, status } = res || {};
    if (status === 200) {
      return data;
    }
  };

  const handleGetListWeather = async () => {
    const res: any = await getListWeather({});
    const { data, status } = res || {};
    if (status === 200) {
      return data;
    }
  };

  return {
    handleGetLocation,
    handleGetListWeather,
  };
};

export default useWeather;
