import { useCallback } from 'react';

import { useScanVulInfo } from '@/contexts/ScanVul';
import { actionType } from '@/contexts/ScanVul/action';

const useVul = () => {
  const { dispatch } = useScanVulInfo();

  const setVulnerability = useCallback(
    (vulnerability: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      vulnerability !== -1
        ? dispatch({
            type: actionType.SET_VUL,
            vulnerability: {
              dataVul: vulnerability?.dataVul,
              progress: vulnerability?.progress,
              severityCounts: vulnerability?.severityCounts,
              report: vulnerability?.report,
              stateRunning: vulnerability?.stateRunning || false,
              keySearch: vulnerability?.keySearch,
              intervalId: vulnerability?.intervalId,
              isFirstCall: vulnerability?.isFirstCall,
              isSuccessApi: vulnerability?.isSuccessApi,
              status: vulnerability?.status,
            },
          })
        : null;
    },
    [dispatch],
  );
  return {
    setVulnerability,
  };
};
export default useVul;
