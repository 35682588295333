import DescriptionIcon from '@mui/icons-material/Description';
import GridViewIcon from '@mui/icons-material/GridView';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Typography, Box } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ItemSideBar from './ItemSideBar';

const SideBar = () => {
  const { t } = useTranslation();

  return (
    <>
      <ItemSideBar icon={TrendingUpIcon} text={t('smcc.trending')} value="/social/trending" />
      <ItemSideBar icon={GridViewIcon} text={t('smcc.topic')} value="/social" />
      <ItemSideBar icon={DescriptionIcon} text={t('smcc.statisticsOverview')} value="/social/statistics-overview" />

      <Box
        sx={{
          mt: 1,
          padding: '5px 10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              color: 'text.secondary',
              fontWeight: 500,
            }}
          >
            {t('smcc.tool')}
          </Typography>
          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '2rem', color: 'text.disabled', ml: 1.5 }} />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ItemSideBar icon={InsertChartOutlinedIcon} text={t('smcc.statistics')} value="/social/overview" />

          <ItemSideBar icon={SmsOutlinedIcon} text={t('smcc.posts')} value="/social/posts" />
        </Box>
      </Box>
    </>
  );
};
export default SideBar;
