import { useLocation, Navigate } from 'react-router-dom';

import useNotify from '@/Hooks/common/useNotify';
import { decrypt } from '@/utils/crypto';

import { FormatTimeExpire } from '../common/format/FormatTimeExpire';

function AuthGuard({ children }: { children: JSX.Element }) {
  const location = useLocation();

  const { onNotify } = useNotify();
  const accessToken = localStorage.getItem('serviceToken');
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  if (!accessToken) {
    return <Navigate to="/login" state={{ login: 'mustLogin' }} replace />;
  }

  if (FormatTimeExpire(inforUser?.expireDate) <= 0) {
    onNotify('error', 'License has expired', 5000);
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default AuthGuard;
