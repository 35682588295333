import { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, AppBar, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as _ from 'lodash';

import ProfileHeader from '@/components/Header/ProfileHeader';
import useBreakpoints from '@/helpers/useBreakpoints';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

import ModalMenu from './ModalMenu';

const HeaderAsm = () => {
  const { isMobile, isTablet } = useBreakpoints();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  const [openModalMenu, setOpenModalMenu] = useState(false);

  return (
    <>
      <AppBar
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '60px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'background.dark',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(isMobile || isTablet) && (
            <MenuIcon
              onClick={() => {
                setOpenModalMenu(true);
              }}
              sx={{ color: 'text.primary', fontSize: '2rem', ml: 2 }}
            />
          )}
          <Link
            href="/"
            rel="noopener"
            underline="none"
            sx={{
              fontSize: '1.5em',
              color: 'rgb(231 232 236)',
              textDecoration: 'none',
              fontWeight: 700,
              ml: { sm: 1, xs: 0 },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
              sx={{
                width: 100,
                display: 'flex',
                alignSelf: 'center',
                marginLeft: 1.5,
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                color: themeApp === 'dark' ? grey[300] : grey[700],
                fontSize: isMobile ? '1.5rem' : '2rem',
                ml: 0.5,
              }}
            >
              ASM
            </Typography>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ProfileHeader />
        </Box>
        <ModalMenu
          openModal={openModalMenu}
          handleCloseModal={() => {
            setOpenModalMenu(false);
          }}
        />
      </AppBar>
    </>
  );
};
export default HeaderAsm;
