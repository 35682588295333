import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useSourceService = () => {
  const { methodPost, methodPostWithLongTimeout, methodFilePost } = useRequest();
  const sourceSafety = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPostWithLongTimeout(urlApi.source.safety, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const sourceUpload = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodFilePost(urlApi.source.file, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const repoUpload = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.source.repo, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const snippetUpload = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.source.snippet, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { sourceSafety, sourceUpload, repoUpload, snippetUpload };
};

export default useSourceService;
