import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useSecurityAssessmentService = () => {
  const { methodGet } = useRequest();

  const getListTool = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.securityAssessment.listTool, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const downloadTool = (params: { file_name: string }) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.securityAssessment.download, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getListTool,
    downloadTool,
  };
};
export default useSecurityAssessmentService;
