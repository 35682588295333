import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

interface BarChartSentimentStatisticProps {
  data: { [key: string]: string | number }[];
}

interface Payload {
  name: string;
  negative: number;
  positive: number;
  neutral: number;
  sum: number;
}

interface TooltipProps {
  active?: boolean;
  payload?: { payload: Payload }[];
}

interface SentimentDetailProps {
  color: string;
  label: string;
  value: number;
  percent: string;
}

const SentimentDetail: React.FC<SentimentDetailProps> = ({ color, label, value, percent }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" sx={{ color }}>
      {label}: {value}
      <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
        {t('smcc.overview.postLow')}
      </Typography>
      <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
        {`(${percent}%)`}
      </Typography>
    </Typography>
  );
};

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (active && payload && payload.length) {
    const { name, negative, positive, neutral, sum } = payload[0].payload;
    const negativePercent = ((negative / sum) * 100).toFixed(2);
    const positivePercent = ((positive / sum) * 100).toFixed(2);
    const neutralPercent = ((neutral / sum) * 100).toFixed(2);

    return (
      <div
        style={{
          backgroundColor: theme.palette.background.paper,
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {name}
        </Typography>
        <SentimentDetail color="#cb997e" label={t('smcc.status.neutral')} value={neutral} percent={neutralPercent} />
        <SentimentDetail
          color="success.main"
          label={t('smcc.status.positive')}
          value={positive}
          percent={positivePercent}
        />
        <SentimentDetail
          color="error.main"
          label={t('smcc.status.negative')}
          value={negative}
          percent={negativePercent}
        />
      </div>
    );
  }
  return null;
};

const BarChartSentimentStatistic: React.FC<BarChartSentimentStatisticProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -1, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />

          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="negative"
            fill={theme.palette.error.main}
            barSize={35}
            stackId="a"
          />

          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="positive"
            fill={theme.palette.success.main}
            barSize={35}
            stackId="a"
          />
          <Bar yAxisId="linearAxis" type="monotone" dataKey="neutral" fill="#cb997e" barSize={35} stackId="a" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default BarChartSentimentStatistic;
