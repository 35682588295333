import React, { useState, useCallback } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Button,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  AppBar,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AceEditor from 'react-ace';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-monokai';

import useBreakpoints from '@/helpers/useBreakpoints';
import useSource from '@/Hooks/fetchApi/useSource';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';


const LoadingOverlay = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    <CircularProgress color="primary" size={60} />
  </Box>
);

const SourceCodeScan: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [codeString, setCodeString] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [githubUrl, setGithubUrl] = useState('');
  const [gitlabUrl, setGitlabUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [mode, setMode] = useState(localStorage.getItem('theme') || 'dark');
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();
  const navigate = useNavigate();
  const { handleSourceFile, handleSourceCode, handleRepo, handleSourceSafety } = useSource();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
      const compressedExtensions = ['zip', 'rar', '7z', 'tar', 'gz'];

      if (compressedExtensions.includes(fileExtension || '')) {
        setFile(selectedFile);
        setError(null);
      } else {
        setFile(null);
        setError('Please upload a compressed file (zip, rar, 7z, tar, or gz).');
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleGitHubLogin = () => {
    const clientId = 'Ov23liArONbobZ1JtSBn'; // env later
    const redirectUri = encodeURIComponent('http://localhost:3000/github/callback');
    const scope = 'repo';
    const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    const popup = window.open(githubAuthUrl, 'GitHub Login', 'width=600,height=600');

    if (popup) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.data.type === 'GITHUB_AUTH_SUCCESS') {
            popup.close();
            navigate('/sourcescan');
          }
        },
        false,
      );
    } else {
      // Popup was blocked or failed to open
      console.error('Popup window was blocked or failed to open');
      alert('Please allow popups for this site to use GitHub login');
      // Optionally, fall back to redirect:
      // window.location.href = githubAuthUrl;
    }
  };

  const handleGitLabLogin = () => {
    const clientId = 'dc6ab798fe539c9b83d828b422a1862ca628405b18d53281597ef2134a87c3a8'; // env later
    const redirectUri = encodeURIComponent('http://localhost:3000/gitlab/callback');
    const scope = encodeURIComponent('api read_api read_repository read_user');
    const gitlabAuthUrl = `https://gitlab.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;

    const popup = window.open(gitlabAuthUrl, 'GitLab Login', 'width=600,height=600');
    
    if (popup) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.data.type === 'GITHUB_AUTH_SUCCESS') {
            popup.close();
            navigate('/sourcescan');
          }
        },
        false,
      );
    } else {
      // Popup was blocked or failed to open
      console.error('Popup window was blocked or failed to open');
      // You might want to inform the user or fall back to redirect
      alert('Please allow popups for this site to use GitHub login');
      // Optionally, fall back to redirect:
      // window.location.href = githubAuthUrl;
    }
  };
  const handleSubmit = async () => {
    if (tabValue === 0 && file) {
      setIsLoading(true);
      setError(null);
      try {
        const safetyData = new FormData();
        safetyData.append('file', file);
        const safetyRes = await handleSourceSafety(safetyData);

        if (safetyRes && safetyRes.is_safe) {
          try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('scan_type', 'file');
            formData.append('fcmToken', localStorage.getItem('fcmToken') || '');
            const response = await handleSourceFile(formData);

            console.log('Response from handleSourceFile:', response); // Debug log

            if (response && response.task_id) {
              setTaskId(response.task_id);
            } else if (response) {
              setError(`No task was created: ${response.message || 'Unknown error'}`);
            } else {
              setError('No response received from the server.');
            }
          } catch (err) {
            console.error('Error in handleSubmit:', err);
            setError('An error occurred while scanning the file.');
          }
        } else {
          throw new Error('File is not safe');
        }
      } catch (err) {
        console.error('Error in safety check:', err);
        setError('An error occurred while checking file safety.');
      } finally {
        setIsLoading(false);
      }
    } else if (tabValue === 1 && codeString) {
      setIsLoading(true);
      setError(null);

      try {
        const response = await handleSourceCode({
          scan_type: 'snippet',
          codestring: codeString,
          language,
          fcmToken: localStorage.getItem('fcmToken'),
        });
        if (response && response.task_id) {
          setTaskId(response.task_id);
        } else if (response) {
          setError(`No task was created: ${response.message || 'Unknown error'}`);
        } else {
          setError('No response received from the server.');
        }
      } catch (err) {
        console.error('Error in handleSubmit:', err);
        setError('An error occurred while scanning the code snippet.');
      } finally {
        setIsLoading(false);
      }
    } else if (tabValue === 2 && githubUrl) {
      setIsLoading(true);
      setError(null);

      try {
        // const isSafe = await checkUrlWithVirusTotal(githubUrl);
        const isSafe = true;
        const ghToken = localStorage.getItem('github_access_token');
        if (!ghToken) {
          throw new Error('Access token not found. Please login with GitHab first.');
        }
        if (isSafe) {
          const response = await handleRepo({
            scan_type: 'github_repo',
            url: githubUrl,
            github_token: ghToken,
            fcmToken: localStorage.getItem('fcmToken'),
          });
          if (response && response.task_id) {
            setTaskId(response.task_id);
            setFile(null);
          } else if (response) {
            setError(`No task was created: ${response.message || 'Unknown error'}`);
          } else {
            setError('No response received from the server.');
          }
        } else {
          setError('The provided GitHub URL is potentially unsafe.');
        }
      } catch (err) {
        setError('An error occurred while processing the GitHub URL.');
        console.error('Error:', err);
      } finally {
        setIsLoading(false);
      }
    } else if (tabValue === 3 && gitlabUrl) {
      setIsLoading(true);
      setError(null);

      try {
        const gitlabToken = localStorage.getItem('gitlab_access_token');

        if (!gitlabToken) {
          throw new Error('GitLab access token not found. Please login with GitLab first.');
        }
        const response = await handleRepo({
          scan_type: 'gitlab_repo',
          repo_url: gitlabUrl,
          gitlab_access_token: gitlabToken,
          fcmToken: localStorage.getItem('fcmToken') || '',
        });

        if (response && response.task_id) {
          setTaskId(response.task_id);
          setFile(null);
        } else if (response) {
          setError(`No task was created: ${response.message || 'Unknown error'}`);
        } else {
          setError('No response received from the server.');
        }
      } catch (err) {
        setError('An error occurred while processing the GitLab URL.');
        console.error('Error:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value as string);
  };

  const handleGithubUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGithubUrl(event.target.value);
  };

  const handleGitlabUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGitlabUrl(event.target.value);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {isLoading && <LoadingOverlay />}
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.dark',
          height: { xs: '50px', sm: '60px' }, 
          width: '100%',
        }}
      >
        <Link
          href="/"
          rel="noopener"
          underline="none"
          sx={{
            fontSize: '1.5em',
            color: 'rgb(231 232 236)',
            textDecoration: 'none',
            fontWeight: 700,
            ml: { sm: 1, xs: 0 },
            display: 'flex',
            alignItems: 'center',
            px: { xs: 2, sm: 3 },
          }}
        >
          <Box
            component="img"
            src={mode === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
            sx={{
              width: { xs: 80, sm: 100 },
              display: 'flex',
              alignSelf: 'center',
              marginLeft: 1.5,
              cursor: 'pointer',
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? '1.5rem' : '2rem',
              ml: 0.5,
            }}
          >
            ASM
          </Typography>
        </Link>
      </AppBar>
      <Box display="flex" justifyContent="center" my={4}>
        <Paper elevation={3} sx={{ padding: 4, width: '70%' }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Upload File" />
            <Tab label="Paste Code" />
            <Tab label="GitHub" />
            <Tab label="GitLab" />
          </Tabs>
          <Box p={3} sx={{ width: '100%', justifyContent: 'center' }}>
            {tabValue === 0 && (
              <>
                <Box
                  {...getRootProps()}
                  sx={{
                    border: '2px dotted #000000',
                    borderRadius: '4px',
                    padding: '40px', // increased padding
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: isDragActive ? theme.palette.grey[400] : theme.palette.grey[300],
                    width: '100%',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.grey[200],
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <input {...getInputProps()} />
                  <CloudUploadIcon sx={{ fontSize: 48, color: '#000000' }} />
                  {isDragActive ? (
                    <Typography color="#000000">{t('malware_scan.drag_dropzone')}</Typography>
                  ) : (
                    <Typography color="#000000">{t('malware_scan.nodrag_dropzone')}</Typography>
                  )}
                </Box>
                {file && (
                  <Typography align="center" variant="body1" sx={{ mt: 2 }}>
                    Selected file: {file.name}
                  </Typography>
                )}
                {taskId && (
                  <Typography align="center" variant="body2">
                    Task ID: {taskId}. Please wait while we work...
                  </Typography>
                )}
                {error && (
                  <Typography align="center" variant="body2" color="error" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
              </>
            )}
            {tabValue === 1 && (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="language-select-label">Language</InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={language}
                    label="Language"
                    onChange={handleLanguageChange}
                  >
                    <MenuItem value="javascript">JavaScript</MenuItem>
                    <MenuItem value="python">Python</MenuItem>
                    <MenuItem value="java">Java</MenuItem>
                    <MenuItem value="csharp">C#</MenuItem>
                    <MenuItem value="ruby">Ruby</MenuItem>
                    <MenuItem value="angular">Angular</MenuItem>
                    <MenuItem value="cplusplus">C++</MenuItem>
                    <MenuItem value="php">PHP</MenuItem>
                    <MenuItem value="SQL">SQL</MenuItem>
                    <MenuItem value="XML">XML</MenuItem>
                  </Select>
                </FormControl>
                <AceEditor
                  mode={language}
                  theme="monokai"
                  onChange={setCodeString}
                  name="code-editor"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showPrintMargin: false,
                  }}
                  style={{ width: '100%', height: '500px' }}
                />
              </>
            )}
            {tabValue === 2 && (
              <>
                <TextField
                  fullWidth
                  label="GitHub Repository URL"
                  variant="outlined"
                  value={githubUrl}
                  onChange={handleGithubUrlChange}
                  placeholder="https://github.com/username/repository"
                  sx={{ mb: 2 }}
                />
                {!localStorage.getItem('github_access_token') && (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t('codescan.repo_warning')}
                  </Typography>
                )}
                <Box display="flex" justifyContent="center">
                  <Button variant="contained" color="secondary" onClick={handleGitHubLogin} sx={{ mb: 2 }}>
                    Authorize with GitHub
                  </Button>
                </Box>
              </>
            )}
            {tabValue === 3 && (
              <>
                <TextField
                  fullWidth
                  label="GitLab Repository URL"
                  variant="outlined"
                  value={gitlabUrl}
                  onChange={handleGitlabUrlChange}
                  placeholder="https://gitlab.com/username/repository"
                  sx={{ mb: 2 }}
                />
                {!localStorage.getItem('gitlab_access_token') && (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t('codescan.repo_warning')}
                  </Typography>
                )}
                <Box display="flex" justifyContent="center">
                  <Button variant="contained" color="primary" onClick={handleGitLabLogin} sx={{ mb: 2 }}>
                    Authorize with GitLab
                  </Button>
                </Box>
                {taskId && (
                  <Typography align="center" variant="body2">
                    Task ID: {taskId}. Please wait while we work...
                  </Typography>
                )}
                {error && (
                  <Typography align="center" variant="body2" color="error" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
              </>
            )}
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={
                  (tabValue === 0 && !file) ||
                  (tabValue === 1 && !codeString) ||
                  (tabValue === 2 && !githubUrl) ||
                  (tabValue === 3 && !gitlabUrl)
                }
              >
                Submit Code
              </Button>
              <Button variant="outlined" color="primary" onClick={() => navigate('/sourcescan/results')} sx={{ ml: 2 }}>
                View Scan Results
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default SourceCodeScan;
