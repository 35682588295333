import { useEffect, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Typography, TextField, Breadcrumbs, Link, Button, CircularProgress, Autocomplete } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useNotify from '@/Hooks/common/useNotify';
import useAuth from '@/Hooks/fetchApi/useAuth';
import useManagenent from '@/Hooks/fetchApi/useManagenent';

interface User {
  user_name: string;
}

interface AddIdentityProps {
  onIsAddPermissionChange: (newValue: boolean) => void;
  handleGetListPermission: (arg1: string, arg2: number) => Promise<void>;
  page: number;
  onPageChange: (newValue: number) => void;
}

const AddIdentity: React.FC<AddIdentityProps> = ({
  onIsAddPermissionChange,
  handleGetListPermission,
  page,
  onPageChange,
}) => {
  const { onNotify } = useNotify();
  const { handleGetListUser } = useAuth();
  const { handlePermissionIdentity } = useManagenent();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<any>([]);
  const [options, setOptions] = useState<readonly User[]>([]);
  const loading = open && options.length === 0;

  const navigateSuccess = () => {
    onIsAddPermissionChange(false);
    onPageChange(1);
    if (page === 1) {
      handleGetListPermission('', 1);
    }
    onNotify('success', t('notify.addPermission'), 3000);
  };

  const handleSubmit = () => {
    const listUsernameSelected = userSelected.map((user: User) => user.user_name);
    const params = {
      list_user: listUsernameSelected,
      type: 'create',
    };
    if (!_.isEmpty(userSelected)) {
      handlePermissionIdentity(params, navigateSuccess);
    }
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await handleGetListUser();
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { list_groups } = res;
      const flated = list_groups.flat();
      if (active) {
        setOptions([...flated]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Box sx={{ borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover', boxShadow: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mt: 1 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link
                underline="hover"
                key="1"
                color="inherit"
                onClick={() => {
                  onIsAddPermissionChange(false);
                }}
                sx={{ cursor: 'pointer', fontSize: '1.2rem' }}
              >
                {t('breadcrumb.addPermissionIdentity.listIdentity')}
              </Link>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {t('breadcrumb.addPermissionIdentity.addIdentity')}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
        <Box sx={{ width: '100%', marginTop: 2, marginBottom: 2 }}>
          <Autocomplete
            fullWidth
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            multiple
            isOptionEqualToValue={(option, value) => option.user_name === value.user_name}
            getOptionLabel={(option) => option.user_name}
            options={options}
            loading={loading}
            value={userSelected}
            onChange={(event, newValue) => {
              setUserSelected(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('placeholder.username')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{
              '& .MuiChip-label': {
                fontSize: '1.4rem',
              },
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              handleSubmit();
            }}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('action.add')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default AddIdentity;
