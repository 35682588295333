export const FormatTimeExpire = (date: string) => {
  const currentDate = new Date();
  const futureDateObj = new Date(date);
  // Tính toán số miligiây còn lại
  const timeRemaining = futureDateObj.getTime() - currentDate.getTime();
  // Chuyển đổi từ miligiây sang ngày và làm tròn xuống
  const daysRemaining = Math.ceil(timeRemaining / (1000 * 60 * 60 * 24));

  return daysRemaining;
};
