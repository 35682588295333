import React, { useState } from 'react';

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  FormControl,
  MenuItem,
  useTheme,
  SelectChangeEvent,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import UpdateButton from '@/components/common/Button/UpdateButton';
import { Transition } from '@/components/common/Transition';
import { SelectedUserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface DialogUpdatePermissionProps {
  openDialogUpdatePermission: boolean;
  onOpenDialogUpdatePermissionChange: (newValue: boolean) => void;
  handleChangePermission: (arg: string) => void;
  selectedUser?: SelectedUserType;
  onSelectedUserChange?: (user: SelectedUserType) => void;
}

const DialogUpdatePermission: React.FC<DialogUpdatePermissionProps> = ({
  openDialogUpdatePermission,
  onOpenDialogUpdatePermissionChange,
  handleChangePermission,
  selectedUser,
  onSelectedUserChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [permissionSelected, setPermissionSelected] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSelectPermission = (event: SelectChangeEvent<string>) => {
    setPermissionSelected(event.target.value);
    setError(null);
  };

  const handleClickUpdate = () => {
    if (!permissionSelected) {
      setError(t('validation.fieldRequired'));
      return;
    }
    handleChangePermission(permissionSelected);
  };

  const handleCloseDialog = () => {
    onOpenDialogUpdatePermissionChange(false);
    setPermissionSelected('');
    setError(null);
    if (onSelectedUserChange) {
      onSelectedUserChange({ userId: '', username: '', role: '' });
    }
  };

  return (
    <Dialog
      open={openDialogUpdatePermission}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: 'background.dark',
            border: `1px solid ${theme.palette.divider}`,
          },
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.1)',
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>{t('dialog.updatePermission')}</Typography>
      </DialogTitle>
      <DialogContent>
        {selectedUser && <Typography variant="body2">{selectedUser?.username}</Typography>}
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('management.asm.groups.permission')}</InputLabel>
            <Select
              value={permissionSelected || selectedUser?.role}
              onChange={handleSelectPermission}
              label={t('management.asm.groups.permission')}
              error={Boolean(error)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: 15,
                  },
                },
              }}
            >
              {inforUser.role === 'super admin' && (
                <MenuItem sx={{ fontSize: '1.4rem' }} value="manager">
                  Manager
                </MenuItem>
              )}
              <MenuItem sx={{ fontSize: '1.4rem' }} value="admin">
                Admin
              </MenuItem>
              <MenuItem sx={{ fontSize: '1.4rem' }} value="member">
                Member
              </MenuItem>
            </Select>
            {error && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
          </FormControl>

          <UpdateButton handleClick={handleClickUpdate} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogUpdatePermission;
