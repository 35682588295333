import { useTheme, Box, Paper, Grid, Typography, ListItem, List } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCommonInfo } from '@/contexts/Common';
import LoadingRecords from '@/pages/asm/Informations/LoadingRecords';

interface Props {
  dns: any;
}
const DNSRecords: React.FC<Props> = ({ dns }) => {
  const theme = useTheme();
  const { isLoading } = useCommonInfo();
  const { t } = useTranslation();

  const ContentDetailBox = styled(Box)({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'background.main',
    borderRadius: '10px',
    marginTop: '10px',
  });

  const TitleDetailBox = styled(Typography)({
    color: `${theme.palette.text.secondary}`,
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '16px',
  });

  return (
    <>
      {isLoading ? (
        <LoadingRecords />
      ) : (
        <>
          {Object.keys(dns).length !== 0 && (
            <Grid container spacing={4}>
              {Object.keys(dns)
                .sort()
                .map((key) => (
                  <Grid key={key} item xs={12} md={6}>
                    <Box
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        borderRadius: '5px',
                        bgcolor: 'action.hover',
                      }}
                    >
                      <Box>
                        <TitleDetailBox>{key} records</TitleDetailBox>
                        {dns[key]?.map((item: any, index: any) => (
                          <ContentDetailBox key={index}>
                            <List>
                              {Object.entries(item || {})?.map(
                                ([itemKey, itemValue]) =>
                                  itemKey !== 'type' && (
                                    <ListItem key={itemKey}>
                                      <Box sx={{ fontSize: '1.4rem', overflow: 'hidden' }}>
                                        {`${itemKey} : ${itemValue}`}
                                      </Box>
                                    </ListItem>
                                  ),
                              )}
                            </List>
                          </ContentDetailBox>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}
          {Object.keys(dns).length === 0 && (
            <Typography
              variant="body2"
              sx={{
                marginLeft: 3,
                color: 'text.secondary',
              }}
            >
              {t('notify.noResult')}
            </Typography>
          )}
        </>
      )}
    </>
  );
};
export default DNSRecords;
