import { useLayoutEffect, useRef } from 'react';

import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5wc from '@amcharts/amcharts5/wc';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PieChartProps {
  data: { [key: string]: string | number }[];
}

const WordCloud: React.FC<PieChartProps> = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current ? chartRef.current : 'chartdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/word-cloud/
    const series = root.container.children.push(
      am5wc.WordCloud.new(root, {
        categoryField: 'label',
        valueField: 'count',
        maxFontSize: am5.percent(18),
        minFontSize: am5.percent(6),
        angles: [0],
        colors: am5.ColorSet.new(root, {}),
        randomness: 0.1,
      }),
    );

    // Configure labels
    series.labels.template.setAll({
      fontFamily: 'Roboto',
      fontWeight: '500',
    });

    series.labels.template.set(
      'tooltipHTML',
      `
        <div style="padding: 5px; font-size: 13px; text-align: center;">
          <span style="font-weight: bold; color: ${theme.palette.text.primary};">{category}</span><br/>
          <span style="color: ${theme.palette.text.secondary};">{value} ${t('smcc.overview.postLow')}</span>
        </div>
      `,
    );
    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [theme, data]);

  return <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '340px' }} />;
};
export default WordCloud;
