/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import sweetKiss from '@/assets/image/sweet_kiss.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';

import { DisplaySentiment } from '../ButtonSentiment';

interface ForumDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: any;
}

const ForumDetail: React.FC<ForumDetailProps> = ({ openDialogDetail, onDialogDetailChange, detailSelected }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const locale = localStorage.getItem('locale') || 'vi';
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  const components = {};
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography
              sx={{
                letterSpacing: '0.01em',
                fontWeight: 600,
              }}
            >
              <HighlightTextSocial text={detailSelected?.title} keyword={topicSocial?.key_word_main} />
            </Typography>
            <Box>
              <DisplaySentiment sentiment={detailSelected?.sentiment} />
            </Box>
          </Stack>
          <Box sx={{ mt: 1, display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <PermIdentityIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
              <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
                {detailSelected?.author}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                ml: 4,
              }}
            >
              <AccessTimeIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
              <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
                {detailSelected?.created_time
                  ? format(
                      new Date(detailSelected?.created_time),
                      locale === 'vi' ? "dd MMM yyyy 'lúc' HH:mm" : "dd MMM yyyy 'at' HH:mm",
                      {
                        locale: locale === 'vi' ? vi : enUS,
                      },
                    )
                  : 'NaN'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {detailSelected.views}
              </Typography>
              <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
                {t('smcc.views')}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {detailSelected.replies}
              </Typography>
              <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
                {t('smcc.comment')}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mt: 3, mb: 2 }} />
        </Box>

        <Box sx={{ m: 1 }}>
          {!_.isEmpty(detailSelected) && (
            <>
              {detailSelected.threadMessages.map((item: any, index: number) => (
                <>
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    sx={{ mt: 2, backgroundColor: 'action.hover', padding: '0px 16px 16px 16px', borderRadius: 1 }}
                  >
                    <Grid item xs={12} sm={2}>
                      <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
                        <Link href={`https://voz.vn/u/${item?.username}`}>
                          <Avatar />
                        </Link>
                        <Link
                          href={`https://voz.vn/u/${item?.username}`}
                          sx={{
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <Typography sx={{ fontWeight: 600, color: 'text.primary', wordBreak: 'break-word' }}>
                            {item?.username}
                          </Typography>
                        </Link>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {item.userTitleText}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Box sx={{ padding: 2, backgroundColor: 'background.hoverMenu', borderRadius: 1 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                          {item?.post_time
                            ? format(
                                new Date(item?.post_time),
                                locale === 'vi' ? "dd MMM yyyy 'lúc' HH:mm" : "dd MMM yyyy 'at' HH:mm",
                                {
                                  locale: locale === 'vi' ? vi : enUS,
                                },
                              )
                            : 'NaN'}
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                        <ReactMarkdown components={components} linkTarget="_blank">
                          {item?.rawHTML}
                        </ReactMarkdown>
                        {item?.reactions && (
                          <>
                            <Divider sx={{ mb: 1 }} />
                            <Stack direction="row">
                              <Avatar alt="Love Icon" src={sweetKiss} sx={{ width: 19, height: 19 }} />
                              <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '1.2rem' }}>
                                {item?.reactions}
                              </Typography>
                            </Stack>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {/* <Divider sx={{ mb: 1 }} /> */}
                </>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ForumDetail;
