import { ReactElement } from 'react';

import { Container, Grid, Paper, styled } from '@mui/material';

import BlurItem from '@/components/identity/layout/Blurred';
import { IdentityCard } from '@/components/identity/searchResult/IdentityCard';
import { IdentityData } from '@/interfaces/identity';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'whitesmoke',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
}));

const IdentityResult = ({ identity }: { identity: IdentityData }): ReactElement => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12}>
            <Item>
              <IdentityCard identity={identity} />
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <BlurItem>
                <div />
              </BlurItem>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IdentityResult;
