import axios from 'axios';
import { gapi } from 'gapi-script';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@/contexts/User';

import useApiError from './useApiError';
import useLoading from './useLoading';
import useLoadingGif from './useLoadingGif';
import useNotify from './useNotify';
import useScanError from './useScanError';
import useShowModal from './useShowModal';

const TIME_OUT = 120000;

const useRequest = () => {
  const axiosMethod = axios;
  axiosMethod.defaults.timeout = TIME_OUT;
  // const { updateUserToken } = useUser();
  const { setShowModal } = useShowModal();
  const { setLoading } = useLoading();
  const { setLoadingGif } = useLoadingGif();
  const { setScanError } = useScanError();
  const { setApiError } = useApiError();
  const { onNotify } = useNotify();
  const { dispatch } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getHeaders = async () => {
    const accessToken = localStorage.getItem('serviceToken');
    if (accessToken) {
      return {
        'Content-Type': 'application/json',
        // 'Content-Type': '*',
        Authorization: `Bearer ${accessToken}`,
        // 'Access-Control-Allow-Origin': '*',
      };
    }
    return {
      // 'Content-Type': '*',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    };
  };

  const handleError = (err: any, reject: any) => {
    setLoadingGif(false);
    setLoading(false);
    setScanError(true);
    setApiError(true);
    if (err?.message?.includes?.('401')) {
      onNotify('error', t('notify.endSession'), 5000);
      setShowModal({ isShow: true, content: t('notify.endSession') });
      logout();
      localStorage.removeItem('serviceToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('authState');
      localStorage.removeItem('inforUser');
      localStorage.removeItem('domainActive');
      localStorage.removeItem('myAppData');
      localStorage.removeItem('report');
      localStorage.removeItem('scanSitemap');
      localStorage.removeItem('scanSubdomain');
      localStorage.removeItem('scanVul');
    } else if (err?.message === 'Network Error') {
      onNotify('error', err?.message, 5000);
    } else if (err?.message?.includes?.('403')) {
      onNotify('error', t('notify.dontAccess'), 5000);
    } else if (err?.message?.includes('timeout')) {
      onNotify('error', err?.message, 5000);
    } else if (err?.message?.includes('500') || err?.message?.includes('502') || err?.message?.includes('503')) {
      onNotify('error', t('notify.serverError'), 5000);
    } else if (
      err?.response?.data?.message?.includes('News matching query does not exist') ||
      err?.response?.data?.message?.includes('invalid literal for int() with')
    ) {
      navigate('*');
    } else {
      onNotify('error', err?.response.data.message, 5000);
    }
    return reject(err);
  };

  const logoutGoogle = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(() => {
        auth2.disconnect();
        console.log('Logout success');
      });
    }
  };

  const logout = async () => {
    logoutGoogle();
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('refreshToken');
    dispatch({ type: 'LOGOUT' });
  };

  const methodGet = async (url: string, params?: any) => {
    setApiError(false);
    const attributes = { headers: await getHeaders(), params };
    return new Promise((resolve, reject) => {
      axiosMethod
        .get(url, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodPost = async (url: string, body: any) => {
    setApiError(false);
    const accessToken = localStorage.getItem('serviceToken');
    const attributes = {
      // cache: true,
      headers: await getHeaders(),
      Authorization: `Bearer ${accessToken}`,
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .post(url, body, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodPostWithLongTimeout = async (url: string, body: any) => {
    setApiError(false);
    const attributes = {
      // cache: true,
      timeout: 600000,
      headers: await getHeaders(),
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .post(url, body, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodPostWithoutHandleError = async (url: string, body: any) => {
    setApiError(false);
    const attributes = {
      // cache: true,
      headers: await getHeaders(),
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .post(url, body, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const methodFilePost = async (url: string, body: any) => {
    setApiError(false);
    const accessToken = localStorage.getItem('serviceToken');
    const attributes = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .post(url, body, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodPut = async (url: string, body: any) => {
    const attributes = {
      // cache: true,
      headers: await getHeaders(),
    };

    return new Promise((resolve, reject) => {
      axiosMethod
        .put(url, body, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodDelete = async (url: string, params?: any) => {
    const attributes = { headers: await getHeaders(), params };
    return new Promise((resolve, reject) => {
      axiosMethod
        .delete(url, attributes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          handleError(err, reject);
        });
    });
  };

  const methodPostUpload = async (url: string, body: any) => {
    const accessToken = localStorage.getItem('serviceToken');
    const attributes = {
      // cache: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .post(url, body, attributes)
        .then((res) => resolve(res))
        .catch((err) => handleError(err, reject));
    });
  };

  const methodPutUpload = async (url: string, body: any) => {
    const accessToken = localStorage.getItem('serviceToken');
    const attributes = {
      // cache: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return new Promise((resolve, reject) => {
      axiosMethod
        .put(url, body, attributes)
        .then((res) => resolve(res))
        .catch((err) => handleError(err, reject));
    });
  };

  return {
    methodPost,
    methodGet,
    methodPut,
    methodDelete,
    methodPostUpload,
    methodPutUpload,
    methodPostWithoutHandleError,
    methodPostWithLongTimeout,
    methodFilePost,
  };
};

export default useRequest;
