import { useCallback } from 'react';

import { useReportInfo } from '@/contexts/Report';
import { actionType } from '@/contexts/Report/action';

const useReport = () => {
  const { dispatch } = useReportInfo();

  const setReport = useCallback(
    (report: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      report !== -1
        ? dispatch({
            type: actionType.SET_REPORT,
            report: {
              dataReport: report?.dataReport,
              stateRunning: report?.stateRunning || false,
              keySearch: report?.keySearch,
              intervalId: report?.intervalId,
              isFirstCall: report?.isFirstCall || false,
              isSuccessApi: report?.isSuccessApi || false,
              isFinish: report?.isFinish || false,
            },
          })
        : null;
    },
    [dispatch],
  );
  return {
    setReport,
  };
};
export default useReport;
