import { Box, useTheme, Grid, Link } from '@mui/material';

import AosComponent from '@/components/animation/AosComponent';
import { NewsConfig } from '@/configs/NewsConfig';
import { NewsTypes, NewsState } from '@/types/news';

import ItemLabel from './ItemLabel';

interface SubPostProps {
  listNews: NewsTypes[];
  updateNewsState: (newState: Partial<NewsState>) => void;
}

const SubPost: React.FC<SubPostProps> = ({ listNews, updateNewsState }) => {
  const theme = useTheme();
  const locale = localStorage.getItem('locale') || 'vi';
  const { checkValidUrl } = NewsConfig();

  return (
    <>
      {listNews?.map((item: NewsTypes, index: number) => (
        <AosComponent key={item.id} dataAos="fade-left" dataAosDuration="1000">
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} md={4}>
              <Link href={`/news/${item.path}`}>
                <Box
                  sx={{
                    cursor: 'pointer',
                    borderRadius: 1,
                    overflow: 'hidden',
                    border: `1px solid ${theme.palette.divider}`,
                    ':hover': {
                      '.img': {
                        transform: 'scale(1.1)',
                      },
                    },
                    mt: { xs: 0, md: 1 },
                    maxWidth: '160px',
                  }}
                >
                  <Box
                    component="img"
                    sx={{ width: '100%', height: { xs: '100px', md: '90px' }, transition: '0.6s' }}
                    className="img"
                    alt=""
                    src={checkValidUrl(item.img_url, item.image_url_minio)}
                  />
                </Box>
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                mt: { xs: 2, md: 1 },
                paddingBottom: { xs: 0, md: 1 },
                minWidth: { md: 0, lg: '150px' },
              }}
            >
              <Link href={`/news/${item.path}`} style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    marginLeft: { xs: 0, md: 2 },
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: 'text.primary',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    transition: 'color 0.3s ease',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 3,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {locale === 'vi' ? item.title_vi : item.title_en}
                </Box>
              </Link>
              <Box sx={{ marginLeft: { xs: 0, md: 2 } }}>
                <ItemLabel item={item} updateNewsState={updateNewsState} />
              </Box>
            </Grid>
          </Grid>
        </AosComponent>
      ))}
    </>
  );
};
export default SubPost;
