import { useTheme, Box, Stack, Modal, Typography, Button, Grid } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';

import { DANGEROUS_PORT } from '@/utils/constants';

interface PortModalProps {
  port: [];
  openPort: boolean;
  handleClosePort: () => void;
}

const PortModal: React.FC<PortModalProps> = ({ port, openPort, handleClosePort }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={openPort} onClose={handleClosePort}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 450,
          bgcolor: 'background.main',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '10px',
          p: 3,
          boxShadow: 3,
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography sx={{ color: `${theme.palette.text.primary}` }} variant="h6">
            {t('asm.subdomain.listPort')}
          </Typography>
          <Grid sx={{ marginTop: '1rem' }} container spacing={1}>
            {!_.isEmpty(port) ? (
              <>
                {port?.map((items: any, index: any) => (
                  <Grid item key={index}>
                    {DANGEROUS_PORT.includes(+items.port) ? (
                      <Button
                        variant="text"
                        sx={{
                          padding: 0,
                          paddingInline: 2,
                          borderRadius: '20px',
                          color: theme.palette.mode === 'dark' ? '#455a64' : '#424242',
                          backgroundColor: theme.palette.mode === 'dark' ? '#eeeeee' : '#bbdefb',
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#eeeeee' : '#bbdefb',
                          },
                        }}
                      >
                        {items.port}
                        <UseAnimations strokeColor="#e8090d" animation={alertCircle} size={30} />
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        sx={{
                          borderRadius: '20px',
                          color: theme.palette.mode === 'dark' ? '#455a64' : '#424242',
                          backgroundColor: theme.palette.mode === 'dark' ? '#eeeeee' : '#bbdefb',
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#eeeeee' : '#bbdefb',
                          },
                        }}
                      >
                        {items.port}
                      </Button>
                    )}
                  </Grid>
                ))}
              </>
            ) : (
              <Typography variant="body2" sx={{ color: `${theme.palette.text.secondary}` }}>
                {t('notify.noData')}
              </Typography>
            )}
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};
export default PortModal;
