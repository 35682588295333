import { useMemo } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';

import { LinksType, ScanUrlTypes } from '@/types/Osint';

interface UrlscanProps {
  listUrlscan: ScanUrlTypes;
}

const Links: React.FC<UrlscanProps> = ({ listUrlscan }) => {
  const theme = useTheme();

  const dataLinks = useMemo(() => listUrlscan?.data?.links, [listUrlscan?.data?.links]);

  return (
    <>
      <Box sx={{ pb: 3 }}>
        {!_.isEmpty(dataLinks) && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6"> {`${dataLinks.length} Outgoing links`}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              These are links going to different origins than the main page.
            </Typography>

            <Box sx={{ mt: 2 }}>
              {dataLinks.map((item: LinksType, index: number) => (
                <Box key={index} sx={{ borderBottom: `1px solid ${theme.palette.divider}`, padding: 0.5, mt: 1.5 }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      URL:
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {item.href}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Title:
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 1, fontStyle: 'italic' }}>
                      {item.text}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Links;
