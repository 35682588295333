/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { decrypt } from '@/utils/crypto';

import UpdateTopic from './UpdateTopic';

interface DetailTopicProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  page: number;
  handleGetListTopic: (pageValue: number) => void;
  detail: any;
  onPageChange: (newValue: number) => void;
}

const DetailTopic: React.FC<DetailTopicProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  page,
  handleGetListTopic,
  detail,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogDetailChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '500px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('smcc.detailTopic')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, padding: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{`${t('smcc.topic')} :`}</Typography>
              <Typography variant="body2" sx={{ color: 'text.primary', ml: 2, fontWeight: 600 }}>
                {detail.topic_name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {`${t('table.topicSmcc.createAt')} :`}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary', ml: 2 }}>
                {detail.created_at}
              </Typography>
            </Box>
            {detail.created_at !== detail.modified_at && (
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {`${t('table.topicSmcc.modifyAt')} :`}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary', ml: 2 }}>
                  {detail.modified_at}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>{`${t(
                'smcc.keyword',
                // eslint-disable-next-line react/jsx-closing-tag-location
              )} :`}</Typography>
              <Box sx={{ display: 'flex', ml: 2 }}>
                {detail.key_word_main?.map((item: any, index: number) => (
                  <Button variant="contained" color="inherit" key={index} sx={{ ml: 1 }}>
                    {item}
                  </Button>
                ))}
              </Box>
            </Box>
            {inforUser.role !== 'super admin' && (
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={() => {
                    setOpenDialogUpdate(true);
                  }}
                  sx={{
                    width: '90px',
                    boxShadow: 'none',
                    borderRadius: '5px',
                    textTransform: 'none',
                    transition: 'all .1s ease-in-out',
                    '&:active': {
                      transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
                      transition: 'all .1s ease-in-out',
                    },
                    whiteSpace: 'nowrap',
                    alignSelf: 'center',
                  }}
                >
                  {t('action.update')}
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <UpdateTopic
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        onDialogDetailChange={onDialogDetailChange}
        detail={detail}
        page={page}
        handleGetListTopic={handleGetListTopic}
        onPageChange={onPageChange}
      />
    </>
  );
};
export default DetailTopic;
