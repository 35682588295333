/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import { FacebookPostsTypes } from '@/types/Social';

import { DisplaySentiment } from '../ButtonSentiment';

interface TitlePostsFacebookProps {
  item: FacebookPostsTypes;
}

const TitlePostsFacebook: React.FC<TitlePostsFacebookProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Link href={`https://facebook.com/${item?.username}`} target="_blank" rel="noopener">
          <Avatar />
        </Link>
        <Box>
          <Link
            href={`https://facebook.com/${item?.username}`}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>{item?.name || 'User Facebook'}</Typography>
          </Link>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item?.post_time
              ? format(new Date(item?.post_time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
                  locale: locale === 'vi' ? vi : enUS,
                })
              : 'NaN'}
          </Typography>
        </Box>
      </Stack>
      <DisplaySentiment sentiment={item?.sentiment} />
    </Stack>
  );
};
export default TitlePostsFacebook;
