import { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, CircularProgress, InputBase, Typography, debounce, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { PAGE_SIZE } from '@/utils/constants';

import AddIdentity from '../../components/Management/identity/AddIdentity';
import ListIdentity from '../../components/Management/identity/ListIdentity';

const IdentityManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleGetListPermissionIdentity } = useManagenent();

  const [isAddPermission, setIsAddPermission] = useState(false);
  const [listUsers, setListUsers] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const fetchFilter = useMemo(
    () =>
      debounce(async (search) => {
        if (search !== '' && search.length > 2) {
          handleGetListPermission(search);
        } else {
          setLoadingFilter(false);
        }
      }, 1500),
    [],
  );

  const handleInputChange = (event: any) => {
    setPage(1);
    setSearchValue(event.target.value);
    fetchFilter(event.target.value);
    if (event.target.value.length > 2) {
      setLoadingFilter(true);
    }
    if (event.target.value === '') {
      handleGetListPermission('', 1);
      setLoadingFilter(false);
    }
  };

  const handleGetListPermission = async (searchKey?: string, pageValue?: number) => {
    const params = {
      keyword: searchKey ?? searchValue,
      page: pageValue ?? page,
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleGetListPermissionIdentity(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_user, total } = dataRes;
    setListUsers(list_user);
    setTotalRow(total);
    setLoadingFilter(false);
  };

  useEffect(() => {
    handleGetListPermission();
  }, [page]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.identity.titleIdentity')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '10px 10px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && !loadingFilter && (
                    <ClearIcon
                      onClick={() => {
                        setSearchValue('');
                        if (page === 1) {
                          handleGetListPermission('', 1);
                        } else {
                          setPage(1);
                        }
                      }}
                      sx={{
                        mr: 3,
                        color: 'text.secondary',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={searchValue}
              onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setIsAddPermission(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',

              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addPermission')}
          </Button>
        </Box>
        <Box>
          {isAddPermission ? (
            <AddIdentity
              onIsAddPermissionChange={setIsAddPermission}
              handleGetListPermission={handleGetListPermission}
              page={page}
              onPageChange={setPage}
            />
          ) : (
            <ListIdentity
              listUsers={listUsers}
              page={page}
              onPageChange={setPage}
              totalRow={totalRow}
              handleGetListPermission={handleGetListPermission}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
export default IdentityManagement;
