/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import { Box, Grid, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoResult } from '@/components/common/NoResult';
import SkeletonItemSocial from '@/components/Skeleton/SkeletonItemSocial';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocialApi from '@/Hooks/fetchApi/useSocial';
import {
  KeywordTypes,
  MentionedTypes,
  OverviewSourceByTopicTypes,
  SentimentByTimeTypes,
  SourceItemTypes,
  StatisticalByTimeOverviewTypes,
  UserItemTypes,
} from '@/types/Social';
import { SEARCH_FACEBOOK, TYPE_SEARCH } from '@/utils/constants';

import OverviewFacebook from './OverviewFacebook';
import LineChartMentionsByTime from '../../chart/LineChartMentionsByTime';
import LineChartSentimentByTime from '../../chart/LineChartSentimentByTime';
import PieChart from '../../chart/PieChart';
import WordCloud from '../../chart/WordCloud';
import GroupItemFacebook from '../../facebook/GroupItemFacebook';
import UserItemFacebook from '../../facebook/UserItemFacebook';

const Facebook = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { topicSocial, timeScan } = useSocialInfo();
  const { handleGetOverviewSocial } = useSocialApi();
  const { apiError } = useCommonInfo();

  const [overviewData, setOverviewData] = useState<OverviewSourceByTopicTypes[]>([]);
  const [listUsers, setListUsers] = useState<UserItemTypes[]>([]);
  const [listGroups, setListGroups] = useState<SourceItemTypes[]>([]);
  const [statisticalByTime, setStatisticalByTime] = useState<StatisticalByTimeOverviewTypes[]>([]);
  const [mentionedBySource, setMentionedBySource] = useState<KeywordTypes[]>([]);
  const [mentionedBySentiment, setMentionedBySentiment] = useState<MentionedTypes[]>([]);
  const [sentimentByTime, setSentimentByTime] = useState<SentimentByTimeTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [listKeyword, setListKeyword] = useState<KeywordTypes[]>([]);

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  // Đề cập theo nguồn
  const transformDataMentionBySource = (data: KeywordTypes[]) => {
    return data.map((item: KeywordTypes) => {
      let label;
      switch (item.label) {
        case 'total_mentions':
          label = t('smcc.overview.totalMentions');
          break;
        case 'pages':
          label = t('smcc.overview.page');
          break;
        case 'groups':
          label = t('smcc.overview.group');
          break;
        case 'personal_pages':
          label = t('smcc.overview.profile');
          break;
        default:
          label = item.label;
          break;
      }
      return {
        label,
        count: item.count,
      };
    });
  };

  // Đề cập theo sắc thái
  const transformDataMentionBySentiment = (data: MentionedTypes[]) => {
    return data.map((item: MentionedTypes) => {
      let source;
      let sliceSettings;
      switch (item.source) {
        case 'positive':
          source = t('smcc.status.positive');
          sliceSettings = {
            fill: theme.palette.success.main,
            stroke: theme.palette.success.main,
          };
          break;
        case 'neutral':
          source = t('smcc.status.neutral');
          sliceSettings = {
            fill: '#cb997e',
            stroke: '#cb997e',
          };
          break;
        case 'negative':
          source = t('smcc.status.negative');
          sliceSettings = {
            fill: theme.palette.error.main,
            stroke: theme.palette.error.main,
          };
          break;
        default:
          source = item.source;
          sliceSettings = {};
          break;
      }
      return {
        source,
        count: item.count,
        sliceSettings,
      };
    });
  };

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (topicSocial && timeScan) {
      handleRequest();
    }
  }, [topicSocial, timeScan]);

  const handleRequest = async () => {
    setLoading(true);
    const params = {
      keyword: topicSocial?.key_word_main,
      social: SEARCH_FACEBOOK,
      search_type: TYPE_SEARCH,
      time_range: timeScan,
    };

    const res = await handleGetOverviewSocial(params, handleLoading);
    const { data } = res;
    const {
      overviews,
      mentioned_by_source,
      sentiment_mention,
      statistical_time,
      sentiment_time,
      user_list,
      page_group_lists,
      keywords,
    } = data;

    setOverviewData(overviews);
    setListUsers(user_list);
    setListGroups(page_group_lists);
    setMentionedBySource(mentioned_by_source);
    setStatisticalByTime(statistical_time);
    setMentionedBySentiment(transformDataMentionBySentiment(sentiment_mention));
    setSentimentByTime(sentiment_time);
    setListKeyword(keywords);
  };

  return (
    <>
      {!loading ? (
        <>
          <OverviewFacebook data={overviewData} />

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  padding: 2,
                  boxShadow: 4,
                  borderRadius: 1,
                  backgroundColor: 'background.main',
                  minHeight: '416px',
                }}
              >
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.featuredKeywords')}
                </Typography>
                {!_.isEmpty(listKeyword) ? <WordCloud data={listKeyword} /> : <NoResult />}
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsByTimeGraph')}
                </Typography>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-around' }}>
                  {transformDataMentionBySource(mentionedBySource).map((item: KeywordTypes, index: number) => (
                    <Box key={index}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {item.label}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, textAlign: 'center', color: 'text.secondary' }}
                      >
                        {item.count}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <LineChartMentionsByTime data={statisticalByTime} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.sentimentByTimeGraph')}
                </Typography>
                <LineChartSentimentByTime data={sentimentByTime} />
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsBySentimentGraph')}
                </Typography>
                <PieChart data={mentionedBySentiment} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('smcc.overview.listUsers')}</Typography>
            {!_.isEmpty(listUsers) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {listUsers.map((item: UserItemTypes) => (
                  <Grid item sm={6} xs={12} key={item.username}>
                    <UserItemFacebook item={item} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
          </Box>
          <Box sx={{ mt: 4, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('smcc.overview.listGroups')}</Typography>
            {!_.isEmpty(listGroups) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {listGroups.map((item: SourceItemTypes) => (
                  <Grid item sm={6} xs={12} key={item.name}>
                    <GroupItemFacebook item={item} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
          </Box>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Facebook;
