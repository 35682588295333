import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import { NewsConfig } from '@/configs/NewsConfig';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { Category, CatTypes } from '@/types/news';

interface CreateReportProps {
  openDialogReport: boolean;
  onDialogReportChange: (newValue: boolean) => void;
}

const CreateReport: React.FC<CreateReportProps> = ({ openDialogReport, onDialogReportChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { typeNews, categoriesBusiness, categoriesCyberSecurity } = NewsConfig();
  const { handleSendReportNews } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('both');
  const [fieldNews, setFieldNews] = useState('');

  const today = new Date().toISOString().split('T')[0];

  const handleClearInput = () => {
    setValue('subject', '');
    setValue('emails', []);
    setValue('startDate', '');
    setValue('endDate', '');
    setType('both');
    setFieldNews('');
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleChangeField = (event: SelectChangeEvent) => {
    setFieldNews(event.target.value);
  };

  const sendReportSuccess = () => {
    onDialogReportChange(false);
    handleClearInput();
    setSearchParams({ page: String(1) });
  };

  const validationSchema = yup.object().shape({
    subject: yup.string(),
    emails: yup
      .array()
      .of(yup.string().email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired'))
      .default([]),
    startDate: yup.string().required(t('validation.fieldRequired')),
    endDate: yup
      .string()
      .required(t('validation.fieldRequired'))
      .test('is-after-startDate', t('validation.endDate'), function (value) {
        // Kiểm tra endDate phải lớn hơn hoặc bằng startDate
        // eslint-disable-next-line react/no-this-in-sfc
        const { startDate } = this.parent; // Lấy giá trị của startDate từ schema
        return !startDate || !value || value >= startDate;
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      emails: [], // emails khởi tạo dưới dạng một mảng rỗng
    },
  });

  const onSubmit = async (data: any) => {
    const params = {
      email_subject: data.subject,
      emails: data.emails,
      start_date: data.startDate,
      end_date: data.endDate,
      type_news: type,
      selected_field: fieldNews,
      impact_level: 'high',
    };
    handleSendReportNews(params, sendReportSuccess);
  };

  return (
    <>
      <Dialog
        open={openDialogReport}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogReportChange(false);
          handleClearInput();
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '600px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.news.reportTitle')}</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ color: 'text.primary', mb: 0.5 }}>
                {t('management.news.subject')}
              </Typography>
              <Controller
                name="subject"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.subject')}
                    sx={{ mt: 0.5 }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    id="input-with-icon-textfield"
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ color: 'text.primary' }}>
                {t('management.news.email')}
              </Typography>
              <Controller
                name="emails"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    value={field.value || []}
                    onChange={(event, newValue) => {
                      // Chỉ thêm những email hợp lệ
                      const validEmails = newValue.filter((email) => {
                        // Kiểm tra email bằng RegEx hoặc yup
                        const isValid = yup.string().email().isValidSync(email);
                        if (!isValid) {
                          // eslint-disable-next-line no-alert
                          alert(`Email không hợp lệ: ${email}`); // Thông báo nếu email không hợp lệ
                        }
                        return isValid;
                      });
                      field.onChange(validEmails); // Cập nhật chỉ với email hợp lệ
                    }}
                    renderTags={
                      (value, getTagProps) =>
                        value.map((option, index) => (
                          <>
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          </>
                        ))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    sx={{
                      mt: 0.5,
                      '.MuiChip-label': {
                        fontSize: '1.4rem',
                      },
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t('placeholder.email')}
                        error={!!errors.emails}
                        helperText={errors.emails ? errors.emails.message : ''}
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>
                {t('management.news.enterToAdd')}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" sx={{ color: 'text.primary' }}>
                {t('management.news.typeNews')}
              </Typography>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={type}
                onChange={handleChangeType}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: 5,
                    },
                  },
                }}
                sx={{
                  mt: 0.5,
                  '.MuiInputBase-input': {
                    padding: '15.6px 14px',
                  },
                }}
              >
                {typeNews.map((item: CatTypes) => (
                  <MenuItem
                    key={item.id}
                    value={item.param}
                    sx={{
                      '&.MuiMenuItem-root': {
                        '&.Mui-selected': {
                          backgroundColor: 'action.selected',
                        },
                      },
                    }}
                  >
                    <Typography variant="body2">{item.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {type === 'cyber_security' && (
              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" sx={{ color: 'text.primary' }}>
                  {t('management.news.field')}
                </Typography>
                <Select
                  labelId="field-label"
                  id="field-id"
                  value={fieldNews}
                  onChange={handleChangeField}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: 5,
                      },
                    },
                  }}
                  sx={{
                    mt: 0.5,
                    '.MuiInputBase-input': {
                      padding: '15.6px 14px',
                    },
                  }}
                >
                  {categoriesCyberSecurity.map((item: Category) => (
                    <MenuItem
                      key={item.id}
                      value={item.param}
                      sx={{
                        '&.MuiMenuItem-root': {
                          '&.Mui-selected': {
                            backgroundColor: 'action.selected',
                          },
                        },
                      }}
                    >
                      <Typography variant="body2">{item.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            {type === 'business' && (
              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" sx={{ color: 'text.primary' }}>
                  {t('management.news.field')}
                </Typography>
                <Select
                  labelId="field-label"
                  id="field-id"
                  value={fieldNews}
                  onChange={handleChangeField}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: 5,
                      },
                    },
                  }}
                  sx={{
                    mt: 0.5,
                    '.MuiInputBase-input': {
                      padding: '15.6px 14px',
                    },
                  }}
                >
                  {categoriesBusiness.map((item: Category) => (
                    <MenuItem
                      key={item.id}
                      value={item.param}
                      sx={{
                        '&.MuiMenuItem-root': {
                          '&.Mui-selected': {
                            backgroundColor: 'action.selected',
                          },
                        },
                      }}
                    >
                      <Typography variant="body2">{item.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ color: 'text.primary' }}>
                {t('management.news.startDate')}
              </Typography>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="date"
                    style={{ width: '100%' }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    id="input-with-icon-textfield"
                    inputProps={{ max: today }}
                    sx={{
                      mt: 0.5,
                      '& .MuiInputBase-input[type="date"]::-webkit-calendar-picker-indicator': {
                        filter: 'invert(30%) sepia(80%) saturate(300%) hue-rotate(180deg)',
                      },
                      '& .MuiInputBase-input[type="date"]::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                      '& .MuiInputBase-input[type="date"]::-webkit-clear-button': {
                        display: 'none',
                      },
                    }}
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ color: 'text.primary' }}>
                {t('management.news.endDate')}
              </Typography>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="date"
                    style={{ width: '100%' }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    id="input-with-icon-textfield"
                    inputProps={{ max: today }}
                    sx={{
                      mt: 0.5,
                      '& .MuiInputBase-input::placeholder': {
                        color: 'text.secondary',
                      },
                      '& .MuiInputBase-input[type="date"]::-webkit-calendar-picker-indicator': {
                        filter: 'invert(30%) sepia(80%) saturate(300%) hue-rotate(180deg)',
                      },
                      '& .MuiInputBase-input[type="date"]::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                      '& .MuiInputBase-input[type="date"]::-webkit-clear-button': {
                        display: 'none',
                      },
                    }}
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                {t('action.send')}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateReport;
