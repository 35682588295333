import React, { ReactNode } from 'react';

import { AppBar, Box } from '@mui/material';

import SideBar from '@/components/Social/SideBar/SideBar';
import useBreakpoints from '@/helpers/useBreakpoints';

import Header from './Header';

interface SocialLayoutProps {
  children: ReactNode;
}

const SocialLayout: React.FC<SocialLayoutProps> = ({ children }) => {
  const { isMobile, isTablet } = useBreakpoints();
  return (
    <div>
      <Header />
      <Box>
        {!isMobile && !isTablet && (
          <AppBar
            elevation={0}
            sx={{
              width: '180px',
              position: 'fixed',
              left: 0,
              top: '60px',
              bottom: 0,
              backgroundColor: 'background.main',
              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
              padding: 1,
            }}
          >
            <SideBar />
          </AppBar>
        )}
        <Box
          sx={{
            marginLeft: !isMobile && !isTablet ? '180px' : 0,
            padding: !isMobile && !isTablet ? '60px 32px 30px' : '60px 15px 15px',
            minHeight: '100vh',
            backgroundColor: 'background.dark',
          }}
        >
          <Box sx={{ mt: '20px' }}>{children}</Box>
        </Box>
      </Box>
    </div>
  );
};

export default SocialLayout;
