import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Button,
  TableContainer,
  useTheme,
  TableHead,
  Table,
  TableCell,
  tableCellClasses,
  styled,
  TableRow,
  TableBody,
  Tooltip,
  Skeleton,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { useCommonInfo } from '@/contexts/Common';
import usePhishing from '@/Hooks/fetchApi/usePhishing';
import { IFilteredMalware } from '@/interfaces/phishing';
import { decrypt } from '@/utils/crypto';
import { fDateTime } from '@/utils/function';

import DetailCampaign from './DetailCampaign';
import UpdateCampaign from './UpdateCampaign';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

interface TableCampaignProps {
  campaign: [];
  setSearchValue: (newValue: string) => void;
  setFilterCampaign: (newValue: []) => void;
  setPage: (newValue: number) => void;
  loadingFilter?: boolean;
  handleGetListCampaign: (arg: number) => Promise<void>;
  listMalware: IFilteredMalware[];
}

const TableCampaign: React.FC<TableCampaignProps> = ({
  campaign,
  setSearchValue,
  setFilterCampaign,
  setPage,
  loadingFilter,
  handleGetListCampaign,
  listMalware,
}) => {
  const { t } = useTranslation();
  const { isLoading } = useCommonInfo();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleDeleteDetailCampaign, handleRunDetailCampaign } = usePhishing();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [campaignSelected, setCampaignSelected] = useState<any>({});

  const deleteCampaignSuccess = () => {
    setOpenDialogDelete(false);
    setSearchValue('');
    setFilterCampaign([]);
    handleGetListCampaign(1);
    setPage(1);
  };

  const handleDeleteCampaign = () => {
    handleDeleteDetailCampaign(idDelete, deleteCampaignSuccess);
  };

  const handleRunCampaign = (value: string) => {
    handleRunDetailCampaign(value, {});
  };

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: '20%' }}>{t('asm.phishing.campaignName')}</StyledTableCell>
            <StyledTableCell sx={{ width: '30%' }}>{t('asm.phishing.description')}</StyledTableCell>
            <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>
              {t('asm.phishing.modifiedAt')}
            </StyledTableCell>
            {inforUser.role !== 'member' && <StyledTableCell sx={{ width: '30%' }}> </StyledTableCell>}
          </TableRow>
        </TableHead>

        {loadingFilter ? (
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="30%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              {inforUser.role !== 'member' && (
                <TableCell>
                  <Skeleton height={20} width="30%" />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {!isLoading ? (
              <>
                {!_.isEmpty(campaign) ? (
                  <>
                    {campaign?.map((row: any) => (
                      <React.Fragment key={row.id}>
                        <TableRow
                          sx={{
                            verticalAlign: 'baseline',
                          }}
                          hover
                        >
                          <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>{row.name}</StyledTableCell>
                          <StyledTableCell sx={{ width: '30%', whiteSpace: 'nowrap' }}>
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>
                            {fDateTime(row.modified_at)}
                          </StyledTableCell>
                          {inforUser.role !== 'member' && (
                            <StyledTableCell sx={{ width: '30%', whiteSpace: 'nowrap', textAlign: 'end' }}>
                              <Tooltip title={t('tooltip.launch')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    handleRunCampaign(row.id);
                                  }}
                                >
                                  <RocketLaunchIcon
                                    sx={{
                                      color: 'success.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title={t('tooltip.detail')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogDetail(true);
                                    setCampaignSelected(row);
                                  }}
                                >
                                  <ListAltIcon
                                    sx={{
                                      color: 'primary.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title={t('tooltip.edit')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogUpdate(true);
                                    setCampaignSelected(row);
                                  }}
                                >
                                  <EditIcon
                                    sx={{
                                      color: 'secondary.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title={t('tooltip.delete')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogDelete(true);
                                    setIdDelete(row.id);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: 'error.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                              <DetailCampaign
                                openDialogDetail={openDialogDetail}
                                setOpenDialogDetail={setOpenDialogDetail}
                                campaign={campaignSelected}
                              />
                              <UpdateCampaign
                                openDialogUpdate={openDialogUpdate}
                                setOpenDialogUpdate={setOpenDialogUpdate}
                                campaign={campaignSelected}
                                handleGetListCampaign={handleGetListCampaign}
                                setPage={setPage}
                                listMalware={listMalware}
                              />
                              <ConfirmDeleteModal
                                openDialogDelete={openDialogDelete}
                                onOpenDialogDeleteChange={setOpenDialogDelete}
                                handleDelete={handleDeleteCampaign}
                              />
                            </StyledTableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton height={20} width="20%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="30%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="20%" />
                </TableCell>
                {inforUser.role !== 'member' && (
                  <TableCell>
                    <Skeleton height={20} width="30%" />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
export default TableCampaign;
