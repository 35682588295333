import { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Typography, InputBase, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { FormatTimeExpire } from '@/components/common/format/FormatTimeExpire';
import { CompanyType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface ClientsProps {
  listCompanies: CompanyType[];
}

const Clients: React.FC<ClientsProps> = ({ listCompanies }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [filterCompany, setFilterCompany] = useState<CompanyType[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    const filteredCompanies = listCompanies.filter((company: CompanyType) => {
      const normalizedCompanyName = company?.name_client.toLowerCase();
      return normalizedCompanyName.includes(event.target.value);
    });
    setFilterCompany(filteredCompanies);

    if (event.target.value === '') {
      setFilterCompany([]);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: 2,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <InputBase
          sx={{
            width: '100%',
            ml: 1,
            flex: 1,
            '.MuiInputBase-input': {
              padding: '10px 10px',
            },
          }}
          placeholder={t('placeholder.typeSearch')}
          inputProps={{
            'aria-label': 'filter company',
          }}
          endAdornment={
            searchValue && (
              <ClearIcon
                onClick={() => {
                  setSearchValue('');
                  setFilterCompany([]);
                }}
                sx={{
                  mr: 3,
                  color: 'text.secondary',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            )
          }
          value={searchValue}
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ borderRadius: 1, mt: 3, padding: 1, backgroundColor: 'action.hover' }}>
        {searchValue ? (
          <>
            {!_.isEmpty(filterCompany) ? (
              <>
                {filterCompany.map((row: CompanyType, index: number) => (
                  <Box key={index} sx={{ display: 'flex', flexDirection: 'row', padding: 1 }}>
                    <CheckIcon sx={{ alignSelf: 'center' }} />
                    <Box
                      component="img"
                      src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${row?.main_domain}&size=32`}
                      alt="favicon"
                      sx={{
                        ml: 2,
                        width: '17px',
                        height: '17px',
                        alignSelf: 'center',
                      }}
                    />
                    <Typography variant="body2" sx={{ ml: 0.8 }}>
                      {row?.name_client}
                    </Typography>
                    {inforUser?.role !== 'super admin' && (
                      <FiberManualRecordIcon
                        sx={{ ml: 3, alignSelf: 'center', height: '0.6rem', color: 'text.secondary' }}
                      />
                    )}
                    {inforUser?.role !== 'super admin' && FormatTimeExpire(inforUser?.expireDate) > 0 && (
                      <Typography variant="body2" sx={{ ml: 3, color: 'success.main' }}>
                        {t('notify.licenseExpiredIn')} {FormatTimeExpire(inforUser?.expireDate)} {t('time.day')}
                      </Typography>
                    )}
                    {inforUser?.role !== 'super admin' && FormatTimeExpire(inforUser?.expireDate) <= 0 && (
                      <Typography variant="body2" sx={{ ml: 3, color: 'error.main' }}>
                        {t('notify.licenseExpired')}
                      </Typography>
                    )}
                  </Box>
                ))}
              </>
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                {t('notify.noResult')}
              </Typography>
            )}
          </>
        ) : (
          <>
            {listCompanies.map((row: CompanyType, index: number) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'row', padding: 1 }}>
                <CheckIcon sx={{ alignSelf: 'center' }} />
                <Box
                  component="img"
                  src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${row?.main_domain}&size=32`}
                  alt="favicon"
                  sx={{
                    ml: 2,
                    width: '17px',
                    height: '17px',
                    alignSelf: 'center',
                  }}
                />
                <Typography variant="body2" sx={{ ml: 0.8 }}>
                  {row?.name_client}
                </Typography>
                {inforUser?.role !== 'super admin' && (
                  <FiberManualRecordIcon
                    sx={{ ml: 3, alignSelf: 'center', height: '0.6rem', color: 'text.secondary' }}
                  />
                )}
                {inforUser?.role !== 'super admin' && FormatTimeExpire(inforUser?.expireDate) > 0 && (
                  <Typography variant="body2" sx={{ ml: 3, color: 'success.main' }}>
                    {t('notify.licenseExpiredIn')} {FormatTimeExpire(inforUser?.expireDate)} {t('time.day')}
                  </Typography>
                )}
                {inforUser?.role !== 'super admin' && FormatTimeExpire(inforUser?.expireDate) <= 0 && (
                  <Typography variant="body2" sx={{ ml: 3, color: 'error.main' }}>
                    {t('notify.licenseExpired')}
                  </Typography>
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    </>
  );
};
export default Clients;
