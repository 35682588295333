import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Tooltip,
  Link,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Checkbox,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { useCommonInfo } from '@/contexts/Common';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { ProductSelectedType, ProductType, VendorType } from '@/types/Assets';
import { decrypt } from '@/utils/crypto';

import HeaderTableProduct from './HeaderTableProduct';
import UpdateAsset from './UpdateAsset';

interface TableProductProps {
  listVendors: VendorType;
  onSearchValueChange: (newValue: string) => void;
  searchValue?: string;
  handleFilterAsset?: (searchKey: undefined, pageValue: number) => void;
  handleGetListAsset: () => void;
  groupId: number;
}

const TableProduct = (props: TableProductProps) => {
  const { listVendors, onSearchValueChange, handleGetListAsset, groupId } = props;

  const { isLoading } = useCommonInfo();
  const { t } = useTranslation();

  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleDeleteAsset } = useAsm();

  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<ProductSelectedType[]>([]);
  const [productIdDelete, setProductIdDelete] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<ProductType>();
  const [listProducts, setListProducts] = useState<ProductType[]>(listVendors.list_product);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = listVendors.list_product.map((n: ProductType) => ({
        product_id: n.id,
        group_id: groupId,
      }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string, group_id: number) => {
    const selectedIndex = selected.findIndex((item: ProductSelectedType) => item.product_id === id);
    let newSelected: ProductSelectedType[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { product_id: id, group_id });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.findIndex((item: ProductSelectedType) => item.product_id === id) !== -1;

  const navigateDeleteSuccess = () => {
    setOpenDialogDelete(false);
    onSearchValueChange('');
    setSelected([]);
    setListProducts(listProducts.filter((item) => item.id !== productIdDelete));
  };

  const navigateDeleteAllSuccess = () => {
    setOpenDialogDeleteAll(false);
    onSearchValueChange('');
    setSelected([]);
    setListProducts([]);
  };

  const handleDelete = () => {
    handleDeleteAsset(
      {
        list_product_delete: [
          {
            product_id: productIdDelete,
            group_id: groupId,
          },
        ],
      },
      navigateDeleteSuccess,
    );
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <Table aria-label="simple table">
          <HeaderTableProduct
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={listProducts.length}
            openDialogDeleteAll={openDialogDeleteAll}
            onOpenDialogDeleteAllChange={setOpenDialogDeleteAll}
            selected={selected}
            navigateDeleteAllSuccess={navigateDeleteAllSuccess}
            listProduct={listProducts}
          />
          <TableBody>
            {!isLoading ? (
              <>
                {!_.isEmpty(listProducts) ? (
                  <>
                    {listProducts.map((row: ProductType, index: number) => (
                      <TableRow
                        key={row.id}
                        hover
                        tabIndex={-1}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              role="checkbox"
                              onClick={(event) => handleClick(event, row.id, groupId)}
                              checked={isSelected(row.id)}
                              inputProps={{
                                'aria-labelledby': `enhanced-table-checkbox-${index}`,
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell sx={{ padding: '10px' }}>
                          <Link
                            underline="none"
                            sx={{
                              fontSize: '1.2rem',
                              padding: 0.5,
                              color: 'primary.main',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.dark',
                              },
                            }}
                            href={`/cve?vendor=${listVendors.vendor}&product=${row.product}`}
                            rel="noopener noreferrer"
                          >
                            {row.product_label}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ padding: '10px', fontSize: '1.2rem' }}>{row.version}</TableCell>

                        {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
                          <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                            <Tooltip title={t('tooltip.edit')} placement="bottom">
                              <EditIcon
                                sx={{
                                  fontSize: '2rem',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'primary.main',
                                  },
                                }}
                                onClick={() => {
                                  setOpenDialogUpdate(true);
                                  setSelectedProduct(row);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={t('tooltip.delete')} placement="bottom">
                              <DeleteIcon
                                onClick={() => {
                                  setOpenDialogDelete(true);
                                  setProductIdDelete(row.id);
                                }}
                                sx={{
                                  fontSize: '2rem',
                                  ml: 2,
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'error.main',
                                  },
                                }}
                              />
                            </Tooltip>

                            <ConfirmDeleteModal
                              openDialogDelete={openDialogDelete}
                              onOpenDialogDeleteChange={setOpenDialogDelete}
                              handleDelete={handleDelete}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
                  <TableCell>
                    <Skeleton height={20} width="80%" />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateAsset
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        product={selectedProduct!}
        handleGetListAsset={handleGetListAsset}
        groupId={groupId}
      />
    </>
  );
};
export default TableProduct;
