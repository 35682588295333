import { format } from 'date-fns';

export const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'MMMM do yyyy');
};

export const formatDateAndHours = (dateString: string) => {
  return format(new Date(dateString), 'yyyy-MM-dd HH:mm:ss');
};

export const formatDateHours = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};
