import React, { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

interface IdentityLayoutProps {
  children: ReactNode;
}

const IdentityLayout: React.FC<IdentityLayoutProps> = ({ children }) => {
  return (
    <div>
      <Header />
      <main style={{ fontSize: '16px' }}>{children}</main>
      <Footer />
    </div>
  );
};

export default IdentityLayout;
