import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface LineChartMentionsByTimeStatisticProps {
  data: { [key: string]: string | number }[];
}

const LineChartMentionsByTimeStatistic: React.FC<LineChartMentionsByTimeStatisticProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formatDate = (tick: string) => {
    const [day, month, year] = tick.split('/');
    return `${day}/${month}`;
  };

  const getColorForKey = (key: string): string => {
    const colorMap: { [key: string]: string } = {
      newspaper: theme.palette.info.main,
      forum: theme.palette.warning.main,
      youtube: theme.palette.error.main,
      tiktok: theme.palette.success.main,
      facebook: theme.palette.primary.main,
    };

    return colorMap[key] || theme.palette.primary.main;
  };

  const getNameForKey = (key: string): string => {
    const colorMap: { [key: string]: string } = {
      newspaper: t('smcc.overview.news'),
      forum: t('smcc.overview.forum'),
      youtube: 'Youtube',
      tiktok: 'Tiktok',
      facebook: 'Facebook',
    };

    return colorMap[key] || key;
  };

  const keys = data && data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'day') : [];

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            tickFormatter={formatDate}
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -2, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip
            contentStyle={{ backgroundColor: theme.palette.background.paper }}
            itemStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <Legend wrapperStyle={{ fontSize: 14 }} />
          {keys.map((key) => (
            <Line
              key={key}
              yAxisId="linearAxis"
              type="monotone"
              dataKey={key}
              stroke={getColorForKey(key)}
              activeDot={{ r: 8 }}
              name={getNameForKey(key)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default LineChartMentionsByTimeStatistic;
