import { useState } from 'react';

import {
  Avatar,
  Box,
  Link,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useLoadingGif from '@/Hooks/common/useLoadingGif';
import { FeatureSourceDetailTypes, FeatureSourceTypes } from '@/types/Social';

interface IMappings {
  [key: string]: string;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface FeaturedSourcesProps {
  listSources: FeatureSourceTypes[];
}

const FeaturedSources: React.FC<FeaturedSourcesProps> = ({ listSources }) => {
  const { t } = useTranslation();
  const { setLoadingGif } = useLoadingGif();

  // const [selectedTab, setSelectedTab] = useState('newspaper');
  const [selectedTab, setSelectedTab] = useState(listSources.length > 0 ? listSources[0].source : '');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setLoadingGif(true);
    setTimeout(() => {
      setLoadingGif(false);
    }, 500);
  };

  const mappingSourcename: IMappings = {
    newspaper: t('smcc.overview.news'),
    forum: t('smcc.overview.forum'),
    facebook: 'Facebook',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
  };

  const getProfileLink = (item: FeatureSourceDetailTypes, source: string) => {
    switch (source) {
      case 'facebook':
        return `https://facebook.com/${item.username}`;
      default:
        return item.username;
    }
  };

  return (
    <Box sx={{ mt: 4, boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
      <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('smcc.overview.featuredSources')}</Typography>
      <Box sx={{ mt: 1, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="featured articles tabs">
          {listSources.map((item) => (
            <Tab
              label={mappingSourcename[item.source]}
              value={item.source}
              key={item.source}
              sx={{ textTransform: 'capitalize' }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ mt: 2 }}>
        {listSources.filter((source) => source.source === selectedTab).flatMap((source) => source.featured_source)
          .length > 0 ? (
          // eslint-disable-next-line react/jsx-indent
          <>
            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">#</TableCell>
                    <TableCell>{t('smcc.overview.sourceMentioned')}</TableCell>
                    <TableCell width="20%">{t('smcc.overview.post')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listSources
                    .filter((source) => source.source === selectedTab)
                    .flatMap((source) =>
                      source.featured_source.map((item, index) => (
                        <StyledTableRow key={item.username}>
                          <TableCell sx={{ color: 'text.secondary' }}>{index + 1}</TableCell>
                          <TableCell>
                            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                              <Link href={getProfileLink(item, source.source)} target="_blank" rel="noopener">
                                <Avatar
                                  alt="image"
                                  variant="rounded"
                                  src={item?.avatar}
                                  sx={{
                                    width: '50px',
                                    height: '40px',
                                    '.MuiAvatar-img': {
                                      objectFit: 'contain',
                                    },
                                  }}
                                />
                              </Link>
                              <Box>
                                <Link
                                  href={getProfileLink(item, source.source)}
                                  target="_blank"
                                  rel="noopener"
                                  sx={{ textDecoration: 'none', ':hover': { textDecoration: 'underline' } }}
                                >
                                  <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
                                    {item?.name}
                                  </Typography>
                                </Link>
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>
                            {item?.user_post_count}
                          </TableCell>
                        </StyledTableRow>
                      )),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('notify.noData')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default FeaturedSources;
