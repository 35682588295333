import RemoveIcon from '@mui/icons-material/Remove';
import {
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Pagination,
  Box,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCommonInfo } from '@/contexts/Common';
import { VendorType } from '@/types/Assets';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

import TableCVE from './TableCVE';
import TableProduct from './TableProduct';

interface TableAssetProps {
  listVendors: VendorType[];
  onSearchValueChange: (newValue: string) => void;
  onPageChange: (newValue: number) => void;
  page: number;
  searchValue?: string;
  handleFilterAsset?: (searchKey: undefined, pageValue: number) => void;
  handleGetListAsset: () => void;
  groupId: number;
  totalVendor: number;
}

const TableAsset = (props: TableAssetProps) => {
  const {
    listVendors,
    onSearchValueChange,
    onPageChange,
    page,
    searchValue,
    handleFilterAsset,
    handleGetListAsset,
    groupId,
    totalVendor,
  } = props;

  const { isLoading } = useCommonInfo();
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
    if (searchValue && inforUser.role !== 'super admin') {
      handleFilterAsset?.(undefined, pageValue);
    }
  };

  return (
    <>
      <TableContainer sx={{ boxShadow: 2, padding: 2, borderRadius: 1, backgroundColor: 'action.hover' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ width: '15%' }}>{t('table.asset.vendor')}</TableCell>
              <TableCell align="center" sx={{ width: '35%' }}>
                {t('table.asset.product')}
              </TableCell>
              <TableCell align="center" sx={{ width: '35%' }}>
                {t('table.asset.vulnerability')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              <>
                {!_.isEmpty(listVendors) ? (
                  <>
                    {listVendors.map((row: VendorType) => (
                      <TableRow
                        key={row.vendor}
                        hover
                        tabIndex={-1}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          <Link
                            href={`/cve?vendor=${row.vendor}`}
                            rel="noopener"
                            underline="none"
                            sx={{
                              color: 'primary.main',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.dark',
                              },
                            }}
                          >
                            {row.vendor_label}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {!_.isEmpty(row.list_product) ? (
                            <TableProduct
                              listVendors={row}
                              onSearchValueChange={onSearchValueChange}
                              handleGetListAsset={handleGetListAsset}
                              groupId={groupId}
                            />
                          ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <RemoveIcon />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {!_.isEmpty(row.list_cve) ? (
                            <TableCVE listCVEs={row.list_cve} totalCVE={row.total_cve} vendor={row.vendor} />
                          ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <RemoveIcon />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="80%" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalVendor / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            defaultPage={page}
          />
        </Box>
      </TableContainer>
    </>
  );
};
export default TableAsset;
