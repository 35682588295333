import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoResult = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {t('notify.noData')}
      </Typography>
    </Box>
  );
};
