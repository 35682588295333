import { useEffect, useState } from 'react';

import { Box, Typography, Grid } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import AosComponent from '@/components/animation/AosComponent';
import CarouselNews from '@/components/News/CarouselNews';
import CategoryCyberSecurityNews from '@/components/News/CategoryCyberSecurityNews';
import SubItems from '@/components/News/SubItems';
import SubItemsAnimate from '@/components/News/SubItemsAnimate';
import SubPost from '@/components/News/SubPost';
import SubPostLabel from '@/components/News/SubPostLabel';
import { NewsConfig } from '@/configs/NewsConfig';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { NewsTypes, NewsState } from '@/types/news';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE, TYPE_CYBER_SECURITY, TYPE_FINANCE } from '@/utils/constants';

const CyberSecurity = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { handleListNews, handleFilterNews, handleTopNews } = useAsm();
  const locale = localStorage.getItem('locale') || 'vi';
  const { categoriesCyberSecurity } = NewsConfig();

  const getField = (url: string): string | null => {
    const mappings: { [key: string]: string } = {
      'cyber-security': 'all',
      'cyber-crime': 'Cyber crime',
      'cyber-warfare': 'Cyber warfare',
      apt: 'Apt',
      'data-breach': 'Data breach',
      'deep-web': 'Deep web',
      'digital-id': 'Digital id',
      hacking: 'Hacking',
      hacktivism: 'HackTivism',
      phishing: 'Phishing',
    };
    const regex = /\/([^\/]+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return mappings[match[1]] || match[1];
    }
    return null;
  };

  const [newsState, setNewsState] = useState<NewsState>({
    listNews: [],
    hasMore: true,
    currentPage: 1,
    listFilterModal: [],
    searchValue: '',
    enterKeyPressed: false,
    listFilterPage: [],
    hasMoreFilter: true,
    currentPageFilter: 1,
  });

  const [topNews, setTopNews] = useState<NewsTypes[]>([]);
  const [fieldUpdated, setFieldUpdated] = useState(false);

  const updateNewsState = (newState: Partial<NewsState>) => {
    setNewsState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    if (newState.field !== undefined) {
      setFieldUpdated((prev) => !prev);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, [fieldUpdated, location.pathname]);

  useEffect(() => {
    if (newsState.enterKeyPressed) {
      fetchMoreDataFilter();
    }
  }, [newsState.enterKeyPressed]);

  const fetchMoreData = async () => {
    const params = {
      page: newsState.currentPage,
      page_size: PAGE_SIZE,
      field: getField(location.pathname),
      type_news: TYPE_CYBER_SECURITY,
    };
    const res = await handleListNews(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_news } = res;
    if (list_news && list_news.length > 0) {
      updateNewsState({
        listNews: [...newsState.listNews, ...list_news],
        currentPage: newsState.currentPage + 1,
      });
    } else {
      // Nếu không còn dữ liệu để fetch, setHasMore(false)
      updateNewsState({
        hasMore: false,
      });
    }
  };

  const fetchMoreDataFilter = async () => {
    const params = {
      page: newsState.currentPageFilter,
      page_size: PAGE_SIZE,
      key_word: newsState.searchValue,
      lang: locale,
      type_news: TYPE_CYBER_SECURITY,
    };
    const res = await handleFilterNews(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_news_filter } = res;
    if (list_news_filter && list_news_filter.length > 0) {
      updateNewsState({
        listFilterPage: [...newsState.listFilterPage, ...list_news_filter],
        currentPageFilter: newsState.currentPageFilter + 1,
      });
    } else {
      // Nếu không còn dữ liệu để fetch, setHasMore(false)
      updateNewsState({
        hasMoreFilter: false,
      });
    }
  };

  useEffect(() => {
    if (newsState.searchValue.length > 2) {
      const delayInputTimeoutId = setTimeout(async () => {
        const res = await handleFilterNews({
          page: PAGE_INDEX_DEFAULT,
          page_size: PAGE_SIZE,
          key_word: newsState.searchValue,
          lang: locale,
          type_news: TYPE_CYBER_SECURITY,
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { list_news_filter } = res;
        updateNewsState({
          listFilterModal: list_news_filter,
        });

        if (_.isEmpty(list_news_filter)) {
          updateNewsState({
            listFilterPage: [],
          });
        }
      }, 500);

      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [newsState.searchValue, 500]);

  const handleGetTopNews = async () => {
    const res = await handleTopNews({ type_news: TYPE_CYBER_SECURITY });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_news_top } = res;
    setTopNews(list_news_top);
  };

  useEffect(() => {
    handleGetTopNews();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('titleHelmet.newsCyberSecurity')}</title>
      </Helmet>

      <Box
        sx={{
          maxWidth: '1200px',
          alignSelf: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          minHeight: '100vh',
          position: 'relative',
          padding: { xs: 2 },
        }}
      >
        <CategoryCyberSecurityNews
          category={categoriesCyberSecurity}
          newsState={newsState}
          updateNewsState={updateNewsState}
        />

        {newsState.enterKeyPressed ? (
          <Box
            sx={{
              marginTop: { xs: '100px', sm: '130px', md: '160px', lg: '170px' },
            }}
          >
            {!_.isEmpty(newsState.listFilterPage) ? (
              <InfiniteScroll
                dataLength={newsState.listFilterPage.length}
                next={fetchMoreDataFilter}
                hasMore={newsState.hasMoreFilter}
                loader=""
                endMessage=""
                style={{ overflow: 'hidden' }}
              >
                <SubItems newsState={newsState} updateNewsState={updateNewsState} />
              </InfiniteScroll>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <Typography variant="body2" sx={{ textAlign: 'center', padding: 1, color: 'text.secondary' }}>
                  {t('notify.noResult')}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: { xs: '120px', sm: '140px', md: '160px', lg: '170px' },
            }}
          >
            <InfiniteScroll
              dataLength={newsState.listNews.length}
              next={fetchMoreData}
              hasMore={newsState.hasMore}
              loader=""
              endMessage=""
              style={{ overflow: 'hidden' }}
              scrollThreshold={0.5}
            >
              <CarouselNews listNews={newsState.listNews.slice(0, 4)} updateNewsState={updateNewsState} />
              <Grid container spacing={5} sx={{ mt: { sm: 0, md: '10px' } }}>
                <Grid item xs={12} md={8}>
                  <SubItems newsState={newsState} updateNewsState={updateNewsState} />
                  <SubItemsAnimate newsState={newsState} updateNewsState={updateNewsState} />
                </Grid>
                <Grid
                  item
                  xs={0}
                  md={4}
                  sx={{
                    '@media (max-width: 850px)': {
                      display: 'none',
                    },
                  }}
                >
                  {topNews.length !== 0 && (
                    <AosComponent dataAos="fade-left" dataAosDuration="1000">
                      <SubPostLabel label="news.trending" />
                    </AosComponent>
                  )}

                  <SubPost listNews={topNews} updateNewsState={updateNewsState} />
                </Grid>
              </Grid>
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    </>
  );
};
export default CyberSecurity;
