import { useTranslation } from 'react-i18next';

export interface LinkList {
  url: string;
  text: string;
}

export interface LinkGroup {
  title: string;
  list: LinkList[];
}

export const relatedLink = {
  TERM_AND_CONDITION: '#',
  PRIVACY_POLICY: '#',
  FACEBOOK: '#',
  TWITTER: '#',
};

export const MenuFooterMenu = () => {
  const { t } = useTranslation();

  const footerMenu1: LinkList[] = [
    { url: '#', text: t('identity.footer.menu1.search') },
    { url: '#', text: t('identity.footer.menu1.investigate') },
    { url: '#', text: t('identity.footer.menu1.passwordLeaked') },
    { url: '#', text: t('identity.footer.menu1.faceSearch') },
  ];
  const footerMenu2: LinkList[] = [
    { url: '#', text: t('identity.footer.menu2.massiveChecking') },
    { url: '#', text: t('identity.footer.menu2.identityGuard') },
  ];
  const footerMenu3: LinkList[] = [
    { url: '#', text: t('identity.footer.menu3.ourMission') },
    { url: '#', text: t('identity.footer.menu3.blog') },
    { url: '#', text: t('identity.footer.menu3.ourResearch') },
  ];

  const footerGroup: LinkGroup[] = [
    {
      title: t('identity.footer.groupTitles.quickLink'),
      list: footerMenu1,
    },
    {
      title: t('identity.footer.groupTitles.service'),
      list: footerMenu2,
    },
    {
      title: t('identity.footer.groupTitles.about'),
      list: footerMenu3,
    },
  ];

  return footerGroup;
};
