import { Box, Typography, useTheme } from '@mui/material';

export interface SearchOptionType {
  name: string;
  value: string;
}

export const searchOptions: SearchOptionType[] = [
  {
    name: 'Phone',
    value: 'phone',
  },
  {
    name: 'PII',
    value: 'pii',
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'Username',
    value: 'username',
  },
  {
    name: 'Facebook',
    value: 'facebook_user_id',
  },
  {
    name: 'TwitterID',
    value: 'username_twitter',
  },
];

interface OptionType {
  searchOption: SearchOptionType;
  setSearchOption: React.Dispatch<React.SetStateAction<SearchOptionType>>;
}

export const OptionBox: React.FC<OptionType> = ({ setSearchOption, searchOption }) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box sx={{ paddingRight: '1.5rem', height: '100%', width: '10rem' }}>
          <Box
            sx={{
              '&:hover .service, &:focus-within .service': {
                transform: 'translate(0, 20px)',
                opacity: 1,
                visibility: 'visible',
              },
              whiteSpace: 'nowrap',
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>{searchOption.name}</Typography>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <text
                x="90%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={{ fontSize: '1.5rem', fill: theme.palette.text.primary }}
              >
                |
              </text>
            </svg>
            <Box
              className="service"
              sx={{
                position: 'absolute',
                top: '3rem',
                left: '-1rem',
                opacity: 0,
                visibility: 'hidden',
                transition: '0.4s ease',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'action.focus',
                  borderRadius: '16px',
                  padding: 1,
                  minWidth: '12rem',
                }}
              >
                {searchOptions.map((option, index) => (
                  <Box
                    key={index}
                    onClick={() => setSearchOption(option)}
                    sx={{
                      alignItems: 'center',
                      borderRadius: '12px',
                      '&:hover': {
                        color: 'primary.main',
                        backgroundColor: 'background.main',
                        transition: 'color 0.4s ease, background-color 0.3s ease',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      sx={{
                        padding: 1,
                        paddingLeft: '16px',
                        fontSize: '1.4rem',
                        mt: 0.5,
                        letterSpacing: 0.8,
                        whiteSpace: 'nowrap',
                        textAlign: 'justify',
                      }}
                    >
                      {option.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
