import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import newsDefault from '@/assets/image/news-default.png';
import { UserItemTypes } from '@/types/Social';

interface SourceItemNewsProps {
  item: UserItemTypes;
}

const SourceItemNews: React.FC<SourceItemNewsProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'action.hover',
          borderRadius: 1,
          padding: '8px 16px',
        }}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Link href={`${item?.username}`} target="_blank" rel="noopener">
            <Avatar
              variant="rounded"
              alt="image"
              src={item.avatar || newsDefault}
              sx={{
                width: '70px',
                height: '60px',
                '.MuiAvatar-img': {
                  objectFit: 'contain',
                },
              }}
            />
          </Link>

          <Link
            href={`${item?.username}`}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {item?.name}
            </Typography>
          </Link>
        </Stack>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('smcc.overview.mentions')}
          </Typography>
          <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary', fontWeight: 600 }}>
            {item.user_post_count}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default SourceItemNews;
