import { useCommonInfo } from '@/contexts/Common';
import { actionType } from '@/contexts/Common/action';

const useSetKeySearch = () => {
  const { dispatch } = useCommonInfo();

  const setKeySearch = (keySearch: string) => {
    dispatch({
      type: actionType.SET_KEY_SEARCH,
      keySearch,
    });
  };

  return { setKeySearch };
};
export default useSetKeySearch;
