import { Box, Stack, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import useBreakpoints from '@/helpers/useBreakpoints';

const LoadingSsl = () => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <Stack sx={{ alignItems: 'left' }}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            width: isMobile ? '100%' : '60%',
            textAlign: 'center',
            backgroundColor: 'background.main',
          }}
        >
          <Box>
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
          </Box>
        </Paper>
      </Stack>
    </>
  );
};
export default LoadingSsl;
