import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Typography, IconButton } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';

const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onNotify = (status: VariantType, title: string | undefined, duration: number | null, body?: string) => {
    enqueueSnackbar(
      <div>
        <Typography variant="body2" sx={{ color: '#ffff', fontWeight: 400 }}>
          {title || t('notify.somethingWrong')}
        </Typography>
        {body && (
          <Typography variant="body2" sx={{ color: '#cccccc' }}>
            {body}
          </Typography>
        )}
      </div>,
      {
        variant: status,
        action: (key) => (
          <IconButton size="small" color="inherit" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        ),
        autoHideDuration: duration,
      },
    );
  };
  return { onNotify };
};

export default useNotify;
