import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

interface BarChartMentionBySourceProps {
  data: { [key: string]: string | number }[];
}

interface Payload {
  [key: string]: string | number;
}

interface TooltipProps {
  active?: boolean;
  payload?: { payload: Payload }[];
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const excludedFields = ['name'];

    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <Typography sx={{ fontWeight: 600, color: theme.palette.text.primary }}>{data.name}</Typography>

        {Object.entries(data).map(([key, value]) => {
          if (!excludedFields.includes(key)) {
            return (
              <>
                {key !== 'total' && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {key}: {value}
                    <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
                      {t('smcc.overview.postLow')}
                    </Typography>
                  </Typography>
                )}
                {key === 'total' && (
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 600 }}>
                    {t('smcc.total')}: {value}
                    <Typography variant="caption" sx={{ ml: 0.5, color: 'text.secondary', fontWeight: 600 }}>
                      {t('smcc.overview.postLow')}
                    </Typography>
                  </Typography>
                )}
              </>
            );
          }
          return null;
        })}
      </Box>
    );
  }
  return null;
};

const BarChartMentionBySource: React.FC<BarChartMentionBySourceProps> = ({ data }) => {
  const { t } = useTranslation();

  const transformedData = data.map((topic) => {
    const total = Object.values(topic).reduce((sum, value) => {
      if (typeof value === 'number') return Number(sum) + value; // Ignore the "name" field
      return sum;
    }, 0);

    return {
      ...topic,
      total,
    };
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={transformedData} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -1, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />

          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="total"
            fill="#8884d8"
            name={t('smcc.total')}
            barSize={35}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default BarChartMentionBySource;
