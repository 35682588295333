import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import { NewsConfig } from '@/configs/NewsConfig';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { Category } from '@/types/news';

interface UpdateNewsProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  detail: any;
  onPageChange: (newValue: number) => void;
  page: number;
  handleGetListNews: () => void;
}

const UpdateNews: React.FC<UpdateNewsProps> = ({
  openDialogUpdate,
  onDialogUpdateChange,
  detail,
  onPageChange,
  page,
  handleGetListNews,
}) => {
  const { t } = useTranslation();
  const { categoriesBusiness, categoriesCyberSecurity } = NewsConfig();
  const { handleUpdateNews } = useManagenent();

  const contentRef = useRef<HTMLDivElement>(null);
  const [imgPreview, setImgPreview] = useState(detail.img_url || '');
  const [imgMinioPreview, setImgMinioPreview] = useState(detail.image_url_minio || '');
  const [valueType, setValueType] = useState(detail.type_news);

  useEffect(() => {
    if (openDialogUpdate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogUpdate]);

  useEffect(() => {
    setValueType(detail.type_news || '');
  }, [detail.type_news]);

  useEffect(() => {
    setImgPreview(detail.img_url || '');
  }, [detail.img_url]);

  useEffect(() => {
    setImgMinioPreview(detail.image_url_minio || '');
  }, [detail.image_url_minio]);

  const handleImgBlur = (event: any) => {
    setImgPreview(event.target.value);
  };
  const handleImgMinioBlur = (event: any) => {
    setImgMinioPreview(event.target.value);
  };

  const validationSchema = yup.object().shape({
    type: yup.string(),
    field: yup.string(),
    status: yup.boolean(),
    titleVi: yup.string(),
    titleEn: yup.string(),
    excerptVi: yup.string(),
    excerptEn: yup.string(),
    href: yup.string(),
    imgUrl: yup.string(),
    imgUrlMinio: yup.string(),
    path: yup.string(),
    contentVi: yup.string().optional(),
    contentEn: yup.string(),
    errorLog: yup.string(),
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleResetValue = () => {
    setValue('type', detail.type_news);
    setValue('field', detail.field);
    setValue('status', detail.is_status_analytic_news);
    setValue('titleVi', detail.title_vi);
    setValue('titleEn', detail.title_en);
    setValue('excerptVi', detail.excerpt_vi);
    setValue('excerptEn', detail.excerpt_en);
    setValue('href', detail.href);
    setValue('imgUrl', detail.img_url);
    setValue('imgUrlMinio', detail.image_url_minio);
    setValue('path', detail.path);
    setValue('contentVi', '');
    setValue('contentEn', '');
    setValue('errorLog', '');
    setImgPreview('');
    setImgMinioPreview('');
    setValueType('');
  };

  const updateSuccess = () => {
    onDialogUpdateChange(false);
    handleResetValue();
    reset();
    if (page === 1) {
      handleGetListNews();
    } else {
      onPageChange(1);
    }
  };

  const onSubmit = (data: any) => {
    const params = {
      id: detail.id,
      title_vi: data.titleVi || detail.title_vi,
      title_en: data.titleEn || detail.title_en,
      excerpt_vi: data.excerptVi || detail.excerpt_vi,
      excerpt_en: data.excerptEn || detail.excerpt_en,
      content_vi: data.contentVi,
      content_en: data.contentEn,
      href: data.href || detail.href,
      img_url: data.imgUrl || detail.img_url,
      image_url_minio: data.imgUrlMinio || detail.image_url_minio,
      field: data.field || detail.field,
      type_news: data.type || detail.type_news,
      is_status_analytic_news: data.status !== undefined ? data.status : detail.is_status_analytic_news,
      error_log: data.errorLog,
    };
    handleUpdateNews(params, updateSuccess);
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogUpdateChange(false);
          handleResetValue();
          reset();
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.news.updateNews')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">{`Id : ${detail.id}`}</Typography>
          </Box>
          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">{`${t('management.news.createTime')} : ${detail.create_time}`}</Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    select
                    {...field}
                    variant="outlined"
                    label={t('management.news.typeNews')}
                    key={detail.type_news}
                    defaultValue={detail.type_news}
                    style={{ width: '100%', marginTop: '3px' }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onChange={(event) => {
                      field.onChange(event); // Ensure react-hook-form is aware of the change
                      setValueType(event.target.value);
                    }}
                  >
                    <MenuItem value="cyber_security" sx={{ fontSize: '1.4rem' }}>
                      {t('home.label.news.cyberSecurity')}
                    </MenuItem>
                    <MenuItem value="finance" sx={{ fontSize: '1.4rem' }}>
                      {t('home.label.news.business')}
                    </MenuItem>
                  </TextField>
                )}
              />
            </Box>
            {valueType === 'finance' && (
              <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
                <Controller
                  name="field"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      select
                      {...field}
                      variant="outlined"
                      label={t('management.news.field')}
                      key={detail.field}
                      defaultValue={detail.field}
                      style={{ width: '100%', marginTop: '3px' }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    >
                      {categoriesBusiness.map((item: Category) => (
                        <MenuItem key={item.id} value={item.param} sx={{ fontSize: '1.4rem' }}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            )}
            {valueType === 'cyber_security' && (
              <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
                <Controller
                  name="field"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      select
                      {...field}
                      variant="outlined"
                      label={t('management.news.field')}
                      key={detail.field}
                      defaultValue={detail.field}
                      style={{ width: '100%', marginTop: '3px' }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                    >
                      {categoriesCyberSecurity.map((item: Category) => (
                        <MenuItem key={item.id} value={item.param} sx={{ fontSize: '1.4rem' }}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            )}

            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="status"
                control={control}
                defaultValue={detail.is_status_analytic_news}
                render={({ field, fieldState }) => (
                  <FormControl component="fieldset" error={!!fieldState.error}>
                    <FormLabel component="legend">{t('management.news.analytic')}</FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      key={detail.is_status_analytic_news}
                      defaultValue={detail.is_status_analytic_news}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t('management.news.success')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t('management.news.error')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                    </RadioGroup>
                    {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Box>

            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="titleVi"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.title_vi}
                    defaultValue={detail.title_vi}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.titleVi')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="titleEn"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.title_en}
                    defaultValue={detail.title_en}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.titleEn')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="excerptVi"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.excerpt_vi}
                    defaultValue={detail.excerpt_vi}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.excerptVi')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="excerptEn"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.excerpt_en}
                    defaultValue={detail.excerpt_en}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.excerptEn')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="contentVi"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.contentVi')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="contentEn"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.contentEn')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="errorLog"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label="Error log"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="href"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.href}
                    defaultValue={detail.href}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.href')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="imgUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.img_url}
                    defaultValue={detail.img_url}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.imgUrl')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onBlur={handleImgBlur}
                  />
                )}
              />
              {imgPreview && (
                <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                  <img src={imgPreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </Box>

            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="imgUrlMinio"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.image_url_minio}
                    defaultValue={detail.image_url_minio}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.imgUrlMinio')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onBlur={handleImgMinioBlur}
                  />
                )}
              />
              {imgMinioPreview && (
                <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                  <img src={imgMinioPreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="path"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={detail.path}
                    defaultValue={detail.path}
                    multiline
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: 0,
                      },
                    }}
                    label={t('management.news.path')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
          </form>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: '90px',
                borderRadius: '5px',
                textTransform: 'none',
                transition: 'all .1s ease-in-out',
                '&:active': {
                  transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
                  transition: 'all .1s ease-in-out',
                },
                whiteSpace: 'nowrap',
                alignSelf: 'center',
              }}
            >
              {t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateNews;
