import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  InputBase,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddSession from '@/components/Management/session/AddSession';
import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { formatDateAndHours } from '@/configs/date';
import useManagenent from '@/Hooks/fetchApi/useManagenent';
import { SessionType } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const SessionTelegramManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleGetListSession, handleDeleteSession } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listSession, setListSession] = useState<SessionType[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedSession, setSelectedSession] = useState('');

  const handleGetSessions = async (page?: number) => {
    const params = {
      page: Number(searchParams.get('page') || 1),
    };
    const dataRes = await handleGetListSession(params);
    const { results, count } = dataRes;
    setListSession(results);
    setTotalPage(count);
  };

  useEffect(() => {
    handleGetSessions();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  const deleteSessionSuccess = () => {
    setOpenDialogDelete(false);
    setSearchParams({ page: '1' });
    handleGetSessions();
  };

  const handleDelete = () => {
    handleDeleteSession(selectedSession, deleteSessionSuccess);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.sessionTelegram.titleSessionTelegram')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '10px 10px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={<></>}
              // value={searchValue}
              // onChange={handleInputChange}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addSession')}
          </Button>
        </Box>
        <Box>
          <TableContainer
            sx={{
              padding: 2,
              borderRadius: 2,
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: 'action.hover',
              mt: 3,
            }}
          >
            <Table aria-label="simple table" sx={{}}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'action.hover' }}>
                  <TableCell>{t('table.sessionTelegramManagement.id')}</TableCell>
                  <TableCell>{t('table.sessionTelegramManagement.username')}</TableCell>
                  <TableCell>{t('table.sessionTelegramManagement.isActive')}</TableCell>
                  <TableCell>{t('table.sessionTelegramManagement.balanceMoon')}</TableCell>
                  <TableCell>{t('table.sessionTelegramManagement.createAt')}</TableCell>
                  <TableCell sx={{ width: '10%' }}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {!_.isEmpty(listSession) ? (
                    <>
                      {listSession.map((row: SessionType) => (
                        <TableRow
                          key={row.id}
                          hover
                          tabIndex={-1}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.username}</TableCell>

                          <TableCell>
                            {!row.is_active ? (
                              <ErrorOutlineIcon sx={{ color: 'error.main', fontSize: '2rem' }} />
                            ) : (
                              <CheckIcon sx={{ color: 'success.main', fontSize: '2rem' }} />
                            )}
                          </TableCell>
                          <TableCell sx={{ lineBreak: 'anywhere' }}>{row.moon_account_balance}</TableCell>
                          <TableCell>{formatDateAndHours(row.created_at)}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Tooltip title={t('tooltip.delete')} placement="bottom">
                              <DeleteIcon
                                onClick={() => {
                                  setSelectedSession(row.id);
                                  setOpenDialogDelete(true);
                                }}
                                sx={{
                                  fontSize: '2rem',
                                  ml: 3,
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'error.main',
                                  },
                                }}
                              />
                            </Tooltip>

                            <ConfirmDeleteModal
                              openDialogDelete={openDialogDelete}
                              onOpenDialogDeleteChange={setOpenDialogDelete}
                              handleDelete={handleDelete}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        {t('notify.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Pagination
                count={Math.ceil(totalPage / PAGE_SIZE)}
                color="primary"
                onChange={(event, value) => {
                  handleChangePage(value);
                }}
                page={Number(searchParams.get('page') || 1)}
              />
            </Box>
          </TableContainer>
        </Box>
      </Box>
      <AddSession
        openDialogCreate={openDialogCreate}
        onDialogCreateChange={setOpenDialogCreate}
        handleGetSessions={handleGetSessions}
      />
    </>
  );
};
export default SessionTelegramManagement;
