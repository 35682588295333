/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import tiktokImge from '@/assets/image/tiktok-img.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { TiktokPostsTypes } from '@/types/Social';

import TiktokDetail from './TiktokDetail';
import TitlePosts from './TitlePosts';

const initialPost: TiktokPostsTypes = {
  comment_count: '',
  sentiment: '',
  post_author: '',
  link: '',
  post_desc: '',
  author_link: '',
  image: '',
  like_count: '',
  author_avatar: '',
  post_time: '',
  collect_count: '',
  tags: [],
  share_count: '',
  view_count: '',
};

interface PostsTiktokProps {
  item: TiktokPostsTypes;
}

const PostsTiktok: React.FC<PostsTiktokProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<TiktokPostsTypes>(initialPost);

  return (
    <>
      <TitlePosts item={item} />
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            component="img"
            src={tiktokImge}
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              // width: '185px',
              height: '122px',
              borderRadius: 1,
              objectFit: 'cover',
              cursor: 'pointer',
            }}
          />
          <Box sx={{ width: '90%', paddingX: 2 }}>
            <Typography
              variant="body2"
              onClick={() => {
                setOpenDialogDetail(true);
                setDetailSelected(item);
              }}
              sx={{
                color: 'text.secondary',
                letterSpacing: '0.01em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 5,
                textOverflow: 'ellipsis',
                cursor: 'pointer',
              }}
            >
              <HighlightTextSocial text={item.post_desc} keyword={topicSocial?.key_word_main} />
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body2"
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
          sx={{
            mt: 1,
            width: 'fit-content',
            cursor: 'pointer',
            color: 'text.secondary',
            ':hover': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
            fontStyle: 'italic',
          }}
        >
          {t('action.showMore')}
        </Typography>
      </Box>
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Stack direction="row">
          <Box>
            <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
              <FavoriteIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
            </Avatar>
            <Typography
              sx={{
                mt: 0.5,
                textAlign: 'center',
                color: 'text.secondary',
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
            >
              {item.like_count}
            </Typography>
          </Box>
          <Box sx={{ ml: 2 }}>
            <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
              <CommentIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
            </Avatar>
            <Typography
              sx={{
                mt: 0.5,
                textAlign: 'center',
                color: 'text.secondary',
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
            >
              {item.comment_count}
            </Typography>
          </Box>
          <Box sx={{ ml: 2 }}>
            <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
              <VisibilityIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
            </Avatar>
            <Typography
              sx={{
                mt: 0.5,
                textAlign: 'center',
                color: 'text.secondary',
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
            >
              {item.view_count}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <TiktokDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsTiktok;
