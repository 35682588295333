import { useEffect, useState } from 'react';

import { Box, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import useBreakpoints from '@/helpers/useBreakpoints';

import Header from '../HomePage/Header';
import HeaderMobile from '../HomePage/HeaderMobile';

interface IProps {
  children: React.ReactNode;
}

const CVELayout = ({ children }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/cve');
        break;
      case 1:
        navigate('/vendors');
        break;
      case 2:
        navigate('/cwe');
        break;
      case 3:
        navigate('/analysis');
        break;
      default:
        navigate('/cve');
        break;
    }
  };
  const path = location.pathname;
  useEffect(() => {
    switch (path) {
      case '/cve':
        setValue(0);
        break;
      case '/vendors':
        setValue(1);
        break;
      case '/cwe':
        setValue(2);
        break;
      case '/analysis':
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
  }, [path]);

  return (
    <>
      <Helmet>
        <title>Vulnerabilities (CVE) - ESS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.dark',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
        }}
      >
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}
        <Box sx={{ mt: '70px', minHeight: '100vh', padding: { xs: 0, md: 2 } }}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <StyledTab label={`${t('cve.vulnerabilities')} (CVE)`} />
                <StyledTab label={`${t('cve.vendorsAndProducts')} (CPE)`} />
                <StyledTab label={`${t('cve.categories')} (CWE)`} />
                <StyledTab label={`${t('cve.analysis')}`} />
              </StyledTabs>
            </Box>
            <Box sx={{ mt: 3 }}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CVELayout;
