import React from 'react';

import { useTheme, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useBreakpoints from '@/helpers/useBreakpoints';

interface Props {
  handleClick: () => void;
}
const ScanButton: React.FC<Props> = ({ handleClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{
        height: '52px',
        marginLeft: 2,
        color: '#f5f5f5',
        backgroundColor: theme.palette.mode === 'dark' ? '#2aa391' : '#2e7d32',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? '#1c7568' : '#19591e',
          transform: 'scale(1.1)',
          transition: 'transform 0.3s',
        },
      }}
      style={{
        textTransform: 'none',
        fontWeight: '500',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        boxShadow: 'none',
        fontSize: '1.4rem',
      }}
    >
      {t('action.scan')}
    </Button>
  );
};
export default ScanButton;
