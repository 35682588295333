/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import News from '@/assets/image/news.png';
import { NewsPostsTypes } from '@/types/Social';

import { DisplaySentiment } from '../ButtonSentiment';

interface TitlePostsNewsProps {
  item: NewsPostsTypes;
}

const TitlePostsNews: React.FC<TitlePostsNewsProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Link href={`https://${item?.source}`}>
          <Avatar
            alt="image"
            variant="rounded"
            src={item?.source_logo || News}
            sx={{
              width: '50px',
              height: '40px',
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        </Link>
        <Box>
          <Link
            href={`https://${item?.source}`}
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>{item?.source_name}</Typography>
          </Link>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item?.post_time
              ? format(new Date(item?.post_time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
                  locale: locale === 'vi' ? vi : enUS,
                })
              : 'NaN'}
          </Typography>
        </Box>
      </Stack>
      <DisplaySentiment sentiment={item?.sentiment} />
    </Stack>
  );
};
export default TitlePostsNews;
