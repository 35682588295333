import { ReactElement, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRange from '@/components/input/DateRange';
import Facebook from '@/components/Social/facebook/Facebook';
import FilterSentiment from '@/components/Social/FilterSentiment';
import Forum from '@/components/Social/forum/Forum';
import MenuTopic from '@/components/Social/MenuTopic';
import Newspaper from '@/components/Social/news/Newspaper';
import Tiktok from '@/components/Social/tiktok/Tiktok';
import Twitter from '@/components/Social/twitter/Twitter';
import Youtube from '@/components/Social/youtube/Youtube';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { SentimentStateTypes } from '@/types/Social';

const Posts = (): ReactElement => {
  const { t } = useTranslation();

  const [valueTab, setValueTab] = useState(0);
  const [sentimentState, setSentimentState] = useState<SentimentStateTypes>({
    positive: true,
    neutral: true,
    negative: true,
  });

  const handleSentimentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSentimentState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      <MenuTopic />

      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <StyledTab label="Facebook" />
            <StyledTab label={t('smcc.overview.forum')} />
            <StyledTab label="TikTok" />
            <StyledTab label="Youtube" />
            <StyledTab label={t('smcc.overview.news')} />
            <StyledTab label="Twitter" />
          </StyledTabs>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {valueTab !== 5 && <FilterSentiment sentimentState={sentimentState} onChange={handleSentimentChange} />}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, mt: { xs: 1, sm: 0 } }}
            >
              <DateRange />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>{valueTab === 0 && <Facebook sentimentState={sentimentState} />}</Box>
          <Box sx={{ mt: 3 }}>{valueTab === 1 && <Forum sentimentState={sentimentState} />}</Box>
          <Box sx={{ mt: 3 }}>{valueTab === 2 && <Tiktok sentimentState={sentimentState} />}</Box>
          <Box sx={{ mt: 3 }}>{valueTab === 3 && <Youtube sentimentState={sentimentState} />}</Box>
          <Box sx={{ mt: 3 }}>{valueTab === 4 && <Newspaper sentimentState={sentimentState} />}</Box>
          <Box sx={{ mt: 3 }}>{valueTab === 5 && <Twitter sentimentState={sentimentState} />}</Box>
        </Box>
      </Box>
    </>
  );
};
export default Posts;
