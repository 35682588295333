import React, { useState } from 'react';

import { Box, useTheme, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { NewsConfig } from '@/configs/NewsConfig';
import useBreakpoints from '@/helpers/useBreakpoints';
import { NewsTypes, NewsState } from '@/types/news';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ItemLabel from './ItemLabel';

interface CarouselNewsProps {
  listNews: NewsTypes[];
  updateNewsState: (newState: Partial<NewsState>) => void;
}

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
}

const CarouselNews: React.FC<CarouselNewsProps> = ({ listNews, updateNewsState }) => {
  const theme = useTheme();
  const locale = localStorage.getItem('locale') || 'vi';
  const { isMobile, isTablet } = useBreakpoints();
  const { checkValidUrl } = NewsConfig();

  const [activeSlide, setActiveSlide] = useState(0);

  function SampleNextArrow(props: ArrowProps) {
    const { className, style } = props;
    return <div className={className} style={{ ...style, display: 'none' }} />;
  }

  function SamplePrevArrow(props: ArrowProps) {
    const { className, style } = props;
    return <div className={className} style={{ ...style, display: 'none' }} />;
  }

  const spacingValues = { xs: 0, lg: 3 };
  const spacing = { xs: 1, sm: 2, lg: 0 };

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (next: number) => {
      if (next === 0 || next === 1 || next === 2) {
        setActiveSlide(next + 1);
      } else {
        setActiveSlide(0);
      }
    },
  };

  return (
    <>
      <Grid container spacing={spacingValues}>
        <>
          <Grid item xs={12} md={12} lg={8}>
            <Box className="wow animate__animated animate__zoomIn">
              <Slider {...settings}>
                {listNews.map((item: NewsTypes) => (
                  <div key={item.id}>
                    <Box sx={{ position: 'relative' }}>
                      <Link to={`/news/${item.path}`}>
                        <Box
                          sx={{
                            border: `1px solid ${theme.palette.divider}`,
                            cursor: 'pointer',
                            borderRadius: 2,
                            alignSelf: 'center',
                            overflow: 'hidden',
                            ':hover': {
                              '.img': {
                                transform: 'scale(1.1)',
                              },
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              width: '100%',
                              height: { xs: '80%', sm: '60%' },
                              bottom: '0',
                              left: 0,
                              background: 'linear-gradient(to top, #000000, rgba(0,0,0,0))',
                              borderBottomLeftRadius: '16px',
                              borderBottomRightRadius: '16px',
                            },
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              width: '100%',
                              height: { xs: '200px', sm: '400px', md: '500px', lg: '550px' },
                              transition: '0.6s',
                            }}
                            className="img"
                            alt=""
                            src={checkValidUrl(item.img_url, item.image_url_minio)}
                          />
                        </Box>
                      </Link>

                      <Box
                        sx={{
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                          padding: { xs: 2, sm: 3 },
                          width: '100%',
                        }}
                      >
                        <Link to={`/news/${item.path}`}>
                          <Typography
                            variant="h4"
                            sx={{
                              mt: 2,
                              color: theme.palette.common.white,
                              cursor: 'pointer',
                              transition: 'color 0.3s ease',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 3,
                              textOverflow: 'ellipsis',
                              fontWeight: 700,
                              '&:hover': {
                                color: '#02BB9F',
                              },
                            }}
                          >
                            {locale === 'vi' ? item.title_vi : item.title_en}
                          </Typography>
                        </Link>
                        <ItemLabel item={item} updateNewsState={updateNewsState} type="carousel" />
                      </Box>
                    </Box>
                  </div>
                ))}
              </Slider>
            </Box>
          </Grid>

          {!isMobile && !isTablet && (
            <Grid item xs={12} md={12} lg={4} sx={{ marginTop: { xs: 3, lg: 0 } }}>
              <Grid container spacing={spacing}>
                {listNews.map((item: NewsTypes, index: number) => (
                  <Grid item key={item.id} xs={12} sm={6} md={6} lg={12}>
                    <Grid
                      container
                      sx={{ marginBottom: { lg: 2, md: 1 }, justifyContent: 'space-between' }}
                      className="wow animate__animated animate__fadeInRight"
                    >
                      <Grid item xs={4}>
                        <Link to={`/news/${item.path}`}>
                          <Box
                            sx={{
                              cursor: 'pointer',
                              borderRadius: 1,
                              overflow: 'hidden',
                              border: `1px solid ${theme.palette.divider}`,
                              '.img': {
                                transform: index === activeSlide ? 'scale(1.15)' : 'scale(1)',
                              },
                            }}
                          >
                            <Box
                              component="img"
                              sx={{ width: '100%', height: '110px', transition: '0.6s' }}
                              className="img"
                              alt=""
                              src={checkValidUrl(item.img_url, item.image_url_minio)}
                            />
                          </Box>
                        </Link>
                      </Grid>

                      <Grid
                        item
                        xs={7.4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingBottom: { xs: 0, md: 1 },
                        }}
                      >
                        <Link to={`/news/${item.path}`} style={{ textDecoration: 'none' }}>
                          <Box
                            sx={{
                              fontWeight: 600,
                              color: index === activeSlide ? '#00b398' : 'text.primary',
                              cursor: 'pointer',
                              transition: 'color 0.3s ease',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 3,
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {locale === 'vi' ? item.title_vi : item.title_en}
                          </Box>
                        </Link>
                        <ItemLabel item={item} updateNewsState={updateNewsState} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
};
export default CarouselNews;
