import React from 'react';

import { useCommonInfo } from '@/contexts/Common';
import { actionType } from '@/contexts/Common/action';

const useScanError = () => {
  const { dispatch } = useCommonInfo();
  const setScanError = (isScanError: any) => {
    dispatch({
      type: actionType.SET_SCAN_ERROR,
      isScanError,
    });
  };

  return { setScanError };
};
export default useScanError;
