import { Box, Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { ICampaign } from '@/interfaces/phishing';
import { fDateTime } from '@/utils/function';

import { getFilenameMalware } from '../Malware/DetailMalware';

interface DetailCampaignProps {
  openDialogDetail: boolean;
  setOpenDialogDetail: (newValue: boolean) => void;
  campaign: ICampaign;
}

const DetailCampaign: React.FC<DetailCampaignProps> = ({ openDialogDetail, setOpenDialogDetail, campaign }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialogDetail(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.phishing.detailCampaign')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('asm.phishing.createAt')}
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
              {campaign?.created_at ? fDateTime(campaign?.created_at) : ''}
            </Typography>
          </Box>
          {(campaign?.created_at ? fDateTime(campaign?.created_at) : '') !==
            (campaign?.modified_at ? fDateTime(campaign?.modified_at) : '') && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.modifiedAt')}
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
                {campaign?.modified_at ? fDateTime(campaign?.modified_at) : ''}
              </Typography>
            </Box>
          )}

          <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.campaignName')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.name}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.description')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.description}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Email
              </Typography>
              <Box sx={{ marginLeft: 1.5 }}>
                {campaign.user_list?.map((row: string) => (
                  <Typography variant="body2" key={row}>
                    {row}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.content')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.phishing_content}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.malware')}
              </Typography>
              <Box sx={{ marginLeft: 1.5 }}>
                {campaign.attachments?.map((row: any) => (
                  <Typography variant="body2" key={row.name}>
                    {`${row.name} (${getFilenameMalware(row.file)})`}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.landingPage')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.landing_page}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.subject')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.subject}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: 1.5,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.senderName')}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1.5 }}>
                {campaign.sender_name}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DetailCampaign;
