import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClick: () => void;
}
const UpdateButton: React.FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      fullWidth
      type="button"
      onClick={handleClick}
      sx={{
        width: '110px',
        mt: 4,
        boxShadow: 'none',
        fontWeight: '400',
        borderRadius: '5px',
        textTransform: 'none',
        fontSize: '1.4rem',
        color: '#ffff',
        backgroundColor: '#1578cd',
        transition: 'all .1s ease-in-out',
        '&:hover': {
          color: '#ffff',
          backgroundColor: '#146ebb',
          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.302), 0 4px 8px 3px rgba(0,0,0,0.149)',
        },
        '&:active': {
          transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
          transition: 'all .1s ease-in-out',
        },
        whiteSpace: 'nowrap',
        alignSelf: 'center',
      }}
    >
      {t('action.confirm')}
    </Button>
  );
};
export default UpdateButton;
