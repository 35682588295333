import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useSecurityAssessmentService from '@/services/securityAssessment';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

const useSecurityAssessment = () => {
  const { getListTool, downloadTool } = useSecurityAssessmentService();
  const { onNotify } = useNotify();
  const { t } = useTranslation();
  const { setLoadingGif } = useLoadingGif();

  const handleListTool = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListTool(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleDownloadTool = async (params: { file_name: string }) => {
    const res: any = await downloadTool(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.download'), 3000);
      return data;
    }
  };

  return {
    handleListTool,
    handleDownloadTool,
  };
};

export default useSecurityAssessment;
