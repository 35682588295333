import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend } from 'recharts';

interface BarChartSentimentProps {
  data: { [key: string]: string | number }[];
}

const BarChartSentiment: React.FC<BarChartSentimentProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="source"
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -2, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip
            contentStyle={{ backgroundColor: theme.palette.background.paper }}
            cursor={{ fill: theme.palette.action.hover }}
            itemStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <Legend wrapperStyle={{ fontSize: 14 }} />
          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="positive"
            fill={theme.palette.success.main}
            name={t('smcc.status.positive')}
            barSize={20}
          />
          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="neutral"
            fill="#cb997e"
            name={t('smcc.status.neutral')}
            barSize={20}
          />
          <Bar
            yAxisId="linearAxis"
            type="monotone"
            dataKey="negative"
            fill={theme.palette.error.main}
            name={t('smcc.status.negative')}
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default BarChartSentiment;
