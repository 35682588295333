import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Box, TextField, Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { ProductType } from '@/types/Assets';

type FormData = {
  version: string;
};
interface UpdateAssetProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  product: ProductType;
  handleGetListAsset: () => void;
  groupId: number;
}

const UpdateAsset: React.FC<UpdateAssetProps> = ({
  openDialogUpdate,
  onDialogUpdateChange,
  product,
  handleGetListAsset,
  groupId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleEditAsset } = useAsm();

  const updateAssetSuccess = () => {
    onDialogUpdateChange(false);
    handleGetListAsset();
    setValue('version', product.version);
  };

  const validationSchema = yup.object().shape({
    version: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue('version', product?.version);
  }, [product, setValue]);

  const onSubmit = (data: FormData) => {
    const params = {
      product_id: product.id,
      group_id: groupId,
      version: data.version,
    };
    handleEditAsset(params, updateAssetSuccess);
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogUpdateChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
              boxShadow: 'none',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.asset.updateAsset')}</Typography>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="version"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={product?.id}
                    defaultValue={product?.version}
                    autoFocus
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    sx={{
                      '& label': {
                        fontSize: '0.85rem',
                      },
                      '.MuiInputBase-input': {
                        padding: '12px',
                      },
                    }}
                    label={t('placeholder.version')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
          </form>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: '90px',
                borderRadius: '5px',
                textTransform: 'none',
                fontSize: '1.4rem',
                transition: 'all .1s ease-in-out',
                '&:active': {
                  transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
                  transition: 'all .1s ease-in-out',
                },
                whiteSpace: 'nowrap',
                alignSelf: 'center',
              }}
            >
              {t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateAsset;
