import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useIdentityService = () => {
  const { methodPost } = useRequest();

  const getIdentityData = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.identity.search, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getIdentityData,
  };
};

export default useIdentityService;
