import React, { useMemo, useState } from 'react';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {
  Box,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';

import { ScanUrlTypes, SecurityHeaderTypes } from '@/types/Osint';

interface UrlscanProps {
  listUrlscan: ScanUrlTypes;
}

function Row(props: { row: ReturnType<any> }) {
  const { row } = props;
  const [openCollapse, setOpenCollapse] = useState(false);

  const extractBaseDomain = (url: string) => {
    const regex = /^https?:\/\/(.*\/)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  };

  const extractFilenameFromUrl = (url: string) => {
    const pattern = /\/([^\/\?]+)(?:\?|$)/;
    const match = url.match(pattern);
    return match ? match[1] : null;
  };

  return (
    <>
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell component="th" scope="row">
          <Box>
            <Typography variant="body2">{row.request.request?.method}</Typography>
            <Typography variant="body2">{row.response.response?.protocol}</Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box>
            <Typography variant="body2">{row.response.response?.status}</Typography>
            <Typography variant="body2">{row.response.response?.statusText}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">{extractFilenameFromUrl(row.request.request?.url)}</Typography>
            <Typography variant="caption">{extractBaseDomain(row.request.request?.url)}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              {row.response?.dataLength ? `${Math.ceil(row.response?.dataLength / 1000)} kB` : '-'}
            </Typography>
            <Typography variant="caption">
              {row.response?.encodedDataLength ? `${Math.ceil(row.response?.encodedDataLength / 1000)} kB` : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              {row.response?.response?.timing?.receiveHeadersEnd
                ? `${Math.ceil(row.response?.response?.timing?.receiveHeadersEnd)}ms`
                : '-'}
            </Typography>
            <Typography variant="caption">
              {row.response?.response?.timing?.receiveHeadersEnd
                ? `${Math.ceil(
                    row.response?.response?.timing?.receiveHeadersEnd - row.response?.response?.timing?.sendEnd,
                  )}ms`
                : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">{row.response?.type}</Typography>
            <Typography variant="caption">{row.response.response?.mimeType}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">{row.response.asn?.ip}</Typography>
            <Typography variant="caption">
              {row.response.asn?.country ? `${row.response.asn?.country} - ${row.response.asn?.name}` : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell onClick={() => setOpenCollapse(!openCollapse)}>
          <ZoomInIcon sx={{ fontSize: '2.5rem', cursor: 'pointer' }} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2, backgroundColor: 'background.menu' }}>
              <Box sx={{ margin: 1 }}>
                <Typography gutterBottom component="div">
                  General
                </Typography>
                <dl>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Full URL
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>{row.response.response?.url || '-'}</dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Requested by
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>{row.request?.documentURL || '-'}</dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Protocol
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>{row.response.response?.protocol || '-'}</dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Security
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>
                    {`${row.response.response?.securityDetails?.protocol || '-'}, ${
                      row.response.response?.securityDetails?.keyExchange || '-'
                    }, ${row.response.response?.securityDetails?.cipher || '-'}`}
                  </dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Server
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>
                    {`${row.response.asn?.country || '-'} ${row.response.asn?.ip || '-'}, ASN${
                      row.response.asn?.asn || '-'
                    } (${row.response.asn?.description || '-'})`}
                  </dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Reverse DNS
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>{row.response.response?.rdns?.ptr ?? '_'}</dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Software
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>
                    {`${row.response.response?.headers?.Server || '-'} / ${
                      row.response?.response?.headers['X-Powered-By'] ?? '-'
                    }`}
                  </dd>
                  <dt
                    style={{ float: 'left', width: '160px', textAlign: 'right', fontWeight: 500, fontSize: '1.2rem' }}
                  >
                    Resource Hash
                  </dt>
                  <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>{row.response.hash || '_'}</dd>
                  {row.response.response?.securityHeaders && (
                    <>
                      <dt
                        style={{
                          float: 'left',
                          width: '160px',
                          textAlign: 'right',
                          fontWeight: 500,
                          fontSize: '1.2rem',
                        }}
                      >
                        Security Headers
                      </dt>
                      <dd style={{ marginLeft: '180px', fontSize: '1.2rem' }}>
                        <dl>
                          <dt
                            style={{
                              float: 'left',
                              width: '200px',
                              textAlign: 'right',
                              fontWeight: 500,
                              fontSize: '1.2rem',
                            }}
                          >
                            Name
                          </dt>
                          <dd style={{ marginLeft: '220px', fontWeight: 500, fontSize: '1.2rem' }}>Value</dd>
                          {row.response.response.securityHeaders.map((securityHeader: SecurityHeaderTypes) => (
                            <dl key={securityHeader.name}>
                              <dt
                                style={{
                                  float: 'left',
                                  width: '200px',
                                  textAlign: 'right',
                                  fontWeight: 500,
                                  fontSize: '1.2rem',
                                }}
                              >
                                {securityHeader.name}
                              </dt>
                              <dd style={{ marginLeft: '220px', fontSize: '1.2rem' }}>{securityHeader.value || '_'}</dd>
                            </dl>
                          ))}
                        </dl>
                      </dd>
                    </>
                  )}
                </dl>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography>Request headers</Typography>
                    <dl>
                      {Object.entries(row.request.request.headers).map(([key, value]) => (
                        <React.Fragment key={key}>
                          <dt
                            style={{
                              float: 'left',
                              width: '250px',
                              textAlign: 'right',
                              fontWeight: 500,
                              fontSize: '1.2rem',
                            }}
                          >
                            {key}
                          </dt>
                          <dd style={{ marginLeft: '270px', fontSize: '1.2rem' }}>{String(value)}</dd>
                        </React.Fragment>
                      ))}
                    </dl>
                  </Grid>
                  {typeof row.response.response?.headers === 'object' && (
                    <Grid item xs={12} md={6}>
                      <Typography>Response headers</Typography>
                      <dl>
                        {Object.entries(row.response.response?.headers).map(([key, value]) => (
                          <React.Fragment key={key}>
                            <dt
                              style={{
                                float: 'left',
                                width: '250px',
                                textAlign: 'right',
                                fontWeight: 500,
                                fontSize: '1.2rem',
                              }}
                            >
                              {key}
                            </dt>
                            <dd style={{ marginLeft: '270px', fontSize: '1.2rem' }}>{String(value)}</dd>
                          </React.Fragment>
                        ))}
                      </dl>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const HTTP: React.FC<UrlscanProps> = ({ listUrlscan }) => {
  const dataRequests = useMemo(() => listUrlscan?.data?.requests, [listUrlscan?.data?.requests]);

  return (
    <>
      <Box sx={{ pb: 3 }}>
        {!_.isEmpty(dataRequests) && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">{`${dataRequests.length} HTTP transactions`}</Typography>
            <Box sx={{ mt: 1 }}>
              <Box sx={{ mt: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">Method</Typography>
                            <Typography variant="body2">Protocol</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">Resource</Typography>
                            <Typography variant="caption">Path</Typography>
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box>
                            <Typography variant="body2">Size</Typography>
                            <Typography variant="caption">x-fer</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">Time</Typography>
                            <Typography variant="caption">Latency</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">Type</Typography>
                            <Typography variant="caption">MIME-Type</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography variant="body2">IP</Typography>
                            <Typography variant="caption">Location</Typography>
                          </Box>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataRequests.map((row: any) => (
                        <Row key={row.name} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default HTTP;
