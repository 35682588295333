import { TableRow, TableCell } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const SkeletonCell = <Skeleton height={20} width="80%" />;
const LoadingListCVE = () => {
  return (
    <>
      <TableRow style={{ width: '100%' }}>
        <TableCell style={{ width: '20%' }}>{SkeletonCell}</TableCell>
        <TableCell style={{ width: '20%' }}>{SkeletonCell}</TableCell>
        <TableCell style={{ width: '25%' }}>{SkeletonCell}</TableCell>
        <TableCell style={{ width: '25%' }}>{SkeletonCell}</TableCell>
        <TableCell style={{ width: '10%' }}>{SkeletonCell}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6}>{SkeletonCell}</TableCell>
      </TableRow>
    </>
  );
};
export default LoadingListCVE;
