import { useEffect, useRef } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { KeywordTypes } from '@/types/Social';

interface KeywordDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  listKeywords: KeywordTypes[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const KeywordDetail: React.FC<KeywordDetailProps> = ({ openDialogDetail, onDialogDetailChange, listKeywords }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ marginTop: 1 }}>
          {t('smcc.overview.featuredKeywords')}
        </Typography>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell width="10%">#</TableCell>
                <TableCell>{t('smcc.keyword')}</TableCell>
                <TableCell width="30%">{t('smcc.overview.mentions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listKeywords.map((keyword: KeywordTypes, index: number) => (
                <StyledTableRow key={keyword?.label}>
                  <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>{index + 1}</TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>{keyword?.label}</TableCell>
                  <TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>{keyword?.count}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
export default KeywordDetail;
