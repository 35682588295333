import { useEffect, useRef } from 'react';

import { Button, Box, TextField, Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import usePhishing from '@/Hooks/fetchApi/usePhishing';
import { IMalware } from '@/interfaces/phishing';
import { fDateTime } from '@/utils/function';

interface UpdateMalwareProps {
  openDialogUpdate: boolean;
  setOpenDialogUpdate: (newValue: boolean) => void;
  setPage: (newValue: number) => void;
  malware: IMalware;
  handleGetListMalware: (arg: number) => Promise<void>;
}

const UpdateMalware: React.FC<UpdateMalwareProps> = ({
  openDialogUpdate,
  setOpenDialogUpdate,
  setPage,
  malware,
  handleGetListMalware,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleUpdateDetailMalware } = usePhishing();

  useEffect(() => {
    setValue('name', malware.name);
    setValue('description', malware.description);
  }, [malware]);

  const updateMalwareSuccess = () => {
    setOpenDialogUpdate(false);
    handleGetListMalware(1);
    setPage(1);
    setValue('name', malware.name);
    setValue('description', malware.description);
    setValue('file', '');
  };

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('file', data.file[0]);

    handleUpdateDetailMalware(malware.id, formData, updateMalwareSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialogUpdate(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.phishing.updateMalware')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('asm.phishing.createAt')}
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
              {malware?.created_at ? fDateTime(malware?.created_at) : ''}
            </Typography>
          </Box>
          {(malware?.created_at ? fDateTime(malware?.created_at) : '') !==
            (malware?.modified_at ? fDateTime(malware?.modified_at) : '') && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.modifiedAt')}
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
                {malware?.modified_at ? fDateTime(malware?.modified_at) : ''}
              </Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <TextField
                {...register('name', { required: true })}
                key={malware.name}
                defaultValue={malware.name}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.malwareName')}
                error={!!errors.name}
              />
              {errors.name && errors.name.type === 'required' && (
                <span style={{ color: 'error.main', fontSize: '1.4rem' }}>{t('validation.fieldRequired')}</span>
              )}
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <TextField
                {...register('description', { required: true })}
                key={malware.description}
                defaultValue={malware.description}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.description')}
                error={!!errors.description}
              />
              {errors.description && errors.description.type === 'required' && (
                <span style={{ color: 'error.main', fontSize: '1.4rem' }}>{t('validation.fieldRequired')}</span>
              )}
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <TextField
                {...register('file', {
                  required: true,
                  validate: {
                    maxSize: (value) => value[0]?.size <= 10 * 1000 * 1024,
                  },
                })}
                type="file"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                error={!!errors.file}
                onKeyDown={handleKeyDown}
              />
              {errors.file && errors.file.type === 'required' && (
                <span style={{ color: 'error.main', fontSize: '1.4rem' }}>{t('validation.fieldRequired')}</span>
              )}
              {errors.file && errors.file.type === 'maxSize' && (
                <span style={{ color: 'error.main', fontSize: '1.4rem' }}>{t('validation.sizeFileUpload')}</span>
              )}
            </Box>
          </form>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: '90px',
                boxShadow: 'none',
                borderRadius: '5px',
                textTransform: 'none',
                transition: 'all .1s ease-in-out',
                color: 'common.white',
                '&:active': {
                  transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
                  transition: 'all .1s ease-in-out',
                },
                whiteSpace: 'nowrap',
                alignSelf: 'center',
              }}
            >
              {t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateMalware;
