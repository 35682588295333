import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useMalwareService = () => {
  const { methodPostWithLongTimeout } = useRequest();

  const malwareUpload = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPostWithLongTimeout(urlApi.malware.analyze, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    malwareUpload,
  };
};

export default useMalwareService;
