import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend } from 'recharts';

import './style.css';

export default function ChartHandsome() {
  const { t } = useTranslation();

  const [chartWidth, setChartWidth] = useState<number>(window.innerWidth * 0.9);
  const [chartHeight, setChartHeight] = useState<number>(window.innerHeight * 0.4);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.9);
      setChartHeight(window.innerHeight * 0.4);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const sample = [
    { day: 'Mon', users: 10, queries: 150 },
    { day: 'Tue', users: 15, queries: 120 },
    { day: 'Wed', users: 12, queries: 189 },
    { day: 'Thu', users: 25, queries: 170 },
    { day: 'Fri', users: 19, queries: 20 },
    { day: 'Sat', users: 20, queries: 100 },
    { day: 'Sun', users: 16, queries: 140 },
  ];
  // const chartWidth = document.body.clientWidth * 0.95;
  // const chartHeight = chartWidth * 0.3;
  const today = new Date();
  const currentDay = today.getDay(); // 0 (Sun) to 6 (Sat)
  const filteredData = sample.slice(currentDay, 7).concat(sample.slice(0, currentDay));
  return (
    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
      <div className="chart-container">
        {/* <ResponsiveContainer width={chartWidth} height={chartHeight}> */}
        <LineChart
          width={chartWidth}
          height={chartHeight}
          data={filteredData}
          margin={{ left: 0, right: 20, top: 50, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            label={{ value: t('identity.chart.dayWeek'), position: 'insideBottom', offset: -2, textAnchor: 'middle' }}
          />
          <YAxis
            yAxisId="linearAxis"
            label={{ value: t('identity.chart.users'), angle: -90, position: 'insideLeft', textAnchor: 'middle' }}
          />
          <YAxis
            yAxisId="logAxis"
            orientation="right"
            label={{ value: t('identity.chart.queries'), angle: -90, position: 'insideRight', textAnchor: 'middle' }}
          />
          <Tooltip />
          <Legend />
          <Line
            yAxisId="linearAxis"
            type="monotone"
            dataKey="users"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={t('identity.chart.numberUsers')}
          />
          <Line
            yAxisId="logAxis"
            type="monotone"
            dataKey="queries"
            stroke="#82ca9d"
            name={t('identity.chart.numberQueries')}
          />
        </LineChart>
        {/* </ResponsiveContainer> */}
      </div>
    </Stack>
  );
}
