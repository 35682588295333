import { useTheme, Box, Modal, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import sessionExpiredImg from '@/assets/image/session_ expired.gif';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ErrorFilterModal: React.FC<Props> = ({ open, handleClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.main',
          borderRadius: 2,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <CardMedia component="img" sx={{ width: 120 }} image={sessionExpiredImg} alt="green iguana" />
        <Typography gutterBottom variant="h6" component="div" sx={{ color: 'text.primary' }}>
          {t('notify.sessionExpired')}
        </Typography>
      </Box>
    </Modal>
  );
};
export default ErrorFilterModal;
