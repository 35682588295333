import { useState, useEffect, useRef, forwardRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useTheme,
  Grid,
  styled,
  Typography,
  Box,
  TextField,
  Paper,
  LinearProgress,
  ListItem,
  Link,
  SelectChangeEvent,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { alpha } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { TransitionProps } from '@mui/material/transitions';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { useSpring, animated } from '@react-spring/web';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ScanButton from '@/components/common/Button/ScanButton';
import StopButton from '@/components/common/Button/StopButton';
import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanDoneNotice from '@/components/common/Notice/ScanDoneNotice';
import ScanTime from '@/components/common/ScanTime';
import { useCommonInfo } from '@/contexts/Common';
import { useScanSitemapInfo } from '@/contexts/ScanSitemap';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useBreakpoints from '@/helpers/useBreakpoints';
import useNotify from '@/Hooks/common/useNotify';
import useScanError from '@/Hooks/common/useScanError';
import useAsm from '@/Hooks/fetchApi/useAsm';
import useSitemap from '@/Hooks/useSetSitemap';
import { REGEX_DOMAIN } from '@/utils/constants';
import { decrypt, encrypt } from '@/utils/crypto';

const RecursiveTreeView = ({ items }: { items: any[] }) => (
  <TreeView
    aria-label="customized"
    defaultExpanded={['1']}
    defaultCollapseIcon={<MinusSquare />}
    defaultExpandIcon={<PlusSquare />}
    defaultEndIcon={<CloseSquare />}
    sx={{ overflowX: 'hidden' }}
  >
    {items.map((item, index) => (
      <StyledTreeItem key={index} nodeId={`node-${index}`} label={item.label}>
        {item.children.length > 0 && <RecursiveTreeView items={item.children} />}
      </StyledTreeItem>
    ))}
  </TreeView>
);

// CSS

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.label}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' /* Ngăn ngắn dòng */,
    overflow: 'hidden',
    fontSize: '1.4rem ! important',
  },
}));

const Spider = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const { onNotify } = useNotify();
  const { isScanError } = useCommonInfo();
  const { sitemap } = useScanSitemapInfo();
  const { setScanError } = useScanError();
  const { setSitemap } = useSitemap();
  const intervalRef = useRef<any>();
  const { handleSiteMap } = useAsm();

  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();

  const [sitemapState, setSitemapState] = useState({
    sitemapData: sitemap?.dataSitemap,
    isRunning: sitemap?.stateRunning,
    isFirstCall: sitemap?.isFirstCall,
    isSuccessApi: sitemap?.isSuccessApi,
  });

  const [domain, setDomain] = useState(domainActive);
  const [updateTime, setUpdateTime] = useState('');
  const [statusScanWebsocket, setStatusScanWebsocket] = useState(false);

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  useEffect(() => {
    if (isScanError) {
      setSitemapState((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      clearInterval(intervalRef.current);
      clearInterval(sitemap?.intervalId);
      setSitemap({
        ...sitemap,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
      });
      setScanError(false);
    }
  }, [isScanError]);

  useEffect(() => {
    if (sitemap?.intervalId) {
      clearInterval(sitemap?.intervalId);
    }
  }, []);

  useEffect(() => {
    if (sitemapState.isRunning && !sitemap?.isFinish) {
      if (sitemapState.isFirstCall) {
        handleScan();
      }
      if (sitemapState.isSuccessApi) {
        intervalRef.current = setInterval(() => {
          handleScan();
        }, 10000);
      } else {
        clearInterval(intervalRef.current);
        handleCacheSitemap();
      }
    } else {
      clearInterval(intervalRef.current);
      setSitemap({
        ...sitemap,
        dataSitemap: sitemapState.sitemapData,
        stateRunning: false,
        isSuccessApi: sitemapState.isSuccessApi,
        isFirstCall: sitemapState.isFirstCall,
        keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
        intervalId: intervalRef.current,
      });
    }
    return () => {
      handleCacheSitemap();
    };
  }, [sitemapState.isRunning, sitemapState.isFirstCall, sitemapState.isSuccessApi]);

  const handleScan = async () => {
    const searchKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const params = {
      target: searchKey,
    };
    const dataRes = await handleSiteMap(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, is_finish } = dataRes;
    setSitemapState((prevState) => ({
      ...prevState,
      isFirstCall: false,
      isSuccessApi: true,
      sitemapData: data,
    }));
    if (is_finish === 'true') {
      setSitemap({
        ...sitemap,
        dataSitemap: data,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        isFinish: true,
      });
      clearInterval(intervalRef.current);
      clearInterval(sitemap?.intervalId);
      setSitemapState((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      onNotify('success', t('notify.scanSpiderDone'), null);
      return;
    }
    setSitemap({
      dataSitemap: data,
      stateRunning: sitemapState.isRunning,
      isSuccessApi: sitemapState.isSuccessApi,
      isFirstCall: sitemapState.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
      isFinish: false,
    });
  };

  const handleCacheSitemap = () =>
    setSitemap({
      ...sitemap,
      dataSitemap: sitemapState.sitemapData,
      stateRunning: sitemapState.isRunning,
      isSuccessApi: sitemapState.isSuccessApi,
      isFirstCall: sitemapState.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
    });

  // validate search
  const validationSchema = yup.object({
    searchKey: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(REGEX_DOMAIN, t('validation.invalidDomain')),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      searchKey: sitemap?.keySearch,
    },
  });

  const onSubmit = (data: any) => {
    setSitemap({
      ...sitemap,
      isFinish: false,
    });

    setSitemapState((prevState) => ({
      ...prevState,
      sitemapData: [],
      isRunning: true,
      isFirstCall: true,
    }));
    setSitemap(null);
  };

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      if (!_.isEmpty(filteredList.find((item: any) => item.title === 'crawlDomain')?.data?.data)) {
        setSitemapState((prevState) => ({
          ...prevState,
          sitemapData: filteredList.find((item: any) => item.title === 'crawlDomain')?.data?.data,
        }));
        setUpdateTime(filteredList.find((item: any) => item.title === 'crawlDomain')?.update_time);
        setStatusScanWebsocket(false);
      } else {
        setStatusScanWebsocket(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  // Lọc và xử lý dữ liệu
  const filteredData: string[][] = sitemapState.sitemapData
    ?.filter((url: string) => url.startsWith('http://') || url.startsWith('https://'))
    .map((url: string) => {
      const protocol = url.match(/https?:\/\//); // Lấy protocol
      const path = url.replace(/^(https?:\/\/)/, ''); // Loại bỏ protocol
      const parts = path.split('/'); // Tách chuỗi bằng dấu /
      if (protocol) {
        // Nếu có protocol, thêm protocol trở lại ở phần thứ 0 của mảng
        parts[0] = `${protocol}${parts[0]}`;
        // xóa :443 hoặc :80 () (xóa số sau dấu :)
        parts[0] = parts[0].replace(/:\d+/g, '');
      }
      return parts;
    });

  // Tạo cấu trúc dữ liệu phù hợp với TreeView
  const createTreeViewData = (data: string[][]): any[] => {
    const treeData: any[] = [];
    data?.forEach((parts) => {
      let currentNode: any[] = treeData;
      parts?.forEach((part) => {
        const existingNode = currentNode.find((node) => node.label === part);
        if (existingNode) {
          currentNode = existingNode.children;
        } else {
          const newNode = { label: part, children: [] };
          currentNode.push(newNode);
          currentNode = newNode.children;
        }
      });
    });
    return treeData;
  };

  const treeViewData = createTreeViewData(filteredData);

  // Kiểm tra xem một chuỗi có phải là URL hợp lệ hay không
  const isURL = (str: string): boolean => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/;
    return urlPattern.test(str);
  };

  return (
    <>
      <Helmet>
        <title>Crawl - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        Crawl
      </Typography>
      <Grid sx={{ mt: 2 }}>
        <Box
          sx={{
            backgroundColor: 'background.main',
            flexDirection: 'column',
            padding: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          {inforUser?.role === 'super admin' ? (
            <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: isMobile ? '100%' : '50%' }}>
                <Controller
                  name="searchKey"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      variant="outlined"
                      disabled={!!sitemapState.isRunning}
                      fullWidth
                      helperText={errors.searchKey?.message?.toString()}
                      error={Boolean(errors.searchKey)}
                      onKeyDown={handleKeyDown}
                      sx={{
                        display: isMobile ? 'flex' : '',
                        alignItems: isMobile ? 'center' : '',
                        justifyContent: isMobile ? 'center' : '',
                      }}
                      placeholder={t('placeholder.mainDomain')}
                    />
                  )}
                />
              </form>
              {sitemapState.isRunning ? (
                <StopButton
                  handleClick={() => {
                    setSitemapState((prevState) => ({
                      ...prevState,
                      isSuccessApi: false,
                      isRunning: false,
                    }));
                  }}
                />
              ) : (
                <ScanButton handleClick={handleSubmit(onSubmit)} />
              )}
            </Box>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
              {!_.isEmpty(sitemapState.sitemapData && updateTime) && <ScanTime updateTime={updateTime} />}
            </Box>
          )}
          {sitemap?.isFinish && sitemapState.isRunning !== undefined && <ScanDoneNotice />}
          {(sitemapState.isRunning || statusScanWebsocket) && (
            <>
              <Typography
                variant="body2"
                sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
              >
                {t('notify.scanInProgress')}
              </Typography>
              <br />
              <Box sx={{ width: '100%', marginTop: 3 }}>
                <LinearProgress color="info" />
              </Box>
            </>
          )}
          {!_.isEmpty(sitemapState.sitemapData) ? (
            <Grid container rowSpacing={1} sx={{ justifyContent: 'space-between' }}>
              <Grid item md={4.7} sm={12}>
                <Box
                  sx={{
                    width: isMobile || isTablet ? '83vw' : '100%',
                    marginTop: 3,
                    padding: 2,
                    backgroundColor: 'background.main',
                    borderRadius: 1,
                    overflowWrap: 'anywhere',
                    boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                  }}
                >
                  <RecursiveTreeView items={treeViewData} />
                </Box>
              </Grid>
              <Grid item md={7} sm={12}>
                <Box
                  sx={{
                    width: isMobile || isTablet ? '83vw' : '100%',
                    marginTop: 3,
                    padding: 2,
                    backgroundColor: 'background.main',
                    borderRadius: 1,
                    overflowWrap: 'anywhere',
                    boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                  }}
                >
                  {sitemapState.sitemapData.map((url: any, index: number) => (
                    <ListItem sx={{ color: 'text.primary', padding: 0.5, fontSize: '1.4rem' }} key={index}>
                      {isURL(url) ? (
                        <Link
                          href={url}
                          sx={{ color: `${theme.palette.info.main}`, fontSize: '1.4rem' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {url}
                        </Link>
                      ) : (
                        url
                      )}
                    </ListItem>
                  ))}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box component={Paper} sx={{ marginTop: 4, textAlign: 'center', padding: 3, fontSize: '1.4rem' }}>
              {t('notify.noResult')}
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default Spider;
