import { useSocialInfo } from '@/contexts/Social';
import { actionType } from '@/contexts/Social/action';

const useSetTopicSocial = () => {
  const { dispatch } = useSocialInfo();

  const setTopicSocial = (topicSocial: any) => {
    dispatch({
      type: actionType.SET_TOPIC_SOCIAL,
      topicSocial,
    });
  };

  return { setTopicSocial };
};
export default useSetTopicSocial;
