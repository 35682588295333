import { useCallback } from 'react';

import { useScanSitemapInfo } from '@/contexts/ScanSitemap';
import { actionType } from '@/contexts/ScanSitemap/action';

const useSitemap = () => {
  const { dispatch } = useScanSitemapInfo();

  const setSitemap = useCallback(
    (sitemap: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      sitemap !== -1
        ? dispatch({
            type: actionType.SET_SITE_MAP,
            sitemap: {
              dataSitemap: sitemap?.dataSitemap,
              stateRunning: sitemap?.stateRunning || false,
              keySearch: sitemap?.keySearch,
              intervalId: sitemap?.intervalId,
              isFirstCall: sitemap?.isFirstCall,
              isSuccessApi: sitemap?.isSuccessApi,
              isFinish: sitemap?.isFinish,
            },
          })
        : null;
    },
    [dispatch],
  );
  return {
    setSitemap,
  };
};
export default useSitemap;
