import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Box, Typography, InputBase, useTheme, Pagination, CircularProgress } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import usePhishing from '@/Hooks/fetchApi/usePhishing';
import { IFilteredMalware } from '@/interfaces/phishing';
import { decrypt } from '@/utils/crypto';

import AddCampaign from './CreateCampaign/AddCampaign';
import TableCampaign from './TableCampaign';

interface CampaignProps {
  listCampaign: [];
  listMalware: IFilteredMalware[];
  countCampaign: number;
  handleGetListCampaign: (arg: number) => Promise<void>;
}

const Campaign: React.FC<CampaignProps> = ({ listCampaign, listMalware, countCampaign, handleGetListCampaign }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleListCampaign } = usePhishing();

  const [filterCampaign, setFilterCampaign] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isAddCampaign, setIsAddCampaign] = useState(false);
  const [pageCampaign, setPageCampaign] = useState(1);
  const [countFilter, setCountFilter] = useState(0);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const handleChangePage = (value: number) => {
    handleGetListCampaign(value);
    setPageCampaign(value);
  };

  const handleChangePageFilter = (valuePage: number) => {
    handleFilterCampaign(searchValue, valuePage);
    setPageCampaign(valuePage);
  };

  const handleFilterCampaign = async (name: string, page: number) => {
    const dataRes = await handleListCampaign({ name, page });
    const { count, results } = dataRes;
    setFilterCampaign(results);
    setCountFilter(count);
    setLoadingFilter(false);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchValue !== '') {
        handleFilterCampaign(searchValue, pageCampaign);
      }
    }, 2000);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            marginBottom: inforUser.role === 'super admin' ? '30px' : 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '12px 12px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                searchValue && (
                  <ClearIcon
                    onClick={() => {
                      setSearchValue('');
                      setFilterCampaign([]);
                      handleGetListCampaign(1);
                    }}
                    sx={{
                      mr: 3,
                      color: 'text.secondary',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  />
                )
              }
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setPageCampaign(1);
                setLoadingFilter(true);
                if (event.target.value === '') {
                  handleGetListCampaign(1);
                }
              }}
            />
          </Box>
          {inforUser.role !== 'member' && (
            <Button
              variant="contained"
              fullWidth
              type="button"
              onClick={() => {
                setIsAddCampaign(true);
              }}
              sx={{
                width: 'fit-content',
                ml: 2,
                borderRadius: '5px',
                textTransform: 'none',
                backgroundColor: 'action.selected',
                color: '#02bb9f',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                whiteSpace: 'nowrap',
              }}
            >
              <AddIcon sx={{ mr: 0.5 }} />
              {t('action.addCampaign')}
            </Button>
          )}
        </Box>
        {searchValue ? (
          <>
            {loadingFilter ? (
              <Typography sx={{ textAlign: 'center', color: 'text.secondary', mt: 4 }}>
                <CircularProgress />
              </Typography>
            ) : (
              <>
                {!_.isEmpty(filterCampaign) ? (
                  <Box
                    sx={{
                      width: '100%',
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      mt: 2,
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <TableCampaign
                      campaign={filterCampaign}
                      setSearchValue={setSearchValue}
                      setFilterCampaign={setFilterCampaign}
                      handleGetListCampaign={handleGetListCampaign}
                      setPage={setPageCampaign}
                      loadingFilter={loadingFilter}
                      listMalware={listMalware}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                      <Pagination
                        count={Math.ceil(countFilter / 10)}
                        color="primary"
                        onChange={(event, value) => {
                          handleChangePageFilter(value);
                        }}
                        page={pageCampaign}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      mt: 3,
                      padding: 2,
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noResult')}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              padding: 2,
              borderRadius: '3px',
              mt: 3,
              boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
            }}
          >
            {!_.isEmpty(listCampaign) ? (
              <>
                <TableCampaign
                  campaign={listCampaign}
                  setSearchValue={setSearchValue}
                  setFilterCampaign={setFilterCampaign}
                  handleGetListCampaign={handleGetListCampaign}
                  setPage={setPageCampaign}
                  listMalware={listMalware}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                  <Pagination
                    count={Math.ceil(countCampaign / 10)}
                    color="primary"
                    onChange={(event, value) => {
                      handleChangePage(value);
                    }}
                    page={pageCampaign}
                  />
                </Box>
              </>
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                {t('notify.noResult')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <AddCampaign
        isAddCampaign={isAddCampaign}
        setIsAddCampaign={setIsAddCampaign}
        handleGetListCampaign={handleGetListCampaign}
        listMalware={listMalware}
      />
    </>
  );
};
export default Campaign;
