import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClick: () => void;
  disable?: any;
}
const AddButton: React.FC<Props> = ({ handleClick, disable }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      fullWidth
      type="button"
      onClick={handleClick}
      disabled={disable}
      color="info"
      sx={{
        width: '90px',
        color: 'common.white',
        boxShadow: 'none',
        fontWeight: '500',
        borderRadius: '5px',
        textTransform: 'none',
        transition: 'all .1s ease-in-out',
        '&:active': {
          transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
          transition: 'all .1s ease-in-out',
        },
        whiteSpace: 'nowrap',
        alignSelf: 'center',
      }}
    >
      {t('action.add')}
    </Button>
  );
};
export default AddButton;
