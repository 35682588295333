import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function IconTemperature(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fontSize="inherit" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M16 2.667c2.311 0 4.199 1.809 4.327 4.087l0.007 0.246 0.001 10.603 0.107 0.092c1.234 1.102 2.023 2.628 2.191 4.297l0.025 0.336 0.009 0.339c0 3.682-2.985 6.667-6.667 6.667s-6.667-2.985-6.667-6.667c0-1.817 0.733-3.513 1.986-4.747l0.241-0.226 0.105-0.091 0.001-10.603c0-2.228 1.682-4.064 3.845-4.306l0.242-0.020 0.246-0.007zM16 4.667c-1.224 0-2.228 0.943-2.326 2.142l-0.008 0.191-0.001 11.593-0.411 0.299c-1.199 0.874-1.922 2.263-1.922 3.775 0 2.577 2.089 4.667 4.667 4.667s4.667-2.089 4.667-4.667c0-1.417-0.635-2.726-1.701-3.604l-0.219-0.17-0.411-0.299-0.002-11.593c0-1.289-1.045-2.333-2.333-2.333zM16 10.667c0.552 0 1 0.448 1 1l0.001 7.819c1.352 0.425 2.333 1.688 2.333 3.181 0 1.841-1.492 3.333-3.333 3.333s-3.333-1.492-3.333-3.333c0-1.493 0.981-2.756 2.334-3.181l-0.001-7.819c0-0.552 0.448-1 1-1z" />
    </SvgIcon>
  );
}

export function IconLocation(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fontSize="inherit" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M7.791 6.091c4.534-4.534 11.885-4.534 16.419 0s4.534 11.885 0 16.419l-1.583 1.565c-1.166 1.145-2.68 2.617-4.541 4.416-1.163 1.125-3.008 1.124-4.171 0l-4.655-4.528c-0.585-0.574-1.075-1.059-1.469-1.453-4.534-4.534-4.534-11.885 0-16.419zM22.795 7.505c-3.753-3.753-9.837-3.753-13.59 0s-3.753 9.837 0 13.59l1.983 1.957c1.092 1.069 2.464 2.403 4.117 4.001 0.388 0.375 1.003 0.375 1.39 0l4.527-4.402c0.625-0.614 1.15-1.132 1.573-1.556 3.753-3.753 3.753-9.837 0-13.59zM16 10.665c2.21 0 4.002 1.792 4.002 4.002s-1.792 4.002-4.002 4.002-4.002-1.792-4.002-4.002c0-2.21 1.792-4.002 4.002-4.002zM16 12.665c-1.106 0-2.002 0.896-2.002 2.002s0.896 2.002 2.002 2.002 2.002-0.896 2.002-2.002c0-1.106-0.896-2.002-2.002-2.002z" />
    </SvgIcon>
  );
}

export function IconLocation2(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fontSize="inherit" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M13.065 2.702c-0.551-0.036-1.027 0.381-1.063 0.932s0.381 1.027 0.932 1.063c6.989 0.46 12.578 6.050 13.036 13.040 0.036 0.551 0.512 0.969 1.063 0.933s0.969-0.512 0.933-1.063c-0.523-7.991-6.91-14.379-14.901-14.905zM11.329 20.83c0.829 0 1.5-0.672 1.5-1.5s-0.672-1.5-1.5-1.5-1.5 0.672-1.5 1.5c0 0.829 0.672 1.5 1.5 1.5zM10.635 29.030l-0.518-0.515c-0.319-0.317-0.758-0.752-1.236-1.228-0.957-0.952-2.077-2.065-2.723-2.711-2.862-2.862-2.862-7.502 0-10.364s7.502-2.862 10.364 0c2.862 2.862 2.862 7.502 0 10.364-0.593 0.593-1.712 1.707-2.683 2.671l-1.794 1.782c-0.39 0.387-1.020 0.388-1.41 0.001zM7.572 15.626c-2.081 2.081-2.081 5.455 0 7.535 0.643 0.643 1.761 1.755 2.718 2.707l1.049 1.042 1.089-1.082c0.971-0.965 2.089-2.077 2.679-2.667 2.081-2.081 2.081-5.455 0-7.535s-5.454-2.081-7.535 0zM13.091 7.337c-0.55-0.050-1.036 0.356-1.086 0.906s0.356 1.036 0.906 1.086c4.464 0.403 8.021 3.959 8.426 8.423 0.050 0.55 0.536 0.955 1.086 0.906s0.956-0.536 0.906-1.086c-0.492-5.426-4.811-9.744-10.238-10.234z" />
    </SvgIcon>
  );
}

export function IconHelp(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fontSize="inherit" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12c-6.627 0-12-5.373-12-12s5.373-12 12-12zM16 6c-5.523 0-10 4.477-10 10s4.477 10 10 10c5.523 0 10-4.477 10-10s-4.477-10-10-10zM16 21c0.828 0 1.5 0.672 1.5 1.5s-0.672 1.5-1.5 1.5c-0.828 0-1.5-0.672-1.5-1.5s0.672-1.5 1.5-1.5zM16 9c2.209 0 4 1.791 4 4 0 1.461-0.424 2.279-1.508 3.416l-0.528 0.539c-0.755 0.787-0.964 1.211-0.964 2.045 0 0.552-0.448 1-1 1s-1-0.448-1-1c0-1.461 0.424-2.279 1.508-3.416l0.528-0.539c0.755-0.787 0.964-1.211 0.964-2.045 0-1.105-0.895-2-2-2s-2 0.895-2 2c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-2.209 1.791-4 4-4z" />
    </SvgIcon>
  );
}

export function IconCheck(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fontSize="inherit" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M11.251 22.252l-5.554-6.249c-0.44-0.495-1.199-0.54-1.694-0.1s-0.54 1.199-0.1 1.694l6.4 7.2c0.459 0.516 1.257 0.54 1.745 0.051l16.8-16.8c0.469-0.469 0.469-1.228 0-1.697s-1.228-0.469-1.697 0l-15.9 15.9z" />
    </SvgIcon>
  );
}
