import { useEffect, useMemo, useState } from 'react';

import { Box, SelectChangeEvent, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import TextFieldOsint from '@/components/common/TextField/TextFieldOsint';
import CustomTabPanel from '@/components/Tab/CustomTabPanel';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { getToolname } from '@/configs/osintConfig';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useOsint from '@/Hooks/fetchApi/useOsint';
import { OsintTypes, SubmitData, TheharvesterTypes } from '@/types/Osint';
import { decrypt, encrypt } from '@/utils/crypto';

const Harvester = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const domainActive = localStorage.getItem('domainActive') && decrypt(localStorage.getItem('domainActive') as string);
  const { dataWebSocketOsint } = useWebSocketContext();
  const [searchParams] = useSearchParams();
  const { handleGetOsint } = useOsint();

  const [dataHarvester, setDataHarvester] = useState<TheharvesterTypes>();
  const [valueTab, setValueTab] = useState(0);
  const [domain, setDomain] = useState(domainActive || '');

  const filteredListOsint = useMemo(
    () => dataWebSocketOsint.find((item: OsintTypes) => item.domain === domain),
    [dataWebSocketOsint, domain],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (inforUser?.role === 'super admin' && location.search) {
        const res = await handleGetOsint({ domain: String(searchParams.get('domain')) });
        const { data } = res;
        setDataHarvester(data?.osint?.theharvester?.[0]);
      } else {
        setDataHarvester(filteredListOsint?.data?.osint?.theharvester?.[0]);
      }
    };

    fetchData();
  }, [inforUser?.role, searchParams, filteredListOsint]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const submitHandler = async (data: SubmitData) => {
    const params = new URLSearchParams({
      domain: data.searchInput,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        {getToolname(location.pathname)}
      </Typography>
      {inforUser?.role === 'super admin' ? (
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextFieldOsint submitHandler={submitHandler} defaultValue={searchParams.get('domain') || ''} />
        </Box>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
          {!_.isEmpty(filteredListOsint?.data?.update_time) && (
            <ScanTime updateTime={filteredListOsint?.data?.update_time} />
          )}
        </Box>
      )}
      <Typography sx={{ fontWeight: 600, mt: 2 }}>{domainActive || searchParams.get('domain')}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
        <StyledTabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <StyledTab label="emails" />
          <StyledTab label="hosts" />
          <StyledTab label="interesting_urls" />
          <StyledTab label="ips" />
          <StyledTab label="shodan" />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        {dataHarvester?.emails ? (
          <Box
            sx={{
              backgroundColor: 'background.menu',
              padding: 2,
              mt: 4,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {dataHarvester?.emails?.map((email: string) => (
              <Typography
                key={email}
                variant="body2"
                component="a"
                href={`mailto: ${email}`}
                sx={{
                  p: 1,
                  color: 'text.primary',
                  '&:hover': {
                    color: '#02BB9F',
                  },
                }}
              >
                {email}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        {dataHarvester?.hosts ? (
          <Box sx={{ backgroundColor: 'background.menu', padding: 2, mt: 4, borderRadius: 1 }}>
            {dataHarvester?.hosts?.map((hosts: string) => (
              <Typography
                variant="body2"
                key={hosts}
                sx={{
                  p: 1,
                  color: 'text.primary',
                }}
              >
                {hosts}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={2}>
        {dataHarvester?.interesting_urls ? (
          <Box
            sx={{
              backgroundColor: 'background.menu',
              padding: 2,
              mt: 4,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {dataHarvester?.interesting_urls?.map((url: string) => (
              <Typography
                key={url}
                variant="body2"
                component="a"
                href={url}
                sx={{
                  p: 1,
                  color: 'text.primary',
                  '&:hover': {
                    color: '#02BB9F',
                  },
                }}
              >
                {url}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={3}>
        {dataHarvester?.ips ? (
          <Box sx={{ backgroundColor: 'background.menu', padding: 2, mt: 4, borderRadius: 1 }}>
            {dataHarvester?.ips?.map((ip: string) => (
              <Typography
                key={ip}
                variant="body2"
                sx={{
                  p: 1,
                  color: 'text.primary',
                }}
              >
                {ip}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={4}>
        <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
          No result
        </Typography>
      </CustomTabPanel>
    </>
  );
};
export default Harvester;
