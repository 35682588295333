import React from 'react';

import { Chart } from 'react-google-charts';

interface Props {
  subdomainDetail: any;
}

const GeoChart: React.FC<Props> = ({ subdomainDetail }) => {
  // Kiểm tra subdomainDetail có giá trị và là mảng không
  if (!subdomainDetail || !Array.isArray(subdomainDetail)) {
    return null;
  }
  // Lọc và tạo mảng mới chỉ chứa các object có thuộc tính geo
  const filteredData = subdomainDetail.filter((item) => item?.geo && item?.geo?.latitude && item?.geo?.longitude);

  let data;
  // Kiểm tra filteredData có giá trị không
  if (filteredData.length === 0) {
    data = [
      ['Lat', 'Long', 'url'],
      [NaN, NaN, ''],
    ];
  } else {
    // Tạo mảng mới chứa các mảng gồm latitude, longitude, và url
    const resultArray = filteredData?.map((item: any) => [
      parseFloat(item.geo.latitude),
      parseFloat(item.geo.longitude),
      `${item.url} , ${item.geo.city}`,
    ]);
    data = [['Lat', 'Long', 'url'], ...resultArray];
  }

  const options = {
    region: 'world',
    displayMode: 'markers',
    backgroundColor: 'transparent',
    datalessRegionColor: '#ded7d7',
    defaultColor: '#2646d4',
  };
  return <Chart chartType="GeoChart" width="100%" height="800px" data={data} options={options} />;
};
export default GeoChart;
