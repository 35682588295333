import { useTheme, Typography, Box, Grid, Card, CardContent, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoadingStatistics = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'background.main',
        flexDirection: 'column',
        padding: 2,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Card sx={{ boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }}>
        <CardContent sx={{ padding: 1.5 }}>
          <Typography sx={{ color: 'text.primary' }} gutterBottom component="div">
            {t('asm.subdomain.statistics.title')}
          </Typography>

          <Grid container sx={{ justifyContent: 'space-between', fontWeight: 500, alignItems: 'center' }}>
            <Grid item sx={{ color: 'text.secondary', fontSize: '1.4rem' }}>
              {t('asm.subdomain.statistics.countIp')}
            </Grid>
            <Grid item>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              marginTop: 0.5,

              fontWeight: 500,
              alignItems: 'center',
            }}
          >
            <Grid item sx={{ color: 'text.secondary', fontSize: '1.4rem' }}>
              {t('asm.subdomain.statistics.internetServices')}
            </Grid>
            <Grid item>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: 2, boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }}>
        <CardContent sx={{ padding: 1.5 }}>
          <Typography sx={{ color: 'text.primary' }} gutterBottom component="div">
            {t('asm.subdomain.statistics.byPort')}
          </Typography>

          <Grid container sx={{ justifyContent: 'space-between', marginTop: 1, alignItems: 'center' }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Skeleton animation="wave" sx={{ fontSize: '1.4rem', width: '100px' }} />
            </Grid>
            <Grid item sx={{ fontWeight: 600 }}>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>

          <Typography sx={{ color: 'text.primary', marginTop: 1 }} gutterBottom component="div">
            {t('asm.subdomain.statistics.byScheme')}
          </Typography>

          <Grid container sx={{ justifyContent: 'space-between', marginTop: 1, alignItems: 'center' }}>
            <Grid item>
              <Box sx={{ marginLeft: 0.5, fontWeight: 600, color: 'text.secondary' }}>
                <Skeleton animation="wave" sx={{ fontSize: '1.4rem', width: '100px' }} />
              </Box>
            </Grid>
            <Grid sx={{ fontWeight: 600 }} item>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>

          <Typography sx={{ color: 'text.primary', marginTop: 1 }} gutterBottom component="div">
            {t('asm.subdomain.statistics.byTech')}
          </Typography>

          <Grid container sx={{ justifyContent: 'space-between', marginTop: 1, alignItems: 'center' }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ marginLeft: 0.5, fontWeight: 600, color: 'text.secondary' }}>
                <Skeleton animation="wave" sx={{ fontSize: '1.4rem', width: '100px' }} />
              </Box>
            </Grid>
            <Grid sx={{ fontWeight: 600 }} item>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>

          <Typography sx={{ color: 'text.primary', marginTop: 1 }} gutterBottom component="div">
            {t('asm.subdomain.statistics.byLocation')}
          </Typography>

          <Grid container sx={{ justifyContent: 'space-between', marginTop: 1, alignItems: 'center' }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ marginLeft: 0.5, fontWeight: 600, color: 'text.secondary' }}>
                <Skeleton animation="wave" sx={{ fontSize: '1.4rem', width: '100px' }} />
              </Box>
            </Grid>
            <Grid sx={{ fontWeight: 600 }} item>
              <Skeleton variant="circular" width={30} height={30} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
export default LoadingStatistics;
