import React, { useState, useEffect } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Link, Button, AppBar, Typography } from '@mui/material';
import axios from 'axios';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom'; // Added useNavigate

import useBreakpoints from '@/helpers/useBreakpoints';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

import PdfViewer from './PDFViewer';
import PdfView from '../asm/ScanVul/PdfView';

interface ScanResult {
  task_id: string;
  file_name: string;
  scan_type: string;
  status: string;
  pdf_base64: string;
  updated_at: string;
}

interface ApiResponse {
  data: ScanResult[];
}

const CodescanResults: React.FC = () => {
  const navigate = useNavigate();
  const [scanResults, setScanResults] = useState<ScanResult[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [mode, setMode] = useState(localStorage.getItem('theme') || 'dark');
  const { isMobile, isTablet } = useBreakpoints();
  const formatDate = (dateString: string): string => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(date);
    } catch (e) {
      console.error('Error formatting date:', e);
      return 'Invalid Date';
    }
  };

  const handleViewPdf = (pdf_base64: string) => {
    const width = Math.floor(window.screen.width * 0.75);
    const height = Math.floor(window.screen.height * 0.75);
    const left = Math.floor((window.screen.width - width) / 2);
    const top = Math.floor((window.screen.height - height) / 2);

    const pdfWindow = window.open('', '_blank', `width=${width},height=${height},left=${left},top=${top}`);

    if (pdfWindow) {
      pdfWindow.document.write(`
        <html>
          <head>
            <title>PDF Viewer</title>
            <style>
              body, html {
                margin: 0;
                padding: 0;
                height: 100vh;
                overflow: hidden;
              }
              #root {
                height: 100vh;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      const container = pdfWindow.document.getElementById('root');
      if (container) {
        const root = createRoot(container);

        root.render(
          <Box sx={{ height: '100vh', width: '100%', backgroundColor: '#171c23' }}>
            <PdfViewer
              data={pdf_base64}
              setShowPdf={(show: any) => {
                if (!show) {
                  pdfWindow.close();
                }
              }}
            />
            {/* <PdfView data={pdf_base64} setShowPdf={(show: any) => pdfWindow.close()} /> */}
          </Box>,
        );
      }

      pdfWindow.document.close();
    } else {
      alert('Please allow popups for this site to view PDFs');
    }
  };

  useEffect(() => {
    const fetchScanResults = async (): Promise<void> => {
      try {
        const token = localStorage.getItem('serviceToken');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await axios.get<ApiResponse>('http://localhost:8000/api/v1/get_scan_result', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setScanResults(response.data.data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
        setError(`Error fetching scan results: ${errorMessage}`);
        console.error('Error fetching scan results:', err);
      }
    };

    fetchScanResults();
  }, []);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'task_id',
      label: 'Task ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'file_name',
      label: 'File Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'scan_type',
      label: 'Scan Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          let color;
          switch (value.toLowerCase()) {
            case 'completed':
              color = '#4caf50'; // green
              break;
            case 'processing':
              color = '#2196f3'; // blue
              break;
            default:
              color = '#757575'; // grey
          }

          return (
            <Box
              sx={{
                backgroundColor: `${color}20`,
                color,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                fontWeight: 'medium',
                textTransform: 'capitalize',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: 'updated_at',
      label: 'Update Time',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => formatDate(value),
      },
    },
    {
      name: 'pdf_base64',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => (
          <Link
            component="button"
            variant="body2"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              handleViewPdf(value);
            }}
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            View PDF
          </Link>
        ),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown' as const,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    download: false,
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.dark',
          height: '60px',
        }}
      >
        <Link
          href="/"
          rel="noopener"
          underline="none"
          sx={{
            fontSize: '1.5em',
            color: 'rgb(231 232 236)',
            textDecoration: 'none',
            fontWeight: 700,
            ml: { sm: 1, xs: 0 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={mode === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
            sx={{
              width: 100,
              display: 'flex',
              alignSelf: 'center',
              marginLeft: 1.5,
              cursor: 'pointer',
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? '1.5rem' : '2rem',
              ml: 0.5,
            }}
          >
            ASM
          </Typography>
        </Link>
      </AppBar>
      <Box
        sx={{
          maxWidth: { xs: '95%', sm: '90%', md: '1200px' },
          width: '100%',
          margin: 'auto',
          mt: { xs: 3, sm: 4, md: 5 }, // Top margin from header
          px: { xs: 2, sm: 3, md: 4 }, // Horizontal padding
          pb: { xs: 3, sm: 4, md: 5 }, // Bottom padding
        }}
      >
        {' '}
        <Box
          sx={{
            mb: { xs: 2, sm: 3 },
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-start' },
          }}
        >
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/sourcescan')}
            sx={{
              px: { xs: 2, sm: 3 },
              py: { xs: 1, sm: 1.5 },
              fontSize: { xs: '0.875rem', sm: '1rem' },
            }}
          >
            Back
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: { xs: 1, sm: 2 },
            overflow: 'hidden',
            '& .MuiPaper-root': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
            '& .MUIDataTable-paper': {
              boxShadow: 'none',
            },
          }}
        >
          <MUIDataTable
            title={
              <Typography
                variant="h6"
                sx={{
                  py: 2,
                  fontSize: { xs: '1.1rem', sm: '1.25rem' },
                  fontWeight: 600,
                }}
              >
                Scan Results
              </Typography>
            }
            data={scanResults}
            columns={columns}
            options={{
              ...options,
              responsive: 'standard',
              selectableRows: 'none',
              print: false,
              download: false,
              elevation: 0,
              rowsPerPageOptions: [10, 25, 50],
              rowsPerPage: 10,
              tableBodyHeight: '100%',
              tableBodyMaxHeight: undefined,
              setTableProps: () => ({
                sx: {
                  '& .MuiTableCell-root': {
                    px: { xs: 2, sm: 3 },
                    py: { xs: 1.5, sm: 2 },
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                  },
                },
              }),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CodescanResults;
