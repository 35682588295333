import { useSocialInfo } from '@/contexts/Social';
import { actionType } from '@/contexts/Social/action';

const useSetTimeScan = () => {
  const { dispatch } = useSocialInfo();

  const setTimeScan = (timeScan: any) => {
    dispatch({
      type: actionType.SET_TIME_SCAN,
      timeScan,
    });
  };

  return { setTimeScan };
};
export default useSetTimeScan;
