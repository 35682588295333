import { useTheme, Typography, Skeleton } from '@mui/material';
import * as _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useSetKeySearch from '@/Hooks/common/useSetKeySearch';
import { PAGE_SIZE } from '@/utils/constants';

interface IProps {
  listSubdomain: [];
}

const TableListSubdomain: React.FC<IProps> = ({ listSubdomain }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setKeySearch } = useSetKeySearch();
  const navigate = useNavigate();

  const handleClickSubdomain = (url: any) => {
    setKeySearch(url);
    navigate('/info');
  };

  // table subdomain

  const columnsSubdomain = [
    {
      name: 'url',
      label: 'URL',
      options: {
        customBodyRender: (value: any) => {
          return (
            <Typography
              variant="body2"
              onClick={() => handleClickSubdomain(value)}
              sx={{
                color: `${theme.palette.info.main}`,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'host',
      label: 'Host',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
    {
      name: 'port',
      label: 'Port',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },

    {
      name: 'scheme',
      label: 'Scheme',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
    {
      name: 'tech',
      label: 'Tech',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
    {
      name: 'waf',
      label: 'Waf',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
    {
      name: 'cve',
      label: 'CVE',
      options: {
        customBodyRender: () => {
          return <Skeleton animation="wave" />;
        },
      },
    },
  ];

  const optionsSubdomain = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
    rowsPerPage: PAGE_SIZE,
    rowsPerPageOptions: [],
  };

  return (
    <>
      <MUIDataTable title="" data={listSubdomain} columns={columnsSubdomain} options={optionsSubdomain as any} />
    </>
  );
};
export default TableListSubdomain;
