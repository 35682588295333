import React, { useState, useCallback, useEffect } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  SelectChangeEvent,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  Paper,
  Link,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

import useBreakpoints from '@/helpers/useBreakpoints';
import useMalware from '@/Hooks/fetchApi/useMalware';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

interface FileData {
  virustotal_result: VirusTotalResult;
  hybrid_analysis_result: HybridAnalysisResult;
  alienvault_result: AlienVaultResult;
  file_name?: string;
}

interface VirusTotalResult {
  analysis_result: Record<string, AnalysisResult>;
  stats: Record<string, number>;
  threat_class?: {
    popular_threat_category?: Array<{
      value: string;
      count: number;
    }>;
  };
}

interface HybridAnalysisResult {
  classification_tags: string[];
  tags: string[];
  submissions: Array<{
    submission_id: string;
    filename: string;
    url: string | null;
    created_at: string;
  }>;
  machine_learning_models: any[];
  crowdstrike_ai: {
    executable_process_memory_analysis: any[];
    analysis_related_urls: any[];
  };
  job_id: string;
  environment_id: number;
  environment_description: string;
  size: number;
  type: string;
  type_short: string[];
  target_url: string | null;
  state: string;
  error_type: string | null;
  error_origin: string | null;
  submit_name: string;
  md5: string;
  sha1: string;
  sha256: string;
  sha512: string;
  ssdeep: string;
  imphash: string;
  av_detect: number;
  vx_family: string | null;
  url_analysis: boolean;
  analysis_start_time: string;
  threat_score: number | null;
  interesting: boolean;
  threat_level: number;
  verdict: string;
  certificates: any[];
  domains: string[];
  compromised_hosts: string[];
  hosts: string[];
  total_network_connections: number;
  total_processes: number;
  total_signatures: number;
  extracted_files: any[];
  processes: Array<{
    uid: string;
    parentuid: string | null;
    name: string;
    normalized_path: string;
    command_line: string;
    sha256: string;
    pid: number | null;
    modules: any[];
  }>;
  mitre_attcks: Array<{
    tactic: string;
    technique: string;
    attck_id: string;
    attck_id_wiki: string;
    malicious_identifiers_count: number;
    malicious_identifiers: any[];
    suspicious_identifiers_count: number;
    suspicious_identifiers: any[];
    informative_identifiers_count: number;
    informative_identifiers: any[];
    parent: {
      technique: string;
      attck_id: string;
      attck_id_wiki: string;
    } | null;
  }>;
  network_mode: string;
  signatures: Array<{
    threat_level: number;
    threat_level_human: string;
    category: string;
    identifier: string;
    type: number;
    relevance: number;
    name: string;
    description: string;
    origin: string;
    attck_id: string | null;
    capec_id: string | null;
    attck_id_wiki: string | null;
  }>;
}

interface AlienVaultResult {
  analysis: {
    hash: string;
    metadata: {
      priority: boolean;
      tlp: string;
    };
    plugins: {
      obfuscated_commands: {
        results: {
          cmds: any[];
        };
      };
      cuckoo: {
        result: {
          info: {
            combined_score: number;
          };
        };
      };
    };
    datetime_int: number | null;
  };
  page_type: Record<string, any>;
  malware: Record<string, any>;
}

interface AnalysisResult {
  category: string;
  engine_name: string;
  engine_update: string;
  engine_version: string | null;
  method: string;
  result: string | null;
}

interface FileStatus {
  file: File;
  status: 'pending' | 'uploading' | 'success' | 'error';
  progress: number;
}

function convertAnalysisToTableData(analysisResult: Record<string, AnalysisResult>): any[] {
  return Object.entries(analysisResult).map(([key, value]) => ({
    key,
    ...value,
  }));
}

const MalwarePage: React.FC = () => {
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [fileData, setFileData] = useState<FileData[] | null>(null);
  const [status, setStatus] = useState<string>('');
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mode, setMode] = useState(localStorage.getItem('theme') || 'dark');
  const { t } = useTranslation();
  const { handleMalware } = useMalware();
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const columns = [
    {
      name: 'engine_name',
      label: t('malware_scan.engine_name'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category',
      label: t('malware_scan.category'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'engine_update',
      label: t('malware_scan.engine_update'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'engine_version',
      label: t('malware_scan.engine_version'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string | null) => value || 'N/A',
      },
    },
    {
      name: 'method',
      label: t('malware_scan.method'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'result',
      label: t('malware_scan.table_result'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string | null) => value || 'N/A',
      },
    },
  ];

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setFileStatuses((prevStatuses) => [
      ...prevStatuses,
      ...acceptedFiles.map(
        (file): FileStatus => ({
          file,
          status: 'pending',
          progress: 0,
        }),
      ),
    ]);

    if (fileRejections.length > 0) {
      setStatus(`${fileRejections.length} file(s) ${t('rejected_file')}`);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  useEffect(() => {
    console.log('FileData updated in effect:', fileData);
    if (fileData && fileData.length > 0) {
      setSelectedFileName(fileData[0].file_name || 'File 1');
    }
  }, [fileData]);

  const uploadFiles = async () => {
    if (fileStatuses.length === 0) {
      setStatus(t('malware_scan.no_file_warning'));
      return;
    }

    setStatus(t('malware_scan.uploading'));
    setIsUploading(true);

    const formData = new FormData();
    fileStatuses.forEach((fileStatus) => {
      formData.append('files', fileStatus.file, fileStatus.file.name);
    });

    try {
      const response = await handleMalware(formData);
      console.log('Response from handleMalware:', response);

      // Wrap the response in an array if it's not already an array
      const responseArray = Array.isArray(response) ? response : [response];

      // Add file_name to each item in the array
      const processedData = responseArray.map((item, index) => ({
        ...item,
        file_name:
          item.virustotal_result?.file_name ||
          item.hybrid_analysis_result?.submit_name ||
          `File ${(fileData?.length || 0) + index + 1}`,
      }));

      // Combine existing fileData with new data
      setFileData((prevData) => {
        const newData = prevData ? [...prevData, ...processedData] : processedData;
        return newData;
      });

      setFileStatuses((prev) => prev.map((fs) => ({ ...fs, status: 'success', progress: 100 })));
      setStatus(t('malware_scan.analysis_complete'));
    } catch (error) {
      console.error('Error in handleMalware:', error);
      setFileStatuses((prev) => prev.map((fs) => ({ ...fs, status: 'error', progress: 0 })));
      setStatus(`${t('malware_scan.analysis_failed')} ${axios.isAxiosError(error) ? error.message : 'Unknown error'}`);
    } finally {
      setIsUploading(false);
      setFileStatuses([]);
    }
  };

  const handleFileSelect = (event: SelectChangeEvent<string>) => {
    setSelectedFileName(event.target.value as string);
  };

  const renderCharts = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile) return null;

    const vtResult = selectedFile.virustotal_result;
    if (!vtResult) return null;

    type ColorTypes = {
      [key: string]: string;
    };

    // Custom color scheme for each type
    const typeColors: ColorTypes = {
      malicious: '#FF4842', // Red
      suspicious: '#FFC107', // Amber
      undetected: '#54D62C', // Green
      timeout: '#919EAB', // Grey
      failure: '#7A0C2E', // Dark Red
      harmless: '#00AB55', // Light Green
      type_unsupported: '#637381', // Dark Grey
      confirmed_timeout: '#B76E00', // Dark Orange
    };

    return (
      <Grid container spacing={2} sx={{ mt: 2, justifyContent: 'space-between', paddingLeft: '8px' }}>
        <Grid item xs={12}>
          <Typography variant="h6">{selectedFileName}</Typography>
        </Grid>

        {/* Only render threat category chart if data exists */}
        {vtResult.threat_class?.popular_threat_category && vtResult.threat_class.popular_threat_category.length > 0 && (
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Popular Threat Categories</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={vtResult.threat_class.popular_threat_category}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="value" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}

        {/* Only render stats chart if stats exist */}
        {vtResult.stats && Object.keys(vtResult.stats).length > 0 && (
          <Grid item xs={12} md={vtResult.threat_class?.popular_threat_category ? 6 : 12}>
            <Typography variant="subtitle1">Scan Statistics</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={Object.entries(vtResult.stats).map(([name, value]) => ({
                    name: name.charAt(0).toUpperCase() + name.slice(1), // Capitalize first letter
                    value,
                  }))}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {Object.entries(vtResult.stats).map(([name]) => (
                    <Cell key={`cell-${name}`} fill={typeColors[name] || '#637381'} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  formatter={(value) => <span style={{ color: theme.palette.text.primary }}>{value}</span>}
                />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {/* Show a message if no chart data is available */}
        {!vtResult.threat_class?.popular_threat_category && !vtResult.stats && (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No chart data available for this scan
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTable = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile || !selectedFile.virustotal_result) return null;

    const vtResult = selectedFile.virustotal_result;

    return (
      <Box sx={{ marginX: '8px', margin: '10px', width: '100%' }}>
        <Typography variant="h6">{selectedFileName}</Typography>
        <MUIDataTable
          data={convertAnalysisToTableData(vtResult.analysis_result)}
          columns={columns}
          title={t('malware_scan.analysis_result')}
        />
      </Box>
    );
  };

  const renderHybridAnalysis = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile || !selectedFile.hybrid_analysis_result) return null;

    const haResult = selectedFile.hybrid_analysis_result;

    return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">Hybrid Analysis Results</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">General Information</Typography>
            <Typography>
              {t('malware_scan.threat_score')}: {haResult.threat_score || 'N/A'}
            </Typography>
            <Typography>
              {t('malware_scan.verdict')}: {haResult.verdict}
            </Typography>
            <Typography>
              {t('malware_scan.threat_level')}: {haResult.threat_level}
            </Typography>
            <Typography>
              {t('malware_scan.type')}: {haResult.type}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">File Details</Typography>
            <Typography>MD5: {haResult.md5}</Typography>
            <Typography>SHA1: {haResult.sha1}</Typography>
            <Typography>SHA256: {haResult.sha256}</Typography>
          </Grid>
        </Grid>
        {haResult.mitre_attcks && haResult.mitre_attcks.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">{t('malware_scan.mitre_title')}</Typography>
            <ul>
              {haResult.mitre_attcks.map((attack, index) => (
                <li key={index}>
                  {attack.technique} ({attack.attck_id})
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    );
  };

  const renderAlienVault = () => {
    if (!fileData || fileData.length === 0 || !selectedFileName) return null;

    const selectedFile = fileData.find((file) => file.file_name === selectedFileName);
    if (!selectedFile || !selectedFile.alienvault_result) return null;

    const avResult = selectedFile.alienvault_result;

    return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">AlienVault Results</Typography>
        <Typography>Hash: {avResult.analysis.hash}</Typography>
        <Typography>Priority: {avResult.analysis.metadata.priority ? 'Yes' : 'No'}</Typography>
        <Typography>TLP: {avResult.analysis.metadata.tlp}</Typography>
        {avResult.analysis.plugins.cuckoo.result.info.combined_score !== undefined && (
          <Typography>Cuckoo Combined Score: {avResult.analysis.plugins.cuckoo.result.info.combined_score}</Typography>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.dark',
          height: '60px',
        }}
      >
        <Link
          href="/"
          rel="noopener"
          underline="none"
          sx={{
            fontSize: '1.5em',
            color: 'rgb(231 232 236)',
            textDecoration: 'none',
            fontWeight: 700,
            ml: { sm: 1, xs: 0 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={mode === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
            sx={{
              width: 100,
              display: 'flex',
              alignSelf: 'center',
              marginLeft: 1.5,
              cursor: 'pointer',
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? '1.5rem' : '2rem',
              ml: 0.5,
            }}
          >
            ASM
          </Typography>
        </Link>
      </AppBar>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'background.dark',
            padding: '20px',
            minHeight: '100vh',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              paddingBottom: '20px',
              color: theme.palette.text.primary,
            }}
          >
            {fileData && fileData.length > 0 ? t('malware_scan.submit_more') : t('malware_scan.title')}
          </Typography>

          {/* Upload Zone */}
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: '60%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              backgroundColor: theme.palette.background.paper,
              mb: fileData && fileData.length > 0 ? 4 : 0,
            }}
          >
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dotted #000000',
                borderRadius: '4px',
                padding: '40px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragActive ? theme.palette.grey[400] : theme.palette.grey[300],
                width: '100%',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.grey[200],
                },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 48, color: '#000000' }} />
              {isDragActive ? (
                <Typography color="#000000">{t('malware_scan.drag_dropzone')}</Typography>
              ) : (
                <Typography color="#000000">{t('malware_scan.nodrag_dropzone')}</Typography>
              )}
            </Box>

            {/* File Status Display */}
            {fileStatuses.length > 0 && (
              <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Files to upload:
                </Typography>
                <Box sx={{ pl: 2 }}>
                  {fileStatuses.map((fileStatus, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography>
                            {fileStatus.file.name} - {fileStatus.status}
                          </Typography>
                          {fileStatus.status === 'uploading' && (
                            <CircularProgress size={20} thickness={5} sx={{ ml: 1 }} />
                          )}
                        </Box>
                        {fileStatus.status === 'uploading' && (
                          <LinearProgress variant="determinate" value={fileStatus.progress} sx={{ mt: 0.5 }} />
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {/* Upload Button */}
            <Button
              variant="contained"
              onClick={uploadFiles}
              disabled={fileStatuses.length === 0 || isUploading}
              sx={{
                mt: 2,
                minWidth: '200px',
                width: { xs: '90%', sm: '60%', md: '40%' },
                height: '40px',
              }}
            >
              {isUploading ? t('malware_scan.uploading') : t('malware_scan.upload_complete')}
            </Button>
          </Paper>

          {/* Results Section */}
          {fileData && fileData.length > 0 && (
            <Box sx={{ width: '100%', mt: 4 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                  marginBottom: '20px',
                }}
              >
                <FormControl sx={{ width: '300px' }}>
                  <InputLabel id="file-select-label">Select File</InputLabel>
                  <Select
                    labelId="file-select-label"
                    id="file-select"
                    value={selectedFileName}
                    label="Select File"
                    onChange={handleFileSelect}
                  >
                    {fileData.map((file, index) => (
                      <MenuItem key={index} value={file.file_name || `File ${index + 1}`}>
                        {file.file_name || `File ${index + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {selectedFileName && (
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    sx={{
                      '.MuiTabs-indicator': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Tab label="VirusTotal" />
                    <Tab label="Hybrid Analysis" />
                    <Tab label="AlienVault" />
                  </Tabs>

                  <Box sx={{ mt: 2, px: 3 }}>
                    {activeTab === 0 && (
                      <>
                        {renderCharts()}
                        {renderTable()}
                      </>
                    )}
                    {activeTab === 1 && renderHybridAnalysis()}
                    {activeTab === 2 && renderAlienVault()}
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {/* Status Message */}
          {status && (
            <Typography
              sx={{
                mt: 2,
                color: status.includes('failed') ? 'error.main' : 'success.main',
              }}
            >
              {status}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MalwarePage;
