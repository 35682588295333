import { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';

interface PdfViewProps {
  data: string | null;
  setShowPdf: (show: boolean) => void;
}

const PdfViewer: React.FC<PdfViewProps> = ({ data, setShowPdf }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data && iframeRef.current) {
      const pdfDataUri = `data:application/pdf;base64,${data}`;
      iframeRef.current.src = pdfDataUri;
      iframeRef.current.onload = () => setLoading(false);
    }
  }, [data]);

  const handleClose = () => {
    setShowPdf(false);
  };

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'background.main',
        flexDirection: 'column',
        padding: 2,
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Box sx={{ marginTop: 3, height: 'calc(100vh - 100px)' }}>
        <iframe
          ref={iframeRef}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ display: loading ? 'none' : 'block' }}
        />
        {loading && (
          <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2 }}>
            Loading PDF...
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PdfViewer;
