import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useAuthService = () => {
  const { methodPost, methodGet, methodPostWithoutHandleError } = useRequest();
  const loginGoogle = (body: any) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.loginGoogle, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const logoutAccount = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.logoutAccount, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const loginLocal = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPostWithoutHandleError(urlApi.auth.loginLocal, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const verify2FA = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.verify2FA, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const resetPassword = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.resetPassword, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const changePassword = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.changePassword, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const sendCode = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.sendCode, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createUser = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.createUser, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getInfoUser = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.auth.getInfo, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const checkUserExist = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.checkUser, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListUser = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.auth.getListUsers, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCompany = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.auth.getListCompanies, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editCompany = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.editCompany, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editGroup = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.editGroup, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteGroup = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.deleteGroup, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const changePermission = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.changePermission, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const checkSuperAdmin = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.auth.checkSuperAdmin, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createSuperAdmin = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.createSuperAdmin, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteUser = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.auth.deleteUser, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    loginGoogle,
    logoutAccount,
    loginLocal,
    verify2FA,
    resetPassword,
    changePassword,
    sendCode,
    createUser,
    getInfoUser,
    checkUserExist,
    getListUser,
    getListCompany,
    editCompany,
    editGroup,
    deleteGroup,
    changePermission,
    checkSuperAdmin,
    createSuperAdmin,
    deleteUser,
  };
};
export default useAuthService;
