import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useSocialService = () => {
  const { methodGet, methodPost } = useRequest();

  const createTopic = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.createTopic, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editTopic = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.editTopic, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteTopic = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.deleteTopic, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const listTopic = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.social.listTopic, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const detailTopic = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.social.detailTopic, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getOverviewSocial = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.overview, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getPostSocial = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.search, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getTrendingSocial = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.trend, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getStatisticalSocial = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.social.statistical, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    createTopic,
    editTopic,
    deleteTopic,
    listTopic,
    detailTopic,
    getOverviewSocial,
    getPostSocial,
    getTrendingSocial,
    getStatisticalSocial,
  };
};

export default useSocialService;
