import React from 'react';

import { Box, Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { decrypt } from '@/utils/crypto';

import DialogUpdatePermission from './DialogUpdatePermission';

interface EnhancedProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  openDialogDeleteAll: boolean;
  onOpenDialogDeleteAllChange: (newValue: boolean) => void;
  handleDeleteAll: () => void;
  openDialogUpdatePermission: boolean;
  onOpenDialogUpdatePermissionChange: (newValue: boolean) => void;
  handleChangePermission: (arg: string) => void;
}

const EnhancedHead = (props: EnhancedProps) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    openDialogDeleteAll,
    onOpenDialogDeleteAllChange,
    handleDeleteAll,
    openDialogUpdatePermission,
    onOpenDialogUpdatePermissionChange,
    handleChangePermission,
  } = props;

  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 'inherit' }}>
      <Checkbox
        color="primary"
        size="medium"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
      />

      {numSelected > 0 && (
        <Box>
          <Button
            variant="contained"
            type="button"
            color="info"
            onClick={() => {
              onOpenDialogUpdatePermissionChange(true);
            }}
            sx={{
              width: 'fit-content',
              textTransform: 'none',
              fontSize: '1.2rem',
              color: 'common.white',
              whiteSpace: 'nowrap',
            }}
          >
            {t('action.changePermission')}
          </Button>

          {inforUser.role !== 'super admin' && (
            <Button
              variant="contained"
              type="button"
              color="error"
              onClick={() => {
                onOpenDialogDeleteAllChange(true);
              }}
              sx={{
                ml: 4,
                width: '40px',
                textTransform: 'none',
                fontSize: '1.2rem',
                color: 'common.white',
                whiteSpace: 'nowrap',
              }}
            >
              {t('action.delete')}
            </Button>
          )}
        </Box>
      )}

      <ConfirmDeleteModal
        openDialogDelete={openDialogDeleteAll}
        onOpenDialogDeleteChange={onOpenDialogDeleteAllChange}
        handleDelete={handleDeleteAll}
      />
      <DialogUpdatePermission
        openDialogUpdatePermission={openDialogUpdatePermission}
        onOpenDialogUpdatePermissionChange={onOpenDialogUpdatePermissionChange}
        handleChangePermission={handleChangePermission}
      />
    </Box>
  );
};
export default EnhancedHead;
