/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import { TwitterPostsTypes } from '@/types/Social';

interface TitlePostsTwitterProps {
  item: TwitterPostsTypes;
}

const TitlePostsTwitter: React.FC<TitlePostsTwitterProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Link href={item?.author_link}>
          <Avatar
            alt="image"
            variant="rounded"
            src={item?.author_avatar}
            sx={{
              borderRadius: '50%',
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        </Link>
        <Box sx={{ display: 'flex' }}>
          <Link
            href={item?.author_link}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {item?.author_name}
            </Typography>
          </Link>
          <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary', cursor: 'pointer' }}>
            {item?.author_handle}
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
            .
          </Typography>
          <Tooltip
            title={
              item?.post_time
                ? format(new Date(item?.post_time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
                    locale: locale === 'vi' ? vi : enUS,
                  })
                : 'NaN'
            }
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -30],
                  },
                },
              ],
            }}
          >
            <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary', cursor: 'pointer' }}>
              {item?.post_time
                ? format(new Date(item?.post_time), locale === 'vi' ? 'dd MMM' : 'dd MMM ', {
                    locale: locale === 'vi' ? vi : enUS,
                  })
                : 'NaN'}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
};
export default TitlePostsTwitter;
