import { Box, Paper, Typography, Stack, ListItem } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCommonInfo } from '@/contexts/Common';
import useBreakpoints from '@/helpers/useBreakpoints';

import LoadingSsl from './LoadingSsl';

interface Props {
  ssl: any;
}
const SslDetail: React.FC<Props> = ({ ssl }) => {
  const { t } = useTranslation();
  const { isLoading } = useCommonInfo();
  const { isMobile } = useBreakpoints();

  return (
    <>
      <Box>
        {isLoading ? (
          <LoadingSsl />
        ) : (
          <>
            {Object.keys(ssl).length !== 0 ? (
              <>
                <Stack sx={{ alignItems: 'left' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: isMobile ? '100%' : '60%',
                      textAlign: 'center',
                      backgroundColor: 'background.main',
                      padding: 2,
                    }}
                  >
                    <Box>
                      {Object.entries(ssl || {})?.map(([itemKey, itemValue]) => (
                        <ListItem key={itemKey}>
                          <Box sx={{ overflow: 'hidden', fontSize: '1.4rem' }}>{`${itemKey}: ${itemValue}`}</Box>
                        </ListItem>
                      ))}
                    </Box>
                  </Paper>
                </Stack>
              </>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 3,
                  color: 'text.secondary',
                }}
              >
                {t('notify.noResult')}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default SslDetail;
