import { ReactElement, useState } from 'react';

import { Alert, Box, Container, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LightHeroBackground from '@/assets/image/gradient.jpg';
import DarkHeroBackground from '@/assets/image/gradient_dark.jpg';
import { gradientIdentity } from '@/components/animation/Keyframes';
import { SearchOptionType } from '@/components/identity/inputs/OptionBox';
import SearchBox from '@/components/identity/inputs/SearchBox';
import { NotFound } from '@/components/identity/notfound/NotFound';
import Chart from '@/components/identity/sliders/Chart';
import Validators from '@/helpers/validations/validators';
import useLoading from '@/Hooks/common/useLoading';
import useNotify from '@/Hooks/common/useNotify';
import useIdentity from '@/Hooks/fetchApi/useIdentity';
import { IdentityData } from '@/interfaces/identity';
import { DARK_MODE_THEME } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

import IdentityResult from './IdentityResult';

export const Identity = (): ReactElement => {
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const accessToken = localStorage.getItem('serviceToken');
  const { setLoading } = useLoading();
  const { onNotify } = useNotify();
  const { t } = useTranslation();
  const { handleGetIdentityData } = useIdentity();
  const { formatPhone } = Validators();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  const [data, setData] = useState<IdentityData>({} as IdentityData);
  const [searchOption, setSearchOption] = useState<SearchOptionType>({
    name: 'Phone',
    value: 'phone',
  });
  const [isHomePage, setHomePage] = useState(true);
  const [isResultPage, setResultPage] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();

  const submitHandler = async () => {
    if (!accessToken) {
      setLoading(false);
      setResultPage(true);
      setHomePage(false);
      return;
    }

    const payload = {
      search_term: searchOption.value === 'phone' ? formatPhone(getValues('searchInput')) : getValues('searchInput'),
      search_type: searchOption.value,
    };

    const result = await handleGetIdentityData(payload);
    setData(result);
    setResultPage(true);
    setHomePage(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(submitHandler)();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          paddingTop: { md: '8em', sm: 0 },
          backgroundImage: `url(${themeApp === 'dark' ? DarkHeroBackground : LightHeroBackground})`,
          backgroundSize: 'cover',
          fontSize: '16px',
          pb: '5em',
        }}
      >
        <form onSubmit={handleSubmit(submitHandler)}>
          <Container sx={{ mb: '6em' }}>
            <Box sx={{ marginX: 'auto', maxWidth: '45em', paddingTop: { md: '6em', xs: '12em' }, textAlign: 'center' }}>
              <Box sx={{ marginBottom: '3em' }}>
                <Typography variant="h2" sx={{ lineHeight: '1.1em', fontWeight: 800 }}>
                  {t('identity.footer.appNameUp')}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 800,
                    lineHeight: '1.1em',
                    WebkitTextFillColor: 'transparent',
                    background:
                      'linear-gradient(270deg, rgb(255, 115, 86) 0%, rgb(255, 89, 226) 25.52%, rgb(82, 221, 246) 50%, rgb(234, 223, 78) 76.04%, rgb(255, 115, 86) 100%)',
                    backgroundSize: '200% auto',
                    WebkitBackgroundClip: 'text',
                    backgroundClip: 'text',
                    animation: `${gradientIdentity} 6s linear infinite`,
                  }}
                >
                  {t('identity.footer.appNameDown')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', position: 'relative', mb: '1.5em' }}>
                <SearchBox
                  handleKeyDown={handleKeyDown}
                  register={register}
                  searchOption={searchOption}
                  setSearchOption={setSearchOption}
                />
              </Box>
              {errors.searchInput && <Alert severity="error">{errors.searchInput.message as string}</Alert>}
            </Box>
          </Container>
        </form>
        {isHomePage && <Chart />}
        {isResultPage && (
          <>
            {accessToken && !_.isEmpty(data) && <IdentityResult identity={data} />}

            {accessToken && _.isEmpty(data) && <NotFound />}
          </>
        )}
      </Box>
    </>
  );
};
