import { useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from '@mui/material';

import { formatDate } from '@/configs/date';
import {
  PageTypes,
  StatsTypes,
  CookieTypes,
  TechnologiesTypes,
  TaskTypes,
  DomainStatTypes,
  RegDomainStatTypes,
  ConfidencePatternTypes,
  StatisticsDataTypes,
  ScanUrlTypes,
} from '@/types/Osint';

import DomainInformation from './DomainInformation';

interface SummaryProps {
  listUrlscan: ScanUrlTypes;
}

const Summary: React.FC<SummaryProps> = ({ listUrlscan }) => {
  const theme = useTheme();

  const {
    page: dataPage,
    task: dataTask,
    stats: dataStats,
    technologies: dataTechnologies,
    cookies: dataCookies,
  } = useMemo<{
    page?: PageTypes | undefined;
    task?: TaskTypes | undefined;
    stats?: StatsTypes | undefined;
    technologies?: TechnologiesTypes[] | undefined;
    cookies?: CookieTypes[] | undefined;
  }>(() => {
    return {
      page: listUrlscan?.page,
      task: listUrlscan?.task,
      stats: listUrlscan?.stats,
      technologies: listUrlscan?.meta?.processors?.wappa?.data,
      cookies: listUrlscan?.data?.cookies,
    };
  }, [listUrlscan]);

  const StatisticsData = useMemo<StatisticsDataTypes[]>(
    () => [
      {
        label: 'Requests',
        value: dataStats?.domainStats?.reduce((total: number, item: DomainStatTypes) => total + Number(item?.count), 0),
      },
      {
        label: 'HTTPS',
        value: `${dataStats?.securePercentage} %`,
      },
      {
        label: 'IPv6',
        value: dataStats?.IPv6Percentage,
      },
      {
        label: 'Domains',
        value: dataStats?.regDomainStats?.length,
      },
      {
        label: 'Subdomains',
        value: dataStats?.domainStats?.length,
      },
      {
        label: 'IPs',
        value: dataStats?.domainStats?.reduce(
          (total: number, item: DomainStatTypes) => total + Number(item?.ips?.length),
          0,
        ),
      },
      {
        label: 'Countries',
        value: dataStats?.uniqCountries,
      },
      {
        label: 'Transfer',
        value: `${
          dataStats?.regDomainStats &&
          Math.round(
            dataStats?.regDomainStats?.reduce(
              (total: number, item: RegDomainStatTypes) => total + Number(item.encodedSize),
              0,
            ) / 1024,
          )
        } KB`,
      },
      {
        label: 'Size',
        value: `${
          dataStats?.regDomainStats &&
          Math.round(
            dataStats?.regDomainStats?.reduce(
              (total: number, item: RegDomainStatTypes) => total + Number(item.size),
              0,
            ) / 1024,
          )
        } KB`,
      },
      {
        label: 'Cookies',
        value: dataCookies?.length,
      },
    ],
    [dataStats, dataCookies],
  );

  return (
    <>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography sx={{ fontWeight: 500 }}>Summary</Typography>
            <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: 2, mt: 1 }}>
              {dataPage?.ip && (
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="body2">The main IP is </Typography>
                  <Typography variant="body2" sx={{ ml: 1, fontWeight: 500, color: 'primary.main' }}>
                    {dataPage?.ip}
                  </Typography>
                </Box>
              )}
              {dataPage?.country && (
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography variant="body2">Located in </Typography>
                  <Typography variant="body2" sx={{ ml: 1, fontWeight: 500, color: 'primary.main' }}>
                    {dataPage?.country}
                  </Typography>
                </Box>
              )}
              {dataPage?.asnname && (
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography variant="body2">Belongs to </Typography>
                  <Typography variant="body2" sx={{ ml: 1, fontWeight: 500, color: 'primary.main' }}>
                    {dataPage?.asnname}
                  </Typography>
                </Box>
              )}
              {dataPage?.domain && (
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography variant="body2">The main domain is </Typography>
                  <Typography variant="body2" sx={{ ml: 1, fontWeight: 500, color: 'primary.main' }}>
                    {dataPage?.domain}
                  </Typography>
                </Box>
              )}
              {dataPage?.tlsIssuer && (
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography variant="body2">TLS certificate: </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {`Issued by ${dataPage?.tlsIssuer} on ${formatDate(dataPage?.tlsValidFrom || '')}. Valid for: ${
                      dataPage?.tlsValidDays
                    } days`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: 500 }}>Domain & IP information</Typography>
            <DomainInformation data={listUrlscan} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography sx={{ fontWeight: 500 }}>Screenshot</Typography>
            <Box component="img" sx={{ mt: 1 }} src={dataTask?.screenshotURL} />
          </Box>
          {dataPage?.title && (
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ fontWeight: 500 }}>Page Title</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {dataPage?.title}
              </Typography>
            </Box>
          )}
          {dataTechnologies && (
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ fontWeight: 500, mb: 1 }}>Detected technologies</Typography>
              {dataTechnologies.map((tech: TechnologiesTypes, index: number) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ fontSize: '1.4rem' }}
                  >
                    {`${tech.app} (${tech.categories && tech.categories[0].name})`}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: 'flex' }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                        Overall confidence:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary', ml: 1 }}>
                        {`${tech.confidenceTotal} %`}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                        Detected patterns
                      </Typography>
                      {tech.confidence?.map((confidence: ConfidencePatternTypes) => (
                        <Typography
                          variant="caption"
                          key={confidence.pattern}
                          sx={{ ml: 1, color: 'text.secondary', mt: 1 }}
                        >
                          {confidence.pattern}
                        </Typography>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
          {dataCookies?.length && (
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ fontWeight: 500 }}>Page Statistics</Typography>
              <Box>
                {StatisticsData.map((item: StatisticsDataTypes) => (
                  <Box key={item.label} sx={{ display: 'inline-block', padding: 4 }}>
                    <Typography sx={{ fontSize: '2.2rem', color: 'primary.main' }}>{item.value}</Typography>
                    <Typography sx={{ mt: 1, color: 'text.disabled' }}>{item.label}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Summary;
