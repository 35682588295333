import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import usePhishingService from '@/services/phishing';

import useLoading from '../common/useLoading';
import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

const usePhishing = () => {
  const {
    getContent,
    createCampaign,
    listCampaign,
    getDetailCampaign,
    updateDetailCampaign,
    deleteDetailCampaign,
    runCampaign,
    listMalware,
    getDetailMalware,
    createMalware,
    updateDetailMalware,
    deleteDetailMalware,
  } = usePhishingService();
  const { setLoadingGif } = useLoadingGif();
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const { onNotify } = useNotify();

  const handleGetContent = async (body: any, getContentSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await getContent(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      getContentSuccess();
      setLoadingGif(false);
      return data;
    }
  };

  const handleCreateCampaign = async (body: any, createCampaignSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createCampaign(body);
    const { status } = res || {};
    if (status === httpStatus.StatusCreated) {
      onNotify('success', t('notify.createCampaign'), 3000);
      createCampaignSuccess();
      setLoadingGif(false);
    }
  };

  const handleListCampaign = async (params: {}) => {
    setLoading(true);
    const res: any = await listCampaign(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoading(false);
      return data;
    }
  };

  const handleGetDetailCampaign = async (id: string, params: {}) => {
    setLoadingGif(true);
    const res: any = await getDetailCampaign(id, params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUpdateDetailCampaign = async (id: string, params: {}, updateCampaignSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await updateDetailCampaign(id, params);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      updateCampaignSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteDetailCampaign = async (id: string, deleteCampaignSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteDetailCampaign(id);
    const { status } = res || {};
    if (status === httpStatus.StatusNoContent) {
      deleteCampaignSuccess();
      setLoadingGif(false);
    }
  };

  const handleRunDetailCampaign = async (id: string, body: any) => {
    setLoadingGif(true);
    const res: any = await runCampaign(id, body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.runCampaign'), 3000);
      setLoadingGif(false);
    }
  };

  const handleListMalware = async (params: {}) => {
    setLoading(true);
    const res: any = await listMalware(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoading(false);
      return data;
    }
  };

  const handleCreateMalware = async (body: any, createMalwareSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createMalware(body);
    const { status } = res || {};
    if (status === httpStatus.StatusCreated) {
      onNotify('success', t('notify.createMalware'), 3000);
      createMalwareSuccess();
      setLoadingGif(false);
    }
  };

  const handleGetDetailMalware = async (id: string, params: {}) => {
    setLoadingGif(true);
    const res: any = await getDetailMalware(id, params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleUpdateDetailMalware = async (id: string, body: any, updateMalwareSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await updateDetailMalware(id, body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      updateMalwareSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteDetailMalware = async (id: string, deleteMalwareSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteDetailMalware(id);
    const { status } = res || {};
    if (status === httpStatus.StatusNoContent) {
      deleteMalwareSuccess();
      setLoadingGif(false);
    }
  };

  return {
    handleGetContent,
    handleCreateCampaign,
    handleListCampaign,
    handleGetDetailCampaign,
    handleUpdateDetailCampaign,
    handleDeleteDetailCampaign,
    handleRunDetailCampaign,
    handleListMalware,
    handleCreateMalware,
    handleGetDetailMalware,
    handleUpdateDetailMalware,
    handleDeleteDetailMalware,
  };
};

export default usePhishing;
