import { useCallback } from 'react';

import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import { actionType } from '@/contexts/ScanSubdomain/action';

const useSubdomain = () => {
  const { dispatch } = useScanSubdomainInfo();

  const setSubdomain = useCallback(
    (subdomain: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      subdomain !== -1
        ? dispatch({
            type: actionType.SET_SUBDOMAIN,
            subdomain: {
              listSubdomain: subdomain?.listSubdomain,
              dataSubdomain: subdomain?.dataSubdomain,
              countSubdomain: subdomain?.countSubdomain,
              stateRunning: subdomain?.stateRunning || false,
              stateButton: subdomain?.stateButton || false,
              keySearch: subdomain?.keySearch,
              intervalId: subdomain?.intervalId,
              isFirstCall: subdomain?.isFirstCall,
              isSuccessApi: subdomain?.isSuccessApi,
              isFinish: subdomain?.isFinish,
            },
          })
        : null;
    },
    [dispatch],
  );
  return {
    setSubdomain,
  };
};
export default useSubdomain;
