import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Typography,
  TextField,
  Breadcrumbs,
  Link,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AddButton from '@/components/common/Button/AddButton';
import useNotify from '@/Hooks/common/useNotify';
import useAuth from '@/Hooks/fetchApi/useAuth';
import { CompanyType, UserType } from '@/types/management';

interface AddCompanyProps {
  listUsers: UserType[][];
  listCompanies: CompanyType[];
  setIsAddCompany: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const AddCompany: React.FC<AddCompanyProps> = ({
  listUsers,
  listCompanies,
  setIsAddCompany,
  handleGetUser,
  handleGetCompany,
}) => {
  const { onNotify } = useNotify();
  const { handleEditCompany } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();

  const uniqueGroupNames: string[] = [];
  listUsers.map((group: UserType | UserType[]) => {
    if (_.isArray(group)) {
      if (!uniqueGroupNames.includes(group[0].group_name)) {
        uniqueGroupNames.push(group[0].group_name);
      }
    }
  });

  const [group, setGroup] = useState(uniqueGroupNames[0] || '');

  const navigateSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setIsAddCompany(false);
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('validation.fieldRequired')),
    mainDomain: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, t('validation.invalidDomain')),
    clientName: yup.string().required(t('validation.fieldRequired')),
    country: yup.string().required(t('validation.fieldRequired')),
    address: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string(),
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    const isDomainExists = listCompanies.some((domainCheck: any) => domainCheck.main_domain === data.mainDomain.trim());
    if (isDomainExists) {
      onNotify('warning', t('notify.existedDomain'), 3000);
    } else {
      const params = {
        info_company: {
          name_company: data.companyName.trim(),
          name_client: data.clientName.trim(),
          main_domain: data.mainDomain.trim(),
          country: data.country.trim(),
          address: data.address.trim(),
        },
        group_name: group,
      };
      const body = {
        params,
        type: 'add',
      };
      handleEditCompany(body, navigateSuccess);
    }
  };

  const handleChangeGroup = (event: SelectChangeEvent) => {
    setGroup(event.target.value);
  };

  return (
    <>
      <Box sx={{ borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover', boxShadow: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mt: 1 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link
                underline="hover"
                key="1"
                color="inherit"
                onClick={() => {
                  setIsAddCompany(false);
                }}
                sx={{ cursor: 'pointer', fontSize: '1.2rem' }}
              >
                {t('breadcrumb.addCompany.listCompany')}
              </Link>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {t('breadcrumb.addCompany.addCompany')}
              </Typography>
              ,
            </Breadcrumbs>
          </Box>
        </Box>
        <Box sx={{ width: '100%', marginTop: 1, marginBottom: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              padding: 1,
              borderRadius: '10px',
              mt: 2,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" sx={{ width: '100%', justifyContent: 'flex-end' }}>
                    <InputLabel id="demo-simple-select-standard-label" sx={{ fontSize: '0.85rem' }}>
                      {t('placeholder.group')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={group}
                      onChange={handleChangeGroup}
                      label="Group"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            // backgroundColor: '#3c4a5a',
                            border: `1px solid ${theme.palette.divider}`,
                            boxShadow: 5,
                          },
                        },
                      }}
                      sx={{
                        '.MuiInputBase-input': {
                          padding: '15.6px 14px',
                        },
                      }}
                    >
                      {uniqueGroupNames.map((item: any) => (
                        <MenuItem
                          key={item}
                          value={item}
                          sx={{
                            '&.MuiMenuItem-root': {
                              '&.Mui-selected': {
                                backgroundColor: 'action.selected',
                              },
                            },
                          }}
                        >
                          <Typography variant="body2">{item}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="companyName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.companyName')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="mainDomain"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.mainDomain')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="clientName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.clientName')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.address')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.country')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', mt: 3 }}>
                <AddButton handleClick={handleSubmit(onSubmit)} />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AddCompany;
