import { Outlet, Navigate } from 'react-router-dom';

import { useUser } from '@/contexts/User';

function Logged({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useUser();

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default Logged;
