import { useState, forwardRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  useTheme,
  Box,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableRow,
  TableBody,
  Grid,
  TableHead,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TransitionProps } from '@mui/material/transitions';
import { alpha } from '@mui/system';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useNotify from '@/Hooks/common/useNotify';
import useAsm from '@/Hooks/fetchApi/useAsm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Analysis = () => {
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const theme = useTheme();
  const { handleAnalysisCVE } = useAsm();

  const [listCVE, setListCVE] = useState<any>([]);
  const [listEmail, setListEmail] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const validationSchemaCVE = yup.object({
    cve: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^CVE-\d{4,}-\d+$/, t('validation.cve')),
  });

  const validationSchemaEmail = yup.object({
    email: yup.string().email(t('validation.invalidEmail')).required(t('validation.fieldRequired')),
  });

  const {
    handleSubmit: handleSubmitCVE,
    control: controlCVE,
    setValue: setValueCVE,
  } = useForm({
    resolver: yupResolver(validationSchemaCVE),
  });

  const {
    handleSubmit: handleSubmitEmail,
    control: controlEmail,
    setValue: setValueEmail,
  } = useForm({
    resolver: yupResolver(validationSchemaEmail),
  });

  const onSubmitCVE = (data: any) => {
    const isCVEExists = listCVE.some((cve: any) => cve === data.cve.trim());
    if (isCVEExists) {
      onNotify('warning', t('notify.existedCVE'), 3000);
    } else {
      setListCVE((prevList: []) => [...prevList, data.cve?.trim()]);
      setValueCVE('cve', '');
    }
  };

  const onSubmitEmail = (data: any) => {
    const isEmailExists = listEmail.some((email: any) => email === data.email.trim());
    if (isEmailExists) {
      onNotify('warning', t('notify.existedEmail'), 3000);
    } else {
      setListEmail((prevList: []) => [...prevList, data.email?.trim()]);
      setValueEmail('email', '');
    }
  };

  const handleKeyDownCVE = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmitCVE(onSubmitCVE);
    }
  };

  const handleKeyDownEmail = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmitEmail(onSubmitEmail);
    }
  };

  const navigateSuccess = () => {
    setValueCVE('cve', '');
    setValueEmail('email', '');
    setListCVE([]);
    setListEmail([]);
    setOpenDialog(true);
  };

  const handleSendAnalysis = async () => {
    const params = { list_cve: listCVE, list_user: listEmail };
    await handleAnalysisCVE(params, navigateSuccess);
  };

  return (
    <>
      <Helmet>
        <title>Analysis CVE - ESS</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          fullWidth
          type="button"
          onClick={handleSendAnalysis}
          disabled={_.isEmpty(listCVE) || _.isEmpty(listEmail)}
          sx={{
            width: '90px',
            boxShadow: 'none',
            fontWeight: '500',
            borderRadius: '5px',
            textTransform: 'none',
            fontSize: '1.4rem',
            transition: 'all .1s ease-in-out',
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: 'info.dark',
            color: 'common.white',
            '&:hover': {
              color: alpha(theme.palette.common.white, 0.9),
              backgroundColor: 'info.main',
            },
            '&:active': {
              transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
              transition: 'all .1s ease-in-out',
            },
            whiteSpace: 'nowrap',
            alignSelf: 'center',
          }}
        >
          {t('action.send')}
        </Button>
      </Box>
      <Grid container sx={{ justifyContent: 'space-between', marginTop: 2 }}>
        <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              flexDirection: 'column',
              padding: 2,
              borderRadius: '3px',
              boxShadow: 2,
            }}
          >
            <form onSubmit={handleSubmitCVE(onSubmitCVE)}>
              <Controller
                name="cve"
                control={controlCVE}
                render={({ field, fieldState }) => {
                  const isEmpty = field.value === '';
                  const hasError = !!fieldState.error && !isEmpty;
                  const errorMessage = fieldState.error?.message;
                  return (
                    <TextField
                      {...field}
                      variant="outlined"
                      style={{ width: '100%' }}
                      placeholder={t('placeholder.cve')}
                      error={hasError}
                      helperText={hasError ? errorMessage : null}
                      onKeyDown={handleKeyDownCVE}
                      InputProps={{
                        endAdornment: (
                          <Button
                            sx={{
                              backgroundColor: 'action.selected',
                              color: 'text.primary',
                              border: `1px solid ${theme.palette.divider}`,
                              '&:hover': {
                                color: 'primary.light',
                                backgroundColor: 'action.hover',
                                border: `1px solid ${theme.palette.primary.light}`,
                              },
                            }}
                            onClick={handleSubmitCVE(onSubmitCVE)}
                          >
                            <AddIcon />
                          </Button>
                        ),
                      }}
                    />
                  );
                }}
              />
            </form>

            {!_.isEmpty(listCVE) && (
              <Box sx={{ marginTop: 4, padding: 2, backgroundColor: 'background.dark', boxShadow: 4 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>{`CVE (${listCVE.length})`}</Typography>
                <TableContainer
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,

                    marginTop: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: '80%' }}>CVE</StyledTableCell>
                        <StyledTableCell sx={{ width: '20%' }}> </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {listCVE.map((row: any, index: number) => (
                        <StyledTableRow
                          key={index}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'action.focus',
                            },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title={t('tooltip.delete')} placement="left">
                              <DeleteIcon
                                onClick={() => {
                                  setListCVE((prevList: []) => prevList.filter((cve: []) => cve !== row));
                                }}
                                sx={{
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'error.main',
                                  },
                                }}
                              />
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              flexDirection: 'column',
              padding: 2,
              borderRadius: '3px',
              boxShadow: 2,
            }}
          >
            <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
              <Controller
                name="email"
                control={controlEmail}
                render={({ field, fieldState }) => {
                  const isEmpty = field.value === '';
                  const hasError = !!fieldState.error && !isEmpty;
                  const errorMessage = fieldState.error?.message;
                  return (
                    <TextField
                      {...field}
                      variant="outlined"
                      style={{ width: '100%' }}
                      placeholder={t('placeholder.email')}
                      error={hasError}
                      helperText={hasError ? errorMessage : null}
                      onKeyDown={handleKeyDownEmail}
                      InputProps={{
                        endAdornment: (
                          <Button
                            sx={{
                              backgroundColor: 'action.selected',
                              color: 'text.primary',
                              border: `1px solid ${theme.palette.divider}`,
                              '&:hover': {
                                color: 'primary.light',
                                backgroundColor: 'action.hover',
                                border: `1px solid ${theme.palette.primary.light}`,
                              },
                            }}
                            onClick={handleSubmitEmail(onSubmitEmail)}
                          >
                            <AddIcon />
                          </Button>
                        ),
                      }}
                    />
                  );
                }}
              />
            </form>

            {!_.isEmpty(listEmail) && (
              <Box sx={{ marginTop: 4, padding: 2, backgroundColor: 'background.dark', boxShadow: 4 }}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {`Email (${listEmail.length})`}
                </Typography>
                <TableContainer
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,

                    marginTop: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: '80%' }}>Email</StyledTableCell>
                        <StyledTableCell sx={{ width: '20%' }}> </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {listEmail.map((row: any, index: number) => (
                        <StyledTableRow
                          key={index}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'action.focus',
                            },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title={t('tooltip.delete')} placement="left">
                              <DeleteIcon
                                onClick={() => {
                                  setListEmail((prevList: []) => prevList.filter((email: []) => email !== row));
                                }}
                                sx={{
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'error.main',
                                  },
                                }}
                              />
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'background.dark',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
        }}
        BackdropProps={{
          style: {
            opacity: 0.2,
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent>
          <Typography fontSize={16}>{t('notify.analysisCVE')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            variant="outlined"
            sx={{ color: 'text.primary', fontWeight: 500 }}
          >
            {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Analysis;
