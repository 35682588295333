import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import { actionType } from '@/contexts/ScanSubdomain/action';

const useLoadingStatistics = () => {
  const { dispatch } = useScanSubdomainInfo();

  const setLoadingStatistics = (isLoadingStatistics: any) => {
    dispatch({
      type: actionType.SET_LOADING_STATISTICS,
      isLoadingStatistics,
    });
  };

  return {
    setLoadingStatistics,
  };
};
export default useLoadingStatistics;
