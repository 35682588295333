import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme, Typography } from '@mui/material';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FormatTimeNews from '@/components/common/format/FormatTimeNews';
import { HighlightedText } from '@/components/common/format/HighlightTexts';
import { NewsConfig } from '@/configs/NewsConfig';
import useBreakpoints from '@/helpers/useBreakpoints';
import { NewsTypes, NewsState } from '@/types/news';

import { TooltipCustom } from '../common/LightTooltip';

interface SearchResultBoxProps {
  newsState: NewsState;
  updateNewsState: (newState: Partial<NewsState>) => void;
}

const SearchResultBox: React.FC<SearchResultBoxProps> = ({ newsState, updateNewsState }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const locale = localStorage.getItem('locale') || 'vi';
  const { isMobile } = useBreakpoints();
  const { checkValidUrl } = NewsConfig();

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '55px', sm: '62px', md: '70px' },
          maxHeight: '500px',
          width: { xs: '100%', sm: '70%', md: '40%' },
          bgcolor: 'background.main',
          borderRadius: 1,
          boxShadow: 5,
          right: 0,
          overflowX: 'auto',
          transition: 'all 500ms cubic-bezier(0, 0.11, 0, 1.15)',
        }}
      >
        {!_.isEmpty(newsState.listFilterModal) ? (
          <>
            {newsState.listFilterModal.map((item: NewsTypes) => (
              <Link key={item.id} to={`/news/${item.path}`} style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    display: 'flex',
                    padding: { xs: '5px 10px 15px 10px', md: '10px 20px 10px 20px' },
                    ':hover': {
                      backgroundColor: 'action.selected',
                    },
                    transition: 'backgroundColor 0.3s ease-in-out',
                  }}
                >
                  <Box sx={{ width: isMobile ? '50%' : '30%' }}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        cursor: 'pointer',
                        borderRadius: 1,
                        overflow: 'hidden',
                        alignSelf: 'center',
                        ':hover': {
                          '.img': {
                            transform: 'scale(1.1)',
                          },
                        },
                      }}
                    >
                      <img
                        style={{ width: '100%', height: '100px', transition: '0.6s' }}
                        className="img"
                        alt=""
                        src={checkValidUrl(item.img_url, item.image_url_minio)}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: { xs: '100%', md: '65%' },
                      padding: { xs: 0, md: '0px 0px 0px 20px' },
                      ml: { xs: 1, md: 0 },
                    }}
                  >
                    <Box
                      sx={{
                        color: 'text.primary',
                        cursor: 'pointer',
                        fontSize: isMobile ? '1.2' : '1.4rem',
                        transition: 'color 0.3s ease',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                        fontWeight: 600,
                      }}
                    >
                      <HighlightedText
                        text={locale === 'vi' ? item.title_vi : item.title_en}
                        keyword={newsState.searchValue}
                      />
                    </Box>

                    <Typography
                      sx={{
                        fontSize: isMobile ? '1rem' : '1.2rem',
                        color: 'text.secondary',
                        mt: 1,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                        fontWeight: 600,
                      }}
                    >
                      <HighlightedText
                        text={locale === 'vi' ? item.excerpt_vi : item.excerpt_en}
                        keyword={newsState.searchValue}
                      />
                    </Typography>
                    <TooltipCustom title={format(new Date(item.create_time), 'HH:mm, dd-MM-yyyy')} arrow>
                      <Typography
                        sx={{
                          mt: 1,
                          cursor: 'pointer',
                          fontSize: '1rem',
                          color: 'text.secondary',
                          width: 'fit-content',
                          '&:hover': {
                            color: 'primary.light',
                          },
                          transition: ' color 0.4s ease',
                        }}
                      >
                        <FormatTimeNews publishDate={item.create_time} />
                      </Typography>
                    </TooltipCustom>
                  </Box>
                </Box>
              </Link>
            ))}
            {newsState.listFilterModal.length > 9 && (
              <Box
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                  updateNewsState({
                    enterKeyPressed: true,
                    listFilterModal: [],
                    listNews: [],
                    currentPage: 1,
                    currentPageFilter: 1,
                  });
                }}
                sx={{
                  fontSize: '1.2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 1.5,
                  color: 'primary.main',
                  whiteSpace: 'nowrap',
                  borderRadius: '15px',
                  '&:hover': {
                    color: 'text.primary',
                    cursor: 'pointer',
                  },
                  transition: 'color 0.3s ease',
                }}
              >
                {t('action.showMore')} <ChevronRightIcon sx={{ ml: 1, verticalAlign: 'text-bottom' }} />
              </Box>
            )}
          </>
        ) : (
          <>
            {newsState.searchValue.length > 2 && (
              <Typography variant="body2" sx={{ textAlign: 'center', padding: 1, color: 'text.secondary' }}>
                {t('notify.noResult')}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default SearchResultBox;
