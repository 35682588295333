import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Category, NewsState } from '@/types/news';

interface CategoryBusinessDialogProps {
  open: boolean;
  onClose: () => void;
  updateNewsState: (newState: Partial<NewsState>) => void;
  categories: Category[];
}

const CategoryBusinessDialog: React.FC<CategoryBusinessDialogProps> = ({
  onClose,
  open,
  updateNewsState,
  categories,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      disableScrollLock
      sx={{
        '& .MuiDialog-container': {
          backdropFilter: 'blur(5px)',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { xs: '100vw', sm: '80vw' },
            height: { xs: '100vh', sm: '80vh' },
            backgroundColor: 'transparent',
            boxShadow: 'none',
            backgroundImage: 'none',
          },
        },
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle variant="h5" sx={{ color: 'common.white' }}>
        {t('news.category')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: 'common.white',
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon sx={{ fontSize: '2rem' }} />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              textDecoration: 'none',
              position: 'relative',
              '&:not(:first-of-type)': {
                ml: { md: 2, lg: '30px' },
              },
            }}
            onClick={() => {
              if (!location.pathname.includes('/news/business')) {
                navigate('/news/business');
                updateNewsState({
                  currentPage: 1,
                  listNews: [],
                  hasMore: true,
                  searchValue: '',
                  listFilterModal: [],
                  listFilterPage: [],
                  currentPageFilter: 1,
                  enterKeyPressed: false,
                });
              }

              handleClose();
            }}
          >
            <Box
              sx={{
                color: 'text.primary',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                letterSpacing: { xs: 0, md: '0.3px' },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  padding: { sm: 2, md: 0.5 },
                  fontWeight: 700,
                }}
              >
                {t('home.label.news.business')}
              </Typography>
            </Box>
          </Box>
          {categories.map((category: Category) => (
            <Box
              key={category.id}
              sx={{ textDecoration: 'none', position: 'relative' }}
              onClick={() => {
                navigate(`/news/${category.path}`);
                updateNewsState({
                  currentPage: 1,
                  listNews: [],
                  hasMore: true,
                  searchValue: '',
                  listFilterModal: [],
                  listFilterPage: [],
                  currentPageFilter: 1,
                  enterKeyPressed: false,
                });
                handleClose();
              }}
            >
              <Box
                sx={{
                  color: location.pathname?.includes(`/${category.path}`) ? '#4ec0e9' : 'common.white',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  letterSpacing: { xs: 0, md: '0.3px' },
                  mt: 3,
                  ':hover': {
                    color: '#4ec0e9',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '3px',
                    bgcolor: '#4ec0e9',
                    top: '100%',
                    left: 0,
                    transform: location.pathname?.includes(`/${category.path}`) ? 'scaleX(1)' : 'scaleX(0)',
                    transformOrigin: 'right',
                    transition: 'transform 0.5s',
                  },
                  '&:hover::after': {
                    transform: 'scaleX(1)',
                    transformOrigin: 'left',
                  },
                }}
              >
                <Typography sx={{ padding: 0.5, fontWeight: 700 }}>{category.label}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryBusinessDialog;
