import { SvgIconComponent } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import CommentIcon from '@mui/icons-material/Comment';
import SourceIcon from '@mui/icons-material/Source';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Typography, Box, Grid, ListItem, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OverviewTopicTypes } from '@/types/Social';

interface BoxStatisticProps {
  data: OverviewTopicTypes[];
}

interface ListItemCustomProps {
  Icon: SvgIconComponent;
  label: string;
  value: number;
}

const ListItemCustom: React.FC<ListItemCustomProps> = ({ Icon, label, value }) => {
  return (
    <ListItem disablePadding>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Icon
            sx={{
              fontSize: '3rem',
              backgroundColor: 'background.menu',
              padding: 0.5,
              borderRadius: 0.5,
              color: 'text.secondary',
            }}
          />
          <Typography
            sx={{ fontSize: '1.5rem', fontWeight: 600, alignSelf: 'center', ml: 1 }}
            color="text.disabled"
            gutterBottom
          >
            {label}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 600, alignSelf: 'center' }} color="text.secondary" gutterBottom>
          {value || 0}
        </Typography>
      </Box>
    </ListItem>
  );
};

const BoxStatistic: React.FC<BoxStatisticProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {data.map((item: OverviewTopicTypes) => (
        <Grid item lg={4} sm={6} xs={12} key={item.img}>
          <Box
            sx={{ padding: 2, boxShadow: 4, borderRadius: 1, minHeight: '220px', backgroundColor: 'background.main' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: '40px', height: '40px' }} component="img" src={item.img} />
              <Typography
                sx={{ fontSize: '1.8rem', ml: 2, fontWeight: 600, alignSelf: 'center' }}
                color="text.secondary"
                gutterBottom
              >
                {item.label}
              </Typography>
            </Box>
            <List sx={{ mt: 2 }}>
              {item.label === 'Youtube' && <ListItemCustom Icon={VideoLibraryIcon} label="Video" value={item.counts} />}

              {item.label !== 'Youtube' && (
                <ListItemCustom Icon={ArticleIcon} label={t('smcc.overview.post')} value={item.counts} />
              )}
              {item.label !== t('smcc.overview.news') && (
                <ListItemCustom Icon={CommentIcon} label={t('smcc.overview.comments')} value={item.comments} />
              )}
              {item.label !== t('smcc.overview.news') && (
                <ListItemCustom Icon={ThumbUpAltIcon} label={t('smcc.overview.reactions')} value={item.reactions} />
              )}
              {item.label === t('smcc.overview.news') && (
                <ListItemCustom
                  Icon={SourceIcon}
                  label={t('smcc.overview.sourceMentioned')}
                  value={item.source_mentions}
                />
              )}
            </List>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default BoxStatistic;
