import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Drawer, Typography, Button, useTheme, IconButton } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import useBreakpoints from '@/helpers/useBreakpoints';
import { decrypt } from '@/utils/crypto';

import ChangePassword from '../authentication/ChangePassword';

interface Props {
  openModal: boolean;
  handleCloseModal: () => void;
  width?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Profile: React.FC<Props> = ({ openModal, handleCloseModal, width }) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setOpenChangePassword(false);
  };

  return (
    <Drawer
      open={openModal}
      onClose={() => {
        handleCloseModal();
        setOpenChangePassword(false);
      }}
      anchor="right"
      transitionDuration={{ appear: 900, enter: 300, exit: 400 }}
      sx={{ zIndex: theme.zIndex.drawer + 1, paddingBlock: 3 }}
    >
      <Box
        sx={{
          width: width || '60vw',
          padding: 2,
          height: '100%',
        }}
      >
        {isMobile || isTablet ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '22px' }}>{t('profile.profile')}</Typography>
            <IconButton
              onClick={() => {
                handleCloseModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Typography sx={{ fontWeight: 600, fontSize: '22px' }}>{t('profile.profile')}</Typography>
        )}

        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <StyledTab label={t('profile.information.information')} />
              <StyledTab label={t('profile.notification.notification')} />
            </StyledTabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography>{t('profile.information.title')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('profile.information.describe')}
            </Typography>
            {!openChangePassword ? (
              <Box
                sx={{
                  mt: 2,
                  padding: 2,
                  boxShadow: 5,
                  borderRadius: 2,
                  textAlign: '-webkit-center',
                }}
              >
                <Box
                  sx={{
                    width: 140,
                    height: 140,
                    borderRadius: '50%',
                    bgcolor: 'primary.dark',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 2,
                  }}
                >
                  <Typography
                    sx={{ fontSize: '6rem', fontWeight: '700', textTransform: 'capitalize', color: 'common.white' }}
                  >
                    {inforUser?.username?.charAt(0) || ''}
                  </Typography>
                </Box>
                <Typography sx={{ fontWeight: 700, mt: 1, textTransform: 'capitalize' }}>
                  {inforUser?.username}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
                  {inforUser?.email}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 1 }}>
                  {inforUser?.role !== 'super admin' && (
                    <>
                      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                        {inforUser?.group}
                      </Typography>
                      <FiberManualRecordIcon sx={{ alignSelf: 'center', height: '1rem' }} />
                    </>
                  )}
                  <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                    {inforUser?.role}
                  </Typography>
                </Box>
                <Button
                  onClick={() => {
                    setOpenChangePassword(true);
                  }}
                  sx={{
                    mt: 3,
                    textTransform: 'initial',
                    fontSize: '1.2rem',
                    color: 'text.primary',
                    border: `1px solid ${theme.palette.divider}`,
                    '&:hover': {
                      bgcolor: 'action.focus',
                      color: 'primary.main',
                    },
                  }}
                >
                  {t('action.changePassword')}
                </Button>
              </Box>
            ) : (
              <ChangePassword setOpenChangePassword={setOpenChangePassword} />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Typography>{t('profile.notification.title')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('profile.notification.describe')}
            </Typography>
          </CustomTabPanel>
        </Box>
      </Box>
    </Drawer>
  );
};
export default Profile;
