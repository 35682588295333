import { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, Stepper, Step, StepLabel, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { IFilteredMalware } from '@/interfaces/phishing';

import GetContent from './GetContent';
import NewCampaign from './NewCampaign';

interface AddCampaignProps {
  isAddCampaign: boolean;
  setIsAddCampaign: (newValue: boolean) => void;
  handleGetListCampaign: (arg: number) => Promise<void>;
  listMalware: IFilteredMalware[];
}

const AddCampaign: React.FC<AddCampaignProps> = ({
  isAddCampaign,
  setIsAddCampaign,
  handleGetListCampaign,
  listMalware,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const steps = [t('asm.phishing.getContentForCampaign'), t('asm.phishing.createCampaign')];

  const [activeStep, setActiveStep] = useState(0);
  const [senderName, setSenderName] = useState('');
  const [contentGenerated, setContentGenerated] = useState('');
  const [subjectMail, setSubjectMail] = useState('');

  return (
    <>
      <Dialog
        open={isAddCampaign}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsAddCampaign(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step sx={{ mt: 2 }} key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>

        <DialogContent>
          {activeStep === 0 && (
            <>
              <GetContent
                setActiveStep={setActiveStep}
                setContentGenerated={setContentGenerated}
                setSubjectMail={setSubjectMail}
                setSenderName={setSenderName}
              />
            </>
          )}
          {activeStep === 1 && (
            <>
              <NewCampaign
                setIsAddCampaign={setIsAddCampaign}
                setActiveStep={setActiveStep}
                contentGenerated={contentGenerated}
                subjectMail={subjectMail}
                senderName={senderName}
                handleGetListCampaign={handleGetListCampaign}
                listMalware={listMalware}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddCampaign;
