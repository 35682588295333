import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

interface AddButtonCustomProps {
  handleClick: () => void;
  text: string;
}
const AddButtonCustom: React.FC<AddButtonCustomProps> = ({ handleClick, text }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      type="button"
      onClick={handleClick}
      sx={{
        width: 'fit-content',
        ml: 2,
        boxShadow: 'none',
        fontWeight: 600,
        borderRadius: '5px',
        textTransform: 'none',
        backgroundColor: 'action.selected',
        color: '#02bb9f',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
        whiteSpace: 'nowrap',
      }}
    >
      <AddIcon sx={{ mr: 0.5 }} />
      {text}
    </Button>
  );
};
export default AddButtonCustom;
