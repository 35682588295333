import { Box, Stack, Grid, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import useBreakpoints from '@/helpers/useBreakpoints';

const LoadingInformation = () => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <Stack sx={{ alignItems: 'left' }}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            width: isMobile ? '100%' : '60%',
            textAlign: 'center',
            backgroundColor: 'background.main',
          }}
        >
          <Box>
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
          </Box>
        </Paper>
      </Stack>
      <Grid container spacing={4} sx={{ justifyContent: 'center', marginTop: 2 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ backgroundColor: 'background.main', padding: 2 }}>
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ backgroundColor: 'background.main', padding: 2 }}>
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
            <Skeleton style={{ marginBottom: 10 }} height={20} width="100%" />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default LoadingInformation;
