import { useLayoutEffect, useRef } from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PieChartProps {
  data: { [key: string]: string | number }[];
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const chartRef = useRef<HTMLDivElement>(null);

  function calculateTotalCount(arr: any) {
    return arr.reduce((total: any, obj: any) => total + obj.count, 0);
  }

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current ? chartRef.current : 'chartdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: 80,
        innerRadius: 60,
        layout: root.verticalLayout,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'count',
        categoryField: 'source',
        legendLabelText: '{category}',
        legendValueText: '',
      }),
    );

    series.slices.template.setAll({
      templateField: 'sliceSettings',
    });

    series.data.setAll(data);

    // Add label
    const label = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.percent(70),
        fill: am5.color(theme.palette.text.primary),
        fontSize: 14,
        fontWeight: '500',
        html: `<span style="color: ${
          theme.palette.primary.main
        }; font-size:30px; font-weight: 500">${calculateTotalCount(data)}</span>`,
      }),
    );

    series.slices.template.set(
      'tooltipHTML',
      `
        <div style="padding: 5px; font-size: 13px; text-align: center;">
          <span style="font-weight: bold; color: ${theme.palette.text.primary};">{category}</span><br/>
          <span style="color: ${theme.palette.text.secondary};">{count} ${t('smcc.overview.postLow')}</span>
        </div>
      `,
    );

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.gridLayout,
      }),
    );

    legend.data.setAll(series.dataItems);

    // Thiết định màu cho label
    legend.labels.template.setAll({
      fill: am5.color(theme.palette.text.primary),
      fontSize: 14,
    });
    legend.valueLabels.template.setAll({
      fill: am5.color(theme.palette.text.primary),
      fontSize: 14,
    });

    // Ẩn chú thích
    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('forceHidden', true);

    series.appear(1000, 100);
    chart.appear();

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '300px' }} />;
};
export default PieChart;
