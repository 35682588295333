/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { NewsConfig } from '@/configs/NewsConfig';
import { NewsState, UpdateNewsStateFunction } from '@/types/management';
import { AnalyticType, Category, CatTypes } from '@/types/news';

interface FilterNewsProps {
  openDialogFilter: boolean;
  onDialogFilterChange: (newValue: boolean) => void;
  setState: (newState: NewsState) => void;
  stateFilter: NewsState;
  updateStateFilter: UpdateNewsStateFunction;
  onPageChange: (newValue: number) => void;
}

const FilterNews: React.FC<FilterNewsProps> = ({
  openDialogFilter,
  onDialogFilterChange,
  setState,
  stateFilter,
  updateStateFilter,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const { typeFilterNews, categoriesBusiness, categoriesCyberSecurity, errorAnalytic } = NewsConfig();

  const [error, setError] = useState<string | null>(null);

  const handleConfirm = () => {
    setState(stateFilter);
    onPageChange(1);
    onDialogFilterChange(false);
  };

  const handleCancel = () => {
    onDialogFilterChange(false);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = e.target.value;
    if (stateFilter.selectedStartDate && endDate <= stateFilter.selectedStartDate) {
      setError(t('validation.endDate'));
    } else {
      setError(null);
      updateStateFilter('selectedEndDate', endDate);
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = e.target.value;
    updateStateFilter('selectedStartDate', startDate);
    if (stateFilter.selectedEndDate && startDate >= stateFilter.selectedEndDate) {
      setError(t('validation.startDate'));
    } else {
      setError(null);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      <Dialog
        open={openDialogFilter}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogFilterChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '500px',
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.news.filterNews')}</Typography>
          <Divider sx={{ mt: 3 }} />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="caption">{t('management.news.typeNews')}</Typography>
            <Box sx={{ display: 'flex', mt: 1, ml: 1 }}>
              {typeFilterNews.map((item: CatTypes) => (
                <Button
                  key={item.id}
                  variant="outlined"
                  color={item.param === stateFilter.type ? 'info' : 'inherit'}
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '1.2rem',
                    ml: 1,
                    color: item.param === stateFilter.type ? 'info.main' : 'text.secondary',
                  }}
                  onClick={() => {
                    updateStateFilter('type', item.param);
                    updateStateFilter('field', '');
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>
          {stateFilter.type !== '' && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption">{t('management.news.field')}</Typography>
              <Box>
                {stateFilter.type === 'cyber_security' && (
                  <>
                    {categoriesCyberSecurity.map((item: Category) => (
                      <Button
                        key={item.id}
                        variant="outlined"
                        color={item.param === stateFilter.field ? 'info' : 'inherit'}
                        size="small"
                        sx={{
                          textTransform: 'capitalize',
                          fontSize: '1.2rem',
                          ml: 1,
                          mt: 1,
                          color: item.param === stateFilter.field ? 'info.main' : 'text.secondary',
                        }}
                        onClick={() => {
                          updateStateFilter('field', item.param);
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </>
                )}
                {stateFilter.type === 'finance' && (
                  <>
                    {categoriesBusiness.map((item: Category) => (
                      <Button
                        key={item.id}
                        variant="outlined"
                        color={item.param === stateFilter.field ? 'info' : 'inherit'}
                        size="small"
                        sx={{
                          textTransform: 'capitalize',
                          fontSize: '1.2rem',
                          ml: 1,
                          mt: 1,
                          color: item.param === stateFilter.field ? 'info.main' : 'text.secondary',
                        }}
                        onClick={() => {
                          updateStateFilter('field', item.param);
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption">{t('management.news.keyword')}</Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="text"
                label=""
                type="text"
                size="small"
                value={stateFilter.keyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateStateFilter('keyword', e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption">{t('management.news.startDate')}</Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="date"
                label=""
                type="date"
                size="small"
                value={stateFilter.selectedStartDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: today }}
                sx={{
                  '& .MuiInputBase-input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: 'invert(30%) sepia(80%) saturate(300%) hue-rotate(180deg)',
                  },
                  '& .MuiInputBase-input[type="date"]::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input[type="date"]::-webkit-clear-button': {
                    display: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption">{t('management.news.endDate')}</Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="date"
                label=""
                type="date"
                size="small"
                value={stateFilter.selectedEndDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: today }}
                sx={{
                  '& .MuiInputBase-input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: 'invert(30%) sepia(80%) saturate(300%) hue-rotate(180deg)',
                  },
                  '& .MuiInputBase-input[type="date"]::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& .MuiInputBase-input[type="date"]::-webkit-clear-button': {
                    display: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
          <Box sx={{ mt: 2, alignItems: 'center' }}>
            <Typography variant="caption">{t('management.news.analytic')}</Typography>
            <Box sx={{ display: 'flex', mt: 1, ml: 1 }}>
              {errorAnalytic.map((item: AnalyticType) => (
                <Button
                  key={item.id}
                  variant="outlined"
                  color={item.value === stateFilter.analyticStatus ? 'info' : 'inherit'}
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '1.2rem',
                    ml: 1,
                    color: item.value === stateFilter.analyticStatus ? 'info.main' : 'text.secondary',
                  }}
                  onClick={() => {
                    updateStateFilter('analyticStatus', item.value);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>
          <Divider sx={{ mt: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button variant="text" color="inherit" onClick={handleCancel}>
              {t('action.cancel')}
            </Button>
            <Button variant="contained" color="info" sx={{ ml: 2 }} onClick={handleConfirm}>
              {t('action.confirm')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FilterNews;
