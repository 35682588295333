export const HighlightedText: React.FC<{ text: string; keyword: string }> = ({ text, keyword }) => {
  if (typeof text !== 'string' || !keyword) {
    return null;
  }
  // Sử dụng biểu thức chính quy để tìm và highlight từ khóa
  const regex = new RegExp(`(${keyword})`, 'gi');
  const parts = text?.split(regex);
  return (
    <span>
      {parts.map((part: any, index: number) =>
        regex.test(part) ? (
          <span key={index} style={{ color: '#212121', backgroundColor: '#e0e0e0' }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
};
