import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Drawer, Link } from '@mui/material';

import bgHeader from '@/assets/image/bg-hoatiet.png';
import ProfileHeader from '@/components/Header/ProfileHeader';
import SideBar from '@/components/Social/SideBar/SideBar';
import useBreakpoints from '@/helpers/useBreakpoints';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS_SOCIAL_WHITE, LOGO_ESS_SOCIAL } from '@/utils/imgBase64';

const Header: React.FC = () => {
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;
  const { isMobile, isTablet } = useBreakpoints();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 'fab',
        width: '100%',
        height: '60px',
        background:
          themeApp === DARK_MODE_THEME
            ? `#1c1a1a url(${bgHeader}) repeat-x top left`
            : `#f3efef url(${bgHeader}) repeat-x top left`,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(isMobile || isTablet) && <MenuIcon onClick={toggleDrawer(true)} sx={{ fontSize: '2rem', ml: 2 }} />}
          <Link
            href="/"
            sx={{
              fontSize: '1.5em',
              color: 'rgb(231 232 236)',
              textDecoration: 'none',
              fontWeight: 700,
              ml: { sm: 4, xs: 0 },
            }}
          >
            <Box
              component="img"
              src={themeApp === DARK_MODE_THEME ? LOGO_ESS_SOCIAL_WHITE : LOGO_ESS_SOCIAL}
              sx={{
                width: '85px',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        </Box>
        <Box sx={{ padding: '8px 16px' }}>
          <ProfileHeader />
        </Box>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ padding: 1 }}>
          <Link
            href="/"
            sx={{
              fontSize: '1.5em',
              color: 'rgb(231 232 236)',
              textDecoration: 'none',
              fontWeight: 700,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              src={themeApp === DARK_MODE_THEME ? LOGO_ESS_SOCIAL_WHITE : LOGO_ESS_SOCIAL}
              sx={{
                width: '85px',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
          <Divider sx={{ mb: 3 }} />
          <SideBar />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
