import moment from 'moment';

export const formatTimeCVEDetail = (isoDateString: string) => {
  const date = moment(isoDateString);
  const formattedDate = date.format('YYYY-MM-DD HH:mm');

  return `${formattedDate}`;
};

export const formatTimeCVEList = (isoDateString: string) => {
  const date = moment(isoDateString);
  const formattedDate = date.format('YYYY-MM-DD');

  return `${formattedDate}`;
};
