import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AosComponent from '@/components/animation/AosComponent';
import useBreakpoints from '@/helpers/useBreakpoints';
import Header from '@/pages/HomePage/Header';
import HeaderMobile from '@/pages/HomePage/HeaderMobile';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

const Contact = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useBreakpoints();
  const { t } = useTranslation();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  return (
    <>
      <Helmet>
        <title>Contact Us | ESS</title>
      </Helmet>
      <Box
        sx={{
          // background: `url(${themeApp === 'dark' ? BgDark : BgLight}) center center no-repeat`,
          // backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          backgroundColor: 'background.dark',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
        }}
      >
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}
        <AosComponent dataAos="fade-right" dataAosDuration="1000">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              maxWidth: '900px',
              mt: { xs: 10, md: 0 },
              alignSelf: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              minHeight: '93vh',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
              sx={{
                textAlign: '-webkit-center',
                width: '400px',
                height: '150px',
                alignSelf: 'center',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                ml: 2,
                mt: { xs: 3, md: 0 },
              }}
            >
              <Typography variant="h5" sx={{ color: 'text.primary', fontWeight: 600 }}>
                {t('contact.company')}
              </Typography>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ display: 'flex' }}>
                  <LocationOnIcon sx={{ fontSize: '2.2rem', color: 'error.main' }} />
                  <Typography sx={{ ml: 1, color: 'text.secondary', whiteSpace: 'nowrap' }}>
                    {t('contact.label.address')}:
                  </Typography>
                </Box>
                <Typography sx={{ ml: 2, color: 'text.primary' }}>{t('contact.address')}</Typography>
              </Box>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ display: 'flex' }}>
                  <MailIcon sx={{ fontSize: '2.2rem', color: 'error.main' }} />
                  <Typography sx={{ ml: 1, color: 'text.secondary' }}>Email:</Typography>
                </Box>
                <Typography
                  component="a"
                  href="mailto: general@essvn.vn"
                  sx={{
                    ml: 2,

                    color: 'text.primary',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                  }}
                >
                  general@essvn.vn
                </Typography>
              </Box>
            </Box>
          </Box>
        </AosComponent>
      </Box>
    </>
  );
};
export default Contact;
