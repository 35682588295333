import { useTheme, Box, Grid, Paper, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

const LoadingRecords = () => {
  const theme = useTheme();
  const ContentDetailBox = styled(Box)({
    border: `1px solid ${theme.palette.divider}`,
    padding: '16px',
    marginTop: 10,
    borderRadius: '10px',
  });

  const TitleDetailBox = styled(Typography)({
    fontSize: '14px',
    fontWeight: 'bold',
  });

  const Loading = () => (
    <Grid item xs={12} md={6}>
      <Box sx={{ p: 2, borderRadius: '5px', bgcolor: 'action.hover' }}>
        <Box>
          <TitleDetailBox>
            <Skeleton variant="rectangular" style={{ marginTop: 3 }} height={20} width="40%" />
          </TitleDetailBox>
          <ContentDetailBox>
            <Skeleton style={{ marginTop: 10 }} height={20} width="80%" />
            <Skeleton style={{ marginTop: 10 }} height={20} width="80%" />
            <Skeleton style={{ marginTop: 10 }} height={20} width="80%" />
          </ContentDetailBox>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </Grid>
  );
};
export default LoadingRecords;
