import { Box, CardMedia, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import successImg from '@/assets/image/success.gif';

const ScanDoneNotice = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        color: `${theme.palette.text.primary}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <CardMedia component="img" sx={{ width: 70 }} image={successImg} alt="green iguana" />
      <Typography component="div">{t('notify.scanDone')}</Typography>
    </Box>
  );
};
export default ScanDoneNotice;
