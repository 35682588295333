import { Navigate, useLocation } from 'react-router-dom';

import { decrypt } from '@/utils/crypto';

function SuperAdminGuard({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  if (inforUser?.role === 'super admin' || inforUser?.identity === true) {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
}

export default SuperAdminGuard;
