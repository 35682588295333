import React from 'react';

import { useCommonInfo } from '@/contexts/Common';
import { actionType } from '@/contexts/Common/action';

const useLoadingGif = () => {
  const { dispatch } = useCommonInfo();

  const setLoadingGif = (isLoadingGif: any) => {
    dispatch({
      type: actionType.SET_LOADING_GIF,
      isLoadingGif,
    });
  };

  return { setLoadingGif };
};
export default useLoadingGif;
