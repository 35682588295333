import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Box, TextField, Typography, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { IconHelp } from '@/assets/svg/svgIcon';
import usePhishing from '@/Hooks/fetchApi/usePhishing';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#5b5b5b',
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

interface GetContentProps {
  setActiveStep: (newValue: number) => void;
  setContentGenerated: (newValue: string) => void;
  setSubjectMail: (newValue: string) => void;
  setSenderName: (newValue: string) => void;
}

const GetContent: React.FC<GetContentProps> = ({
  setActiveStep,
  setContentGenerated,
  setSubjectMail,
  setSenderName,
}) => {
  const { t } = useTranslation();
  const { handleGetContent } = usePhishing();

  const getContentSuccess = () => {
    setActiveStep(1);
    setValue('content', '');
    setValue('senderName', '');
    setValue('url', '');
  };

  const validationSchema = yup.object().shape({
    content: yup.string().required(t('validation.fieldRequired')),
    url: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/, t('validation.invalidUrl')),
    senderName: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const params = { content: data.content, url: data.url, send_name: data.senderName };
    const res = await handleGetContent(params, getContentSuccess);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { content: contentAI, subject, sender_name } = res;
    setContentGenerated(contentAI);
    setSubjectMail(subject);
    setSenderName(sender_name);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="content"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                multiline
                rows={4}
                style={{ width: '100%', marginTop: '3px' }}
                sx={{
                  '& label': {
                    fontSize: '0.85rem',
                  },
                  '.MuiInputBase-input': {
                    padding: '12px',
                  },
                }}
                label={t('placeholder.content')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                InputProps={{
                  endAdornment: (
                    <CustomTooltip title={t('asm.phishing.contentHelper')}>
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          ml: '5px',
                          alignSelf: 'flex-start',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'text.secondary',
                          },
                        }}
                      >
                        <IconHelp />
                      </Box>
                    </CustomTooltip>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="url"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.url')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <CustomTooltip title={t('asm.phishing.urlHelper')}>
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          ml: '5px',
                          alignSelf: 'center',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'text.secondary',
                          },
                        }}
                      >
                        <IconHelp />
                      </Box>
                    </CustomTooltip>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="senderName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.sender')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </Box>
      </form>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{
            width: '90px',
            fontWeight: '500',
            borderRadius: '5px',
            textTransform: 'none',
            transition: 'all .1s ease-in-out',
            color: 'common.white',
            '&:active': {
              transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
              transition: 'all .1s ease-in-out',
            },
            whiteSpace: 'nowrap',
            alignSelf: 'center',
          }}
        >
          {t('action.send')}
        </Button>
      </Box>
    </>
  );
};
export default GetContent;
