const PATH = {
  AUTH: {
    LOGIN: 'login',
    LOGIN_GOOGLE: 'authentication/verify/',
    LOGOUT_GOOGLE: 'authentication/logout/',
    LOGIN_LOCAL: 'authentication/token/',
    VERIFY_2FA: 'authentication/verify-2fa/',
    RESET_PASSWORD: 'authentication/reset_password/',
    CHANGE_PASSWORD: 'authentication/change_password/',
    SEND_PASS_CODE: 'authentication/send_pass_code/',
    CREATE_USER: 'authentication/create_user/',
    GET_INFO_USER: 'authentication/get_user_info/',
    CHECK_USER_EXIST: 'authentication/check_email_exist/',
    GET_LIST_USERS: 'authentication/list_user_info/',
    GET_LIST_COMPANIES: 'authentication/list_company/',
    EDIT_COMPANY: 'authentication/edit_company/',
    EDIT_GROUP: 'authentication/edit_group/',
    DELETE_GROUP: 'authentication/delete_group_domain/',
    CHANGE_PERMISSION: 'authentication/change_permission_user/',
    CHECK_SUPERADMIN: 'authentication/check_exist_super_user/',
    CREATE_SUPERADMIN: 'authentication/create_super_user/',
    DELETE_USER: 'authentication/delete_user/',
  },
  SEARCH: 'data/search',
  NOTIFICATION_TOKEN: 'send_fcm_token',
};

export default PATH;
