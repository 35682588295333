interface CityMappings {
  [key: string]: string;
}

export const cityMappings: CityMappings = {
  Hanoi: 'Hà Nội',
  'Ho Chi Minh': 'TP HCM',
  'Da Nang': 'Đà Nẵng',
  'Tinh Quang Nam': 'Quảng Nam',
  'Tinh Nghe An': 'Nghệ An',
  Haiphong: 'Hải Phòng',
  'Tinh Thanh Hoa': 'Thanh Hoá',
  'Tinh Bac Ninh': 'Bắc Ninh',
  'Tinh Binh GJinh': 'Bình Định',
  'Gia Lai': 'Gia Lai',
  'Tinh Hai Duong': 'Hải Dương',
  'Tinh Ha Tinh': 'Hà Tĩnh',
  'Đắk Lắk': 'Đắk Lắk',
  'Tinh Binh Duong': 'Bình Dương',
  'Tinh Bac Giang': 'Bắc Giang',
  'Tinh GJong Nai': 'Đồng Nai',
  'Tinh Yen Bai': 'Yên Bái',
  'Can Tho': 'Cần Thơ',
  'Tinh Khanh Hoa': 'Khánh Hoà',
  'Tinh Phu Tho': 'Phú Thọ',
  'Tinh Thai Nguyen': 'Thái Nguyên',
  'Tinh Nam GJinh': 'Nam Định',
  'Tinh Ba Ria-Vung Tau': 'Vũng Tàu',
  'Long An': 'Long An',
  'Tinh Thua Thien-Hue': 'Thừa Thiên Huế',
  'Tinh Lam GJong': 'Lâm Đồng',
  'Tinh Thai Binh': 'Thái Bình',
  'Tinh Tuyen Quang': 'Tuyên Quang',
  'Tây Ninh Province': 'Tây Ninh',
  'Quảng Ngãi Province': 'Quảng Ngãi',
  'Tien Giang': 'Tiền Giang',
  'Tinh Quang Ninh': 'Quảng Ninh',
  'Tinh Soc Trang': 'Sóc Trăng',
  'Tinh Quang Binh': 'Quảng Bình',
  'Tinh Ben Tre': 'Bến Tre',
  'Tinh Hung Yen': 'Hưng Yên',
  'Tinh Kien Giang': 'Kiên Giang',
  'Tinh Phu Yen': 'Phú Yên',
  'Tinh Ninh Binh': 'Ninh Bình',
  'An Giang': 'An Giang',
  'Tinh Quang Tri': 'Quảng Trị',
  'Tinh Tra Vinh': 'Trà Vinh',
  'Tinh Vinh Phuc': 'Vĩnh Phúc',
  'Tinh Ca Mau': 'Cà Mau',
  'Tinh GJong Thap': 'Đồng Tháp',
  'Tinh Ha Giang': 'Hà Giang',
  'Tinh Binh Thuan': 'Bình Thuận',
  'Dak Nong': 'Đắk Nông',
  'Tinh Bac Lieu': 'Bạc Liêu',
  'Kon Tum': 'Kon Tum',
  'Tinh Ha Nam': 'Hà Nam',
  'Tinh Ninh Thuan': 'Ninh Thuận',
  'Tinh Hoa Binh': 'Hoà Bình',
  'Tinh Lang Son': 'Lạng Sơn',
  'Tinh Vinh Long': 'Vĩnh Long',
  'Tinh Lao Cai': 'Lào Cai',
  'Tinh Son La': 'Sơn La',
  'Tinh Lai Chau': 'Lai Châu',
  'Tinh Cao Bang': 'Cao Bằng',
  'Tinh Dien Bien': 'Điện Biên',
  'Hau Giang': 'Hậu Giang',
  'Tinh Bac Kan': 'Bắc Kạn',
  'Tinh Binh Phuoc': 'Bình Phước',
  'Thanh Pho Ha Noi': 'Hà Nội',
  'Tinh GJak Lak': 'Đắk Lắk',
  'Tinh Tien Giang': 'Tiền Giang',
  'Tinh Tay Ninh': 'Tây Ninh',
  'Tinh Binh Dinh': 'Bình Định',
  'Thanh Pho GJa Nang': 'Đà Nẵng',
  'Thanh Pho Hai Phong': 'Hải Phòng',
  'Thanh Pho Can Tho': 'Cần Thơ',
  'Tinh Quang Ngai': 'Quảng Ngãi',
  'Ho Chi Minh City': 'TP HCM',
  'Bac Lieu': 'Bạc Liêu',
  'Dien Bien': 'Điện Biên',
  'Tuyen Quang': 'Tuyên Quang',
  'Lam Dong': 'Lâm Đồng',
  'Thai Nguyen': 'Thái Nguyên',
  'Thai Binh': 'Thái Bình',
  'Binh Thuan': 'Bình Thuận',
  'Son La': 'Sơn La',
  'Hung Yen': 'Hưng Yên',
  'Quang Nam': 'Quảng Nam',
  'Soc Trang': 'Sóc Trăng',
  'Phu Tho': 'Phú Thọ',
  'Ha Noi': 'Hà Nội',
  'Nam Dinh': 'Nam Định',
  'Ben Tre': 'Bến Tre',
  'Hai Duong': 'Hải Dương',
  'Dong Thap': 'Đồng Tháp',
  'Hai Phong': 'Hải Phòng',
  'Quang Ninh': 'Quảng Ninh',
  'Thanh Hoa': 'Thanh Hoá',
};
