import { useCallback } from 'react';

import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import { actionType } from '@/contexts/ScanSubdomain/action';

const useStatistics = () => {
  const { dispatch } = useScanSubdomainInfo();

  const setStatistics = useCallback(
    (statistics: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      statistics !== -1
        ? dispatch({
            type: actionType.SET_STATISTICS,
            statistics: {
              countPort: statistics?.countPort,
              countTech: statistics?.countTech,
              countScheme: statistics?.countScheme,
              countTotalDomain: statistics?.countTotalDomain,
              countTotalUniqHost: statistics?.countTotalUniqHost,
              countTotalUniqUrl: statistics?.countTotalUniqUrl,
            },
          })
        : null;
    },
    [dispatch],
  );
  return {
    setStatistics,
  };
};
export default useStatistics;
