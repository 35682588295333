/* eslint-disable @typescript-eslint/naming-convention */

import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ChartMentionStatisticTypes,
  FeatureArticleTypes,
  FeatureSourceTypes,
  KeywordTypes,
  MentionedBySourceTypes,
  SentimentByTimeStatisticTypes,
  SentimentStatisticTypes,
  StatisticalByTimeTypes,
  TotalMentionsTypes,
} from '@/types/Social';

import FeaturedPosts from './FeaturedPosts';
import FeaturedSources from './FeaturedSources';
import KeywordStatistic from './KeywordStatistic';
import BarChartMentionBySource from '../chart/BarChartMentionBySource';
import BarChartSentimentStatistic from '../chart/BarChartSentimentStatistic';
import BarChartSocial from '../chart/BarChartSocial';
import LineChartMentionsByTimeStatistic from '../chart/LineChartMentionsByTimeStatistic';
import LineChartSentimentByTime from '../chart/LineChartSentimentByTime';

interface DetailProps {
  listKeywords: KeywordTypes[];
  sentimentStatistic?: SentimentStatisticTypes[];
  mentionedBySource: MentionedBySourceTypes[];
  totalMentions: TotalMentionsTypes[];
  statisticalByTime: StatisticalByTimeTypes[];
  sentimentByTime: SentimentByTimeStatisticTypes[];
  listArticles: FeatureArticleTypes[];
  listSources: FeatureSourceTypes[];
}

const Detail: React.FC<DetailProps> = ({
  listKeywords,
  sentimentStatistic,
  mentionedBySource,
  totalMentions,
  statisticalByTime,
  sentimentByTime,
  listArticles,
  listSources,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // Đề cập theo nguồn
  const transformDataMentionBySource = (data: ChartMentionStatisticTypes[]) => {
    return data.map((item: ChartMentionStatisticTypes) => {
      let name;
      switch (item.name) {
        case 'newspaper':
          name = t('smcc.overview.news');
          break;
        case 'forum':
          name = t('smcc.overview.forum');
          break;
        case 'youtube':
          name = 'Youtube';
          break;
        case 'tiktok':
          name = 'Tiktok';
          break;
        case 'facebook':
          name = 'Facebook';
          break;
        default:
          name = item.name;
          break;
      }
      return {
        ...item,
        name,
        // mentioned: item.mentioned,
      };
    });
  };

  const transformDataSentimentByTime = (data?: SentimentByTimeStatisticTypes[]) => {
    if (!data) return [];
    return data.map((entry) => ({
      day: entry.day,
      negative: Object.values(entry.negative).reduce((sum, count) => sum + count, 0),
      positive: Object.values(entry.positive).reduce((sum, count) => sum + count, 0),
      neutral: Object.values(entry.neutral).reduce((sum, count) => sum + count, 0),
    }));
  };

  return (
    <>
      <KeywordStatistic listKeywords={listKeywords} />

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={4} xs={12}>
          <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.mentionsByTopicGraph')}
            </Typography>
            <BarChartSocial
              data={totalMentions}
              xAxisKey="name"
              yAxisKey="count"
              colorBar={theme.palette.warning.light}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.sentimentByTopicGraph')}
            </Typography>
            <BarChartSentimentStatistic data={sentimentStatistic || []} />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.mentionsBySourceGraph')}
            </Typography>
            <BarChartMentionBySource data={transformDataMentionBySource(mentionedBySource)} />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.sentimentByTimeGraph')}
            </Typography>
            <LineChartSentimentByTime data={transformDataSentimentByTime(sentimentByTime)} />
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.mentionsByTimeGraph')}
            </Typography>
            <LineChartMentionsByTimeStatistic data={statisticalByTime} />
          </Box>
        </Grid>
      </Grid>

      <FeaturedPosts listArticles={listArticles} />

      <FeaturedSources listSources={listSources} />
    </>
  );
};
export default Detail;
