import { gapi } from 'gapi-script';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormatTimeExpire } from '@/components/common/format/FormatTimeExpire';
import { httpStatus } from '@/configs/Enums/httpStatus';
import { useUser } from '@/contexts/User';
import useAuthService from '@/services/auth';
import { encrypt } from '@/utils/crypto';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';
import useSetKeySearch from '../common/useSetKeySearch';

const regexGetUsername = /^[^@]+/;

const setSession = async (serviceToken: string | undefined, refreshToken = '') => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('refreshToken');
  }
};
const setAuthState = async (isLogin: string) => {
  localStorage.setItem('authState', isLogin);
};

const removeLocalStorage = () => {
  localStorage.removeItem('serviceToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('authState');
  localStorage.removeItem('inforUser');
  localStorage.removeItem('domainActive');
  localStorage.removeItem('listDomain');
  localStorage.removeItem('myAppData');
  localStorage.removeItem('report');
  localStorage.removeItem('scanSitemap');
  localStorage.removeItem('scanSubdomain');
  localStorage.removeItem('scanVul');
  sessionStorage.removeItem('social');
};

const useAuth = () => {
  const { onNotify } = useNotify();
  const { dispatch } = useUser();
  const { setLoadingGif } = useLoadingGif();
  const { setKeySearch } = useSetKeySearch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    logoutAccount,
    loginLocal,
    verify2FA,
    resetPassword,
    changePassword,
    sendCode,
    createUser,
    getInfoUser,
    checkUserExist,
    getListUser,
    getListCompany,
    editCompany,
    editGroup,
    deleteGroup,
    changePermission,
    checkSuperAdmin,
    createSuperAdmin,
    deleteUser,
  } = useAuthService();

  const logoutGoogle = async () => {
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(() => {
        auth2.disconnect();
      });
    }
  };

  const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const body = { refresh_token: refreshToken };
    setLoadingGif(true);
    await logoutAccount(body);
    setLoadingGif(false);
    navigate('/');
    removeLocalStorage();
    await setSession(undefined);
    await setAuthState('false');
    setKeySearch('');
    dispatch({ type: 'LOGOUT' });

    onNotify('warning', t('notify.loggedOut'), 3000);
  };

  const handleLogin = async (account: any, navigateSuccess?: any, setIsLoginFail?: any) => {
    setLoadingGif(true);
    try {
      const res: any = await loginLocal(account);
      const { data, status } = res || {};
      if (status === httpStatus.StatusOK) {
        setLoadingGif(false);
        navigateSuccess();
        return data;
      }
    } catch (error: any) {
      setLoadingGif(false);
      if (error?.message?.includes?.('400')) {
        setIsLoginFail(true);
      } else {
        onNotify('error', error?.response.data.message, 5000);
      }
    }
  };

  const handleVerification = async (account: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await verify2FA(account);
    const { data, status } = res || {};
    const { access, refresh } = data || {};
    switch (status) {
      case httpStatus.StatusOK:
        if (access) {
          await setSession(access, refresh);
          await setAuthState('true');
          const resInfor: any = await getInfoUser({});
          const { data: dataInfor } = resInfor || {};

          if (dataInfor.permission === 'super admin') {
            const inforUser = {
              username: account.username.match(regexGetUsername)[0] || '',
              email: account.username || '',
              role: dataInfor.permission || '',
            };
            const encryptedInforUser = await encrypt(inforUser);
            localStorage.setItem('inforUser', encryptedInforUser);
          } else {
            const resCompany: any = await getListCompany({});
            const { data: dataCompany } = resCompany || {};

            const inforUser = {
              username: account.username.match(regexGetUsername)[0] || '',
              email: account.username || '',
              role: dataInfor.permission || '',
              expireDate: dataInfor.expire_date_license || '',
              group: dataInfor.group || '',
              groupId: dataCompany.list_company.map((item: any) => item.group_id)[0] || null,
              identity: dataInfor.permission_investigate || null,
            };
            const encryptedInforUser = await encrypt(inforUser);
            localStorage.setItem('inforUser', encryptedInforUser);
            localStorage.setItem(
              'listDomain',
              await encrypt(dataCompany.list_company.map((item: any) => item.main_domain)),
            );
            localStorage.setItem(
              'domainActive',
              (await encrypt(dataCompany.list_company.map((item: any) => item.main_domain)[0])) || '',
            );
          }

          dispatch({
            type: 'LOGIN',
            payload: {
              isAuthenticated: true,
            },
          });
          if (FormatTimeExpire(dataInfor.expire_date_license) > 0 || dataInfor.permission === 'super admin') {
            onNotify('success', t('notify.loggedIn'), 3000);
          }
          if (
            FormatTimeExpire(dataInfor.expire_date_license) < 7 &&
            FormatTimeExpire(dataInfor.expire_date_license) > 0
          ) {
            setTimeout(() => {
              onNotify(
                'warning',
                ` ${t('notify.licenseExpiredAfter')} ${FormatTimeExpire(dataInfor.expire_date_license)} ${t(
                  'time.day',
                )}`,
                5000,
              );
            }, 2000);
          }
          if (FormatTimeExpire(dataInfor.expire_date_license) <= 0 && dataInfor.permission !== 'super admin') {
            onNotify('error', t('notify.licenseExpired'), 3000);
          }
          navigateSuccess();
          setLoadingGif(false);
        } else {
          setLoadingGif(false);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePass = async (body: {}, navigateSuccess?: any) => {
    const refreshToken = localStorage.getItem('refreshToken');
    const params = { refresh_token: refreshToken };
    const res: any = await changePassword(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.changePassword'), 3000);
        navigateSuccess();
        setLoadingGif(true);
        await logoutAccount(params);
        setLoadingGif(false);
        removeLocalStorage();
        await setSession(undefined);
        await setAuthState('false');
        setKeySearch('');
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
        break;
      default:
        break;
    }
  };

  const handleResetPassword = async (body: {}, navigateSuccess?: any) => {
    const res: any = await resetPassword(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.resetPassword'), 3000);
        navigateSuccess();
        break;
      default:
        break;
    }
  };

  const handleSendCode = async (body: {}, navigateSuccess?: any) => {
    const res: any = await sendCode(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        navigateSuccess && navigateSuccess();
        break;
      default:
        break;
    }
  };

  const handleCreateUser = async (list_create_user: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const body = { list_create_user };
    const res: any = await createUser(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.addUser'), 3000);
        navigateSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleCheckUserExist = async (user: any, navigateSuccessCheckUser?: any) => {
    const res: any = await checkUserExist(user);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        navigateSuccessCheckUser();
        break;
      default:
        break;
    }
  };

  const handleGetListUser = async () => {
    const res: any = await getListUser({});
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleGetListCompany = async () => {
    const res: any = await getListCompany({});
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleEditCompany = async (body: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const { params, type } = body;
    const res: any = await editCompany(params);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        if (type === 'delete') {
          onNotify('success', t('notify.deleteCompany'), 3000);
        } else {
          onNotify('success', t('notify.addCompany'), 3000);
        }
        navigateSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleEditGroup = async (body: {}, navigateEditSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await editGroup(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.updateGroup'), 3000);
        navigateEditSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleDeleteGroup = async (body: any, navigateDeleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteGroup(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.deleteGroup'), 3000);
        navigateDeleteSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleChangePermision = async (body: any, navigateChangePermissionSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await changePermission(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.changePermission'), 3000);
        navigateChangePermissionSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleCheckSuperAdmin = async (body: {}, navigateCheckSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await checkSuperAdmin({});
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        if (res.data.message === 'Tài khoản superuser đã được tạo') {
          navigateCheckSuccess();
        }
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleCreateSuperAdmin = async (body: any, navigateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createSuperAdmin(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.createSuperAdmin'), 3000);
        navigateSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  const handleDeleteUser = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteUser(body);
    const { status } = res || {};
    switch (status) {
      case httpStatus.StatusOK:
        onNotify('success', t('notify.deleteUser'), 3000);
        deleteSuccess();
        setLoadingGif(false);
        break;
      default:
        break;
    }
  };

  return {
    logout,
    logoutGoogle,
    handleLogin,
    handleVerification,
    handleChangePass,
    handleResetPassword,
    handleSendCode,
    handleCreateUser,
    handleCheckUserExist,
    handleGetListUser,
    handleGetListCompany,
    handleEditCompany,
    handleEditGroup,
    handleDeleteGroup,
    handleChangePermision,
    handleCheckSuperAdmin,
    handleCreateSuperAdmin,
    handleDeleteUser,
  };
};

export default useAuth;
