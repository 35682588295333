/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import UpdateButton from '@/components/common/Button/UpdateButton';
import { Transition } from '@/components/common/Transition';
import useSocialApi from '@/Hooks/fetchApi/useSocial';
import { decrypt } from '@/utils/crypto';

interface UpdateTopicProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  onDialogDetailChange: (newValue: boolean) => void;
  detail: any;
  page: number;
  handleGetListTopic: (pageValue: number) => void;
  onPageChange: (newValue: number) => void;
}

const UpdateTopic: React.FC<UpdateTopicProps> = ({
  openDialogUpdate,
  onDialogUpdateChange,
  onDialogDetailChange,
  detail,
  page,
  handleGetListTopic,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleUpdateTopic } = useSocialApi();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [tags, setTags] = useState<string[]>(detail.key_word_main);
  const [inputValue, setInputValue] = useState('');
  const [isEnter, setIsEnter] = useState(false);

  const handleTagsChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    setTags(value);
    setIsEnter(false);
  };

  const updateSuccess = () => {
    onDialogUpdateChange(false);
    onDialogDetailChange(false);
    setValue('topicName', '');
    setTags([]);
    if (page === 1) {
      handleGetListTopic(1);
    } else {
      onPageChange(1);
    }
  };

  const validationSchema = yup.object().shape({
    topicName: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      topicName: detail.topic_name, // giá trị mặc định cho topicName
    },
  });

  useEffect(() => {
    // Khi detail.topic_name thay đổi, cập nhật lại giá trị của topicName trong form
    setValue('topicName', detail.topic_name);
    setIsEnter(false);
  }, [detail.topic_name, setValue]);

  const onSubmit = async (data: any) => {
    const params = {
      topic_id: detail.topic_id,
      topic_name: data.topicName || detail.topic_name,
      key_word_main: tags || detail.key_word_main,
      group_id: inforUser.groupId,
    };
    if (detail.topic_name && tags?.length !== 0) {
      await handleUpdateTopic(params, updateSuccess);
    } else {
      setIsEnter(true);
    }
  };

  const handleBlur = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags((prevTags) => [...prevTags, inputValue]);
      setInputValue('');
    }
  };

  return (
    <Dialog
      open={openDialogUpdate}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogUpdateChange(false);
        setValue('topicName', detail.topic_name);
        setTags(detail.key_word_main);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.updateTopic')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, padding: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="topicName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  key={detail.topic_id}
                  defaultValue={detail.topic_name}
                  style={{ width: '100%' }}
                  sx={{
                    mt: 1,
                  }}
                  label={t('placeholder.topic')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  id="input-with-icon-textfield"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={tags}
              defaultValue={detail.key_word_main}
              key={detail.topic_id}
              onChange={handleTagsChange}
              renderTags={
                (value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              sx={{
                mt: 3,
                '.MuiChip-label': {
                  fontSize: '1.4rem',
                },
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('placeholder.keyword')}
                  onBlur={handleBlur}
                  error={Boolean(isEnter)}
                />
              )}
            />
            {isEnter ? (
              <Typography sx={{ fontSize: '1.2rem', color: 'error.main', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            ) : (
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            )}

            <Box sx={{ display: 'flex' }}>
              <UpdateButton handleClick={handleSubmit(onSubmit)} />
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateTopic;
