import { useState } from 'react';

import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Box, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoResult } from '@/components/common/NoResult';
import { KeywordTypes } from '@/types/Social';

import KeywordDetail from './KeywordDetail';
import WordCloud from '../chart/WordCloud';

interface KeywordStatisticProps {
  listKeywords: KeywordTypes[];
}

const KeywordStatistic: React.FC<KeywordStatisticProps> = ({ listKeywords }) => {
  const { t } = useTranslation();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);

  return (
    <>
      <Box
        sx={{
          mt: 2,
          padding: 2,
          boxShadow: 4,
          borderRadius: 1,
          backgroundColor: 'background.main',
          minHeight: '416px',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
            {t('smcc.overview.featuredKeywords')}
          </Typography>
          <ReadMoreIcon
            onClick={() => {
              setOpenDialogDetail(true);
            }}
            sx={{
              ml: 1,
              color: 'primary.main',
              fontSize: '2.5rem',
              ':hover': {
                color: 'primary.dark',
                cursor: 'pointer',
              },
            }}
          />
        </Box>

        {!_.isEmpty(listKeywords) ? <WordCloud data={listKeywords} /> : <NoResult />}
      </Box>

      <KeywordDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        listKeywords={listKeywords}
      />
    </>
  );
};
export default KeywordStatistic;
