import { httpStatus } from '@/configs/Enums/httpStatus';
import useOsintService from '@/services/osint';

import useLoadingGif from '../common/useLoadingGif';

const useOsint = () => {
  const { getListOsint } = useOsintService();
  const { setLoadingGif } = useLoadingGif();

  const handleGetOsint = async (params: { domain: string }) => {
    setLoadingGif(true);
    const res: any = await getListOsint(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  return {
    handleGetOsint,
  };
};

export default useOsint;
