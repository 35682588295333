import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import icNotFound from '@/assets/image/ic_not_found.png';

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Stack style={{ marginTop: 100 }} alignItems="center">
      <Box component="img" src={icNotFound} sx={{ width: 100, height: 100 }} />
      <span style={{ color: 'text.secondary', fontSize: 20 }}>{t('notify.noData')}</span>
    </Stack>
  );
};
