import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Box,
  TextField,
  Tooltip,
  Grid,
  TableCell,
  TableBody,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import useNotify from '@/Hooks/common/useNotify';
import usePhishing from '@/Hooks/fetchApi/usePhishing';
import { ICampaign, IFilteredMalware } from '@/interfaces/phishing';
import { fDateTime } from '@/utils/function';

function getStyles(name: any, malware: readonly any[], theme: Theme) {
  return {
    backgroundColor: malware.some((item: any) => item.id === name.id) ? theme.palette.action.hover : 'transparent',
  };
}
interface UpdateCampaignProps {
  openDialogUpdate: boolean;
  setOpenDialogUpdate: (newValue: boolean) => void;
  setPage: (newValue: number) => void;
  campaign: ICampaign;
  handleGetListCampaign: (arg: number) => Promise<void>;
  listMalware: IFilteredMalware[];
}

const UpdateCampaign: React.FC<UpdateCampaignProps> = ({
  openDialogUpdate,
  setOpenDialogUpdate,
  setPage,
  campaign,
  handleGetListCampaign,
  listMalware,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const { handleUpdateDetailCampaign } = usePhishing();

  const [listReceiver, setListReceiver] = useState<any[]>(campaign.user_list);
  const [malware, setMalware] = useState<any>([]);

  const listMalwareId = malware?.map((item: any) => {
    return { id: item?.id };
  });

  useEffect(() => {
    setValue('name', campaign.name);
    setValue('description', campaign.description);
    setValue('receiver', '');
    setValue('content', campaign.phishing_content);
    setValue('landingPage', campaign.landing_page);
    setValue('subject', campaign.subject);
    setValue('senderName', campaign.sender_name);
    setListReceiver(campaign.user_list);
    setMalware(campaign.attachments);
  }, [campaign]);

  useEffect(() => {
    setMalware(malware);
  }, []);

  const updateCampaignSuccess = () => {
    setOpenDialogUpdate(false);
    handleGetListCampaign(1);
    setPage(1);
    setValue('name', campaign.name);
    setValue('description', campaign.description);
    setValue('receiver', '');
    setValue('content', campaign.phishing_content);
    setValue('landingPage', campaign.landing_page);
    setValue('subject', campaign.subject);
    setValue('senderName', campaign.sender_name);
    setListReceiver(campaign.user_list);
    // setMalware(getMalware(campaign.attachments));
  };

  const validationSchema = yup.object().shape({
    name: !campaign.name ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    description: !campaign.description ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    receiver: _.isEmpty(listReceiver) ? yup.string().email().required(t('validation.fieldRequired')) : yup.string(),
    content: !campaign.phishing_content ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    landingPage: !campaign.landing_page ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    subject: !campaign.subject ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    senderName: !campaign.sender_name ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
  });

  const { control, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    const params = {
      name: data.name || campaign.name,
      description: data.description || campaign.description,
      user_list: listReceiver,
      phishing_content: data.content || campaign.phishing_content,
      landing_page: data.landingPage || campaign.landing_page,
      subject: data.subject || campaign.subject,
      sender_name: data.senderName || campaign.sender_name,
      attachments: listMalwareId,
    };
    handleUpdateDetailCampaign(campaign.id, params, updateCampaignSuccess);
  };

  const handleAddReceiver = () => {
    const emailValue: any = getValues('receiver');
    const isEmailExists = listReceiver.some((email: any) => email === emailValue.trim());
    if (!isEmailExists && getValues('receiver') !== '') {
      setListReceiver((prevList) => [...prevList, getValues('receiver')]);
      setValue('receiver', '');
    } else if (isEmailExists) {
      onNotify('warning', t('notify.duplicateEmail'), 3000);
    }
  };

  const handleChangeMalware = (event: SelectChangeEvent<typeof malware>) => {
    const {
      target: { value },
    } = event;

    const selectedMalware = listMalware.find((selected: any) => selected.id === value.id);
    if (selectedMalware && !malware.some((item: any) => item.id === selectedMalware.id)) {
      setMalware([...malware, selectedMalware]);
    } else if (selectedMalware) {
      const updatedMalware = malware.filter((item: any) => item.id !== selectedMalware.id);
      setMalware(updatedMalware);
    }
  };

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialogUpdate(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.phishing.updateCampaign')}</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('asm.phishing.createAt')}
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
              {campaign?.created_at ? fDateTime(campaign?.created_at) : ''}
            </Typography>
          </Box>
          {(campaign?.created_at ? fDateTime(campaign?.created_at) : '') !==
            (campaign?.modified_at ? fDateTime(campaign?.modified_at) : '') && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('asm.phishing.modifiedAt')}
              </Typography>
              <Typography variant="body2" sx={{ ml: 1, color: 'text.primary' }}>
                {campaign?.modified_at ? fDateTime(campaign?.modified_at) : ''}
              </Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.name}
                    defaultValue={campaign.name}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.campaignName')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.description}
                    defaultValue={campaign.description}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.description')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
                backgroundColor: 'action.hover',
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Grid item xs={12} md={!_.isEmpty(listReceiver) ? 5.8 : 12}>
                <Controller
                  name="receiver"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      variant="outlined"
                      style={{ width: '100%', marginTop: '3px' }}
                      label={t('placeholder.receiver')}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      InputProps={{
                        endAdornment: (
                          <Button variant="outlined" color="primary" onClick={handleAddReceiver}>
                            <AddIcon sx={{ fontSize: '2rem' }} />
                          </Button>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {!_.isEmpty(listReceiver) && (
                <Grid item xs={12} md={5.8}>
                  <TableContainer sx={{ backgroundColor: 'action.hover', boxShadow: 'none' }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '80%' }}>Email</TableCell>
                          <TableCell sx={{ width: '20%' }}> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listReceiver.map((row: string) => (
                          <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{row}</TableCell>
                            <TableCell>
                              <Tooltip title={t('tooltip.delete')} placement="left">
                                <DeleteIcon
                                  onClick={() => {
                                    setListReceiver((prevList) => prevList.filter((receiver) => receiver !== row));
                                  }}
                                  sx={{
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      color: 'error.main',
                                    },
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="content"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.phishing_content}
                    defaultValue={campaign.phishing_content}
                    type="text"
                    variant="outlined"
                    multiline
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.content')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-standard-label">{t('asm.phishing.malware')}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={malware}
                  onChange={handleChangeMalware}
                  label={t('asm.phishing.malware')}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((value: any) => (
                          <Chip key={value} label={value.name} />
                        ))}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: 'background.main',
                      },
                    },
                  }}
                >
                  {listMalware?.map((item: any) => (
                    <MenuItem key={item} value={item} style={getStyles(item, malware, theme)}>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="landingPage"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.landing_page}
                    defaultValue={campaign.landing_page}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.landingPage')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="subject"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.subject}
                    defaultValue={campaign.subject}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.subject')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
              <Controller
                name="senderName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    key={campaign.sender_name}
                    defaultValue={campaign.sender_name}
                    type="text"
                    variant="outlined"
                    style={{ width: '100%', marginTop: '3px' }}
                    label={t('placeholder.sender')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                  />
                )}
              />
            </Box>
          </form>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: '90px',
                borderRadius: '5px',
                textTransform: 'none',
                transition: 'all .1s ease-in-out',
                color: '#ffff',
                '&:active': {
                  transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
                  transition: 'all .1s ease-in-out',
                },
                whiteSpace: 'nowrap',
                alignSelf: 'center',
              }}
            >
              {t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateCampaign;
