import * as React from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export interface HistoryItem {
  icon: React.ReactNode;
  type: string;
  primaryText: string[];
}

interface CustomizableListProps {
  items: HistoryItem[];
}

export default function CustomizableList({ items }: CustomizableListProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <nav aria-label="main mailbox folders">
        <List>
          {items.map((item, index) => (
            <ListItem key={index} disablePadding>
              {item.primaryText.length !== 0 && (
                <ListItemButton
                  onClick={() => {
                    navigator.clipboard.writeText(`${item.primaryText}`);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: 'text.secondary',
                      '& svg': {
                        fontSize: '1.8em',
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText className="text-white">
                    {item.primaryText.map((text, index2) => (
                      <React.Fragment key={index2}>
                        {item.type === 'facebook' ? (
                          <Link fontSize={16} href={`https://www.facebook.com/${text}`}>
                            Facebook
                          </Link>
                        ) : (
                          <Typography variant="body1" style={{ fontSize: '12pt' }}>
                            {' '}
                            {text}{' '}
                          </Typography>
                        )}
                        {index !== item.primaryText.length - 1}
                      </React.Fragment>
                    ))}

                    <hr />
                  </ListItemText>
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </nav>
      <Divider />
    </Box>
  );
}
