import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClick: () => void;
}
const AddMoreButton: React.FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      fullWidth
      type="button"
      color="success"
      onClick={handleClick}
      sx={{
        width: 'fit-content',
        boxShadow: 'none',
        fontWeight: '500',
        borderRadius: '5px',
        textTransform: 'none',
        fontSize: '1.4rem',
        transition: 'all .1s ease-in-out',
        color: 'common.white',
        '&:active': {
          transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
          transition: 'all .1s ease-in-out',
        },
        whiteSpace: 'nowrap',
        alignSelf: 'center',
      }}
    >
      {t('action.addMore')}
    </Button>
  );
};
export default AddMoreButton;
