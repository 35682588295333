import { httpStatus } from '@/configs/Enums/httpStatus';
import useMalwareService from '@/services/malware';

const useMalware = () => {
  const { malwareUpload } = useMalwareService();
  const handleMalware = async (params: {}) => {
    const res: any = await malwareUpload(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };
  return {
    handleMalware,
  };
};

export default useMalware;