import { useEffect } from 'react';

import { Box, AppBar, useTheme, Typography, Grid, Link, alpha } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ASM from '@/assets/image/asm.png';
import Banner from '@/assets/image/banner.png';
import BgDark from '@/assets/image/bgDark.png';
import eKYC from '@/assets/image/eKYC.png';
import BgLight from '@/assets/image/gradient.jpg';
import Search from '@/assets/image/search.png';
import SMCC from '@/assets/image/smcc.png';
import AosComponent from '@/components/animation/AosComponent';
import { rotateCircle, scaleBanner, gradient, fadeInDown, gradientIdentity } from '@/components/animation/Keyframes';
import BackToTop from '@/components/common/Button/BackToTop';
import useBreakpoints from '@/helpers/useBreakpoints';
import useNotify from '@/Hooks/common/useNotify';
import Header from '@/pages/HomePage/Header';
import HeaderMobile from '@/pages/HomePage/HeaderMobile';
import { DARK_MODE_THEME } from '@/utils/constants';

const HomePage = () => {
  const theme = useTheme();
  const location = useLocation();
  const { isMobile, isTablet } = useBreakpoints();
  const { t } = useTranslation();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;
  const { onNotify } = useNotify();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname;
  useEffect(() => {
    if (from === '/identity') {
      onNotify('warning', t('notify.havePermission'), 3000);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [from]);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          position: 'relative',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            background: `url(${themeApp === 'dark' ? BgDark : BgLight}) center center no-repeat`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            filter: 'blur(3px)',
          }}
        />
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}
        <Box sx={{ paddingTop: '80px', textAlign: '-webkit-center' }}>
          <Grid
            container
            sx={{
              position: 'relative',
              marginTop: { xs: '10px', md: '30px' },
              maxWidth: '1500px',
              padding: { xs: '50px', md: '150px' },
              justifyContent: 'space-between',
              bgcolor: alpha(theme.palette.text.secondary, 0.05),
              borderRadius: 1,
            }}
          >
            <Grid item xs={12} md={5.5} sx={{ padding: { xs: '50px', md: 0 } }}>
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '100%', md: '80%' },
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    width: '124%',
                    height: '124%',
                    left: '-12%',
                    top: '-12%',
                    borderRadius: '50%',
                    border: `1.5px solid ${theme.palette.text.disabled}`,
                    transition: '0.4s ease-in-out',
                    animation: `${rotateCircle} 20s linear infinite`,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: { xs: '3rem', md: '4.8rem' },
                      height: { xs: '3rem', md: '4.8rem' },
                      backgroundImage:
                        'radial-gradient(circle, #aba1ac, #b5b2be, #bfc3ce, #cbd4dd, #d9e5ea, #dae8eb, #dbeaec, #ddeded, #cfe3e3, #c1d8d8, #b3cece, #a5c4c4)',
                      top: { xs: '-1.5rem', md: '-2.4rem' },
                      left: 'calc(50% - 2.4rem)',
                      borderRadius: '50%',
                      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.7)',
                      backgroundSize: '200% 200%',
                      animation: `${gradient} 2s linear infinite`,
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      width: '2.6rem',
                      height: '2.6rem',
                      bottom: '-1.3rem',
                      left: 'calc(50% - 1.3rem)',
                      borderRadius: '50%',
                      boxShadow: 'inset 0 0 10px rgba(255, 255, 255, 0.2)',
                      background:
                        'linear-gradient(270deg, rgb(213, 3, 3) 0%, rgb(193, 15, 15) 25.52%, rgb(247, 80, 43) 50%, rgb(239, 9, 9) 76.04%, rgb(213, 3, 3) 100%)',
                      backgroundSize: '200% auto',
                      animation: `${gradientIdentity} 2s linear infinite`,
                    },
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '124%',
                    height: '124%',
                    left: '-12%',
                    top: '-12%',
                    pointerEvents: 'none',
                    borderRadius: '50%',
                    border: `1px solid ${theme.palette.text.disabled}`,
                    zIndex: -1,
                    animationDelay: '0.6s',
                    animation: `${scaleBanner} 3s linear infinite`,
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '160%',
                    height: '160%',
                    left: '-30%',
                    top: '-30%',
                    pointerEvents: 'none',
                    borderRadius: '50%',
                    border: `1px solid ${theme.palette.text.disabled}`,
                    animationDelay: '0.8s',
                    animation: `${scaleBanner} 3s linear infinite`,
                  }}
                />

                <Box
                  component="img"
                  src={Banner}
                  className="animate__animated animate__zoomIn"
                  sx={{
                    WebkitUserSelect: 'none',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    filter: `drop-shadow(0 0 1rem ${theme.palette.action.disabled})`,
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                alignSelf: 'center',
                maxWidth: '600px',
                marginTop: isTablet ? '70px' : 0,
                animationDelay: '0.1s',
                transform: 'translateZ(0) scale(1)',
                textAlign: '-webkit-left',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  fontWeight: 600,
                  WebkitUserSelect: 'none',
                  userSelect: 'none',
                  pointerEvents: 'none',
                  backgroundClip: 'text',
                  color: 'transparent',
                  backgroundImage:
                    'radial-gradient(circle, #a18796, #a68aa3, #a88fb2, #a595c2, #9c9cd2, #8da3dc, #7babe4, #64b3e9, #4ebae5, #41c0dc, #44c5d0, #55c9c2)',
                  backgroundSize: '200% 200%',
                  animation: `${gradient} 8s linear infinite, ${fadeInDown} 0.5s linear 1`,
                }}
              >
                {t('homepage.companyName')}
              </Typography>

              <Typography
                className="animate__animated animate__fadeInUp"
                variant="h4"
                sx={{
                  mt: 2,
                  color: theme.palette.text.secondary,
                  fontWeight: 300,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  animationDelay: '0.8s',
                  WebkitUserSelect: 'none',
                  userSelect: 'none',
                  pointerEvents: 'none',
                }}
              >
                {t('homepage.companyTitle')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              paddingTop: { xs: '80px', md: '120px' },
              maxWidth: '1130px',
              padding: isTablet ? 3 : 0,
              flexDirection: isTablet ? 'column-reverse' : '',
              paddingBottom: isTablet ? '30px' : '80px',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                alignSelf: 'center',
                maxWidth: '600px',
                marginTop: isTablet ? 2 : 0,
                textAlign: '-webkit-left',
              }}
            >
              <AosComponent dataAos="fade-right" dataAosDuration="1000">
                <Link
                  href="/score"
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  variant="h4"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: '600',
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    transition: 'color 0.4s ease',
                  }}
                >
                  {t('homepage.asm')}
                </Link>
                <Typography variant="h6" sx={{ fontWeight: 400, mt: 2, color: theme.palette.text.secondary }}>
                  {t('homepage.asmTitle')}
                </Typography>
              </AosComponent>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ textAlign: { xs: '-webkit-center', md: '' } }}>
              <AosComponent dataAos="fade-left" dataAosDuration="1000">
                <Link href="/score" target="_blank" rel="noopener">
                  <Box
                    component="img"
                    src={ASM}
                    sx={{
                      width: '80%',
                      height: 'auto',
                      alignSelf: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      transition: 'transform 0.4s ease-in-out',
                    }}
                  />
                </Link>
              </AosComponent>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: isTablet ? '40px' : '100px',
              maxWidth: '1130px',
              padding: isTablet ? 3 : 0,
              flexDirection: isTablet ? 'column-reverse' : 'row-reverse',
              paddingBottom: isTablet ? '30px' : '80px',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                alignSelf: 'center',
                maxWidth: '600px',
                marginTop: isTablet ? 2 : 0,
                textAlign: '-webkit-left',
              }}
            >
              <AosComponent dataAos="fade-left" dataAosDuration="1000">
                <Link
                  href="/social"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  variant="h4"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    transition: 'color 0.4s ease',
                  }}
                >
                  {t('homepage.smcc')}
                </Link>
                <Typography variant="h6" sx={{ fontWeight: 400, mt: 2, color: theme.palette.text.secondary }}>
                  {t('homepage.smccTitle')}
                </Typography>
              </AosComponent>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ textAlign: { xs: '-webkit-center', md: '-webkit-right' } }}>
              <AosComponent dataAos="fade-right" dataAosDuration="1000">
                <Link href="/social" target="_blank" rel="noopener">
                  <Box
                    component="img"
                    src={SMCC}
                    sx={{
                      width: '80%',
                      height: 'auto',
                      alignSelf: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      transition: 'transform 0.4s ease-in-out',
                    }}
                  />
                </Link>
              </AosComponent>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: isTablet ? 0 : '100px',
              maxWidth: '1130px',
              padding: isTablet ? 3 : 0,
              flexDirection: isTablet ? 'column-reverse' : '',
              paddingBottom: isTablet ? '30px' : '80px',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                alignSelf: 'center',
                maxWidth: '600px',
                marginTop: isTablet ? 2 : 0,
                mr: 4,
                textAlign: '-webkit-left',
              }}
            >
              <AosComponent dataAos="fade-right" dataAosDuration="1000">
                <Link
                  href="/identity"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  variant="h4"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    transition: 'color 0.4s ease',
                  }}
                  onClick={() => {
                    window.open('/identity', '_blank');
                  }}
                >
                  {t('homepage.investigate')}
                </Link>
                <Typography variant="h6" sx={{ fontWeight: 400, mt: 2, color: theme.palette.text.secondary }}>
                  {t('homepage.investigateTitle')}
                </Typography>
              </AosComponent>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ textAlign: { xs: '-webkit-center', md: '' } }}>
              <AosComponent dataAos="fade-left" dataAosDuration="1000">
                <Link href="/identity" target="_blank" rel="noopener">
                  <Box
                    component="img"
                    src={Search}
                    sx={{
                      width: '80%',
                      height: 'auto',
                      alignSelf: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      transition: 'transform 0.4s ease-in-out',
                    }}
                  />
                </Link>
              </AosComponent>
            </Grid>
          </Grid>
          {/* <Grid
            container
            sx={{
              marginTop: isTablet ? '40px' : '100px',
              maxWidth: '1130px',
              padding: isTablet ? 3 : 0,
              flexDirection: isTablet ? 'column-reverse' : 'row-reverse',
              paddingBottom: isTablet ? '30px' : '80px',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                alignSelf: 'center',
                maxWidth: '600px',
                textAlign: '-webkit-left',
                marginTop: isTablet ? 2 : 0,
              }}
            >
              <AosComponent dataAos="fade-left" dataAosDuration="1000">
                <Link
                  href="/phishing"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  variant="h4"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    '&:hover': {
                      color: '#02BB9F',
                    },
                    transition: 'color 0.4s ease',
                  }}
                >
                  {t('homepage.ekyc')}
                </Link>
                <Typography variant="h6" sx={{ fontWeight: 400, mt: 2, color: theme.palette.text.secondary }}>
                  {t('homepage.ekycTitle')}
                </Typography>
              </AosComponent>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ textAlign: { xs: '-webkit-center', md: '-webkit-right' } }}>
              <AosComponent dataAos="fade-right" dataAosDuration="1000">
                <Link href="/phishing" target="_blank" rel="noopener">
                  <Box
                    component="img"
                    src={eKYC}
                    sx={{
                      width: '80%',
                      height: 'auto',
                      alignSelf: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      transition: 'transform 0.4s ease-in-out',
                    }}
                  />
                </Link>
              </AosComponent>
            </Grid>
          </Grid> */}
        </Box>
        <BackToTop />
      </AppBar>
    </>
  );
};
export default HomePage;
