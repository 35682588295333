import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';
import useLoadingStatistics from '@/Hooks/subdomain/useLoadingStatistics';

const useAsmService = () => {
  const { methodGet, methodPost } = useRequest();
  const { setLoadingStatistics } = useLoadingStatistics();

  const getScanSubdomain = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.scanSubdomain, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          setLoadingStatistics(false);
          reject(error);
        });
    });
  };

  const getSearchSubdomain = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchSubdomain, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          setLoadingStatistics(false);
          reject(error);
        });
    });
  };

  const getSearchDns = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchDns, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getSearchInfo = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchInfo, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getSearchCidr = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchCidr, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getSearchPort = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchPort, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getSearchSsl = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.searchSsl, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getScanVul = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.scanVul, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getSiteMap = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.siteMap, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getStatistics = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.statistics, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getFilterInfoSubdomain = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.filterInfoSubdomain, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCVE = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.cve.listCVE, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCWE = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.cve.listCWE, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListVendors = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.cve.listVendors, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getAnalysisCVE = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.cve.analysisCVE, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getRemainTokenService = () => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.remainingTokens)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.news.listNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDetailNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.news.detailNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getFiterNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.news.filterNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getTopNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.news.topNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getInvicti = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.invicti, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getScoreSystem = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.scoreSystem, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Assets
  const getListAsset = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.asset.listAsset, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createAsset = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.asset.createAsset, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editAsset = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.asset.editAsset, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteAsset = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.asset.deleteAsset, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCVEAsset = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.asset.listCVE, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getScanSubdomain,
    getRemainTokenService,
    getSearchSubdomain,
    getSearchDns,
    getSearchInfo,
    getSearchCidr,
    getSearchPort,
    getSearchSsl,
    getScanVul,
    getSiteMap,
    getStatistics,
    getFilterInfoSubdomain,
    getListCVE,
    getListCWE,
    getListVendors,
    getAnalysisCVE,
    getListNews,
    getFiterNews,
    getTopNews,
    getInvicti,
    getDetailNews,
    getScoreSystem,
    getListAsset,
    createAsset,
    editAsset,
    deleteAsset,
    getListCVEAsset,
  };
};

export default useAsmService;
