import { useTheme } from '@mui/material';

export const HighlightTextSocial: React.FC<{ text: string; keyword: string }> = ({ text, keyword }) => {
  const theme = useTheme();
  if (typeof text !== 'string') {
    return null;
  }

  if (!keyword) {
    return <span>{text}</span>;
  }
  // Sử dụng biểu thức chính quy để tìm và highlight từ khóa
  const regex = new RegExp(`(${keyword})`, 'gi');
  const parts = text?.split(regex);

  return (
    <span>
      {parts.map((part: any, index: number) =>
        regex.test(part) ? (
          <span key={index} style={{ color: theme.palette.warning.main, fontStyle: 'italic', fontWeight: 500 }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
};
