import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Typography, alpha, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormatTimeNews from '@/components/common/format/FormatTimeNews';
import { NewsConfig } from '@/configs/NewsConfig';
import { NewsTypes, NewsState } from '@/types/news';

import { TooltipCustom } from '../common/LightTooltip';

interface ItemLabelProps {
  item: NewsTypes;
  updateNewsState: (newState: Partial<NewsState>) => void;
  type?: string;
}

interface IMappings {
  [key: string]: string;
}

const ItemLabel: React.FC<ItemLabelProps> = ({ item, updateNewsState, type }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categoriesBusiness, categoriesCyberSecurity } = NewsConfig();

  const categoryMappings: IMappings = {
    finance: t('news.finance'),
    real_estate: t('news.realEstate'),
    stock: t('news.stock'),
    news_summary: t('news.summary'),
  };

  function getPathByParam(param: string): string | undefined {
    const allCategories = [...categoriesBusiness, ...categoriesCyberSecurity];
    const category = allCategories.find((cat) => cat.param === param);
    return category?.path;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
        }}
      >
        <AccessTimeIcon
          sx={{ fontSize: '1.6rem', alignSelf: 'center', color: type ? 'common.white' : 'text.disabled' }}
        />
        <TooltipCustom title={format(new Date(item?.create_time), 'HH:mm, dd-MM-yyyy')}>
          <Typography
            sx={{
              fontSize: '1.2rem',
              ml: 1,
              alignSelf: 'center',
              cursor: 'pointer',
              color: type ? 'common.white' : 'text.secondary',
              '&:hover': {
                color: type ? alpha(theme.palette.common.white, 0.8) : 'text.primary',
              },
              transition: ' color 0.4s ease',
              position: 'relative',
            }}
          >
            <FormatTimeNews publishDate={item?.create_time} />
          </Typography>
        </TooltipCustom>
        {item?.field && (
          <Box sx={{ fontSize: '1.6rem', marginLeft: '10px', color: type ? 'common.white' : 'text.disabled' }}>/</Box>
        )}
        <Box
          onClick={() => {
            navigate(`/news/${getPathByParam(item?.field)}`);
            updateNewsState({
              currentPage: 1,
              listNews: [],
              hasMore: true,
              searchValue: '',
              listFilterModal: [],
              listFilterPage: [],
              currentPageFilter: 1,
              enterKeyPressed: false,
            });
          }}
          sx={{
            alignSelf: 'center',
            marginLeft: '10px',
            fontSize: '1.2rem',
            fontWeight: 400,
            color: type ? 'common.white' : 'text.secondary',
            cursor: 'pointer',
            ':hover': {
              color: type ? 'primary.light' : 'primary.main',
            },
            transition: ' color 0.4s ease',
            textTransform: 'capitalize',
          }}
        >
          {categoryMappings[item?.field] || item?.field}
        </Box>
      </Box>
    </>
  );
};
export default ItemLabel;
