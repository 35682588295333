import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
  Button,
  TableContainer,
  useTheme,
  TableHead,
  Table,
  TableCell,
  tableCellClasses,
  styled,
  TableRow,
  TableBody,
  Tooltip,
  Skeleton,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { useCommonInfo } from '@/contexts/Common';
import usePhishing from '@/Hooks/fetchApi/usePhishing';
import { IMalware } from '@/interfaces/phishing';
import { decrypt } from '@/utils/crypto';
import { fDateTime } from '@/utils/function';

import DetailMalware from './DetailMalware';
import UpdateMalware from './UpdateMalware';

interface TableMalwareProps {
  malware: IMalware[];
  setSearchValue: (newValue: string) => void;
  setFilterMalware: (newValue: []) => void;
  setPageMalware: (newValue: number) => void;
  loadingFilter?: boolean;
  handleGetListMalware: (arg: number) => Promise<void>;
}

const TableMalware: React.FC<TableMalwareProps> = ({
  malware,
  setSearchValue,
  setFilterMalware,
  setPageMalware,
  loadingFilter,
  handleGetListMalware,
}) => {
  const { t } = useTranslation();
  const { isLoading } = useCommonInfo();
  const theme = useTheme();
  const { handleDeleteDetailMalware } = usePhishing();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [malwareSelected, setMalwareSelected] = useState<any>({});

  const deleteMalwareSuccess = () => {
    setOpenDialogDelete(false);
    setSearchValue('');
    setFilterMalware([]);
    handleGetListMalware(1);
    setPageMalware(1);
  };

  const handleDeleteMalware = () => {
    handleDeleteDetailMalware(idDelete, deleteMalwareSuccess);
  };

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>{t('asm.phishing.malwareName')}</TableCell>
            <TableCell sx={{ width: '30%' }}>{t('asm.phishing.description')}</TableCell>
            <TableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>{t('asm.phishing.modifiedAt')}</TableCell>
            {inforUser.role !== 'member' && <TableCell sx={{ width: '30%' }}> </TableCell>}
          </TableRow>
        </TableHead>
        {loadingFilter ? (
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="30%" />
              </TableCell>
              <TableCell>
                <Skeleton height={20} width="20%" />
              </TableCell>
              {inforUser.role !== 'member' && (
                <TableCell>
                  <Skeleton height={20} width="30%" />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {!isLoading ? (
              <>
                {!_.isEmpty(malware) ? (
                  <>
                    {malware?.map((row: any) => (
                      <React.Fragment key={row.id}>
                        <TableRow
                          sx={{
                            verticalAlign: 'baseline',
                          }}
                          hover
                        >
                          <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.name}</TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.description}</TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDateTime(row.modified_at)}</TableCell>
                          {inforUser.role !== 'member' && (
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                              <Tooltip title={t('tooltip.detail')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogDetail(true);
                                    setMalwareSelected(row);
                                  }}
                                >
                                  <ListAltIcon
                                    sx={{
                                      fontSize: '2rem',
                                      color: 'primary.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title={t('tooltip.edit')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogUpdate(true);
                                    setMalwareSelected(row);
                                  }}
                                >
                                  <EditIcon
                                    sx={{
                                      fontSize: '2rem',
                                      color: 'secondary.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>

                              <Tooltip title={t('tooltip.delete')} placement="bottom">
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  sx={{
                                    ml: 1,
                                    minWidth: '45px',
                                  }}
                                  onClick={() => {
                                    setOpenDialogDelete(true);
                                    setIdDelete(row.id);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      fontSize: '2rem',
                                      color: 'error.main',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                              <DetailMalware
                                openDialogDetail={openDialogDetail}
                                setOpenDialogDetail={setOpenDialogDetail}
                                malware={malwareSelected}
                              />
                              <UpdateMalware
                                openDialogUpdate={openDialogUpdate}
                                setOpenDialogUpdate={setOpenDialogUpdate}
                                malware={malwareSelected}
                                handleGetListMalware={handleGetListMalware}
                                setPage={setPageMalware}
                              />
                              <ConfirmDeleteModal
                                openDialogDelete={openDialogDelete}
                                onOpenDialogDeleteChange={setOpenDialogDelete}
                                handleDelete={handleDeleteMalware}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton height={20} width="20%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="30%" />
                </TableCell>
                <TableCell>
                  <Skeleton height={20} width="20%" />
                </TableCell>
                {inforUser.role !== 'member' && (
                  <TableCell>
                    <Skeleton height={20} width="30%" />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
export default TableMalware;
