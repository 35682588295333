import { Card, CardHeader, Skeleton } from '@mui/material';

const SkeletonPostsSocial = () => {
  return (
    <Card>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={<Skeleton animation="wave" width="70px" height="30px" variant="rounded" />}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
    </Card>
  );
};
export default SkeletonPostsSocial;
