import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Typography,
  InputBase,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  Tooltip,
  useTheme,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useAuth from '@/Hooks/fetchApi/useAuth';
import { CompanyType, GroupType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

interface CompaniesProps {
  listCompanies: Array<CompanyType>;
  setIsAddCompany: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const Companies: React.FC<CompaniesProps> = ({ listCompanies, setIsAddCompany, handleGetUser, handleGetCompany }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleEditCompany } = useAuth();

  const [filterCompany, setFilterCompany] = useState<CompanyType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [paramsDelete, setParamsDelete] = useState({
    nameCompany: '',
    nameClient: '',
    mainDomain: '',
    address: '',
    country: '',
    groupName: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    const filteredCompanies = listCompanies.filter((company: CompanyType) => {
      const normalizedCompanyName = company?.name_company.toLowerCase();
      return normalizedCompanyName.includes(event.target.value);
    });
    setFilterCompany(filteredCompanies);

    if (event.target.value === '') {
      setFilterCompany([]);
    }
  };

  const groupedCompanies = Object.values(
    listCompanies.reduce((groups: Record<string, GroupType>, company: CompanyType) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { group_name } = company;
      groups[group_name] = groups[group_name] || { group_name, companies: [] };
      groups[group_name].companies.push(company);
      return groups;
    }, {}),
  );

  const navigateSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setSearchValue('');
    setFilterCompany([]);
    setOpenDialog(false);
  };

  const handleDeleteCompany = () => {
    const params = {
      info_company: {
        name_company: paramsDelete.nameCompany,
        name_client: paramsDelete.nameClient,
        main_domain: paramsDelete.mainDomain,
        country: paramsDelete.country,
        address: paramsDelete.address,
      },
      group_name: paramsDelete.groupName,
    };
    const body = {
      params,
      type: 'delete',
    };
    handleEditCompany(body, navigateSuccess);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Box
          sx={{
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{
              width: '100%',
              ml: 1,
              flex: 1,
              '.MuiInputBase-input': {
                padding: '10px 10px',
              },
            }}
            placeholder={t('placeholder.typeSearch')}
            inputProps={{
              'aria-label': 'filter company',
            }}
            endAdornment={
              searchValue && (
                <ClearIcon
                  onClick={() => {
                    setSearchValue('');
                    setFilterCompany([]);
                  }}
                  sx={{
                    mr: 3,
                    color: 'text.secondary',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              )
            }
            value={searchValue}
            onChange={handleInputChange}
          />
        </Box>
        {inforUser.role === 'super admin' && (
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setIsAddCompany(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addCompany')}
          </Button>
        )}
      </Box>
      <Box sx={{ borderRadius: 2, mt: 3 }}>
        {searchValue ? (
          <>
            {!_.isEmpty(filterCompany) ? (
              <Box sx={{ boxShadow: 5, borderRadius: 2, padding: 2 }}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('table.company.company')}</TableCell>
                        <TableCell>{t('table.company.client')}</TableCell>
                        <TableCell>{t('table.company.domain')}</TableCell>
                        <TableCell>{t('table.company.address')}</TableCell>
                        <TableCell>{t('table.company.country')}</TableCell>
                        <TableCell>{t('table.company.group')}</TableCell>
                        {inforUser.role === 'super admin' && <TableCell> </TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterCompany.map((row: CompanyType, index: number) => (
                        <>
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>{row?.name_company}</TableCell>
                            <TableCell>{row?.name_client}</TableCell>
                            <TableCell>{row?.main_domain}</TableCell>
                            <TableCell>{row?.address}</TableCell>
                            <TableCell>{row?.country}</TableCell>
                            <TableCell>{row?.group_name}</TableCell>
                            {inforUser.role === 'super admin' && (
                              <TableCell>
                                <Tooltip title={t('tooltip.delete')} placement="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      setOpenDialog(true);
                                      setParamsDelete({
                                        nameCompany: row?.name_company,
                                        nameClient: row?.name_client,
                                        mainDomain: row?.main_domain,
                                        address: row?.address,
                                        country: row?.country,
                                        groupName: row?.group_name,
                                      });
                                    }}
                                    sx={{
                                      cursor: 'pointer',
                                      '&:hover': {
                                        color: 'error.main',
                                      },
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            )}
                          </TableRow>
                          <ConfirmDeleteModal
                            openDialogDelete={openDialog}
                            onOpenDialogDeleteChange={setOpenDialog}
                            handleDelete={handleDeleteCompany}
                          />
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Box sx={{ width: '100%', borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover' }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noResult')}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {inforUser.role !== 'super admin' ? (
              <Box sx={{ boxShadow: 5, borderRadius: 2, padding: 2 }}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('table.company.company')}</TableCell>
                        <TableCell>{t('table.company.client')}</TableCell>
                        <TableCell>{t('table.company.domain')}</TableCell>
                        <TableCell>{t('table.company.address')}</TableCell>
                        <TableCell>{t('table.company.country')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listCompanies.map((row: CompanyType, index: number) => (
                        <>
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>{row?.name_company}</TableCell>
                            <TableCell>{row?.name_client}</TableCell>
                            <TableCell>{row?.main_domain}</TableCell>
                            <TableCell>{row?.address}</TableCell>
                            <TableCell>{row?.country}</TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <>
                {groupedCompanies.map((group: GroupType, indexGroup: number) => (
                  <Box key={indexGroup} sx={{ mt: 1 }}>
                    <Accordion sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 3 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography variant="body2">{group?.group_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('table.company.company')}</TableCell>
                                <TableCell>{t('table.company.client')}</TableCell>
                                <TableCell>{t('table.company.domain')}</TableCell>
                                <TableCell>{t('table.company.address')}</TableCell>
                                <TableCell>{t('table.company.country')}</TableCell>
                                {inforUser.role === 'super admin' && <TableCell> </TableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {group?.companies?.map((company: CompanyType, indexCompany: number) => (
                                <>
                                  <TableRow
                                    key={indexCompany}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                  >
                                    <TableCell>{company?.name_company}</TableCell>
                                    <TableCell>{company?.name_client}</TableCell>
                                    <TableCell>{company?.main_domain}</TableCell>
                                    <TableCell>{company?.address}</TableCell>
                                    <TableCell>{company?.country}</TableCell>
                                    {inforUser.role === 'super admin' && (
                                      <TableCell>
                                        <Tooltip title={t('tooltip.delete')} placement="right">
                                          <DeleteIcon
                                            onClick={() => {
                                              setOpenDialog(true);
                                              setParamsDelete({
                                                nameCompany: company?.name_company,
                                                nameClient: company?.name_client,
                                                mainDomain: company?.main_domain,
                                                address: company?.address,
                                                country: company?.country,
                                                groupName: group?.group_name,
                                              });
                                            }}
                                            sx={{
                                              cursor: 'pointer',
                                              '&:hover': {
                                                color: 'error.main',
                                              },
                                            }}
                                          />
                                        </Tooltip>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  <ConfirmDeleteModal
                                    openDialogDelete={openDialog}
                                    onOpenDialogDeleteChange={setOpenDialog}
                                    handleDelete={handleDeleteCompany}
                                  />
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default Companies;
