import { ReactElement, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRange from '@/components/input/DateRange';
import FilterSentiment from '@/components/Social/FilterSentiment';
import Facebook from '@/components/Social/trending/Facebook';
import Newspaper from '@/components/Social/trending/Newspaper';
import Youtube from '@/components/Social/trending/Youtube';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { SentimentStateTypes } from '@/types/Social';

const Trending = (): ReactElement => {
  const { t } = useTranslation();

  const [valueTab, setValueTab] = useState(0);

  const [sentimentState, setSentimentState] = useState<SentimentStateTypes>({
    positive: true,
    neutral: true,
    negative: true,
  });

  const handleSentimentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSentimentState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('smcc.trending')}
      </Typography>

      <Box sx={{}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <StyledTab label={t('smcc.overview.news')} />
            <StyledTab label="Facebook" />
            <StyledTab label="Youtube" />
            {/* <StyledTab label={t('smcc.overview.forum')} />
            <StyledTab label="TikTok" />
            <StyledTab label="Youtube" /> */}
          </StyledTabs>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FilterSentiment sentimentState={sentimentState} onChange={handleSentimentChange} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, mt: { xs: 1, sm: 0 } }}
          >
            <DateRange />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>{valueTab === 0 && <Newspaper sentimentState={sentimentState} />}</Box>
        <Box sx={{ mt: 3 }}>{valueTab === 1 && <Facebook sentimentState={sentimentState} />}</Box>
        <Box sx={{ mt: 3 }}>{valueTab === 2 && <Youtube sentimentState={sentimentState} />}</Box>
      </Box>
    </>
  );
};
export default Trending;
