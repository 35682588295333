import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  TextField,
  Typography,
  SelectChangeEvent,
  linearProgressClasses,
  LinearProgress,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { decrypt, encrypt } from '@/utils/crypto';

const scoreCvss = (value: number) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const ranges = [
    { min: 0.1, max: 3.9, label: t('cve.cvss.low'), color: '#00c0ef' },
    { min: 4.0, max: 6.9, label: t('cve.cvss.medium'), color: '#f39c12' },
    { min: 7.0, max: 8.9, label: t('cve.cvss.high'), color: '#dd4b39' },
    { min: 9.0, max: 10.0, label: t('cve.cvss.critical'), color: '#972b1e' },
  ];

  const result = ranges.find((range) => value >= range.min && value <= range.max) || {
    label: 'N/A',
    color: 'text.disabled',
  };
  return result;
};

const TotalScore: React.FC<{ point: any }> = ({ point }) => {
  const scoreState = scoreCvss(point);
  return (
    <Typography
      sx={{
        color: scoreState.color,
        fontWeight: 700,
        alignSelf: 'center',
        fontSize: '4rem',
      }}
    >
      {point}
    </Typography>
  );
};

const ProgressScore: React.FC<{ point: any }> = ({ point }) => {
  const scoreState = scoreCvss(point);
  return (
    <Box sx={{ color: scoreState.color, mt: 1 }}>
      <LinearProgress
        variant="determinate"
        value={point * 10}
        color="inherit"
        sx={{
          height: 10,
          borderRadius: '5px',
          '&::before': {
            backgroundColor: '#b3b3b3',
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '5px',
          },
        }}
      />
    </Box>
  );
};
const LabelTotalScore: React.FC<{ point: any }> = ({ point }) => {
  const scoreState = scoreCvss(point);
  return (
    <Typography
      variant="body2"
      sx={{ alignSelf: 'end', color: scoreState.color, fontWeight: 500, marginBottom: 1, textTransform: 'uppercase' }}
    >
      {scoreState.label}
    </Typography>
  );
};

const SubdomainScore: React.FC<{ point: any }> = ({ point }) => {
  const scoreState = scoreCvss(point);
  return (
    <Typography
      sx={{
        color: scoreState.color,
        fontWeight: 700,
        alignSelf: 'center',
        fontSize: '2.5rem',
      }}
    >
      {point}
    </Typography>
  );
};
const LabelSubdomainScore: React.FC<{ point: any }> = ({ point }) => {
  const scoreState = scoreCvss(point);
  return (
    <Typography
      sx={{
        alignSelf: 'center',
        color: scoreState.color,
        fontWeight: 500,
        fontSize: '1.2rem',
        textTransform: 'uppercase',
      }}
    >
      {scoreState.label}
    </Typography>
  );
};

const ScoreCVSS = () => {
  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();
  const { handleScoreSystem } = useAsm();
  const { t } = useTranslation();

  const [scoreCVSS, setScoreCVSS] = useState<any>({ data: [], update_time: '' });
  const [domain, setDomain] = useState(domainActive);
  const [loadingState, setLoadingState] = useState(false);

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      const scoreCVSSWebsocket = filteredList.find((item: any) => item.title === 'score_system');
      if (!_.isEmpty(scoreCVSSWebsocket?.data?.data)) {
        setScoreCVSS({
          data: scoreCVSSWebsocket?.data?.data?.slice()?.sort((a: any, b: any) => b.cvssV3 - a.cvssV3) || [],
          update_time: scoreCVSSWebsocket?.update_time,
        });
        setLoadingState(false);
      } else {
        setLoadingState(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  // tính trung bình công của cvss v3 score
  const averageCVSSCcore = (data: any) => {
    const total = data.reduce((sum: any, item: any) => sum + item.cvssV3, 0);
    const average = total / data.length;

    return average.toFixed(1);
  };

  const handleCVSSScore = async (key: string) => {
    const dataRes = await handleScoreSystem({ domain: key });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_score } = dataRes;
    setScoreCVSS({
      data: list_score?.slice()?.sort((a: any, b: any) => b.cvssV3 - a.cvssV3) || [],
      update_time: null,
    });
    setLoadingState(false);
  };

  const validationSchema = yup.object({
    domainKey: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, 'Invalid domain format'),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    setScoreCVSS({ data: null, update_time: null });
    setLoadingState(true);
    handleCVSSScore(data.domainKey?.trim());
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  return (
    <>
      <Helmet>
        <title>Score CVSS - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.score.title')}
      </Typography>
      <Box>
        {inforUser?.role === 'super admin' ? (
          <Box sx={{ width: '100%', mt: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="domainKey"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.mainDomain')}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onKeyDown={handleKeyDown}
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '12px 12px',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          onClick={handleSubmit(onSubmit)}
                          sx={{
                            fontSize: '2rem',
                            mr: 1,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
            </Box>

            {!_.isEmpty(scoreCVSS.data && scoreCVSS?.update_time) && <ScanTime updateTime={scoreCVSS?.update_time} />}
          </Box>
        )}

        {loadingState && (
          <>
            <Typography
              variant="body2"
              sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
            >
              {t('notify.scanInProgress')}
            </Typography>
            <br />
            <Box sx={{ width: '100%', marginTop: 3 }}>
              <LinearProgress color="info" />
            </Box>
          </>
        )}

        {!_.isEmpty(scoreCVSS.data) && !loadingState && (
          <>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '600px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {t('asm.score.score')}
                    </Typography>
                    <TotalScore point={averageCVSSCcore(scoreCVSS.data)} />
                  </Box>
                  <LabelTotalScore point={averageCVSSCcore(scoreCVSS.data)} />
                </Box>

                <ProgressScore point={averageCVSSCcore(scoreCVSS.data)} />
              </Box>
            </Box>
            <Box sx={{ marginTop: 5 }}>
              <Typography sx={{ fontWeight: 500, color: 'text.primary' }}>{t('asm.score.describe')}</Typography>
              <Grid container spacing={3} sx={{ marginTop: 0.5 }}>
                {scoreCVSS.data.map((item: any, index: number) => (
                  <Grid key={index} item xs={6} md={4} lg={3} sx={{}}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: 'action.hover',
                        borderRadius: 1,
                        minHeight: '100%',
                        boxShadow: 2,
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 400 }}>
                        {item.url}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
                        <SubdomainScore point={item.cvssV3} />
                        <LabelSubdomainScore point={item.cvssV3} />
                      </Box>
                      <Grid container sx={{ marginTop: 1.5, display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} md={6}>
                          {item.exploitabilityScore !== 0 && (
                            <Box sx={{ display: 'flex', padding: '5px' }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  alignSelf: 'center',
                                  color: 'text.secondary',
                                  fontWeight: 500,
                                }}
                              >
                                {t('cve.cveDetail.exploitability')}
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {item.exploitabilityScore}
                              </Typography>
                            </Box>
                          )}
                          {item.impactScore !== 0 && (
                            <Box sx={{ padding: '0px 5px', display: 'flex' }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  alignSelf: 'center',
                                  color: 'text.secondary',
                                  fontWeight: 500,
                                }}
                              >
                                {t('cve.cveDetail.impact')}
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {item.impactScore}
                              </Typography>
                            </Box>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ mt: { xs: 1.5, md: 0 } }}>
                          {!_.isEmpty(item.cve) && (
                            <>
                              <TableContainer sx={{ maxHeight: 230, overflow: 'auto' }}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center" sx={{ whiteSpace: 'nowrap', padding: '8px' }}>
                                        {`CVE (${item.cve.length})`}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.cve.map((itemCVE: any, indexCVE: number) => (
                                      <TableRow key={indexCVE} hover>
                                        <TableCell sx={{ padding: '6px' }}>
                                          <Link
                                            underline="none"
                                            sx={{
                                              padding: '5px',
                                              display: 'flex',
                                              fontSize: '1.2rem',
                                              color: 'primary.dark',
                                              cursor: 'pointer',
                                              '&:hover': {
                                                color: 'secondary.main',
                                              },
                                            }}
                                            href={`/cve/${itemCVE}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {itemCVE}
                                          </Link>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default ScoreCVSS;
