const Validators = () => {
  const formatPhone = (phoneNumber: string) => {
    const regex = /[^\d]/;
    if (regex.test(phoneNumber)) {
      return false;
    }
    if (phoneNumber.length < 10 || phoneNumber.length > 11) {
      return false;
    }
    if (phoneNumber.length === 10 && phoneNumber.startsWith('0')) {
      return `84${phoneNumber.slice(1)}`;
    }
    if (phoneNumber.length === 11) {
      if (phoneNumber.startsWith('016')) {
        return `843${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.startsWith('0120')) {
        return `8470${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0121')) {
        return `8479${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0122')) {
        return `8477${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0126')) {
        return `8476${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0128')) {
        return `8478${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0123')) {
        return `8483${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0124')) {
        return `8484${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0125')) {
        return `8485${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0127')) {
        return `8481${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0129')) {
        return `8482${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('0199')) {
        return `8459${phoneNumber.slice(4)}`;
      }
      if (phoneNumber.startsWith('018')) {
        return `845${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.startsWith('84')) {
        return phoneNumber;
      }
    }
    return false;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    return email;
  };
  const validateUsername = (username: string) => {
    const usernameRegex = /^[a-zA-Z0-9_.-]+$/;
    if (!usernameRegex.test(username)) {
      return false;
    }
    return username;
  };
  const validatePii = (number: string) => {
    const numberRegex = /^[0-9]+$/;
    if (numberRegex.test(number) && (number.length === 9 || number.length === 12)) {
      return number;
    }
    return false;
  };

  const validateFBId = (number: string) => {
    const numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(number)) {
      return false;
    }
    return number;
  };

  return {
    formatPhone,
    validateEmail,
    validateUsername,
    validatePii,
    validateFBId,
  };
};

export default Validators;
