/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import TagIcon from '@mui/icons-material/Tag';
import { Avatar, Box, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { NoResult } from '@/components/common/NoResult';
import SkeletonItemSocial from '@/components/Skeleton/SkeletonItemSocial';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocialApi from '@/Hooks/fetchApi/useSocial';
import {
  OverviewSourceByTopicTypes,
  UserItemTypes,
  SourceItemTypes,
  StatisticalByTimeOverviewTypes,
  MentionedTypes,
  SentimentByTimeTypes,
} from '@/types/Social';
import { SEARCH_TIKTOK, TYPE_SEARCH } from '@/utils/constants';

import OverviewTiktok from './OverviewTiktok';
import LineChartMentionsByTime from '../../chart/LineChartMentionsByTime';
import LineChartSentimentByTime from '../../chart/LineChartSentimentByTime';
import PieChart from '../../chart/PieChart';

const Tiktok = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { topicSocial, timeScan } = useSocialInfo();
  const { handleGetOverviewSocial } = useSocialApi();
  const { apiError } = useCommonInfo();

  const [overviewData, setOverviewData] = useState<OverviewSourceByTopicTypes[]>([]);
  const [listUsers, setListUsers] = useState<UserItemTypes[]>([]);
  const [listTags, setListTags] = useState<SourceItemTypes[]>([]);
  const [statisticalByTime, setStatisticalByTime] = useState<StatisticalByTimeOverviewTypes[]>([]);
  const [mentionedBySentiment, setMentionedBySentiment] = useState<MentionedTypes[]>([]);
  const [sentimentByTime, setSentimentByTime] = useState<SentimentByTimeTypes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  // Đề cập theo sắc thái
  const transformDataMentionBySentiment = (data: MentionedTypes[]) => {
    return data.map((item: MentionedTypes) => {
      let source;
      let sliceSettings;
      switch (item.source) {
        case 'positive':
          source = t('smcc.status.positive');
          sliceSettings = {
            fill: theme.palette.success.main,
            stroke: theme.palette.success.main,
          };
          break;
        case 'neutral':
          source = t('smcc.status.neutral');
          sliceSettings = {
            fill: '#cb997e',
            stroke: '#cb997e',
          };
          break;
        case 'negative':
          source = t('smcc.status.negative');
          sliceSettings = {
            fill: theme.palette.error.main,
            stroke: theme.palette.error.main,
          };
          break;
        default:
          source = item.source;
          sliceSettings = {};
          break;
      }
      return {
        source,
        count: item.count,
        sliceSettings,
      };
    });
  };

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (topicSocial && timeScan) {
      handleRequest();
    }
  }, [topicSocial, timeScan]);

  const handleRequest = async () => {
    setLoading(true);
    const params = {
      keyword: topicSocial?.key_word_main,
      social: SEARCH_TIKTOK,
      search_type: TYPE_SEARCH,
      time_range: timeScan,
    };

    const res = await handleGetOverviewSocial(params, handleLoading);
    const { data } = res;
    const { overviews, sentiment_mention, statistical_time, sentiment_time, user_list, featured_hashtags } = data;

    setOverviewData(overviews);
    setListUsers(user_list);
    setListTags(featured_hashtags);
    setStatisticalByTime(statistical_time);
    setMentionedBySentiment(transformDataMentionBySentiment(sentiment_mention));
    setSentimentByTime(sentiment_time);
  };

  return (
    <>
      {!loading ? (
        <>
          <OverviewTiktok data={overviewData} />
          {/* <Box sx={{ padding: 2, boxShadow: 4, borderRadius: 1, backgroundColor: 'background.main' }}>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.featuredKeywords')}
            </Typography>
            <WordCloud data={[]} />
          </Box> */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsByTimeGraph')}
                </Typography>
                <LineChartMentionsByTime data={statisticalByTime} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.sentimentByTimeGraph')}
                </Typography>
                <LineChartSentimentByTime data={sentimentByTime} />
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsBySentimentGraph')}
                </Typography>
                <PieChart data={mentionedBySentiment} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('smcc.overview.listUsers')}</Typography>
            {!_.isEmpty(listUsers) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {listUsers.map((item: UserItemTypes) => (
                  <Grid item sm={6} xs={12} key={item.name}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'action.hover',
                        borderRadius: 1,
                        padding: '8px 16px',
                      }}
                    >
                      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                        <Link href={`https://tiktok.com/@${item?.name}`} target="_blank" rel="noopener">
                          <Avatar sx={{ width: '35px', height: '35px' }} />
                        </Link>

                        <Link
                          href={`https://tiktok.com/@${item?.name}`}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: 'none',
                            ':hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            {item?.name}
                          </Typography>
                        </Link>
                      </Stack>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('smcc.overview.post')}
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary', fontWeight: 600 }}>
                          {item.user_post_count}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
          </Box>
          <Box sx={{ mt: 4, backgroundColor: 'background.main', borderRadius: 1, boxShadow: 4, padding: 2 }}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
              {t('smcc.overview.featuredHashtags')}
            </Typography>
            {!_.isEmpty(listTags) ? (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {listTags.map((item: SourceItemTypes) => (
                  <Grid item sm={6} xs={12} key={item.name}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'action.hover',
                        borderRadius: 1,
                        padding: '8px 16px',
                      }}
                    >
                      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                        <TagIcon sx={{ fontSize: '2.5rem', color: 'text.secondary' }} />
                        <Link
                          href={item?.link}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: 'none',
                            ':hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
                            {`#${item?.name}`}
                          </Typography>
                        </Link>
                      </Stack>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('smcc.overview.mentions')}
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary', fontWeight: 600 }}>
                          {item.counts}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResult />
            )}
          </Box>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
          <Grid item lg={2.4} sm={6} xs={12}>
            <SkeletonItemSocial />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Tiktok;
