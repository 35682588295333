import { useEffect, useMemo, useRef, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

import AddButton from '@/components/common/Button/AddButton';
import AddMoreButton from '@/components/common/Button/AddMoreButton';
import { Transition } from '@/components/common/Transition';
import useNotify from '@/Hooks/common/useNotify';
import useAsm from '@/Hooks/fetchApi/useAsm';
import { AddAssetType } from '@/types/Assets';
import { FilterVendorType, FilterProductType } from '@/types/CVE';
import { decrypt } from '@/utils/crypto';

interface AddAssetProps {
  isAddAsset: boolean;
  setIsAddAsset: (newValue: boolean) => void;
  handleGetListAsset: () => Promise<void>;
}

const AddAsset: React.FC<AddAssetProps> = ({ isAddAsset, setIsAddAsset, handleGetListAsset }) => {
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const { handleListVendors, handleCreateAsset } = useAsm();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [listAsset, setListAsset] = useState<AddAssetType[]>([]);
  const [listVendor, setListVendor] = useState<FilterVendorType[]>([]);
  const [listProduct, setListProduct] = useState<FilterProductType[]>([]);
  const [loadingSearchVendor, setLoadingSearchVendor] = useState(false);
  const [loadingSearchProduct, setLoadingSearchProduct] = useState(false);
  const [vendor, setVendor] = useState({ label: '', param: '' });
  const [product, setProduct] = useState({ label: '', param: '' });
  const [keyVendor, setKeyVendor] = useState('');
  const [keyProduct, setKeyProduct] = useState({ vendor: '', search: '' });
  const [pageVendor, setPageVendor] = useState(1);
  const [pageProduct, setPageProduct] = useState(1);
  const [loadingContainerVendor, setLoadingContainerVendor] = useState(false);
  const [loadingContainerProduct, setLoadingContainerProduct] = useState(false);
  const [endOfVendor, setEndOfVendor] = useState(false);
  const [endOfProduct, setEndOfProduct] = useState(false);
  const [version, setVersion] = useState('');
  const [vendorError, setVendorError] = useState(false);
  const [productError, setProductError] = useState(false);
  const [versionError, setVersionError] = useState(false);

  const containerListVendorRef = useRef<HTMLDivElement>(null);
  const containerListProductRef = useRef<HTMLDivElement>(null);
  const timeoutSearchVendorRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutSearchProductRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const navigateCreateSuccess = () => {
    setIsAddAsset(false);
    handleGetListAsset();
    setVendor({ label: '', param: '' });
    setProduct({ label: '', param: '' });
    setListVendor([]);
    setListProduct([]);
    setListAsset([]);
    setVersion('');
  };

  const handleAddAsset = async () => {
    let hasError = false;

    if (!vendor.label) {
      setVendorError(true);
      hasError = true;
    } else {
      setVendorError(false);
    }

    if (!product.label) {
      setProductError(true);
      hasError = true;
    } else {
      setProductError(false);
    }

    if (!version) {
      setVersionError(true);
      hasError = true;
    } else {
      setVersionError(false);
    }

    if (!hasError) {
      if (vendor.param && product.param) {
        const params = {
          vendor: vendor.param,
          vendor_label: vendor.label,
          product: product.param,
          product_label: product.label,
          group_id: inforUser.groupId,
          version,
        };
        await handleCreateAsset({ list_asset_create: [params] }, navigateCreateSuccess);
      }
    }
  };

  const handleAddAllAsset = async () => {
    await handleCreateAsset({ list_asset_create: listAsset }, navigateCreateSuccess);
  };

  const handleAddMore = async () => {
    let hasError = false;

    if (!vendor.label) {
      setVendorError(true);
      hasError = true;
    } else {
      setVendorError(false);
    }

    if (!product.label) {
      setProductError(true);
      hasError = true;
    } else {
      setProductError(false);
    }

    if (!version) {
      setVersionError(true);
      hasError = true;
    } else {
      setVersionError(false);
    }

    if (!hasError) {
      if (vendor.param && product.param && version) {
        const params = {
          vendor: vendor.param,
          vendor_label: vendor.label,
          product: product.param,
          product_label: product.label,
          group_id: inforUser.groupId,
          version,
        };
        const isDuplicate = listAsset.some(
          (asset) =>
            asset.vendor_label === vendor.label && asset.product_label === product.label && asset.version === version,
        );
        if (!isDuplicate) {
          setListAsset((prevList) => [...prevList, params]);
        } else {
          onNotify('warning', t('notify.duplicateAsset'), 3000);
        }
        setVendor({ label: '', param: '' });
        setProduct({ label: '', param: '' });
        setListVendor([]);
        setListProduct([]);
        setVersion('');
      }
    }
  };

  const handleGetListVendors = async (search: { search: string }) => {
    if (search.search === '') {
      return [];
    }
    setKeyVendor(search.search);
    const res = await handleListVendors(search);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { info_vendor } = res;
    setLoadingSearchVendor(false);
    setPageVendor(pageVendor + 1);
    return info_vendor.list_vendor;
  };

  const fetchVendors = useMemo(
    () =>
      debounce(async (search, callback) => {
        if (search !== '') {
          const vendors = await handleGetListVendors({ search });
          callback(vendors);
        } else {
          setListVendor([]);
        }
      }, 1000),
    [],
  );

  const handleGetListProducts = async (search: { vendor: string; search: string }) => {
    if (search.search === '') {
      return [];
    }
    setKeyProduct(search);
    const res = await handleListVendors(search);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_product } = res;
    setLoadingSearchProduct(false);
    setPageProduct(pageProduct + 1);
    return list_product;
  };

  const fetchProducts = useMemo(
    () =>
      debounce(async (search, callback) => {
        if (search !== '') {
          const products = await handleGetListProducts({
            vendor: vendor.param !== null ? decodeURIComponent(vendor.param) : vendor.param,
            search,
          });
          callback(products);
        } else {
          setListProduct([]);
        }
      }, 1000),
    [vendor],
  );

  const handleInputChangeVendor = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setListVendor([]);
    setPageVendor(1);
    setKeyVendor('');
    setLoadingContainerVendor(false);
    setEndOfVendor(false);
    setVendor({ label: event.target.value, param: event.target.value });
    event.target.value ? setLoadingSearchVendor(true) : setLoadingSearchVendor(false);
    fetchVendors(event.target.value, (vendors: FilterVendorType[]) => {
      setListVendor(vendors);
    });
  };

  const handleKeyDownVendor: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.keyCode === 13) {
      const target = event.target as HTMLInputElement;
      setVendor({ label: target.value, param: target.value });
      setListVendor([]);
      setPageVendor(1);
      setKeyVendor('');
      setLoadingContainerVendor(false);
      setEndOfVendor(false);
    }
  };

  const handleInputChangeProduct = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setListProduct([]);
    setPageProduct(1);
    setKeyProduct({ vendor: '', search: '' });
    setLoadingContainerProduct(false);
    setEndOfProduct(false);
    setProduct({ label: event.target.value, param: event.target.value });
    event.target.value ? setLoadingSearchProduct(true) : setLoadingSearchProduct(false);
    fetchProducts(event.target.value, (products: FilterProductType[]) => {
      setListProduct(products);
    });
  };

  const handleKeyDownProduct: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.keyCode === 13) {
      const target = event.target as HTMLInputElement;
      setProduct({ label: target.value, param: target.value });
      setListProduct([]);
      setPageProduct(1);
      setKeyProduct({ vendor: '', search: '' });
      setLoadingContainerProduct(false);
      setEndOfProduct(false);
    }
  };

  const handleInputChangeVersion = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value !== '') {
      setVersion(event.target.value);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerListVendorRef.current;
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight && !endOfVendor && keyVendor) {
          setLoadingContainerVendor(true);
          if (timeoutSearchVendorRef.current !== null) {
            clearTimeout(timeoutSearchVendorRef.current); // Xóa bất kỳ hẹn giờ nào còn tồn tại
          }
          timeoutSearchVendorRef.current = setTimeout(async () => {
            const res = await handleListVendors({ search: keyVendor, page: pageVendor });
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { info_vendor } = res;
            setLoadingContainerVendor(false);
            if (info_vendor?.list_vendor && info_vendor?.list_vendor?.length > 0) {
              setListVendor((prevListVendor) => [...prevListVendor, ...info_vendor.list_vendor]);
              setPageVendor(pageVendor + 1);
            } else {
              setEndOfVendor(true);
            }
          }, 1000);
        }
      }
    };

    if (containerListVendorRef.current) {
      containerListVendorRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerListVendorRef.current) {
        containerListVendorRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [listVendor, endOfVendor]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerListProductRef.current;
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight && !endOfProduct && keyProduct.search) {
          setLoadingContainerProduct(true);
          if (timeoutSearchProductRef.current !== null) {
            clearTimeout(timeoutSearchProductRef.current); // Xóa bất kỳ hẹn giờ nào còn tồn tại
          }
          timeoutSearchProductRef.current = setTimeout(async () => {
            const res = await handleListVendors({
              vendor: keyProduct.vendor,
              search: keyProduct.search,
              product_page: pageProduct,
            });
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { list_product } = res;
            setLoadingContainerProduct(false);
            if (list_product && list_product?.length > 0) {
              setListProduct((prevListProduct) => [...prevListProduct, ...list_product]);
              setPageProduct(pageProduct + 1);
            } else {
              setEndOfProduct(true);
            }
          }, 1000);
        }
      }
    };

    if (containerListProductRef.current) {
      containerListProductRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerListProductRef.current) {
        containerListProductRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [listProduct, endOfProduct]);

  return (
    <Dialog
      open={isAddAsset}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setIsAddAsset(false);
        setVendor({ label: '', param: '' });
        setProduct({ label: '', param: '' });
        setListVendor([]);
        setListProduct([]);
        setPageVendor(1);
        setKeyVendor('');
        setPageProduct(1);
        setKeyProduct({ vendor: '', search: '' });
        setListAsset([]);
        setVersion('');
        setVendorError(false);
        setProductError(false);
        setVersionError(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          opacity: 0.2,
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('asm.asset.addAsset')}</Typography>
      </DialogTitle>
      <DialogContent>
        {listAsset.length !== 0 && (
          <Box sx={{ borderRadius: 2, mt: 2, padding: 2, mb: 4, backgroundColor: 'action.hover', boxShadow: 2 }}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('table.asset.vendor')}</TableCell>
                    <TableCell>{t('table.asset.product')}</TableCell>
                    <TableCell>{t('table.asset.version')}</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listAsset.map((row: AddAssetType, index: number) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.vendor_label}</TableCell>
                      <TableCell>{row.product_label}</TableCell>
                      <TableCell>{row.version}</TableCell>
                      <TableCell>
                        <Tooltip title={t('tooltip.delete')} placement="left">
                          <DeleteIcon
                            onClick={() => {
                              setListAsset((prevList) =>
                                prevList.filter(
                                  (asset) =>
                                    asset.vendor_label !== row.vendor_label ||
                                    asset.product_label !== row.product_label ||
                                    asset.version !== row.version,
                                ),
                              );
                            }}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'error.main',
                              },
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              type="submit"
              variant="contained"
              color="info"
              onClick={() => {
                handleAddAllAsset();
              }}
              sx={{
                mt: 1.5,
                textTransform: 'initial',
                color: 'common.white',
              }}
            >
              {t('action.addAll')}
            </Button>
          </Box>
        )}
        <Box sx={{ position: 'relative', marginTop: 2 }}>
          <TextField
            variant="outlined"
            autoComplete="off"
            sx={{
              width: '100%',
              flex: 1,
            }}
            placeholder={t('placeholder.vendor')}
            InputProps={{
              endAdornment: (
                <>
                  {loadingSearchVendor ? <CircularProgress color="inherit" size={20} /> : null}
                  {vendor.label && !loadingSearchVendor && (
                    <ClearIcon
                      onClick={() => {
                        setVendor({ label: '', param: '' });
                        setListVendor([]);
                        setPageVendor(1);
                        setKeyVendor('');
                        setEndOfVendor(false);
                      }}
                      sx={{
                        fontSize: '2rem',
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              ),
            }}
            value={vendor.label}
            onChange={handleInputChangeVendor}
            onKeyDown={handleKeyDownVendor}
            error={vendorError}
            helperText={vendorError ? t('validation.fieldRequired') : ''}
          />

          <Box
            sx={{
              zIndex: 2,
              position: 'fixed',
              marginTop: 1,
              backgroundColor: 'background.main',
              boxShadow: 10,
              maxHeight: '300px',
              overflowY: 'scroll',
            }}
            ref={containerListVendorRef}
          >
            {listVendor.map((result: FilterVendorType) => (
              <Box
                key={result.vendor}
                onClick={() => {
                  setVendor({ label: result.vendor, param: result.params_vendor });
                  setListVendor([]);
                  setPageVendor(1);
                  setKeyVendor('');
                  setEndOfVendor(false);
                }}
                sx={{
                  fontSize: '1.4rem',
                  minWidth: '200px',
                  padding: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    color: 'primary.dark',
                  },
                }}
              >
                {result.vendor}
              </Box>
            ))}
            {loadingContainerVendor && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" size={20} />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ position: 'relative', marginTop: 2 }}>
          <TextField
            variant="outlined"
            disabled={!vendor.label}
            autoComplete="off"
            sx={{
              width: '100%',
              flex: 1,
            }}
            placeholder={t('placeholder.product')}
            InputProps={{
              endAdornment: (
                <>
                  {loadingSearchProduct && <CircularProgress color="inherit" size={20} />}
                  {product.label && !loadingSearchProduct && (
                    <ClearIcon
                      onClick={() => {
                        setProduct({ label: '', param: '' });
                        setListProduct([]);
                        setPageProduct(1);
                        setKeyProduct({ vendor: '', search: '' });
                        setEndOfProduct(false);
                      }}
                      sx={{
                        fontSize: '2rem',
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              ),
            }}
            value={product.label}
            onChange={handleInputChangeProduct}
            onKeyDown={handleKeyDownProduct}
            onFocus={() => {
              setListVendor([]);
            }}
            error={productError}
            helperText={productError ? t('validation.fieldRequired') : ''}
          />

          <Box
            sx={{
              zIndex: 2,
              position: 'fixed',
              marginTop: 1,
              backgroundColor: 'background.main',
              boxShadow: 10,
              maxHeight: '300px',
              overflowY: 'scroll',
            }}
            ref={containerListProductRef}
          >
            {listProduct.map((result: FilterProductType) => (
              <Box
                key={result.product}
                onClick={() => {
                  setProduct({ label: result.product, param: result.params.product });
                  setListProduct([]);
                  setPageProduct(1);
                  setKeyProduct({ vendor: '', search: '' });
                  setEndOfProduct(false);
                }}
                sx={{
                  fontSize: '1.4rem',
                  minWidth: '200px',
                  padding: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    color: 'primary.dark',
                  },
                }}
              >
                {result.product}
              </Box>
            ))}
            {loadingContainerProduct && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" size={20} />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <TextField
            variant="outlined"
            autoComplete="off"
            disabled={!product.label}
            sx={{
              width: '100%',
              flex: 1,
            }}
            placeholder={t('placeholder.version')}
            InputProps={{
              endAdornment: (
                <>
                  {version !== '' && (
                    <ClearIcon
                      onClick={() => {
                        setVersion('');
                      }}
                      sx={{
                        fontSize: '2rem',
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              ),
            }}
            value={version}
            onChange={handleInputChangeVersion}
            error={versionError}
            helperText={versionError ? t('validation.fieldRequired') : ''}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4, paddingBlock: 1 }}>
          <AddButton
            handleClick={() => {
              handleAddAsset();
            }}
            disable={listAsset.length !== 0}
          />
          <Box sx={{ marginLeft: 3 }}>
            <AddMoreButton
              handleClick={() => {
                handleAddMore();
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddAsset;
