import { useEffect, useState } from 'react';

import { Box, CardMedia, Dialog, Typography } from '@mui/material';
import { common } from '@mui/material/colors';

import { Transition } from '@/components/common/Transition';
import { Course } from '@/types/management';

interface DetailCourseProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detail: Course;
}

const DetailCourse: React.FC<DetailCourseProps> = ({ openDialogDetail, onDialogDetailChange, detail }) => {
  const [videoKey, setVideoKey] = useState(0);

  useEffect(() => {
    if (openDialogDetail) {
      setVideoKey((prevKey) => prevKey + 1);
    }
  }, [openDialogDetail]);

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogDetailChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '70vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <Box sx={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <Typography variant="h6" sx={{ color: common.white, padding: 1.5 }}>
            {detail?.title}
          </Typography>
          <CardMedia
            key={videoKey}
            component="video"
            autoPlay
            src={detail?.link_video}
            controls
            // poster={detail?.thumbnail}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default DetailCourse;
