import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography, Grid, Tooltip, Checkbox } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { formatTimeCVEDetail } from '@/components/common/format/FormatTimeCVE';
import useAuth from '@/Hooks/fetchApi/useAuth';
import { SelectedUserType, UserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

import DialogUpdatePermission from './DialogUpdatePermission';
import EnhancedHead from './EnhancedHead';

interface UserBoxProps {
  item: UserType[];
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
  setSearchValue: (newValue: string) => void;
}

const UserBox: React.FC<UserBoxProps> = ({ item, handleGetUser, handleGetCompany, setSearchValue }) => {
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleDeleteUser, handleChangePermision } = useAuth();

  const [openDialogUpdatePermission, setOpenDialogUpdatePermission] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUserType>({ userId: '', username: '', role: '' });
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<readonly { user_id: string }[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setSelected([]);
      return;
    }

    const newSelected = item
      .filter((n: UserType) => inforUser.role === 'super admin' || n.permission_name !== 'manager')
      .map((n: UserType) => ({ user_id: n.user_id }));

    setSelected(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, user_id: string) => {
    const selectedIndex = selected.findIndex((itemSelected: { user_id: string }) => itemSelected.user_id === user_id);
    let newSelected: readonly { user_id: string }[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { user_id });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (user_id: string) =>
    selected.findIndex((itemSelected: { user_id: string }) => itemSelected.user_id === user_id) !== -1;

  const itemWithoutManager = item?.filter((n: UserType) => n.permission_name !== 'manager');

  const deleteSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogDeleteAll(false);
    setSearchValue('');
    setSelected([]);
  };

  const navigateChangePermissionSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogUpdatePermission(false);
  };

  const handleDeleteAll = () => {
    const userIds = selected.map((user) => user.user_id);
    handleDeleteUser({ list_user_delete: userIds }, deleteSuccess);
  };

  const handleChangePermission = (arg: string) => {
    const listUsers = selected.map((user) => ({ user_id: user.user_id, permission: arg }));
    if (!_.isEmpty(selected)) {
      handleChangePermision({ list_change_permission: listUsers }, navigateChangePermissionSuccess);
    } else {
      handleChangePermision(
        { list_change_permission: [{ user_id: selectedUser.userId, permission: arg }] },
        navigateChangePermissionSuccess,
      );
    }
  };

  return (
    <>
      {(inforUser.role === 'manager' || inforUser.role === 'super admin') && (
        <EnhancedHead
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={inforUser.role === 'super admin' ? item?.length : itemWithoutManager?.length}
          openDialogDeleteAll={openDialogDeleteAll}
          onOpenDialogDeleteAllChange={setOpenDialogDeleteAll}
          handleDeleteAll={handleDeleteAll}
          openDialogUpdatePermission={openDialogUpdatePermission}
          onOpenDialogUpdatePermissionChange={setOpenDialogUpdatePermission}
          handleChangePermission={handleChangePermission}
        />
      )}

      <Grid container sx={{ justifyContent: 'space-between' }}>
        {item?.map((itemUser: UserType) => (
          <Grid
            item
            xs={12}
            md={5.8}
            key={itemUser.user_id}
            sx={{
              borderRadius: 2,
              mt: 2,
              padding: 2,
              backgroundColor: 'action.hover',
              boxShadow: 3,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="caption" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
                  {t('management.asm.groups.status')}
                </Typography>
                {itemUser?.is_active ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#02bb9f',
                      backgroundColor: 'rgba(2, 187, 159, 0.1)',
                      ml: 1,
                      borderRadius: '5px',
                      padding: '2px 5px',
                      alignSelf: 'center',
                    }}
                  >
                    {t('management.asm.groups.active')}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#f9806c',
                      backgroundColor: '#ffffff1a',
                      ml: 1,
                      borderRadius: '5px',
                      padding: '2px 5px',
                    }}
                  >
                    {t('management.asm.groups.inactive')}
                  </Typography>
                )}
              </Box>
              {itemUser.permission_name !== 'manager' && inforUser.role === 'manager' && (
                <Box>
                  <Checkbox
                    color="primary"
                    role="checkbox"
                    onClick={(event) => handleClick(event, itemUser.user_id)}
                    checked={isSelected(itemUser.user_id)}
                  />
                </Box>
              )}
              {inforUser.role === 'super admin' && (
                <Box>
                  <Checkbox
                    color="primary"
                    role="checkbox"
                    onClick={(event) => handleClick(event, itemUser.user_id)}
                    checked={isSelected(itemUser.user_id)}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Box sx={{ width: '35%' }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  E-mail
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                  {itemUser?.user_name}
                </Typography>
              </Box>
              <Box sx={{ width: '35%' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {t('role.role')}
                  </Typography>

                  {inforUser.role === 'manager' && itemUser?.permission_name !== 'manager' && (
                    <Tooltip title={t('tooltip.update')} placement="right">
                      <EditIcon
                        onClick={() => {
                          setOpenDialogUpdatePermission(true);
                          setSelectedUser({
                            userId: itemUser?.user_id,
                            username: itemUser?.user_name,
                            role: itemUser?.permission_name,
                          });
                          setSelected([]);
                        }}
                        sx={{
                          fontSize: '1.5rem',
                          marginLeft: 1.5,
                          cursor: 'pointer',
                          color: 'primary.main',
                          '&:hover': {
                            color: 'info.main',
                          },
                        }}
                      />
                    </Tooltip>
                  )}
                  {inforUser.role === 'super admin' && (
                    <Tooltip title={t('tooltip.update')} placement="right">
                      <EditIcon
                        onClick={() => {
                          setOpenDialogUpdatePermission(true);
                          setSelectedUser({
                            userId: itemUser?.user_id,
                            username: itemUser?.user_name,
                            role: itemUser?.permission_name,
                          });
                          setSelected([]);
                        }}
                        sx={{
                          fontSize: '1.5rem',
                          marginLeft: 1.5,
                          cursor: 'pointer',
                          color: 'primary.main',
                          '&:hover': {
                            color: 'info.main',
                          },
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>

                <Typography
                  variant="body2"
                  sx={{ color: 'text.primary', fontWeight: 500, textTransform: 'capitalize' }}
                >
                  {itemUser?.permission_name}
                </Typography>

                <DialogUpdatePermission
                  openDialogUpdatePermission={openDialogUpdatePermission}
                  onOpenDialogUpdatePermissionChange={setOpenDialogUpdatePermission}
                  handleChangePermission={handleChangePermission}
                  selectedUser={selectedUser}
                  onSelectedUserChange={setSelectedUser}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Box sx={{ width: '35%' }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {t('management.asm.groups.groupName')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                  {itemUser?.group_name}
                </Typography>
              </Box>
              <Box sx={{ width: '35%' }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {t('management.asm.groups.expireDate')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500 }}>
                  {formatTimeCVEDetail(itemUser?.expire_date_license)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UserBox;
