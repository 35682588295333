import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Container, Grid, Typography, Link as LinkMui, List, ListItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CardHandsome from '@/components/identity/cards/CardHandsome';
import { AppConfig } from '@/configs/Identity/appConfig';
import { LinkGroup, LinkList, MenuFooterMenu, relatedLink } from '@/configs/Identity/menuConfig';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { APP_NAME, APP_SLOGAN } = AppConfig();
  const footerGroup = MenuFooterMenu();
  return (
    <>
      <CardHandsome />
      <Box sx={{ backgroundColor: 'background.bgFooter', fontSize: '16px', paddingTop: '6em' }}>
        <Container className="container">
          <Grid container spacing={4} sx={{ justifyContent: 'space-between' }}>
            <Grid item md={4} sm={12}>
              <LinkMui
                href="/"
                sx={{ fontSize: '1.25em', fontWeight: 900, color: 'text.primary', textDecoration: 'none' }}
              >
                {APP_NAME}
              </LinkMui>
              <Typography sx={{ mb: '3em', color: 'text.secondary', fontSize: '1em' }}>{APP_SLOGAN}</Typography>
              <Box sx={{ display: 'flex' }} gap={2}>
                <Link to={relatedLink.FACEBOOK} className="group">
                  <FacebookIcon sx={{ color: 'text.secondary', fontSize: '1.5em' }} />
                </Link>
                <Link to={relatedLink.TWITTER} className="group">
                  <TwitterIcon sx={{ color: 'text.secondary', fontSize: '1.5em' }} />
                </Link>
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid container spacing={3}>
                <>
                  {footerGroup.map((item: LinkGroup, index: number) => {
                    return (
                      <Grid item md={4} key={index}>
                        <Typography sx={{ mb: '1.5em', fontSize: '0.875em', fontWeight: 700 }}>{item.title}</Typography>
                        <List>
                          {item.list.map((itemMenu: LinkList, indexMenu: number) => (
                            <ListItem key={indexMenu} sx={{ paddingLeft: 0, paddingY: '3px' }}>
                              <LinkMui
                                href={itemMenu.url}
                                sx={{
                                  fontSize: '1em',
                                  color: 'text.disabled',
                                  textDecoration: 'none',

                                  '&:hover': {
                                    color: 'text.primary',
                                  },
                                }}
                              >
                                {itemMenu.text}
                              </LinkMui>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    );
                  })}
                </>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              paddingTop: '2em',
              paddingBottom: '1em',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <span style={{ fontSize: '0.875em', color: 'text.disabled', alignContent: 'center', textAlign: 'center' }}>
              &copy;
              {APP_NAME}
            </span>
            <List sx={{ display: 'flex' }}>
              <ListItem sx={{ paddingX: '6px', justifyContent: 'center' }}>
                <LinkMui
                  href={relatedLink.TERM_AND_CONDITION}
                  sx={{
                    fontSize: '0.875em',
                    color: 'text.disabled',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      color: 'text.secondary',
                    },
                  }}
                >
                  {t('identity.footer.termsConditions')}
                </LinkMui>
              </ListItem>
              <ListItem sx={{ paddingX: '6px', justifyContent: 'center' }}>
                <LinkMui
                  href={relatedLink.PRIVACY_POLICY}
                  sx={{
                    fontSize: '0.875em',
                    color: 'text.disabled',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      color: 'text.secondary',
                    },
                  }}
                >
                  {t('identity.footer.privacyPolicy')}
                </LinkMui>
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
