import { useEffect, useState } from 'react';

import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useSecurityAssessment from '@/Hooks/fetchApi/useSecurityAssessment';
import { SecurityAssessmentType } from '@/types/securityAssessment';

const SecurityAssessment = () => {
  const { handleListTool, handleDownloadTool } = useSecurityAssessment();
  const { t } = useTranslation();

  const [listTool, setListTool] = useState<SecurityAssessmentType[]>([]);

  const getListTool = async () => {
    const dataRes = await handleListTool({});
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_file } = dataRes;
    setListTool(list_file);
  };

  useEffect(() => {
    getListTool();
  }, []);

  const handleDownload = async (filename: string) => {
    const dataRes = await handleDownloadTool({ file_name: filename });
    const { url } = dataRes;
    if (url) {
      // Mở URL trong cửa sổ mới để tự động tải về
      window.open(url, '_blank');
    } else {
      console.error('URL không khả dụng');
    }
  };

  return (
    <>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ maxWidth: '1000px' }}>
          <Typography variant="h5" sx={{ fontWeight: 600, mt: 2 }}>
            {t('securityAssessment.securityAssessment')}
          </Typography>

          <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
            {t('securityAssessment.intro')}
          </Typography>
          {listTool.map((tool: SecurityAssessmentType) => (
            <Box key={tool.type_os} sx={{ mt: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                  <Typography variant="h6" sx={{ fontWeight: 800, color: 'text.secondary', mr: 2 }}>
                    {tool.type_os}
                  </Typography>
                </Box>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    handleDownload(tool.file_name);
                  }}
                >
                  {t('securityAssessment.download')}
                </Button>
              </Box>

              <Box sx={{ display: 'flex' }}>
                <Typography variant="body2">Size:</Typography>
                <Typography variant="body2" sx={{ ml: 1, fontWeight: 500 }}>
                  {`${tool.size} MB`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SecurityAssessment;
