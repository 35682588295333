import { httpStatus } from '@/configs/Enums/httpStatus';
import useSourceService from '@/services/source';


const useSource = () => {
    const {
        sourceSafety, 
        sourceUpload,
        repoUpload,
        snippetUpload,
    } = useSourceService();
  const handleSourceSafety = async (params: {}) => {
    const res: any = await sourceSafety(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      return data;
    }
  };

  const handleSourceFile = async (params: {}) => {
    const res: any = await sourceUpload(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK || status === httpStatus.StatusCreated || status === httpStatus.StatusAccepted) {
      return data;
    }
  };

  const handleSourceCode = async (params: {}) => {
    const res: any = await snippetUpload(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK || status === httpStatus.StatusCreated || status === httpStatus.StatusAccepted) {
      return data;
    }
  };

  const handleRepo = async (params: {}) => {
    const res: any = await repoUpload(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK || status === httpStatus.StatusCreated || status === httpStatus.StatusAccepted) {
      return data;
    }
  };

  return {
    handleSourceSafety,
    handleSourceFile,
    handleSourceCode,
    handleRepo,
  }
};

export default useSource;