/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LinkIcon from '@mui/icons-material/Link';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Chip, Dialog, DialogContent, DialogTitle, Divider, Link, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { YoutubePostsTypes } from '@/types/Social';

import TitlePostsYoutube from './TitlePostsYoutube';

interface PostsYoutubeDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: YoutubePostsTypes;
}

const PostsYoutubeDetail: React.FC<PostsYoutubeDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  const components = {
    h2: (props: any) => {
      const { children } = props;
      return <p style={{ fontSize: '16px' }}>{children}</p>;
    },
    p: (props: any) => {
      const { children } = props;
      return <p style={{ fontSize: '16px' }}>{children}</p>;
    },
  };

  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <TitlePostsYoutube item={detailSelected} />
          <Box sx={{ mt: 2 }}>
            <Box
              component="img"
              src={detailSelected?.image}
              sx={{
                // height: '70px',
                borderRadius: 1,
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
              <Chip sx={{ fontSize: '1.2rem' }} icon={<VisibilityOutlinedIcon />} label={detailSelected.viewCount} />
              <Chip sx={{ ml: 1, fontSize: '1.2rem' }} icon={<ThumbUpOffAltIcon />} label={detailSelected.likeCount} />
              <Chip
                sx={{ ml: 1, fontSize: '1.2rem' }}
                icon={<FavoriteBorderIcon />}
                label={detailSelected.favoriteCount}
              />
              <Chip
                sx={{ ml: 1, fontSize: '1.2rem' }}
                icon={<CommentOutlinedIcon />}
                label={detailSelected.commentCount}
              />
            </Box>
            <Typography
              sx={{
                fontWeight: 600,
                letterSpacing: '0.01em',
                my: 2,
              }}
            >
              <HighlightTextSocial text={detailSelected?.title} keyword={topicSocial?.key_word_main} />
            </Typography>
            <ReactMarkdown components={components} linkTarget="_blank">
              {detailSelected?.desc}
            </ReactMarkdown>
          </Box>

          <Divider sx={{ mt: 3, mb: 2 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsYoutubeDetail;
