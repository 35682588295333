import { useTranslation } from 'react-i18next';

export const AppConfig = () => {
  const { t } = useTranslation();

  return {
    APP_NAME: t('identity.footer.companyName'),
    APP_SLOGAN: t('identity.footer.appSlogan'),
  };
};
