import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Box, Typography, TextField, CircularProgress } from '@mui/material';
import * as _ from 'lodash';
import Countdown from 'react-countdown';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AosComponent from '@/components/animation/AosComponent';
import { useCommonInfo } from '@/contexts/Common';
import useAuth from '@/Hooks/fetchApi/useAuth';

import EnterPasswordAfterReset from './EnterPasswordAfterReset';

interface ISendCode {
  email: string;
}
const SendCode: React.FC<ISendCode> = ({ email }) => {
  const { handleSendCode } = useAuth();
  const { apiError } = useCommonInfo();
  const { t } = useTranslation();

  const [enterPassword, setEnterPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [endTime, setEndTime] = useState(Date.now() + 60000);
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const navigateSuccess = () => {
    setEnterPassword(true);
    setLoading(false);
  };

  const validationSchema = yup.object({
    code: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (values: any) => {
    handleSendCode({ username: email, pass_code: values.code }, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  const handleResendCode = () => {
    handleSendCode({ username: email });
    setKey((prevKey) => prevKey + 1);
    setEndTime(Date.now() + 60000);
    setCountdownCompleted(false);
  };

  const Completionist = () => (
    <Button
      variant="outlined"
      type="button"
      onClick={() => {
        handleResendCode();
      }}
      sx={{
        width: 'fit-content',
        mt: 2,
        boxShadow: 'none',
        fontWeight: '400',
        height: 45,
        borderRadius: '10px',
        textTransform: 'none',
        fontSize: '1.4rem',
        color: '#02BB9F',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          color: '#8DCAFE',
        },
      }}
    >
      {t('action.resendCode')}
    </Button>
  );

  const formatNumber = (num: any) => (num < 10 ? `0${num}` : num);

  interface IRender {
    minutes: any;
    seconds: any;
    completed: any;
  }
  const renderer = ({ minutes, seconds, completed }: IRender) => {
    if (completed) {
      setCountdownCompleted(true);
      return <Completionist />;
    }
    const formattedMinutes = formatNumber(minutes);
    const formattedSeconds = formatNumber(seconds);
    return (
      <span style={{ fontWeight: 300, fontSize: '1.4rem' }}>
        {t('notify.expiredAfter')} {formattedMinutes}:{formattedSeconds}
      </span>
    );
  };

  return (
    <>
      {!enterPassword ? (
        <AosComponent dataAos="flip-left">
          <Box
            sx={{
              width: { md: '500px', xs: '100%' },
              padding: 2,
              backgroundColor: 'background.main',
              boxShadow: 10,
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 400, color: 'text.primary' }}>
              {t('authen.titleSencode')}
            </Typography>
            <Box sx={{ mt: 1, display: 'flex' }}>
              <Countdown key={key} date={endTime} renderer={renderer} />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      variant="filled"
                      fullWidth
                      style={{ marginTop: '25px' }}
                      label={t('placeholder.code')}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      onKeyDown={handleKeyDown}
                      disabled={countdownCompleted}
                      autoFocus
                      sx={{
                        '& .MuiFormLabel-root': {
                          fontSize: '1.4rem',
                        },
                      }}
                    />
                  )}
                />
              </form>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading={loading}
                loadingIndicator={<CircularProgress size={30} />}
                variant="outlined"
                color="primary"
                fullWidth
                type="button"
                onClick={handleSubmit(onSubmit)}
                disabled={countdownCompleted}
                sx={{
                  width: { xs: '100%', sm: '40%' },
                  mt: 2,
                  fontWeight: '400',
                  height: 45,
                  textTransform: 'none',
                  fontSize: '1.4rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('action.sendCode')}
              </LoadingButton>
            </Box>
          </Box>
        </AosComponent>
      ) : (
        <EnterPasswordAfterReset email={email} />
      )}
    </>
  );
};
export default SendCode;
