import { useState } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Badge, Box, Button, Popover, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAuth from '@/Hooks/fetchApi/useAuth';
import Profile from '@/pages/account/Profile';
import { decrypt } from '@/utils/crypto';

import LanguageOption from './Language';
import ThemeOption from './Theme';
import { FormatTimeExpire } from '../common/format/FormatTimeExpire';
import WelcomeByTime from '../common/format/WelcomeByTime';

const ProfileHeader = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { logout, logoutGoogle } = useAuth();

  const serviceToken = localStorage.getItem('serviceToken');
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [openModalUser, setOpenModalUser] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModalUser = () => {
    setOpenModalUser(false);
  };
  const logoutGmail = () => {
    logoutGoogle();
    logout();
  };

  const getBackgroundColorBadge = (expireDate: string, role: string) => {
    if (FormatTimeExpire(expireDate) > 0 || role === 'super admin') {
      return '#44b700';
    }
    return '#ff0008';
  };

  const StyledBadge = styled(Badge)(({}) => ({
    '& .MuiBadge-badge': {
      backgroundColor: getBackgroundColorBadge(inforUser?.expireDate, inforUser?.role),
      color: getBackgroundColorBadge(inforUser?.expireDate, inforUser?.role),
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <>
      <>
        <Button
          sx={{
            display: 'flex',
            mr: { xs: 0, sm: 3 },
            // padding: '0px 15px',
          }}
          disableFocusRipple={true}
          disableRipple={true}
          aria-describedby={id}
          onClick={handleClick}
        >
          {serviceToken ? (
            <Box sx={{ alignContent: 'center', marginRight: { xs: 2, sm: 3 } }}>
              <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: '50%',
                    bgcolor: '#0099ff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ color: 'common.white', fontSize: '1.6rem', fontWeight: '600' }}>
                    {inforUser?.username?.charAt(0) || ''}
                  </Typography>
                </Box>
              </StyledBadge>
            </Box>
          ) : (
            <Box sx={{ alignContent: 'center', marginRight: { xs: 0, sm: 2 } }}>
              <Button
                onClick={() => {
                  navigate('/login');
                }}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontSize: '1.4rem',
                  border: '1px solid divider',
                  color: 'primary.light',
                  fontWeight: 400,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    color: 'text.primary',
                    boxShadow: 6,
                  },
                }}
              >
                {t('action.login')}
              </Button>
            </Box>
          )}
          <Box sx={{ width: '25px', height: '25px', color: 'text.secondary' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
              />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </Box>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: 2, width: '350px' }}>
            {serviceToken && (
              <Box sx={{ mt: 1, mb: 2, borderBottom: `1px solid ${theme.palette.divider}`, paddingBottom: '15px' }}>
                <WelcomeByTime />

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>
                    {inforUser?.username}
                    <Box component="span" sx={{ ml: 1, fontWeight: 'normal', textTransform: 'capitalize' }}>
                      {`(${inforUser?.role})`}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
            <Box sx={{ paddingBottom: '15px' }}>
              <Box sx={{ borderBottom: '1px solid divider' }}>
                <ThemeOption />
              </Box>
              <Box sx={{ mt: 2 }}>
                <LanguageOption />
              </Box>
            </Box>
            {serviceToken && (
              <Box sx={{ mt: '12px', borderTop: `1px solid ${theme.palette.divider}` }}>
                <Box
                  onClick={() => {
                    setOpenModalUser(true);
                    handleClose();
                  }}
                  sx={{
                    mt: '15px',
                    display: 'flex',
                    p: '8px 5px',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'primary.dark',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    },
                  }}
                >
                  <PermIdentityOutlinedIcon sx={{ fontSize: '2rem' }} />
                  <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'fontWeightMedium' }}>
                    {t('home.profile.profile')}
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    navigate('/management/asm');
                    handleClose();
                  }}
                  sx={{
                    display: 'flex',
                    p: '8px 5px',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'primary.dark',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    },
                  }}
                >
                  <SettingsOutlinedIcon sx={{ fontSize: '2rem' }} />
                  <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'fontWeightMedium' }}>
                    {t('home.profile.management')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    p: '8px 5px',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'error.light',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    },
                  }}
                  onClick={() => {
                    handleClose();
                    logoutGmail();
                  }}
                >
                  <LogoutIcon sx={{ fontSize: '2rem' }} />
                  <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'fontWeightMedium' }}>
                    {t('action.logout')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Popover>
      </>

      <Profile openModal={openModalUser} handleCloseModal={handleCloseModalUser} />
    </>
  );
};
export default ProfileHeader;
