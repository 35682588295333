import { Typography, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const TooltipCustom = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 1,
    fontSize: 11,
    maxWidth: '800px',
  },
}));
interface ILightTooltip {
  title: string;
  text: any;
}
const LightTooltip: React.FC<ILightTooltip> = ({ title, text }) => {
  return (
    <TooltipCustom title={title} arrow>
      <Typography
        sx={{
          color: 'text.secondary',
          ml: 1,
          cursor: 'pointer',
          fontSize: { xs: '1.2rem', sm: '1.4rem' },
          '&:hover': {
            color: 'text.secondary',
          },
          transition: ' color 0.4s ease',
          alignSelf: 'center',
        }}
      >
        {text}
      </Typography>
    </TooltipCustom>
  );
};
export default LightTooltip;
