import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import TelegramIcon from '@mui/icons-material/Telegram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { decrypt } from '@/utils/crypto';

import ItemSideBar from './ItemSideBar';

const SideBar = () => {
  const { t } = useTranslation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  return (
    <>
      <ItemSideBar icon={SecurityIcon} text="ASM" value="/management/asm" />
      {inforUser.role === 'super admin' && (
        <>
          <ItemSideBar icon={NewspaperOutlinedIcon} text={t('home.label.news.news')} value="/management/news/list" />
          <ItemSideBar icon={TravelExploreIcon} text={t('management.identity.identity')} value="/management/identity" />
          <ItemSideBar icon={TelegramIcon} text="Session Telegram" value="/management/session-telegram" />
          <ItemSideBar icon={VerifiedUserIcon} text={t('management.2fa.2fa')} value="/management/two-factor" />
          <ItemSideBar icon={SchoolIcon} text={t('management.training.training')} value="/management/training" />
        </>
      )}
    </>
  );
};
export default SideBar;
