import { Box, Typography } from '@mui/material';

import { ScanUrlTypes } from '@/types/Osint';

interface UrlscanProps {
  listUrlscan: ScanUrlTypes;
}

const Indicators: React.FC<UrlscanProps> = ({ listUrlscan }) => {
  return (
    <>
      <Box sx={{ pb: 3, mt: 3 }}>
        <Typography variant="h6">Indicators</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          This is a term in the security industry to describe indicators such as IPs, Domains, Hashes, etc. This does
          not imply that any of these indicate malicious activity.
        </Typography>

        <Box sx={{ mt: 2, backgroundColor: 'background.menu', padding: 2 }}>
          {listUrlscan?.lists?.domains.map((item: string, index: number) => (
            <Typography key={index} variant="body2" sx={{ mt: 1 }}>
              {item}
            </Typography>
          ))}
          {listUrlscan?.lists?.ips.map((item: string, index: number) => (
            <Typography key={index} variant="body2" sx={{ mt: 1 }}>
              {item}
            </Typography>
          ))}
          {listUrlscan?.lists?.hashes.map((item: string, index: number) => (
            <Typography key={index} variant="body2" sx={{ mt: 1 }}>
              {item}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default Indicators;
