import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

interface BarChartProps {
  data: { [key: string]: string | number }[];
  xAxisKey: string;
  yAxisKey: string;
  colorBar: string;
}

interface Payload {
  [key: string]: string | number;
}

interface TooltipProps {
  active?: boolean;
  payload?: { payload: Payload }[];
  xAxisKeyTooltip: string;
  yAxisKeyTooltip: string;
}

const BarChartSocial: React.FC<BarChartProps> = ({ data, xAxisKey, yAxisKey, colorBar }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, xAxisKeyTooltip, yAxisKeyTooltip }) => {
    if (active && payload && payload.length) {
      const xValue = payload[0].payload[xAxisKeyTooltip];
      const yValue = payload[0].payload[yAxisKeyTooltip];
      return (
        <div
          style={{
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {xValue}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('smcc.overview.post')}: {yValue}
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisKey}
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -1, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomTooltip xAxisKeyTooltip={xAxisKey} yAxisKeyTooltip={yAxisKey} />}
          />

          <Bar yAxisId="linearAxis" type="monotone" dataKey={yAxisKey} fill={colorBar} barSize={35} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default BarChartSocial;
