import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useOsintService = () => {
  const { methodGet } = useRequest();

  const getListOsint = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.osint.listOsint, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getListOsint,
  };
};

export default useOsintService;
