import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormatTimeNews from '@/components/common/format/FormatTimeNews';
import LightTooltip from '@/components/common/LightTooltip';

interface IScanTime {
  updateTime: string;
}
const ScanTime: React.FC<IScanTime> = ({ updateTime }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        sx={{
          alignSelf: 'center',
          color: 'text.secondary',
          fontSize: { xs: '1.2rem', md: '1.4rem' },
        }}
      >
        {t('notify.lastUpdate')}
      </Typography>
      <LightTooltip title={updateTime} text={<FormatTimeNews publishDate={updateTime} />} />
    </Box>
  );
};
export default ScanTime;
