import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface LineChartMentionsByTimeProps {
  data: { [key: string]: string | number }[];
}

const LineChartMentionsByTime: React.FC<LineChartMentionsByTimeProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formatDate = (tick: string) => {
    const [day, month, year] = tick.split('/');
    return `${day}/${month}`;
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} margin={{ left: 0, right: 20, top: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            tickFormatter={formatDate}
            tick={{ fontSize: 14 }}
            label={{ value: '', position: 'insideBottom', offset: -2, textAnchor: 'middle', fontSize: 14 }}
          />
          <YAxis
            yAxisId="linearAxis"
            tick={{ fontSize: 14 }}
            label={{
              value: t('smcc.overview.quantity'),
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 14,
            }}
          />

          <Tooltip
            contentStyle={{ backgroundColor: theme.palette.background.paper }}
            itemStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <Legend wrapperStyle={{ fontSize: 14 }} />
          <Line
            yAxisId="linearAxis"
            type="monotone"
            dataKey="quantity"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={t('smcc.overview.mentionsByTime')}
          />
          <Line
            yAxisId="linearAxis"
            type="monotone"
            dataKey="sum"
            stroke="#26eb8f"
            activeDot={{ r: 8 }}
            name={t('smcc.overview.totalMentions')}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default LineChartMentionsByTime;
