import { Fragment } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme, Box, AppBar, Typography, Link as LinkMui, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import ProfileHeader from '@/components/Header/ProfileHeader';
import { MenuHomeHeader } from '@/configs/MenuHomeHeader';
import useBreakpoints from '@/helpers/useBreakpoints';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS_WHITE, LOGO_ESS } from '@/utils/imgBase64';

import Weather from '../../components/Header/Weather';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;
  const { menuItemHeader, news, services } = MenuHomeHeader();
  const { isLargeDesktop, isUltraWide } = useBreakpoints();

  const MenuHeader = styled(Box)(() => ({
    color: theme.palette.text.primary,
    textTransform: 'none',
    padding: isLargeDesktop || isUltraWide ? 18 : '10px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    transition: 'color 0.3s ease',
    fontWeight: '400',
  }));

  return (
    <>
      <AppBar
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '80px',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'background.dark',
        }}
      >
        <Grid container>
          <Grid item md={5} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            {menuItemHeader.map((item: any, indexMenu: number) => (
              <Fragment key={indexMenu}>
                {item.label === t('home.label.home') && (
                  <Link to={item.path} style={{ textDecoration: 'none' }}>
                    <MenuHeader
                      onClick={() => {
                        if (location.pathname === item.path) {
                          window.location.reload();
                        }
                      }}
                      sx={{
                        color: location.pathname === item.path ? '#03C9D7' : 'inital',
                        fontSize: { md: '1.6rem', lg: '1.8rem' },
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                      }}
                    >
                      {item.label}
                    </MenuHeader>
                  </Link>
                )}
                {item.label !== t('home.label.services.services') &&
                  item.label !== t('home.label.news.news') &&
                  item.label !== t('home.label.home') && (
                    <Link to={item.path} style={{ textDecoration: 'none' }}>
                      <MenuHeader
                        onClick={() => {
                          if (location.pathname === item.path) {
                            window.location.reload();
                          }
                        }}
                        sx={{
                          color: location.pathname.includes(item.path) && item.path !== '/' ? '#03C9D7' : 'inital',
                          fontSize: { md: '1.6rem', lg: '1.8rem' },
                          whiteSpace: 'nowrap',
                          fontWeight: 500,
                        }}
                      >
                        {item.label}
                      </MenuHeader>
                    </Link>
                  )}
                {item.label === t('home.label.services.services') && (
                  <MenuHeader
                    sx={{
                      '&:hover .service, &:focus-within .service': {
                        transform: 'translate(0, 20px)',
                        opacity: 1,
                        visibility: 'visible',
                      },
                      whiteSpace: 'nowrap',
                      fontSize: { md: '1.6rem', lg: '1.8rem' },
                      fontWeight: 500,
                    }}
                  >
                    {t('home.label.services.services')} <ArrowDropDownIcon sx={{ verticalAlign: 'sub' }} />
                    <Box
                      className="service"
                      sx={{
                        position: 'absolute',
                        top: '55px',
                        opacity: 0,
                        visibility: 'hidden',
                        transition: '0.4s ease',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'background.main',
                          borderRadius: 1,
                          padding: 1,
                          pb: 1.5,
                          justifyContent: 'space-around`',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                          maxWidth: '600px',
                        }}
                      >
                        <Grid container>
                          {services.map((service, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Box
                                onClick={() => {
                                  if (service?.src) {
                                    window.open(service.src, '_blank');
                                  } else {
                                    navigate('/');
                                  }
                                }}
                                sx={{
                                  alignItems: 'center',
                                  color: 'text.primary',
                                  transition: 'all 0.5s ease',
                                  '&:hover': {
                                    color: theme.palette.primary.dark,
                                    paddingLeft: '10px',
                                    borderLeft: '3px solid #3ca0e7',
                                    transition: 'all 0.3s ease',
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    padding: 1,
                                    fontSize: '1.4rem',
                                    mt: 0.5,
                                    letterSpacing: 0.8,
                                    whiteSpace: 'nowrap',
                                    fontWeight: 500,
                                  }}
                                >
                                  {service.label}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </MenuHeader>
                )}
                {item.label === t('home.label.news.news') && (
                  <MenuHeader
                    sx={{
                      color: location.pathname.includes('/news/') && item.path !== '/' ? '#03C9D7' : 'inital',
                      whiteSpace: 'nowrap',
                      fontSize: { md: '1.6rem', lg: '1.8rem' },
                      fontWeight: 500,
                      '&:hover .news, &:focus-within .news': {
                        transform: 'translate(0, 20px)',
                        opacity: 1,
                        visibility: 'visible',
                      },
                    }}
                  >
                    {t('home.label.news.news')} <ArrowDropDownIcon sx={{ verticalAlign: 'sub' }} />
                    <Box
                      className="news"
                      sx={{
                        position: 'absolute',
                        top: '55px',
                        opacity: 0,
                        visibility: 'hidden',
                        transition: '0.4s ease',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'background.main',
                          borderRadius: 1,
                          padding: 1,
                          pb: 1.5,
                          justifyContent: 'space-around`',
                          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                        }}
                      >
                        {news.map((newsItem, index) => (
                          <Box
                            key={index}
                            onClick={() => {
                              if (newsItem?.src) {
                                location.pathname.includes(newsItem?.src)
                                  ? window.location.reload()
                                  : navigate(newsItem.src);
                              } else {
                                navigate('/');
                              }
                            }}
                            sx={{
                              alignItems: 'center',
                              color: location.pathname.includes(newsItem.src) ? 'primary.dark' : 'text.primary',
                              borderLeft: location.pathname.includes(newsItem.src) ? '3px solid #3ca0e7' : 'none',
                              transition: 'all 0.5s ease',
                              '&:hover': {
                                color: theme.palette.primary.dark,
                                paddingLeft: '10px',
                                borderLeft: '3px solid #3ca0e7',
                                transition: 'all 0.3s ease',
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                padding: 1,
                                fontSize: '1.4rem',
                                mt: 0.5,
                                letterSpacing: 0.8,
                                whiteSpace: 'nowrap',
                                fontWeight: 500,
                              }}
                            >
                              {newsItem.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </MenuHeader>
                )}
              </Fragment>
            ))}
          </Grid>
          <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
            <LinkMui href="/" rel="noopener" underline="none">
              <Box
                component="img"
                src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
                sx={{
                  width: { md: '120px', lg: '140px' },
                  justifyContent: 'center',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </LinkMui>
          </Grid>
          <Grid item md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Weather />
            <ProfileHeader />
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};
export default Header;
