import { Button, alpha, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ButtonCustom = ({ color, bgColor, text }: { color: string; bgColor: string; text: string }) => {
  return (
    <Button
      variant="contained"
      sx={{
        color,
        backgroundColor: bgColor,
        textTransform: 'capitalize',
        padding: '6px 10px',
        fontSize: '1.2rem',
        fontWeight: 600,
        ':hover': {
          backgroundColor: bgColor,
        },
      }}
    >
      {text}
    </Button>
  );
};

export const DisplaySentiment = ({ sentiment }: { sentiment: string }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  switch (sentiment) {
    case 'negative':
      return (
        <ButtonCustom
          color="error.main"
          bgColor={alpha(theme.palette.error.main, 0.3)}
          text={t('smcc.status.negative')}
        />
      );

    case 'positive':
      return (
        <ButtonCustom
          color="success.main"
          bgColor={alpha(theme.palette.success.main, 0.3)}
          text={t('smcc.status.positive')}
        />
      );

    case 'neutral':
      return (
        <ButtonCustom
          color="warning.main"
          bgColor={alpha(theme.palette.warning.main, 0.3)}
          text={t('smcc.status.neutral')}
        />
      );

    default:
      return null;
  }
};
