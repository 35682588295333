import { httpStatus } from '@/configs/Enums/httpStatus';
import useIdentityService from '@/services/identity';

import useLoadingGif from '../common/useLoadingGif';

const useIdentity = () => {
  const { getIdentityData } = useIdentityService();
  const { setLoadingGif } = useLoadingGif();

  const handleGetIdentityData = async (body: any) => {
    setLoadingGif(true);
    const res: any = await getIdentityData(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  return {
    handleGetIdentityData,
  };
};

export default useIdentity;
